import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CvsIdleBarModule } from '@cvs/components/idle-bar/idle-bar.module';
import { FooterModule } from 'src/app/layout/common/footer/footer.module';
import { LanguagesModule } from 'src/app/layout/common/languages/languages.module';
import { MessagesModule } from 'src/app/layout/common/messages/messages.module';
import { NotificationsModule } from 'src/app/layout/common/notifications/notifications.module';
import { QuickChatModule } from 'src/app/layout/common/quick-chat/quick-chat.module';
import { SearchModule } from 'src/app/layout/common/search/search.module';
import { ShortcutsModule } from 'src/app/layout/common/shortcuts/shortcuts.module';
import { UserModule } from 'src/app/layout/common/user/user.module';
import { FuturisticLayoutComponent } from 'src/app/layout/layouts/vertical/futuristic/futuristic.component';
import { CvsSharedModule, MatSharedModule } from 'src/app/shared';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
    declarations: [FuturisticLayoutComponent],
    imports: [
        HttpClientModule,
        RouterModule,
        MatSharedModule,
        CvsSharedModule,
        CvsIdleBarModule,
        LanguagesModule,
        FooterModule,
        MessagesModule,
        NotificationsModule,
        QuickChatModule,
        SearchModule,
        ShortcutsModule,
        UserModule,
        SharedModule
    ],
    exports: [FuturisticLayoutComponent]
})
export class FuturisticLayoutModule {}
