export class Paginate {
    private totalPages: number;
    private data: any[];
    private currentPage: number;
    private rowsPerPage: number;
    constructor(data: any[], rowsPerPage = 10) {
        this.totalPages = Math.ceil(data.length / rowsPerPage);
        this.rowsPerPage = rowsPerPage || 10;
        this.data = data;
    }
    page(pageNum: number): any {
        if (pageNum < 1) pageNum = 1;
        if (pageNum > this.totalPages) pageNum = this.totalPages;

        this.currentPage = pageNum;

        const start = this.offset(),
            end = start + this.rowsPerPage;

        return {
            items: this.data.slice(start, end),
            metadata: {
                'pagination': {
                    // hasPreviousPage?: boolean;
                    // hasNextPage?: boolean;
                    // isFirstPage?: boolean;
                    // isLastPage?: boolean;
                    // firstItemOnPage?: number;
                    // lastItemOnPage?: number;
                    //'offset': start,
                    'pageSize': this.rowsPerPage,
                    //'previousOffset': Math.max((this.currentPage - 2) * this.rowsPerPage, 0),
                    //'nextOffset': end,
                    'page': this.currentPage,
                    //'pageCount': this.totalPages,
                    'totalItemCount': this.data.length
                }
            }
        };
    }
    private offset(): number {
        return (this.currentPage - 1) * this.rowsPerPage;
    }
}
