import { NgxPermissionsService } from 'ngx-permissions';
import { tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { LoggedUserService } from '@common/services/logged-user';

@Injectable({
    providedIn: 'root'
})
export class StartupService {
    constructor(
        private loggedUserService: LoggedUserService,
        private permissionService: NgxPermissionsService
    ) {}

    /**
     * Load the application only after get the menu or other essential information
     * such as permissions and roles.
     */
    load() {
        return new Promise<void>((resolve, _reject) => {
            this.loggedUserService
                .getPermissions$()
                .pipe(
                    tap((permissions) => {
                        this.permissionService.loadPermissions(permissions);
                    })
                )
                .subscribe({
                    next: () => resolve(),
                    error: () => resolve()
                });
        });
    }
}
