import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CvsAssetsSourcePipeModule } from '@cvs/pipes';
import { TranslocoModule } from '@ngneat/transloco';

import { FooterComponent } from './footer.component';

@NgModule({
    declarations: [FooterComponent],
    imports: [CommonModule, TranslocoModule, CvsAssetsSourcePipeModule],
    exports: [FooterComponent]
})
export class FooterModule {}
