import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PageHeaderModule } from '@common/components/page-header';
import { CvsGridModule } from '@cvs/components/grid';
import { NotificationsComponent } from 'src/app/layout/common/notifications/notifications.component';
import { MatSharedModule } from 'src/app/shared';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
    declarations: [NotificationsComponent],
    imports: [RouterModule,
        OverlayModule,
        PortalModule,
        MatSharedModule,
        SharedModule,
        CvsGridModule,
        PageHeaderModule,],
    exports: [NotificationsComponent]
})
export class NotificationsModule { }
