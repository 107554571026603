import { SettingsModule } from 'src/app/layout/common/settings/settings.module';
import { LayoutComponent } from 'src/app/layout/layout.component';
import { EmptyLayoutModule } from 'src/app/layout/layouts/empty/empty.module';
import { CenteredLayoutModule } from 'src/app/layout/layouts/horizontal/centered/centered.module';
import { EnterpriseLayoutModule } from 'src/app/layout/layouts/horizontal/enterprise/enterprise.module';
import { MaterialLayoutModule } from 'src/app/layout/layouts/horizontal/material/material.module';
import { ModernLayoutModule } from 'src/app/layout/layouts/horizontal/modern/modern.module';
import { ClassicLayoutModule } from 'src/app/layout/layouts/vertical/classic/classic.module';
import { ClassyLayoutModule } from 'src/app/layout/layouts/vertical/classy/classy.module';
import { CompactLayoutModule } from 'src/app/layout/layouts/vertical/compact/compact.module';
import { DenseLayoutModule } from 'src/app/layout/layouts/vertical/dense/dense.module';
import { FuturisticLayoutModule } from 'src/app/layout/layouts/vertical/futuristic/futuristic.module';
import { ThinLayoutModule } from 'src/app/layout/layouts/vertical/thin/thin.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { NgModule } from '@angular/core';

import { CentvisLayoutModule } from './layouts/horizontal/centvis/centvis.module';
import { NoAuthLayoutModule } from './layouts/no-auth/no-auth.module';
import { Centvis1LayoutModule } from './layouts/vertical/centvis1/centvis1.module';
import { ExpoLayoutModule } from './layouts/vertical/expo/expo.module';

const layoutModules = [
    // Empty
    EmptyLayoutModule,

    // Horizontal navigation
    CenteredLayoutModule,
    EnterpriseLayoutModule,
    CentvisLayoutModule,
    MaterialLayoutModule,
    ModernLayoutModule,

    // Vertical navigation
    ClassicLayoutModule,
    ClassyLayoutModule,
    CompactLayoutModule,
    DenseLayoutModule,
    FuturisticLayoutModule,
    ThinLayoutModule,
    ExpoLayoutModule,
    Centvis1LayoutModule,
    // No-Auth
    NoAuthLayoutModule
];

@NgModule({
    declarations: [LayoutComponent],
    imports: [SharedModule, SettingsModule, ...layoutModules],
    exports: [LayoutComponent, ...layoutModules]
})
export class LayoutModule {}
