import { BehaviorSubject } from 'rxjs';

import { Component, InjectionToken, Type } from '@angular/core';

import { CommonLayoutComponentOutlet } from './common-layout.model';
import { EmptyActionComponent } from './empty-component';

export const COMMON_LAYOUT_HEADER_ACTIONS = new InjectionToken<BehaviorSubject<CommonLayoutComponentOutlet>>(
    'COMMON_LAYOUT_HEADER_ACTIONS',
    {
        providedIn: 'root',
        factory: () =>
            new BehaviorSubject<CommonLayoutComponentOutlet>({
                outlet: EmptyActionComponent as unknown as Type<Component>
            })
    }
);
