import { animate, AUTO_STYLE, keyframes, style, transition, trigger } from '@angular/animations';

// -----------------------------------------------------------------------------------------------------
// @ Shake
// -----------------------------------------------------------------------------------------------------
const swing = trigger('swing', [
    // Prevent the transition if the state is false
    transition('void => false', []),

    // Transition
    transition(
        'void => *, * => true',
        [
            animate(
                '{{duration}}ms {{delay}}ms',
                keyframes([
                    style({ 'transform-origin': 'top center', offset: 0 }),
                    style({ visibility: AUTO_STYLE, transform: 'rotate3d(0, 0, 1, 0deg)', easing: 'ease', offset: 0 }),
                    style({ transform: 'rotate3d(0, 0, 1, 15deg)', easing: 'ease', offset: 0.2 }),
                    style({ transform: 'rotate3d(0, 0, 1, -10deg)', easing: 'ease', offset: 0.4 }),
                    style({ transform: 'rotate3d(0, 0, 1, 5deg)', easing: 'ease', offset: 0.6 }),
                    style({ transform: 'rotate3d(0, 0, 1, -5deg)', easing: 'ease', offset: 0.8 }),
                    style({ transform: 'rotate3d(0, 0, 1, 0deg)', easing: 'ease', offset: 1 })
                ])
            )
        ],
        {
            params: {
                delay: 0,
                duration: 1500
            }
        }
    )
]);

export { swing };
