import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CvsIdleBarComponent } from './idle-bar.component';

@NgModule({
    declarations: [CvsIdleBarComponent],
    imports: [CommonModule],
    exports: [CvsIdleBarComponent]
})
export class CvsIdleBarModule {}
