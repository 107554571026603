import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { API_ENDPOINTS } from 'src/app/api-endpoints.constants';
import {
    Chat,
    Message,
    MessageDeliveryRequest,
    SendMessageRequest,
    UserTypingRequest,
    UserTypingResponse
} from 'src/app/layout/common/quick-chat/quick-chat.types';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@cvs/auth';
import { EnvironmentService, LogService } from '@cvs/services';
import { StringFormatUtils } from '@cvs/utils';
import { HubConnectionBuilder } from '@microsoft/signalr';

@Injectable({
    providedIn: 'root'
})
export class QuickChatService {
    private _chat: BehaviorSubject<Chat> = new BehaviorSubject(null);
    private _chats: BehaviorSubject<Chat[]> = new BehaviorSubject<Chat[]>(null);
    private _message: BehaviorSubject<Message> = new BehaviorSubject(null);
    private _messageUpdated: BehaviorSubject<Message> = new BehaviorSubject(null);
    private _userTyping: BehaviorSubject<UserTypingResponse> = new BehaviorSubject(null);
    private hubConnection: any;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private environmentService: EnvironmentService,
        private authService: OAuthService,
        private loggedInUserService: AuthService,
        private logService: LogService
    ) {
        // this.init();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for chat
     */
    get chat$(): Observable<Chat> {
        return this._chat.asObservable();
    }

    /**
     * Getter for chat
     */
    get chats$(): Observable<Chat[]> {
        return this._chats.asObservable();
    }

    get message$(): Observable<Message> {
        return this._message.asObservable();
    }

    get messageUpdate$(): Observable<Message> {
        return this._messageUpdated.asObservable();
    }

    get userTyping$(): Observable<UserTypingResponse> {
        return this._userTyping.asObservable();
    }

    /**
     * Get chat
     *
     * @param id
     */
    public getChatById(id: string): Observable<any> {
        const configKey = API_ENDPOINTS.chatApi.baseUrlKey;
        const endpoints = API_ENDPOINTS.chatApi.conversations.getMessages;
        const messagesUrl = this.environmentService.getApiUrl(configKey, endpoints);
        const url = StringFormatUtils.formatStringWithNumericPlaceholders(messagesUrl, id);
        return this._httpClient.get<Chat>(url).pipe(
            map((chat) => {
                chat.messages = chat.messages.map((x) => {
                    x.isMine = x.senderId === this.loggedInUserService.loggedUser.getUser().externalId;
                    return x;
                });
                // Update the chat
                this._chat.next(chat);

                // Return the chat
                return chat;
            })
        );
        // switchMap((chat) => {
        //     if (!chat) {
        //         return throwError(() => 'Could not found chat with id of ' + id + '!');
        //     }

        //     return of(chat);
        // })
        //);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get chats
     */
    public getChats(): Observable<any> {
        const configKey = API_ENDPOINTS.chatApi.baseUrlKey;
        const endpoints = API_ENDPOINTS.chatApi.conversations.getAll;
        const url = this.environmentService.getApiUrl(configKey, endpoints);
        return this._httpClient.get<Chat[]>(url).pipe(
            tap((response: Chat[]) => {
                this._chats.next(response);
            })
        );
    }

    public init() {
        return new Promise((resolve, reject) => {
            const configKey = API_ENDPOINTS.chatApi.baseUrlKey;
            const endpoints = API_ENDPOINTS.chatApi.chatHub.hub;
            const url = this.environmentService.getApiUrl(configKey, endpoints);
            this.hubConnection = new HubConnectionBuilder()
                .withUrl(url, {
                    accessTokenFactory: () => this.authService.getAccessToken()
                })
                .withAutomaticReconnect()
                .build();

            this.hubConnection
                .start()
                .then(() => {
                    return resolve(true);
                })
                .catch((err: any) => {
                    reject(err);
                });

            this.hubConnection.on('ReceiveMessage', (newMessage: Message): any => {
                const startDate = new Date();
                this.logService.log(
                    'End of Receive message ' + startDate.getSeconds() + ':' + startDate.getMilliseconds()
                );

                const message = this.transformMessage(newMessage);
                if (message) {
                    this._message.next(message);
                }
                const endDate = new Date();
                this.logService.log('End of Receive message ' + endDate.getSeconds() + ':' + endDate.getMilliseconds());
            });

            this.hubConnection.on('OnUserTyping', (response: UserTypingResponse): void => {
                this._userTyping.next(response);
            });

            this.hubConnection.on('OnMessageRead', (response: Message): void => {
                const message = this.transformMessage(response);
                if (message) {
                    this._messageUpdated.next(message);
                }
            });

            this.hubConnection.on('OnMessageDelivered', (response: Message): void => {
                const message = this.transformMessage(response);
                if (message) {
                    this._messageUpdated.next(message);
                }
            });
        });
    }

    public sendMessage(request: SendMessageRequest) {
        try {
            this.hubConnection.invoke('SendMessage', request);
        } catch (err) {
            this.logService.error(err);
        }
    }

    public sendMessageDelivery(request: MessageDeliveryRequest) {
        try {
            this.hubConnection.invoke('UpdateMessageDelivery', request);
        } catch (err) {
            this.logService.error(err);
        }
    }

    public sendMessageReadReceipt(request: MessageDeliveryRequest) {
        try {
            this.hubConnection.invoke('UpdateMessageReadReceipt', request);
        } catch (err) {
            this.logService.error(err);
        }
    }

    public sendUserTyping(request: UserTypingRequest) {
        try {
            this.hubConnection.invoke('UpdateUserTyping', request);
        } catch (err) {
            this.logService.error(err);
        }
    }

    private transformMessage(message: Message): Message {
        if (message) {
            message.isMine = message.senderId === this.loggedInUserService.loggedUser.getUser().externalId;
        }

        return message;
    }
}
