import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SessionService } from '@cvs/services/session.service';
import { fuseAnimations } from '@fuse/animations';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'cvs-idle-bar',
    templateUrl: './idle-bar.component.html',
    styleUrls: ['./idle-bar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    exportAs: 'cvsIdleBar',
    animations: fuseAnimations
})
export class CvsIdleBarComponent implements OnInit {
    public showIdleMessage: boolean;
    public lastIdleMessage: number = -1;
    public countdown?: number = null;
    public countdownMapping: any = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '=1': '# second',
        // prettier-ignore
        'other': '# seconds'
    };
    /**
     * Constructor
     */
    constructor(
        private readonly sessionService: SessionService,
        private readonly cd: ChangeDetectorRef
    ) {}
    ngOnInit(): void {
        this.sessionService.sessionIdle$.pipe(untilDestroyed(this)).subscribe((r) => {
            if (r) {
                this.showIdleMessage = true;
                if (r.countdown > 0) {
                    this.countdown = r.countdown;
                    if (this.countdown != r.countdown) {
                        this.cd.detectChanges();
                    }
                }
                if (this.lastIdleMessage != 1) {
                    this.lastIdleMessage = 1;
                    this.cd.detectChanges();
                }
            } else {
                this.showIdleMessage = false;
                if (this.lastIdleMessage != 0) {
                    this.lastIdleMessage = 0;
                    this.cd.detectChanges();
                }
                this.lastIdleMessage = 0;
            }
        });
    }
}
