import { BehaviorSubject, filter } from 'rxjs';

import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { RoutePartsService } from '@cvs/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class LayoutService {
    public readonly hasNewIndicator$ = new BehaviorSubject(false);
    public readonly title$ = new BehaviorSubject('');

    constructor(
        private router: Router,
        private routePartsService: RoutePartsService,
        private titleService: Title
    ) {
        this.observeRouteChanges();
    }

    public emitTitleFromRoute(): void {
        const breadcrumbs = this.routePartsService.routeParts;
        if (breadcrumbs.length > 0) {
            this.title$.next(breadcrumbs[breadcrumbs.length - 1].title || '');
            this.hasNewIndicator$.next(breadcrumbs.some((breadcrumb) => breadcrumb.isNew));
        }
    }

    /**
     * Sets page title manually.
     * Will be valid until next page navigation.
     */
    public setTitle(title: string): void {
        this.title$.next(title);
        this.titleService.setTitle(`${title} | Incident Management`);
    }

    private observeRouteChanges(): void {
        this.emitTitleFromRoute();
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.emitTitleFromRoute();
            });
    }
}
