import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TenantService } from '@cvs/services';
import { environment } from '@environment';


@Pipe({
    name: 'assetsSource'
})
export class CvsAssetsSourcePipe implements PipeTransform {
    // This will be the url to our assets server.
    // Ex: https://our-assets.com/assets
    private assetsURL: string;
    private subDomain: string;

    constructor(tenantService: TenantService) {
        this.subDomain = environment.apiRoutes.assets.host;
        this.assetsURL = tenantService.getTenantName();
    }

    public transform(imgName: string): string {
        return `${this.subDomain}/${this.assetsURL}/${imgName}`;
    }
}
@NgModule({
    declarations: [CvsAssetsSourcePipe],
    exports: [CvsAssetsSourcePipe]
})
export class CvsAssetsSourcePipeModule {}
