import { NgModule } from '@angular/core';
import { CvsAutocompleteModule } from '@cvs/components/auto-complete';
import { CvsNgTemplateModule } from '@cvs/components/cvs-ng-template.module';
import { CvsDialogsModule } from '@cvs/components/dialogs';
import { CvsGridModule } from '@cvs/components/grid';
import { CvsControlNameIdDirectiveModule } from '@cvs/directives/control-name';
import { CvsErrorFocusModule } from '@cvs/directives/error-focus/error-focus.module';
import { CvsKeypressModule } from '@cvs/directives/keypress';
import { CvsMatSelectBindingModule } from '@cvs/directives/mat-select';
import { CvsCompareWithPipeModule, CvsTruncatePipeModule, TrackByPipeModule } from '@cvs/pipes';
import { FuseAlertModule } from '@fuse/components/alert';
import { FuseAutoCompleteModule } from '@fuse/components/auto-complete';
import { FuseCardModule } from '@fuse/components/card';
import { FuseDrawerModule } from '@fuse/components/drawer';
import { FuseFullscreenModule } from '@fuse/components/fullscreen';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { FuseScrollResetModule } from '@fuse/directives/scroll-reset';
import { FuseScrollbarModule } from '@fuse/directives/scrollbar';
import { FuseFindByKeyPipeModule } from '@fuse/pipes/find-by-key';
import { FuseConfirmationModule } from '@fuse/services/confirmation';
import { FuseLoadingModule } from '@fuse/services/loading';
import { FuseMediaWatcherModule } from '@fuse/services/media-watcher';
import { FusePlatformModule } from '@fuse/services/platform/platform.module';
import { FuseSplashScreenModule } from '@fuse/services/splash-screen';
import { FuseUtilsModule } from '@fuse/services/utils';

const modules = [
    // FUSE
    FuseAlertModule,
    FuseCardModule,
    FuseFindByKeyPipeModule,
    FuseAutoCompleteModule,
    FuseDrawerModule,
    FuseScrollbarModule,
    FuseConfirmationModule,
    FuseLoadingModule,
    FuseMediaWatcherModule,
    FusePlatformModule,
    FuseSplashScreenModule,
    FuseUtilsModule,
    FuseLoadingBarModule,
    FuseNavigationModule,
    FuseFullscreenModule,
    FuseScrollResetModule,

    // CVS
    CvsGridModule,
    CvsErrorFocusModule,
    CvsControlNameIdDirectiveModule,
    CvsNgTemplateModule,
    CvsDialogsModule,
    CvsTruncatePipeModule,
    CvsKeypressModule,
    CvsAutocompleteModule,
    CvsMatSelectBindingModule,
    CvsCompareWithPipeModule,
    TrackByPipeModule
];
@NgModule({
    imports: [modules],
    exports: [modules]
})
export class CvsSharedModule {}
