import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CvsConfirmationConfig } from '@cvs/components/confirmation/confirmation.types';
import { CvsConfirmationDialogComponent } from '@cvs/components/confirmation/dialog/dialog.component';
import { merge } from 'lodash-es';

@Injectable()
export class CvsConfirmationService {
    private _defaultConfig: CvsConfirmationConfig = {
        title: 'Confirm action',
        message: 'Are you sure you want to confirm this action?',
        icon: {
            show: true,
            name: 'heroicons_outline:exclamation',
            color: 'warn'
        },
        actions: {
            confirm: {
                show: true,
                label: 'Confirm',
                color: 'warn'
            },
            cancel: {
                show: true,
                label: 'Cancel'
            }
        },
        dismissible: false
    };

    /**
     * Constructor
     */
    constructor(private _matDialog: MatDialog) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    open(config: CvsConfirmationConfig = {}): MatDialogRef<CvsConfirmationDialogComponent> {
        // Merge the user config with the default config
        const userConfig = merge({}, this._defaultConfig, config);

        // Open the dialog
        return this._matDialog.open(CvsConfirmationDialogComponent, {
            autoFocus: false,
            disableClose: !userConfig.dismissible,
            data: userConfig,
            panelClass: 'cvs-confirmation-dialog-panel'
        });
    }
    openDelete(config: CvsConfirmationConfig = {}): MatDialogRef<CvsConfirmationDialogComponent> {
        // Merge the user config with the default config
        const userConfig = merge(
            this._defaultConfig,
            {
                title: `Delete ${config.titleData}`,
                message: `Are you sure you want to delete this ${
                    config.messageData ? config.messageData + ' ' : ''
                }permanently? <span class="font-semibold">This action cannot be undone!</span>`,
                actions: {
                    confirm: { label: 'Delete' },
                    cancel: { label: "Don't delete" }
                },
                icon: {
                    color: 'warn',
                    show: true,
                    name: 'heroicons_outline:exclamation'
                }
            },
            config
        );
        // Open the dialog
        return this._matDialog.open(CvsConfirmationDialogComponent, {
            autoFocus: false,
            disableClose: !userConfig.dismissible,
            data: userConfig,
            panelClass: 'cvs-confirmation-dialog-panel'
        });
    }
    openSuccess(config: CvsConfirmationConfig = {}): MatDialogRef<CvsConfirmationDialogComponent> {
        // Merge the user config with the default config
        const userConfig = merge(
            this._defaultConfig,
            {
                title: `Successfully ${config.titleData}`,
                message: `Are you sure you want to delete this ${
                    config.messageData ? config.messageData + ' ' : ''
                }permanently? <br><span class="font-semibold">This action cannot be undone!</span>`,
                actions: {
                    confirm: { show: false, label: 'Ok', color: 'primary' },
                    cancel: { label: 'Ok', show: true }
                },
                icon: {
                    color: 'success',
                    show: true,
                    name: 'feather:check-circle'
                }
            },
            config
        );
        // Open the dialog
        return this._matDialog.open(CvsConfirmationDialogComponent, {
            autoFocus: false,
            disableClose: !userConfig.dismissible,
            data: userConfig,
            panelClass: 'cvs-confirmation-dialog-panel'
        });
    }
    openError(config: CvsConfirmationConfig = {}): MatDialogRef<CvsConfirmationDialogComponent> {
        // Merge the user config with the default config
        const userConfig = merge(
            this._defaultConfig,
            {
                title: `Error`,
                message: 'An error occurred',
                actions: {
                    confirm: { show: false },
                    cancel: { label: 'Ok' }
                },
                icon: {
                    color: 'error',
                    show: true,
                    name: 'feather:x-circle'
                }
            },
            config
        );
        // Open the dialog
        return this._matDialog.open(CvsConfirmationDialogComponent, {
            autoFocus: false,
            disableClose: !userConfig.dismissible,
            data: userConfig,
            panelClass: 'cvs-confirmation-dialog-panel'
        });
    }
    closeAll(): CvsConfirmationService {
        this._matDialog.closeAll();
        return this;
    }
}
