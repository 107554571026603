import { Observable } from 'rxjs';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { TemplateRef } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { FiltersType, GridifyConditionEnum, GridifyLogicalEnum } from '@cvs/gridify';

export type AndOrType = GridifyLogicalEnum.Or | GridifyLogicalEnum.And;
export type FieldAccessor = (element?: any) => any;
export type GridEventsType = 'sort' | 'page' | 'action' | 'init' | 'search';
export type ViewTemplateFunction = (cellValue?: any, item?: any, index?: number) => string;
export type downloadFileTypes = 'Csv' | 'Json' | 'Xlsx';

export interface CvsAction {
    children?: CvsAction[];
    iconClass?: string;
    iconSvg?: string;
    linkClass?: string;
    linkText?: string;
    onClick?: (item: any, params?: { [key: string]: any }, index?: number) => void;
    params?: { [key: string]: any; thisObj?: any };
    routerLink?: (item: any, index: number) => string;
    view: 'link' | 'button' | 'icon';
}

export interface CvsColumnCellEditing {
    enabled?: boolean;
}

export interface CvsColumnSorting {
    enabled?: boolean;
    matcher?: (item: any) => any;
    order?: CvsSortingOrder;
}

export interface CvsColumnSummaries {
    enabled?: boolean;
    summariesTypes?: Array<string>;
}

export interface CvsDefaultGridColumn {
    align?: CvsColumnAlign;
    //| string
    autoWidth?: boolean;
    canShowHide?: boolean;
    cssClasses?: string;
    exportable?: boolean;
    headerAlign?: CvsColumnAlign;
    hideHeader?: 'card' | 'table' | 'all' | 'none';
    //| string ;
    initialHide?: boolean;
    showInGrid?: boolean;
    sorting?: boolean | CvsColumnSorting;
    styles?: any;
    tooltip?: boolean | ViewTemplateFunction;
    truncate?: number;
    useInCommonSearch?: boolean;
    width?: string | number;
}

export interface CvsFilterType {
    caseInsensitive?: boolean;
    key?: string;
    label?: string;
    operator?: GridifyConditionEnum;
    operatorLabel?: string;
    pk: string;
    value?: string;
}

export interface CvsGridActionEvent<TData = any> extends CvsAction {
    item: TData;
}

export interface CvsGridColumn<T> extends CvsDefaultGridColumn {
    _columnOrder?: number;
    actions?: (item?: any, index?: number) => CvsAction[];
    cellEditing?: boolean | CvsColumnCellEditing;
    colId?: string;
    colSpan?: number;
    columnOrder?: number;
    dataCssClassFormatter?: (item: any, index: number) => any;
    def?: string;
    enabled?: boolean;
    field?: keyof T | FieldAccessor | `_${string & keyof T}` | `__${string & keyof T}`;
    formatter?: (data: any, item?: any, index?: number) => any;
    header?: string | ViewTemplateFunction;
    // | string | ViewTemplateFunction; // | string | ViewTemplateFunction; // | string | ViewTemplateFunction; // | string | ViewTemplateFunction;
    isIgnored?: boolean;
    isKey?: boolean;
    key?: string;
    name?: string;
    routerLink?: (item: any, index: number) => string;
    //    summaries?: CvsColumnSummaries;
    searchable?: CvsSearchModel<any>;
    sticky?: 'start' | 'end';
    template?: TemplateRef<any>;
    type?: CvsDataType;
    // | string ;
    view?: CvsCellView;

    //    matcher?: (item: any) => any;
}

export interface CvsGridCommon<TContext> {
    context: TContext;
}

export interface CvsGridOptions<TData = any> {
    caption?: string;
    cardColumnMaxSize?: { default?: number; md?: number; xl?: number };
    defaultColumn?: CvsDefaultGridColumn;
    defaultSort?: CvsGridSort<TData> | CvsGridSort<TData>[];
    downloadOption?: {
        func?: (
            event: CvsGridEvent
        ) => Observable<GridData<TData> | GridPagedData<TData> | null | undefined> | null | undefined;
        files?: downloadFileTypes[];
    };
    mode?: 'table' | 'card';
    multiSelectable?: boolean;
    multiSelectionWithClick?: boolean;
    pagination?: {
        enable: boolean;
    };
    rowClassFormatter?: (item: any, index: number) => string;
    rowSelectable?: boolean;
    toolbar?: {
        enable?: boolean;
        showCommonSearch?: boolean;
        showAdvancedSearch?: boolean;
        showColumnSelector?: boolean;
        showSortSelector?: boolean;
        showDisplaySelector?: boolean;
        showFullScreenSelector?: boolean;
        showDownloadOption?: boolean;
        showStyleOption?: boolean;
    };
    translate?: boolean | null | undefined;
}

export interface CvsGridSearchDataType {
    filters: CvsFilterType[];
    id: number;
    name: string;
}

export interface CvsPaging {
    display?: CvsPagingDisplay;
    enabled?: boolean;
    page?: number;
    pageSize?: number;
    pageSizes?: Array<number>;
    pagerBottom?: boolean;
    pagerTop?: boolean;
    totalItemCount?: number;
}

export interface CvsSearchModel<T> {
    autoFocus?: boolean;
    caseInsensitive?: boolean;
    colSpan?: number;
    controlType?:
        | 'text'
        | 'number'
        | 'date'
        | 'datetime'
        | 'select'
        | 'multi-select'
        | 'autocomplete'
        | 'date-range'
        | 'datetime-range'
        | 'check-box';
    enable?: boolean;
    label?: string | ViewTemplateFunction;
    maxLength?: number;
    multiple?: boolean;
    operator?: GridifyConditionEnum;
    options?: Array<{ value: any; label?: string }>;
    paramName?: string;
    placeholder?: string;
    searchFields?: string[];
    searchFieldsAndOr?: AndOrType;
    value?: T;
}

export interface GridData<TData = any> {
    items: TData[];
    metadata?: {
        message?: {
            title?: string;
            body?: string;
            icon?: string;
            color?: string;
        };
        [k: string]: any;
    };
}

export interface GridPagedData<TData = any> extends GridData<TData> {
    metadata: {
        pagination?: GridPagination;
    };
}

export interface GridPagination {
    firstItemOnPage?: number;
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
    isFirstPage?: boolean;
    isLastPage?: boolean;
    lastItemOnPage?: number;
    nextOffset?: number;
    offset?: number;
    page?: number;
    pageCount?: number;
    pageSize?: number;
    previousOffset?: number;
    totalItemCount?: number;
}

export interface GridStorageData {
    columns?: string[];
    searches?: CvsGridSearchDataType[];
}

export class CvsGridEvent {
    public action?: CvsGridActionEvent;
    public page?: CvsGridPaginatedEvent;
    public search?: CvsGridSearchEvent;
    public sort?: CvsGridSortedEvent | CvsGridSortedEvent[];
    public type: GridEventsType;
}

export class CvsGridPaginatedEvent extends PageEvent {}

export class CvsGridSearchEvent {
    public filters: FiltersType | null | undefined;
}

export class CvsGridSort<T = any> {
    public direction: 'asc' | 'desc' | '';
    public field: keyof T;
}

export class CvsGridSortedEvent extends CvsGridSort {}

export enum CvsCellView {
    chip = 1 << 1,
    link = 1 << 2,
    image = 1 << 3,
    bold = 1 << 4,
    italic = 1 << 5,
    checkbox = 1 << 6,
    template = 1 << 7,
    action = 1 << 8,
    html = 1 << 9,
    text = 1 << 10,
    ignore = 1 << 94 // 94 IS MAX
}

export enum CvsColumnAlign {
    auto = 0,
    right = 1 << 1,
    center = 1 << 2,
    left = 1 << 3
}

export enum CvsDataType {
    unknown = 0,
    number = 1 << 1,
    string = 1 << 2,
    boolean = 1 << 3,
    date = 1 << 4,
    zone = 1 << 6,
    timeAmPm = 1 << 7,
    timeHours = 1 << 8,
    custom = 1 << 20
}

export enum CvsPagingDisplay {
    basic = 1 << 0,
    advanced = 1 << 1
}

export enum CvsSortingOrder {
    none = 0,
    asc = 1 << 1,
    desc = 1 << 2
}

export const LOADING_GRID_DATA: GridData<string> = {
    items: [],
    metadata: {
        message: {
            body: 'Initializing',
            color: 'yellow'
        }
    }
};
export const RELOAD_GRID_EVENTS: GridEventsType[] = ['sort', 'page', 'search', 'init'];
export const DEFAULT_GRID_OPTIONS: CvsGridOptions<any> = {
    caption: undefined,
    translate: true,
    rowSelectable: false,
    multiSelectable: false,
    multiSelectionWithClick: false,
    pagination: {
        enable: true
    },
    defaultColumn: {
        autoWidth: true,
        align: CvsColumnAlign.left,
        initialHide: false,
        showInGrid: true,
        exportable: true,
        canShowHide: true,
        useInCommonSearch: true,
        tooltip: false
    },
    toolbar: {
        enable: true,
        showCommonSearch: true,
        showAdvancedSearch: true,
        showColumnSelector: true,
        showDownloadOption: true,
        showDisplaySelector: false,
        showFullScreenSelector: false,
        showSortSelector: false,
        showStyleOption: true
    },
    downloadOption: {
        files: ['Xlsx']
    },
    mode: 'table',
    cardColumnMaxSize: { default: 2, md: 4, xl: 8 },
    rowClassFormatter: (_item: any, _index: number) => ' '
};
export const DEFAULT_GRID_OPTIONS_NOTHING: CvsGridOptions<any> = {
    ...DEFAULT_GRID_OPTIONS,
    pagination: {
        enable: false
    },
    toolbar: {
        enable: false
    }
};
