import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

import { Breadcrumb } from './breadcrumb.model';

@Injectable({
    providedIn: 'root'
})
export class CvsBreadcrumbService {
    public breadcrumbLabels: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public newBreadcrumb: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject<Breadcrumb[]>([]);

    constructor() {}

    updateBreadcrumbLabels(labels: any) {
        this.breadcrumbLabels.next(labels);
    }

    updateBreadcrumb(newBreadcrumb: Breadcrumb[]) {
        this.newBreadcrumb.next(newBreadcrumb);
    }
}
