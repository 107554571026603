import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { cloneDeep } from 'lodash';
import { Paginate } from '../../paginate';
import { countriesData } from './data';
@Injectable({
    providedIn: 'root'
})
export class CountryMockApi {
    private _countries: any = countriesData;
    constructor(private _fuseMockApiService: FuseMockApiService) {
        this.registerHandlers();
    }
    registerHandlers(): void {
        this._fuseMockApiService.onGet('api/geography/countries').reply(({ request }) => {
            const page = parseInt(request.params.get('page') ?? '1', 10);
            const pageSize = parseInt(request.params.get('pageSize') ?? '10', 10);
            //const orderBy = request.params.get('orderBy');
            return [200, cloneDeep(new Paginate(this._countries, pageSize).page(page))];
        });
        this._fuseMockApiService.onGet('api/geography/all-countries').reply(() => {
            //const orderBy = request.params.get('orderBy');
            return [200, cloneDeep(this._countries)];
        });
    }
}
