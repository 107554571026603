// HTTP client
export const HTTP_CLIENT__MAX_RETRIES = 2;
export const HTTP_CLIENT__RETRIES_DELAY_IN_MS = 200;

export const PAGINATION__MAX_ROWS_PER_PAGE = 10;
// Application errors
export const ERROR__NO_PAYLOAD = {
    status: 0,
    message: 'No payload found to initiate an HTTP request.'
};
