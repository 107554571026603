<div class="flex flex-col max-w-screen max-h-screen -m-6">
    <div class="flex flex-0 items-center justify-between h-12 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary"
        *ngIf="dialogTitle.length > 0">
        <ng-content select="[cvsDialogTitle]"></ng-content>
        <ng-container *ngIf="data.dismissible">
            <div class="">
                <button mat-icon-button [matDialogClose]="undefined">
                    <mat-icon class="text-white" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                </button>
            </div>
        </ng-container>
    </div>
    <div class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto" *ngIf="dialogContent.length > 0">
        <ng-content select="[cvsDialogContent]"></ng-content>
    </div>
    <div class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10"
        *ngIf="dialogActions.length > 0">
        <ng-content select="[cvsDialogActions]"></ng-content>
    </div>
</div>