import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class UndefinedToNullInterceptor implements HttpInterceptor {
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    return event.clone({
                        body: this.convertUndefinedToNull(event.body)
                    });
                }
                return event;
            })
        );
    }

    private convertUndefinedToNull(data: any): any {
        if (data === null || typeof data !== 'object') {
            return data;
        }
        if (data instanceof Blob) {
            return data; // Skip processing for Blob objects
        }
        if (Array.isArray(data)) {
            return data.map((item) => this.convertUndefinedToNull(item));
        }

        const convertedData: any = {};

        for (const key in data) {
            if (data[key] === undefined) {
                convertedData[key] = null;
            } else if (typeof data[key] === 'object') {
                convertedData[key] = this.convertUndefinedToNull(data[key]);
            } else {
                convertedData[key] = data[key];
            }
        }

        return convertedData;
    }
}
