import { OAuthService } from 'angular-oauth2-oidc';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, of } from 'rxjs';

import { CookieService, LogService } from '@cvs/services';

import { AuthUtils } from './auth.utils';

const REMEMBER_ME_COOKIE_KEY = 'CUSTOMER_PORTAL_REMEMBER_ME';
const REMEMBER_ME_UNTIL = 1000 * 60 * 60 * 24 * 30;

export abstract class IAuthService {
    constructor(
        protected cookie: CookieService,
        protected logService: LogService,
        protected permissionsService: NgxPermissionsService,
        protected oauthService: OAuthService
    ) {
        // this.permissionsService.loadPermissions([INITIATED_PERMISSIONS]);
    }

    /**
     * Check the authentication status
     */
    public check(): Observable<boolean> {
        // Check the access token availability
        if (!this.oauthService.hasValidAccessToken()) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.oauthService.getAccessToken())) {
            return of(false);
        }
        return of(true);
    }

    /**
     * Checks whether the "remember me" cookie was set or not.
     *
     * @returns True if set, false otherwise
     */
    public isRememberMeSet(): boolean {
        return this.cookie.getCookie(REMEMBER_ME_COOKIE_KEY) !== null;
    }

    public saveRememberMeCookie(rememberMe: boolean): void {
        let expiration = null;

        if (rememberMe) {
            expiration = new Date();
            const time = expiration.getTime();
            const expireTime = time + REMEMBER_ME_UNTIL;
            expiration.setTime(expireTime);
        }
        this.cookie.setCookie({
            name: REMEMBER_ME_COOKIE_KEY,
            value: rememberMe + '',
            expireDays: 30,
            session: false
        });
    }

    /**
     * Extract the expiration date of id token
     */
    public abstract getTokenIdExpirationDate(): Date;
    public abstract getUserInfo(): Observable<any>;
    /**
     * Extract the username from the Keycloak generated access token (JWT)
     */
    public abstract getUserName(): string;
    /**
     * Determines if the current user has a valid id token
     * Returns true if an IdToken exists and not expired within the session storage, false otherwise
     */
    public abstract hasValidIdToken(): boolean;
    /**
     * Redirect to keycloak login page by client id
     */
    public abstract login(): void;
    /**
     * User Password to get token
     */
    public abstract loginWithPassword(loginInfo: {
        username: string;
        password: string;
        rememberMe?: boolean;
        redirectUrl?: string;
    }): Promise<object>;
    /**
     * Logout and clear session
     */
    public abstract logout(): Promise<any>;
    /**
     * Handle incoming events from keycloak
     */
    public abstract redirectOnCallback(): Observable<any>;
    /**
     * Refresh token request
     */
    public abstract refreshToken();
}
