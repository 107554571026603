<!-- Messages toggle -->
<button mat-icon-button (click)="openPanel()" [matTooltip]="'Messages' | transloco" #messagesOrigin>
    <ng-container *ngIf="unReadStatusCount > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span
                class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-thirdary text-on-primary text-xs font-medium">
                {{unReadStatusCount}}
            </span>
        </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:inbox'"  class="text-primary"></mat-icon>
</button>

<!-- Messages panel -->
<ng-template #messagesPanel>
    <div
        class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-sm bg-white overflow-hidden shadow-lg">
        <!-- Header -->
        <div class="flex shrink-0 items-center py-2 pr-4 pl-6 justify-between flex-row border-b-2">
            <div class="flex justify-start items-center">
                <div class="sm:hidden -ml-1 mr-3">
                    <button mat-icon-button (click)="closePanel()">
                        <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                    </button>
                </div>
                <div class="flex-row">
                    <div class="text-lg font-semibold leading-8 gap-4">
                        {{'Messages' | transloco}} <span *ngIf='unReadStatusCount > 0' class="text-sm font-light leading-3">
                            ({{unReadStatusCount}} {{'Unread' | transloco}}) 
                        </span>
                    </div>
                    <!-- <div *ngIf='totalCount > 0' class="text-sm font-light leading-5">
                        Read: {{readStatusCount}} Unread: {{unReadStatusCount}}
                    </div> -->
                </div>
            </div>
            <div class="flex flex-row items-center justify-items-end">
                <button mat-icon-button [matTooltip]="'MarkAllAsRead' | transloco"
                    *ngIf="unreadCount > 0 && !showMarkAllAsUnReadBtn" (click)="markAllAsRead(true)">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_outline:envelope-open'"></mat-icon>
                </button>
                <button mat-icon-button [matTooltip]="'MarkAllAsUnRead' | transloco" *ngIf="showMarkAllAsUnReadBtn"
                    (click)="markAllAsRead(false)">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_outline:envelope'"></mat-icon>
                </button>
            </div>
        </div>
        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">
            <!-- Messages -->
            <ng-container *ngFor="let message of messages; trackBy: trackByFn">
                <div class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5"
                    [ngClass]="{ unread: !message.isRead }">
                    <!-- Message with a link -->
                    <ng-container *ngIf="message.link">
                        <!-- Normal links -->
                        <ng-container *ngIf="!message.useRouter">
                            <a class="flex flex-auto py-2 pl-2 cursor-pointer" [href]="message.link">
                                <ng-container *ngTemplateOutlet="messageContent"></ng-container>
                            </a>
                        </ng-container>
                        <!-- Router links -->
                        <ng-container *ngIf="message.useRouter">
                            <a class="flex flex-auto py-2 pl-2 cursor-pointer" [href]="message.link">
                                <ng-container *ngTemplateOutlet="messageContent"></ng-container>
                            </a>
                        </ng-container>
                    </ng-container>

                    <!-- Message without a link -->
                    <ng-container *ngIf="!message.link">
                        <div class="flex flex-auto py-2 pl-2">
                            <ng-container *ngTemplateOutlet="messageContent"></ng-container>
                        </div>
                    </ng-container>

                    <!-- Actions -->
                    <div class="relative flex flex-col my-2 mr-2 ml-2">
                        <!-- Indicator -->
                        <button *ngIf="message.isRead" mat-icon-button (click)="toggleRead(message)"
                            [matTooltip]="'MarkAsUnRead' | transloco">
                            <mat-icon class="icon-size-4 text-current"
                                [ngClass]="'sm:opacity-0 sm:group-hover:opacity-100'"
                                [svgIcon]="'heroicons_outline:envelope'">
                            </mat-icon>
                        </button>
                        <button *ngIf="!message.isRead" mat-icon-button (click)="toggleRead(message)"
                            [matTooltip]="'MarkAsRead' | transloco">
                            <mat-icon class="icon-size-4 text-current" [svgIcon]="'heroicons_outline:envelope-open'">
                            </mat-icon>
                        </button>
                        <!-- Remove -->
                        <button mat-icon-button (click)="delete(message)" [matTooltip]="'Remove' | transloco">
                            <mat-icon class="icon-size-4 text-current"
                                [ngClass]="'sm:opacity-0 sm:group-hover:opacity-100'"
                                [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                        </button>
                    </div>
                </div>

                <!-- Message content template -->
                <ng-template #messageContent>
                    <!-- Icon -->
                    <ng-container *ngIf="message.icon && !message.image">
                        <div
                            class="flex shrink-0 items-center justify-center w-8 h-8 mr-4 rounded-full bg-gray-100 dark:bg-gray-700">
                            <mat-icon class="icon-size-4" [svgIcon]="message.icon">
                            </mat-icon>
                        </div>
                    </ng-container>
                    <!-- Image -->
                    <ng-container *ngIf="message.image">
                        <img class="shrink-0 w-8 h-8 mr-4 rounded-full overflow-hidden object-cover object-center"
                            [src]="message.image" [alt]="'Message image'" />
                    </ng-container>
                    <!-- Title, description & time -->
                    <div class="flex flex-col flex-auto">
                        <ng-container *ngIf="message.title">
                            <div class="font-semibold line-clamp-1 text-sm" [matTooltip]="message.title"  [innerHTML]="message.title"></div>
                        </ng-container>
                        <ng-container *ngIf="message.description">
                            <div class="line-clamp-2 text-sm" [matTooltip]="message.description" [innerHTML]="message.description"></div>
                        </ng-container>
                        <div class="mt-2 text-sm leading-none text-secondary">
                            {{ message.createdDateTime | date : "MMM dd, h:mm a" }}
                        </div>
                    </div>
                </ng-template>
            </ng-container>

            <!-- No messages -->
            <ng-container *ngIf="!messages || !messages.length">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div
                        class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600">
                        <mat-icon class="text-primary-700 dark:text-primary-50" [svgIcon]="'heroicons_outline:inbox'">
                        </mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">
                        No messages
                    </div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">
                        When you have messages, they will appear here.
                    </div>
                </div>
            </ng-container>
        </div>
        <!-- Footer -->
        <div class="flex bg-white justify-center border-t-2">
            <button mat-flat-button (click)="navigateToNotifications()" [color]="'bg-primary'">
                <span>{{'ViewAll' | transloco}} ({{totalCount}})</span>
            </button>
        </div>
    </div>
</ng-template>