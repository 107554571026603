import { forkJoin } from 'rxjs';
import { NavigationService } from 'src/app/core/navigation/navigation.service';

import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { NotificationsService } from './layout/common/notifications/notifications.service';
import { QuickChatService } from './layout/common/quick-chat/quick-chat.service';

export const InitialDataResolver: ResolveFn<any> = () => {
    const navigationService = inject(NavigationService);
    const notificationsService = inject(NotificationsService);
    const quickChatService = inject(QuickChatService);
    // Fork join multiple API endpoint calls to wait all of them to finish
    return forkJoin([
        navigationService.get(),
        //this._messagesService.getAll(),
        notificationsService.getAll(),
        quickChatService.getChats()
        //this._shortcutsService.getAll()
    ]);
};
