import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseNgTemplateModule } from '@fuse/components/api';
import { CvsSharedModule, MatSharedModule } from 'src/app/shared';
import { PageHeaderComponent } from './page-header.component';

@NgModule({
    declarations: [PageHeaderComponent],
    imports: [CommonModule, CvsSharedModule, MatSharedModule, RouterModule],
    exports: [PageHeaderComponent, FuseNgTemplateModule]
})
export class PageHeaderModule {}
