import { FiltersType, GridifyLogicalEnum, SortsType } from './types';

export function trimEnds(query: string): string {
    let result = query;
    // There should not be any starting or ending operator
    if (result.startsWith(GridifyLogicalEnum.And)) result = result.slice(GridifyLogicalEnum.And.length);
    if (result.startsWith(GridifyLogicalEnum.Or)) result = result.slice(GridifyLogicalEnum.Or.length);
    if (result.endsWith(GridifyLogicalEnum.And)) result = result.slice(0, -GridifyLogicalEnum.And.length);
    if (result.endsWith(GridifyLogicalEnum.Or)) result = result.slice(0, -GridifyLogicalEnum.Or.length);

    return result;
}
export function createGridify(filters: FiltersType) {
    const queries: string = filters.reduce((acc, filter) => {
        // If current filter is an array inside the current array then it should be wrapped in Parentheses
        if (Array.isArray(filter)) {
            const childQueries = createGridify(filter);
            return childQueries ? trimEnds(`${acc}(${childQueries})`) : trimEnds(acc);
        }

        // If current filter is an `string`, it means it is only an operator between two other filters
        if (typeof filter === 'string') return acc + filter;

        const { key, operator, value, caseInsensitive, order } = filter;
        if (value && operator)
            return trimEnds(
                acc +
                    key.toString() +
                    operator +
                    value.toString().trim() +
                    (order ? ` ${order}` : '') +
                    (caseInsensitive ? GridifyLogicalEnum.CaseInsensitive : '')
            );

        return trimEnds(acc);
    }, '');
    return queries;
}

export function createQueryString(filters: FiltersType) {
    const queries: string = filters.reduce((acc, filter) => {
        // If current filter is an array inside the current array then it should be wrapped in Parentheses
        if (Array.isArray(filter)) {
            const childQueries = createQueryString(filter);
            return childQueries
                ? trimEnds(`${acc}&${childQueries}`)
                : trimEnds(acc.replace('|', '?').replace(',', '?'));
        }

        // If current filter is an `string`, it means it is only an operator between two other filters
        if (typeof filter === 'string') return acc + filter.replace('|', '&').replace(',', '&');

        const { key, value } = filter;
        if (value && key) return trimEnds(acc + key.toString() + '=' + value);

        return trimEnds(acc);
    }, '');
    return queries;
}

export function createSort(sorts: SortsType) {
    const sorter = Array.isArray(sorts) ? sorts : [sorts];
    const sorting: string = sorter.reduce((acc, sort) => {
        if (typeof sort === 'string') return acc + ',' + sort;
        const { field, direction } = sort;
        if (field) {
            return trimEnds(acc + ',' + field + (direction ? ` ${direction}` : ''));
        }
        return trimEnds(acc);
    }, '');
    return sorting;
}
/***** Example
const filters: FiltersType = [
    { key: 'createDate', operator: GridifyConditionEnum.GreaterThan, value: '2021-01-12' },
    GridifyLogicalEnum.And,
    [
        {
            key: 'name',
            operator: GridifyConditionEnum.Contains,
            value: 'joe',
            caseInsensitive: true
        },
        GridifyLogicalEnum.Or,
        {
            key: 'count',
            operator: GridifyConditionEnum.LessThan,
            order: GridifyOrderEnum.Ascending,
            value: '50'
        },
        GridifyLogicalEnum.Or,
        { key: 'target', operator: GridifyConditionEnum.StartsWith, value: 'https://', caseInsensitive: true }
    ],
    GridifyLogicalEnum.And,
    { key: 'hitCount', operator: GridifyConditionEnum.GreaterThanOrEqual, value: '50' }
];

// Final generated query
const query = createQuery(filters);
***********/
