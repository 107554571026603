import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { QuickChatComponent } from 'src/app/layout/common/quick-chat/quick-chat.component';
import { CvsSharedModule, MatSharedModule } from 'src/app/shared';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
    declarations: [QuickChatComponent],
    imports: [RouterModule, MatSharedModule, CvsSharedModule, SharedModule],
    exports: [QuickChatComponent]
})
export class QuickChatModule {}
