<mat-form-field class="fuse-mat-dense" [ngClass]="fullWidth ? 'w-full' : ''" #formField >
    <mat-label *ngIf="label !== null" class="cvs-form-field-panel-label">{{ label }}</mat-label>
    <ng-content select="cvs-input-prefix"></ng-content>
    <input matInput [matAutocomplete]="auto" 
        [formControl]="localControl" #primaryAutoInput [title]="tooltip || label" [disabled]="disabled" [readonly]="readonly" tab-directive
        [id]="id || controlName.name + ''" [name]="id || controlName.name + ''"
        [required]="hasRequiredField(ngControl.control)" (keydown)="onKeydownEvent($event)"
        [controlErrorAnchor]="primaryAutoInputErr" (blur)="handleBlur($event)" />
    <mat-autocomplete #auto="matAutocomplete" (optionsScroll)="onScroll()" [displayWith]="displayWith.bind(this)"
        (optionSelected)="optionSelected($event)" [dir]="dir">
        <mat-option class="text-primary" id="mat-option-loading" disabled *ngIf="loading$"  [value]="null">{{
            'Loading' | transloco
            }}</mat-option>
        <mat-option class="text-warn" id="mat-option-no-record" disabled *ngIf="!loading$ && noResult" [value]="null">
            {{ 'NoRecordsFound' | transloco }}
        </mat-option>
        <mat-option [id]="'mat-option-' + getOptionValue(option)" *ngFor="let option of filteredOptions | async"
            [value]="option">
            {{ getOptionLabel(option) }}
        </mat-option>
    </mat-autocomplete>
    <span matSuffix>
        <button mat-icon-button type="button" class="ltr:-mr-2.5 rtl:-ml-2.5" *ngIf="showPopupButton"
            (click)="$event.stopPropagation(); OnPopupClick.emit($event)">
            <mat-icon svgIcon="heroicons_outline:ellipsis-horizontal" class="text-primary-500/70 rotate-90"></mat-icon>
        </button>
        <mat-icon svgIcon="heroicons_outline:ellipsis-horizontal" class="text-primary-500/70 "
            *ngIf="!showPopupButton"></mat-icon>
        <mat-spinner *ngIf="control.status === 'PENDING'" class="spinner" diameter="16"></mat-spinner>
        <ng-content select="cvs-form-input-suffix"></ng-content></span>
    <mat-hint *ngIf="hint.length > 0" align="start">{{ hint }}</mat-hint>
    <mat-hint *ngIf="hintEnd.length > 0" align="end">{{ hintEnd }}</mat-hint>
    <mat-error>
        <ng-template controlErrorAnchor #primaryAutoInputErr="controlErrorAnchor"></ng-template>
    </mat-error>
</mat-form-field>