import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'cvs-empty-actions',
    template: ' ',
    standalone: true
})
export class EmptyActionComponent implements OnInit {
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    public ngOnInit(): void {}
}
