import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EmptyLayoutComponent } from 'src/app/layout/layouts/empty/empty.component';
import { CvsSharedModule } from 'src/app/shared';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
    declarations: [EmptyLayoutComponent],
    imports: [RouterModule, CvsSharedModule, SharedModule],
    exports: [EmptyLayoutComponent]
})
export class EmptyLayoutModule {}
