import { Observable } from 'rxjs';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TenantService } from '@cvs/services/tenant.service';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
    constructor(private tenantService: TenantService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Get the tenant name from the TenantService
        const tenantName = this.tenantService.getTenantName();

        // Clone the request and add the X-Tenant header
        const modifiedReq = req.clone({
            setHeaders: {
                'X-Tenant': tenantName
            }
        });

        // Pass the modified request to the next handler
        return next.handle(modifiedReq);
    }
}
