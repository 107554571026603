import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { AfterViewInit, Directive, EventEmitter, Input, OnDestroy, Optional, Output } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';

export interface IAutoCompleteScrollEvent {
    autoComplete: MatAutocomplete;
    scrollEvent: Event;
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'mat-autocomplete[optionsScroll]'
})
export class OptionsScrollDirective implements OnDestroy {
    @Input() thresholdPercent = 0.8;
    @Output() optionsScroll = new EventEmitter<IAutoCompleteScrollEvent>();
    onDestroy = new Subject<void>();

    constructor(public autoComplete: MatAutocomplete) {
        this.autoComplete.opened
            .pipe(
                tap(() => {
                    // Note: When autocomplete raises opened, panel is not yet created (by Overlay)
                    // Note: The panel will be available on next tick
                    // Note: The panel wil NOT open if there are no options to display
                    setTimeout(() => {
                        // Note: remove listener just for safety, in case the close event is skipped.
                        this.removeScrollEventListener();
                        this.autoComplete.panel.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
                    });
                }),
                takeUntil(this.onDestroy)
            )
            .subscribe();

        this.autoComplete.closed
            .pipe(
                tap(() => this.removeScrollEventListener()),
                takeUntil(this.onDestroy)
            )
            .subscribe();
    }

    private removeScrollEventListener(): any {
        this.autoComplete.panel?.nativeElement.removeEventListener('scroll', this.onScroll);
    }

    ngOnDestroy(): any {
        this.onDestroy.next();
        this.onDestroy.complete();

        this.removeScrollEventListener();
    }

    onScroll(event: Event): any {
        if (this.thresholdPercent === undefined) {
            this.optionsScroll.next({ autoComplete: this.autoComplete, scrollEvent: event });
        } else {
            const eventTarget = event.target as Element;
            const threshold = (this.thresholdPercent * 100 * eventTarget.scrollHeight) / 100;
            const current = eventTarget.scrollTop + eventTarget.clientHeight;
            if (current > threshold) {
                this.optionsScroll.next({
                    autoComplete: this.autoComplete,
                    scrollEvent: event
                });
            }
        }
    }
}

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[tab-directive]' })
export class TabDirective implements AfterViewInit, OnDestroy {
    observable: any;
    constructor(
        @Optional() private autoTrigger: MatAutocompleteTrigger,
        @Optional() private control: NgControl
    ) {}
    ngAfterViewInit(): void {
        this.observable = this.autoTrigger.panelClosingActions.subscribe(() => {
            if (this.control) {
                if (this.control.value === '') {
                    this.autoTrigger.writeValue(undefined);
                    this.autoTrigger.autocomplete.optionSelected.emit();
                    this.autoTrigger.closePanel();
                } else {
                    if (this.autoTrigger.activeOption) {
                        const value = this.autoTrigger.activeOption.value;
                        if (this.control) {
                            this.control?.control?.setValue(value, { emit: false });
                        }
                        this.autoTrigger.writeValue(this.autoTrigger.activeOption.value);
                        this.autoTrigger.activeOption.select();
                    }
                }
            }
        });
    }
    public ngOnDestroy(): void {
        this.observable.unsubscribe();
    }
}
