import {
    AfterContentInit,
    Component,
    ContentChildren,
    Directive,
    Inject,
    QueryList,
    ViewEncapsulation
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CvsDialogConfig } from './dialog.service';

@Directive({ selector: '[cvsDialogTitle]' })
export class CvsDialogTitleDirective {}

@Directive({ selector: '[cvsDialogContent]' })
export class CvsDialogContentDirective {}

@Directive({ selector: '[cvsDialogActions]' })
export class CvsDialogActionsDirective {}

@Component({
    selector: 'cvs-dialog',
    templateUrl: './dialog.component.html',
    styles: [
        `
            .cvs-dialog-panel {
                @apply w-full;
                
                @screen md {
                    @apply w-128;
                }

                @screen lg {
                    @apply w-192;
                }
                
            }
        `
    ],
    encapsulation: ViewEncapsulation.None
})
export class CvsDialogComponent implements AfterContentInit {
    // #region Properties (3)

    @ContentChildren(CvsDialogActionsDirective, { descendants: true })
    dialogActions!: QueryList<CvsDialogActionsDirective>;
    @ContentChildren(CvsDialogContentDirective, { descendants: true })
    dialogContent!: QueryList<CvsDialogContentDirective>;
    @ContentChildren(CvsDialogTitleDirective, { descendants: true })
    dialogTitle!: QueryList<CvsDialogTitleDirective>;
    public data: CvsDialogConfig;
    // #endregion Properties (3)

    // #region Constructors (1)

    /**
     * Constructor
     */
    constructor(@Inject(MAT_DIALOG_DATA) config: CvsDialogConfig) {
        this.data = { dismissible: true, ...config };
    }

    // #endregion Constructors (1)

    // #region Public Methods (1)

    public ngAfterContentInit(): void {
        if (this.dialogTitle.length > 1) {
            throw new Error('Duplicate cvs-dialog-title component at in cvs-dialog.');
        }
        if (this.dialogContent.length > 1) {
            throw new Error('Duplicate cvs-dialog-content component at in cvs-dialog.');
        }
        if (this.dialogActions.length > 1) {
            throw new Error('Duplicate cvs-dialog-actions component at in cvs-dialog.');
        }
    }

    // #endregion Public Methods (1)
}
