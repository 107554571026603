import { LayoutComponent } from 'src/app/layout/layout.component';

import {
    DsignResponseCaptureComponent
} from '../dsign-response/dsign-response-capture/dsign-response-capture.component';

export const authRoutes_NoAuth_EmptyLayout = [
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'dsign-response-route',
                component: DsignResponseCaptureComponent
            },
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import('src/app/modules/auth/confirmation-required/confirmation-required.module').then(
                        (m) => m.AuthConfirmationRequiredModule
                    )
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import('src/app/modules/auth/forgot-password/forgot-password.module').then(
                        (m) => m.AuthForgotPasswordModule
                    )
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import('src/app/modules/auth/reset-password/reset-password.module').then(
                        (m) => m.AuthResetPasswordModule
                    )
            },
            {
                path: 'sign-in',
                data: {
                    title: 'Login'
                },
                loadChildren: () =>
                    import('src/app/modules/auth/sign-in/sign-in.module').then((m) => m.AuthSignInModule)
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('src/app/modules/auth/sign-up/sign-up.module').then((m) => m.AuthSignUpModule)
            },
            {
                path: 'eConnect-login',
                loadChildren: () =>
                    import('src/app/modules/auth/auto-login/auto-login.module').then((m) => m.AuthAutoLoginModule)
            }
        ]
    }
];

export const authRoutes_EmptyLayout = [
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('src/app/modules/auth/sign-out/sign-out.module').then((m) => m.AuthSignOutModule)
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import('src/app/modules/auth/unlock-session/unlock-session.module').then(
                        (m) => m.AuthUnlockSessionModule
                    )
            }
        ]
    }
];
