<!-- Button -->
<button class="w-full flex flex-row items-center justify-between gap-x-2 hover:bg-gray-400/10 p-0.5 px-2"
	[ngClass]="cssClass"
	matTooltipPosition="above" [matMenuTriggerFor]="userActions"
	[matTooltip]="showUserName ? '' : ('SignedInAsUserName' | transloco: { userName: user.email })">
	<div *ngIf="showUserName && user?.firstName?.trim()" class="flex-col items-end justify-center hidden md:flex">
		<span class="text-sm font-semibold">{{ user?.firstName?.trim()+ ' ' + user?.lastName?.trim() }}</span>
	</div>
	<img *ngIf="user.avatar; else panelShortName" alt="" class="cvsui-avatar"
		[src]="'data:image/jpeg;base64,' + user.avatar" />

</button>
<ng-template #panelShortName>
	<div class="cvsui-avatar">
		{{ getInitials(user?.firstName?.trim() + '^' + user?.lastName?.trim() ) }}
	</div>
</ng-template>
<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item type="button">
        <dl class="mt-1 flex-grow flex flex-col justify-between">
            <dt class="sr-only">{{'Title' | transloco}}</dt>
            <dd class="text-gray-500 font-semibold text-sm leading-5">{{ user?.name }}</dd>
            <dd class="text-gray-500 text-sm leading-5">{{ user?.email }}</dd>
            <dt class="sr-only">{{'Role' | transloco}}</dt>
            <dd class="my-1 mb-2">
                <ng-template ngFor let-item="$implicit" [ngForOf]="user?.roles"
                    [ngForTrackBy]="( 'id' | trackByProperty )"><span *ngIf="item.id>=100"
                        class="px-2 py-1 text-green-700 text-xs leading-4 font-medium bg-green-50 rounded-full mr-1 border border-green-700">{{item.name}}</span></ng-template>
            </dd>
        </dl>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item type="button" (click)="viewProfile()">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>{{'Profile' | transloco}}</span>
    </button>
    <button mat-menu-item *ngIf="false" type="button">
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span>{{'Settings' | transloco}}</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()" type="button">
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        <span>{{'SignOut' | transloco}}</span>
    </button>
</mat-menu>