/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Observable, Subject } from 'rxjs';
import {
    debounceTime,
    delay,
    distinctUntilChanged,
    exhaustMap,
    filter,
    finalize,
    first,
    map,
    scan,
    startWith,
    switchMap,
    takeWhile,
    tap
} from 'rxjs/operators';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { DIR_DOCUMENT, Direction } from '@angular/cdk/bidi';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Directive,
    DoCheck,
    ElementRef,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild
} from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    FormControlName,
    FormGroupName,
    NG_VALUE_ACCESSOR,
    NgControl
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ObjectUtils as CvsAutoUtils } from '@cvs/utils';

import { IComparisonType, IDataType, ISearchStringLengthType, SearchFilterModel } from './auto-complete.type';

@Component({
    selector: 'cvs-autocomplete',
    templateUrl: './auto-complete.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteComponent implements OnInit, DoCheck, ControlValueAccessor, OnDestroy {
    private currentLanguage = 'en';
    private isTouched = false;
    private nextPage$ = new Subject<void>();
    private onDestroy = new Subject();
    private service!: (serviceParams: {
        currentPage: number;
        rowsPerPage?: number;
        sort:
            | {
                  field: string;
                  direction: 'asc' | 'desc' | '';
              }
            | null
            | undefined;
        filters?: { key?: string; value?: string; operator?: '=*' } | null | undefined | string;
    }) => Observable<{
        metadata: {
            pagination: { totalItemCount: number };
        };
        items: unknown[];
    }>;

    @Input() public additionalFilters!: string | (() => string);
    @Input()
    public autoCompleteBinding: { [key: string]: string } = {};
    @Input() public bindInitialValue = true;
    @Input() public caseInsensitive?: boolean = true;
    @Input()
    public control: FormControl = new FormControl('');
    @Input() public dataKey = '';
    @Input() public disabled?: boolean;
    @Input() public forceSelection: boolean;
    @Input() public fullWidth = true;
    @Input() public hint = '';
    @Input() public hintEnd = '';
    @Input() public id!: string;
    @Input() public label?: string;
    @Input()
    public localControl: FormControl = new FormControl('');
    @Input() public minSearchLength = 2;
    @Input() public optionLabel = '';
    @Input() public optionLabelType: IDataType = 'string';
    @Input() public optionLocalLabel = 'localized';
    @Input() public optionValue = '';
    @Input() public placeholder = '';
    @Input() public prefix?: string;
    @Input() public readonly = false;
    @Input() public searchFieldName?: string;
    @Input() public searchFilters: SearchFilterModel[] = [];
    @Input() public searchLocalizedFieldName?: string;
    @Input() public showPopupButton?: boolean = false;
    @Input() public tooltip?: string;
    @Output() public OnChange = new EventEmitter();
    @Output() public OnInitialDataLoaded = new EventEmitter();
    @Output() public OnPopupClick = new EventEmitter();
    @ViewChild('primaryAutoInput') public primaryAutoInput!: ElementRef;

    public bindInitialCalled = false;
    public dataOptions: any[] = [];
    public defaultComparison: IComparisonType = IComparisonType.String;
    public dir: Direction = 'ltr';
    public filteredOptions!: Observable<any[]>;
    public loading$ = false;
    public noResult = false;
    public oldControlValue: any = '';
    public oldLocalControlValue: any = '';
    public onChange = (_: any): void => {};
    public onTouched = (): void => {};
    public required = false;
    public selectedItem: false | any = false;
    public types: string[] = ['string', 'number'];
    public value: any = '';

    constructor(
        private _cd: ChangeDetectorRef,
        @Optional() private controlContainer: ControlContainer,
        @Self() @Optional() public ngControl: NgControl,
        @Optional() public controlName: FormControlName,
        @Optional() public controlGroupName: FormGroupName,
        @Optional() @Inject(DIR_DOCUMENT) _document?: Document
    ) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
        this.currentLanguage = window.localStorage.getItem('cvs-translate-lang') || 'en';
        this.forceSelection = true;
    }

    @Input()
    public set dataComparison(inputValue: keyof typeof IComparisonType) {
        this.defaultComparison = IComparisonType[inputValue];
    }

    @Input()
    public set optionService(
        service: (serviceParams: {
            currentPage: number;
            rowsPerPage?: number;
            sort:
                | {
                      field: string;
                      direction: 'asc' | 'desc' | '';
                  }
                | null
                | undefined;
            filters?: { key?: string; value?: string; operator?: '=*' } | null | undefined | string;
        }) => Observable<{
            metadata: {
                pagination: { totalItemCount: number };
            };
            items: unknown[];
        }>
    ) {
        this.service = service;
    }

    @Input() public get options(): any[] {
        return this.dataOptions;
    }

    public get message(): string {
        return this.defaultComparison.toString();
    }

    public set options(val: any[]) {
        this.dataOptions = val;
    }

    public ngOnInit(): void {
        const validators = this.ngControl.control?.validator;
        this.required = this.hasRequiredField(this.ngControl.control);
        this.control.setValidators(validators ? validators : null);
        this.control.updateValueAndValidity();
        this.localControl.setValidators(validators ? validators : null);
        this.localControl.updateValueAndValidity();
        if (this.value && (this.value + '').length > 0) {
            this.bindInitialData(this.value);
        }
        this.bindSearch(this.value);
    }

    public ngDoCheck(): void {
        if (this.ngControl.touched && !this.isTouched) {
            this.isTouched = true;
            this.control.markAllAsTouched();
            this.control.updateValueAndValidity();
            this.localControl.markAllAsTouched();
            this.localControl.updateValueAndValidity();
        }
    }

    public ngOnDestroy(): void {
        this.onDestroy.next(null);
        this.onDestroy.complete();
    }

    public displayWith(option: any | undefined): string {
        if (this.optionLocalLabel) {
            const localRow = CvsAutoUtils.resolveFieldData(option, this.optionLocalLabel);
            if (localRow) {
                const returnData = CvsAutoUtils.resolveFieldData(localRow, this.optionLabel);
                if (returnData) {
                    return returnData;
                }
            }
        }

        return this.optionLabel
            ? CvsAutoUtils.resolveFieldData(option, this.optionLabel)
            : option.label !== undefined
              ? option.label
              : '';
    }

    public getFilterData(query: string): string {
        if (this.searchFilters) {
            const filterArray = this.searchFilters
                ?.filter((x) => this.parseSearchStringLength(query.length, x.searchStringLength))
                .map((f) => this.addToFilterArray(f, query));
            if (filterArray && filterArray.length > 0) return filterArray.join('|');
        }
        return '';
    }

    public getOptionLabel(option: any | undefined): void {
        return this.optionLabel ? this.getAutoFilterData(option) : option.label !== undefined ? option.label : option;
    }

    public getOptionValue(option: any | undefined): any {
        return this.optionValue
            ? CvsAutoUtils.resolveFieldData(option, this.optionValue)
            : option.value !== undefined
              ? option.value
              : option;
    }

    public handleBlur(_value: FocusEvent): void {
        setTimeout(() => {
            if (this.selectedItem === false) {
                const dataVal = this.forceSelection ? null : this.localControl.value;
                this.control.setValue(dataVal);
                this.control.updateValueAndValidity();
                this.control.markAsTouched();
                setTimeout(() => this.onChange(dataVal), 100);
                return;
            }
        }, 500);
    }

    public hasRequiredField(abstractControl: AbstractControl | null): any {
        if (abstractControl?.validator) {
            const validator = abstractControl.validator({} as AbstractControl);
            if (validator && validator['required']) {
                return true;
            }
        }
        return false;
    }

    public onKeydownEvent(event: any | undefined): any {
        //setting value to null when Backspace/delete is Pressed!
        if (event.keyCode == 8 || event.keyCode == 46) {
            this.OnChange.emit({ options: { value: null } });
            setTimeout(() => this.onChange(null), 100);
        }
    }

    public onScroll(): void {
        this.nextPage$.next();
    }

    public optionSelected(event: MatAutocompleteSelectedEvent): void {
        const selectedValue = event ? this.getOptionValue(event.option.value) : null;
        this.selectedItem = selectedValue;
        this.control.setValue(selectedValue);
        this.control.markAsTouched();
        this.multiBinding(selectedValue);
        this.OnChange.emit({ event, options: event?.option });
        setTimeout(() => this.onChange(selectedValue), 100);
    }

    public registerOnChange(fn: any | undefined): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any | undefined): void {
        this.onTouched = fn;
    }

    public reloadData(value: string): void {
        // const defaultNonSearchFilterData = this.searchFilters?.filter((x) => x.defaultSearch !== true);
        // this.defaultFilterData(defaultNonSearchFilterData, value, true);
        this.bindInitialCalled = false;
        this.bindInitialData(value);
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public writeValue(value: any | undefined | { bind: any | undefined }): void {
        if (value?.bind) {
            this.value = value.bind;
            this.bindInitialCalled = false;
        } else {
            this.value = value;
        }
        if (this.value && (this.value + '').length > 0) {
            this.bindInitialData(this.value);
        }
        this.control.patchValue(value);
        this.localControl.patchValue(value);
        this.onChange(value);
    }

    private addToFilterArray(metaInfo: SearchFilterModel, qValue: string): any {
        let comparison = metaInfo.comparison || IComparisonType.String;
        if (comparison === IComparisonType.String) {
            if (qValue.endsWith('%')) comparison = IComparisonType.StartsWith;
            else if (qValue.startsWith('%')) comparison = IComparisonType.EndsWith;
            else comparison = IComparisonType.Contains;
        }
        return this.getSearchComparerData(comparison, metaInfo, qValue);
    }

    private bindInitialData(value: any): any {
        if (this.bindInitialCalled) {
            return;
        }
        this.bindInitialCalled = true;
        this.localControl.setValue(value, { emitEvent: false });
        this.localControl.markAllAsTouched();
        this.selectedItem = false;
        if (this.bindInitialValue) {
            this.service({
                currentPage: 0,
                rowsPerPage: 1,
                sort: null,
                filters: {
                    key: this.optionValue || this.dataKey,
                    value: value
                }
            }).subscribe((data) => {
                if (data && data.metadata.pagination.totalItemCount > 0) {
                    const displayValue = this.displayWith(data.items[0]);
                    this.selectedItem = this.optionValue ? value : data.items[0];
                    setTimeout(() => (this.primaryAutoInput.nativeElement.value = displayValue), 300);
                    this.writeValue(this.optionValue ? value : data.items[0]);
                    this.OnInitialDataLoaded.emit({ event: null, options: { value: data.items[0] } });
                }
            });
        } else {
            this.selectedItem = value;
            setTimeout(() => (this.primaryAutoInput.nativeElement.value = value), 300);
            this.writeValue(value);
        }
    }

    private bindSearch(iniValue?: any): any {
        const filter$ = this.localControl.valueChanges.pipe(
            startWith(iniValue ?? ''),
            debounceTime(200),
            distinctUntilChanged(),
            filter((q) => typeof q === 'string' && q.length >= (this.minSearchLength || 3))
        );
        this.filteredOptions = filter$.pipe(
            switchMap((query: string) => {
                let currentPage = 1;
                this.selectedItem = false;
                this.loading$ = true;
                const limit = 10;
                return this.nextPage$.pipe(
                    startWith(currentPage),
                    exhaustMap(() => {
                        this.loading$ = true;
                        this.control.setValue(null);
                        this.noResult = false;
                        this.selectedItem = false;
                        this._cd.markForCheck();
                        let filterData = '';

                        this.getDefaultSearchFilter();
                        query = query.trim();
                        filterData = this.getFilterData(query);
                        filterData = filterData.replaceAll('%20', ' ') + (this.caseInsensitive ? '/i' : '');
                        let filterValue =
                            query.length > 2 && query.split('').filter((x) => x == '%').length == query.length
                                ? ''
                                : filterData;
                        if (this.additionalFilters) {
                            const addFilters =
                                typeof this.additionalFilters == 'string'
                                    ? this.additionalFilters
                                    : this.additionalFilters();
                            if (addFilters && addFilters.length > 0) {
                                filterValue = filterValue.length > 0 ? `${addFilters},(${filterValue})` : addFilters;
                            }
                        }
                        const offset = (currentPage - 1) * limit;
                        return this.service({
                            currentPage: offset,
                            rowsPerPage: limit,
                            sort: null,
                            filters: (filterValue + '').trim()
                        }).pipe(
                            first(),
                            finalize(() => {
                                this.loading$ = false;
                                this._cd.markForCheck();
                            }),
                            map((data) => {
                                this.noResult = data.metadata.pagination.totalItemCount <= 0;
                                if (data?.items?.length <= 0) {
                                    this.noResult = true;
                                }
                                return data;
                            })
                        );
                    }),
                    tap(() => currentPage++),
                    takeWhile((p: any) => p.items.length > 0, true),
                    scan((allItems, newItems) => allItems.concat(newItems.items), [] as any[]),
                    delay(10)
                );
            })
        );
    }

    private getAutoFilterData(data: any) {
        if (this.searchFilters && data) {
            const localizeData = data[this.optionLocalLabel];
            let filterData = '';
            const filterArray: any[] = [];
            this.searchFilters.forEach((currentValue: any) => {
                if (currentValue.fieldName.indexOf('.') === -1) {
                    if (localizeData && localizeData[currentValue.fieldName]) {
                        filterArray.push(localizeData[currentValue.fieldName]);
                        filterData = `${filterData} - ${localizeData[currentValue.fieldName]}`;
                    } else if (data[currentValue.fieldName]) {
                        filterArray.push(data[currentValue.fieldName]);
                    }
                    return filterArray;
                } else {
                    const fields: string[] = currentValue.fieldName.split('.');
                    let value = data;
                    for (let i = 0, len = fields.length; i < len; ++i) {
                        if (value == null) {
                            return null;
                        }
                        value = value[fields[i]];
                    }
                    filterArray.push(value);

                    return filterArray;
                }
            });

            filterData = filterArray.join(' - ');
            return filterData;
        }
        return data;
    }

    private getDefaultSearchFilter(): void {
        if (this.searchFilters?.length > 0) {
            return;
        }
        this.searchFilters.push({
            fieldName: this.searchFieldName || this.optionLabel,
            localizedFieldName: this.searchLocalizedFieldName,
            type: this.optionLabelType,
            searchStringLength: { '>=': 1 },
            comparison: this.defaultComparison
        });
    }

    private getSearchComparerData(comparison: IComparisonType, metaInfo: SearchFilterModel, qValue: string) {
        let searchData = '';
        let localSearchData = '';
        const value = encodeURIComponent(this.sanitizeValue(qValue));
        const quote = metaInfo.type == 'number' ? '' : '';
        const searchFieldName = metaInfo.searchFieldName || metaInfo.fieldName;
        const searchLocalizedFieldName = metaInfo.searchLocalizedFieldName || metaInfo.localizedFieldName;

        if (comparison === IComparisonType.String) {
            if (qValue.endsWith('%')) comparison = IComparisonType.StartsWith;
            else if (qValue.startsWith('%')) comparison = IComparisonType.EndsWith;
            else comparison = IComparisonType.Contains;
        }

        switch (comparison) {
            default:
                searchData =
                    metaInfo.type == 'number'
                        ? `${searchFieldName}=${value}`
                        : `${searchFieldName}${comparison}${quote}${value}${quote}`;
                localSearchData =
                    metaInfo.type == 'number'
                        ? `${searchLocalizedFieldName}=${value}`
                        : `${searchLocalizedFieldName}${comparison}${quote}${value}${quote}`;
                break;
        }
        if (this.currentLanguage != 'en' && searchLocalizedFieldName) {
            return ` (${searchData}|${localSearchData}) `;
        }
        return searchData;
    }

    private multiBinding(dataItem: any) {
        if (dataItem == null || dataItem == undefined) return;
        if (this.autoCompleteBinding) {
            const keys = Object.keys(this.autoCompleteBinding);
            keys.forEach((key) => {
                const dataValue = dataItem[this.autoCompleteBinding[key]];
                const control = this.controlContainer.control.get(key);
                if (control) {
                    control.patchValue(dataValue);
                    control.updateValueAndValidity();
                }
            });
        }
    }

    private parseSearchStringLength(data: number, length?: ISearchStringLengthType): boolean {
        if (!length) return true;
        const lengthObject: ISearchStringLengthType = typeof length === 'number' ? { '<=': length } : length;
        for (const [key, keyValue] of Object.entries(lengthObject)) {
            const value = keyValue || this.minSearchLength;
            switch (key) {
                case '=':
                    return value == data;
                case '>=':
                    return data >= value;
                case '>':
                    return data > value;
                case '<':
                    return data < value;
                default:
                    return data <= value;
            }
        }
        return false;
    }

    private sanitizeValue(value: string): string {
        return value.replace('%', '').replace('"', '\\"').replace(/\\/g, '\\\\');
    }
}

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'cvs-autocomplete' })
export class CvsFormAutocompleteSuffixDirective {}

export class FilterModel {
    constructor(
        public property: string,
        public comparison: string,
        public value: any | undefined
    ) {}
}

export class FiltersModel extends Array<FilterModel> {
    public add(property: string, comparison: string, value: any | undefined): void {
        if (!property || !value) {
            return;
        }
        this.removeIndex(this.findIndex((x) => x.property === property && x.comparison === comparison));
        this.push(new FilterModel(property, comparison, value));
    }

    private removeIndex(index: number) {
        if (index < 0) {
            return;
        }
        this.splice(index, 1);
    }
}

export const AUTOCOMPLETE_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AutocompleteComponent),
    multi: true
};
