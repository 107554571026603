import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CookieService } from './cookie.service';

export interface TenantDto {}

@Injectable({ providedIn: 'root' })
export class TenantService {
    private tenant: TenantDto | null;

    constructor(private cookieService: CookieService) {
        this.tenant = null;
    }

    /**
     * Creates HTTP headers with the tenant name.
     * @param headers Existing HttpHeaders
     * @param tenantName Name of the tenant
     * @returns HttpHeaders with tenant name
     */
    public createTenantHeaders(headers: HttpHeaders, tenantName: string): HttpHeaders {
        return headers.append('X-Tenant', tenantName);
    }

    /**
     * Retrieves the current tenant data (if set).
     * @returns TenantDto | null
     */
    public getTenantData(): TenantDto | null {
        return this.tenant;
    }

    /**
     * Retrieves the tenant name, checking URL, query parameter, and cookie in that order.
     * The tenant name can come from the subdomain or query parameters.
     * @returns Tenant name (string)
     */
    public getTenantName(): string {
        const urlParams = new URLSearchParams(window.location.search);
        const tenantDomain = this.getDomainName(location.host); // Updated to handle full domains
        const tenantQuery = urlParams.get('tenant');
        const tenantCookie = this.cookieService.getCookie('X-Tenant');
        let tenantName = 'default';

        // Check for tenant from subdomain or domain
        if (tenantDomain) {
            tenantName = tenantDomain;
        } else if (tenantQuery) {
            tenantName = tenantQuery;
        } else if (tenantCookie) {
            tenantName = tenantCookie;
        }

        // Special case for 'office' tenant name
        if (tenantName === 'office') {
            tenantName = 'default';
        }

        return tenantName;
    }

    /**
     * Sets the tenant name in a session cookie.
     * @param tenantName Name of the tenant to set
     */
    public setTenantCookie(tenantName: string) {
        if (!tenantName) {
            return;
        }

        const currentTenant = this.cookieService.getCookie('X-Tenant');

        // Only set the cookie if the tenant has changed
        if (tenantName === currentTenant) {
            return;
        }

        this.cookieService.setCookie({
            name: 'X-Tenant',
            value: tenantName,
            session: true // Cookie is session-based
        });
    }

    /**
     * Sets the tenant data.
     * @param value Tenant data to set
     */
    public setTenantData(value: TenantDto) {
        if (this.tenant === value) {
            return;
        }

        this.tenant = value;
    }

    /**
     * Extracts the domain or subdomain from the host.
     * For example:
     * - 'tenant.example.com' => 'tenant'
     * - 'example.com' => 'example'
     * @param host The full host (e.g., 'tenant.example.com')
     * @returns Tenant domain name (string)
     */
    private getDomainName(host: string): string {
        // Remove port if present (after the colon)
        const hostWithoutPort = host.split(':')[0];

        // Split the host into parts (subdomain.domain.tld)
        const parts = hostWithoutPort.split('.');

        // If there are only two parts, it's a root domain (e.g., example.com)
        if (parts.length <= 2) {
            return parts[0]; // Return the domain itself (e.g., 'example')
        }

        // For more than two parts, consider the first part as the subdomain
        return parts[0]; // Return the subdomain part (e.g., 'tenant')
    }
}
