<cvs-dialog>
    <ng-container cvsDialogTitle *ngIf="title">{{ title }}</ng-container>
    <ng-container cvsDialogContent>
        <div class="flex flex-col sm:flex-row flex-auto items-center sm:items-start">
            <!-- Icon -->
            <ng-container *ngIf="icon.show">
                <div class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full" [ngClass]="{
                        'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600':
                            icon.color === 'primary',
                        'text-accent-600 bg-accent-100 dark:text-accent-50 dark:bg-accent-600':
                            icon.color === 'accent',
                        'text-warn-600 bg-warn-100 dark:text-warn-50 dark:bg-warn-600':
                            icon.color === 'warn',
                        'text-gray-600 bg-gray-100 dark:text-gray-50 dark:bg-gray-600':
                            icon.color === 'basic',
                        'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600':
                            icon.color === 'info',
                        'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500':
                            icon.color === 'success',
                        'text-amber-500 bg-amber-100 dark:text-amber-50 dark:bg-amber-500':
                            icon.color === 'warning',
                        'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600':
                            icon.color === 'error'
                    }">
                    <mat-icon class="text-current icon-size-8" [svgIcon]="icon.name"></mat-icon>
                </div>
            </ng-container>

            <ng-container *ngIf="message">
                <div class="text-secondary" [innerHTML]="message | nl2br"></div>
            </ng-container>
        </div>

    </ng-container>
    <ng-container cvsDialogActions>
        <button mat-stroked-button color="primary" [matDialogClose]="'close'">
            {{ closeButton | transloco }}
        </button>
    </ng-container>
</cvs-dialog>