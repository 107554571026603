import { ActiveToast, ToastrService } from 'ngx-toastr';

import { Injectable } from '@angular/core';
import { environment } from '@environment';

import { SSRService } from './ssr.service';

interface Message {
    text: string;
    title: string;
}

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    public readonly CRITICAL = 1;
    public readonly ERROR = 2;
    public readonly INFO = 4;
    public readonly WARNING = 3;

    constructor(
        private toastr: ToastrService,
        private ssr: SSRService
    ) {}

    public showCritical(message: any, title: string = ''): ActiveToast<any>[] {
        if (this.ssr.isBrowser) {
            const arrayObs: ActiveToast<any>[] = [];
            for (const msg of this.extractMessages(message, title)) {
                if (msg === null) {
                    continue;
                }
                arrayObs.push(this.toastr.error(msg.text, msg.title, { enableHtml: true }));
            }
            return arrayObs;
        }
        return [];
    }

    public showDeprecation(message: string): void {
        if (environment.production) {
            // eslint-disable-next-line no-console
            console.warn(message);
        } else {
            this.showWarning(message);
        }
    }

    public showError(message: any, title: string = ''): ActiveToast<any>[] {
        if (this.ssr.isBrowser) {
            const arrayObs: ActiveToast<any>[] = [];
            for (const msg of this.extractMessages(message, title)) {
                if (msg === null) {
                    continue;
                }
                arrayObs.push(this.toastr.error(msg.text, msg.title, { enableHtml: true }));
            }
            return arrayObs;
        }
        return [];
    }

    public showInfo(message: any, title: string = ''): ActiveToast<any>[] {
        if (this.ssr.isBrowser) {
            const arrayObs: ActiveToast<any>[] = [];
            for (const msg of this.extractMessages(message, title)) {
                if (msg === null) {
                    continue;
                }
                arrayObs.push(this.toastr.info(msg.text, msg.title, { enableHtml: true }));
            }
            return arrayObs;
        }
        return [];
    }

    public showInvalidFormWarning(message?: any, title: string = ''): void {
        this.showWarning(message || 'Check if all fields have the correct information and then retry.', title);
    }

    public showSuccess(message: any, title: string = ''): ActiveToast<any>[] {
        if (this.ssr.isBrowser) {
            const arrayObs: ActiveToast<any>[] = [];
            for (const msg of this.extractMessages(message, title)) {
                if (msg === null) {
                    continue;
                }
                arrayObs.push(this.toastr.success(msg.text, msg.title, { enableHtml: true }));
            }
            return arrayObs;
        }
        return [];
    }

    public showWarning(message: any, title: string = ''): ActiveToast<any>[] {
        if (this.ssr.isBrowser) {
            const arrayObs: ActiveToast<any>[] = [];
            for (const msg of this.extractMessages(message, title)) {
                if (msg === null) {
                    continue;
                }
                arrayObs.push(this.toastr.warning(msg.text, msg.title, { enableHtml: true }));
            }
            return arrayObs;
        }
        return [];
    }

    private extractMessages(msg: any, title: string): Message[] {
        const messages: Message[] = [];

        if (msg === null) {
            messages.push(null);
            return messages;
        }

        if (msg.error && msg.error instanceof ProgressEvent) {
            messages.push({ text: msg.message, title });
            return messages;
        }

        if (msg.error) {
            msg = msg.error;
        }

        if (this.isDict(msg)) {
            for (const k in msg) {
                messages.push({ text: msg[k], title: title || k });
            }
            return messages;
        }

        if (Array.isArray(msg)) {
            for (const m of msg) {
                messages.push({ text: m, title });
            }
            return messages;
        }

        messages.push({ text: msg, title });
        return messages;
    }

    private isDict(dict: any): boolean {
        return typeof dict === 'object' && !Array.isArray(dict);
    }
}
