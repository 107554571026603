import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ShortcutsComponent } from 'src/app/layout/common/shortcuts/shortcuts.component';
import { MatSharedModule } from 'src/app/shared';

@NgModule({
    declarations: [ShortcutsComponent],
    imports: [CommonModule, ReactiveFormsModule, RouterModule, OverlayModule, PortalModule, MatSharedModule],
    exports: [ShortcutsComponent]
})
export class ShortcutsModule {}
