import { from, Observable, of, throwError } from 'rxjs';
import { AuthUtils } from 'src/app/core/auth/auth.utils';
import { UserService } from 'src/app/core/user/user.service';

import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { IAuthService } from '@cvs/auth';

/***
 * Deprecated
 */
@Injectable()
export class AuthServiceDeprecated {
    private _authenticated = false;
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private injector: Injector
    ) {
        this.injector
            .get(IAuthService)
            .getUserInfo()
            .subscribe((userInfo) => {
                this._userService.user = userInfo?.info;
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('access_token', token);
    }

    get accessToken(): string {
        return localStorage.getItem('access_token') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError(() => new Error('User is already logged in.'));
        }
        const result = this.injector
            .get(IAuthService)
            .loginWithPassword({
                username: credentials.email,
                password: credentials.password
            })
            .then((response: any) => {
                {
                    // Store the access token in the local storage
                    this.accessToken = response.accessToken;

                    // Set the authenticated flag to true
                    this._authenticated = true;
                    // Store the user on the user service
                    this._userService.user = response['info'] ? response['info'] : response;

                    // Return a new observable with the response
                    return response;
                }
            });
        return from(result);
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        const result = this.injector.get(IAuthService).hasValidIdToken();
        return of(result);
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        return from(
            this.injector
                .get(IAuthService)
                .logout()
                .then(() => {
                    localStorage.removeItem('accessToken');
                    this._authenticated = false;
                    return true;
                })
        );
        // Return the observable
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
