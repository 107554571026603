import compare from 'just-compare';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { deepMerge } from './object-utils';
import { DeepPartial } from './utility.model';

export class InternalStore<State> {
    private state$ = new BehaviorSubject<State>(this.initialState);

    private update$ = new Subject<DeepPartial<State>>();
    constructor(private initialState: State) {}

    get state(): State {
        return this.state$.value;
    }

    sliceState = <Slice>(
        selector: (state: State) => Slice,
        compareFn: (s1: Slice, s2: Slice) => boolean = compare
    ): Observable<Slice> => this.state$.pipe(map(selector), distinctUntilChanged(compareFn));

    sliceUpdate = <Slice>(
        selector: (state: DeepPartial<State>) => Slice,
        filterFn = (x: Slice): boolean => x !== undefined
    ): Observable<Slice> => this.update$.pipe(map(selector), filter(filterFn));

    patch(state: Partial<State>): void {
        let patchedState = state as State;

        if (typeof state === 'object' && !Array.isArray(state)) {
            patchedState = { ...this.state, ...state };
        }

        this.state$.next(patchedState);
        this.update$.next(patchedState as DeepPartial<State>);
    }

    deepPatch(state: DeepPartial<State>): void {
        this.state$.next(deepMerge(this.state, state));
        this.update$.next(state);
    }

    set(state: State): void {
        this.state$.next(state);
        this.update$.next(state as DeepPartial<State>);
    }

    reset(): void {
        this.set(this.initialState);
    }
}
