import { BehaviorSubject, map, Subject, takeUntil } from 'rxjs';
import { NavigationService } from 'src/app/core/navigation/navigation.service';
import { Navigation } from 'src/app/core/navigation/navigation.types';
import { LayoutService } from 'src/app/layout/layout.service';

import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit,
    ViewEncapsulation
} from '@angular/core';
import { COMMON_LAYOUT_HEADER_ACTIONS, CommonLayoutComponentOutlet } from '@cvs/layouts/layout';
import {
    FuseNavigationService, FuseVerticalNavigationComponent
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';

const timings = '200ms ease-in-out';
const hidden = 'translateX(-100%)';
const visible = 'translateX(0)';
@Component({
    selector: 'centvis1-layout',
    templateUrl: './centvis1.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./centvis1.component.scss'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [style({ transform: hidden }), animate(timings, style({ transform: visible }))]),
            transition(':leave', [style({ transform: visible }), animate(timings, style({ transform: hidden }))])
        ]),
        trigger('sidebarTrigger', [
            // To add a cool "enter" animation for the sidebar
            //transition(':enter', [style({ marginLeft: '-220px' }), animate('250ms ease-in', style({ marginLeft: 0 }))]),

            // To define animations based on trigger actions
            state('open', style({})),
            state('close', style({ marginLeft: '-220px' })),
            transition('open => close', [animate('250ms ease-in')]),
            transition('close => open', [animate('250ms ease-out')])
        ])
    ]
})
export class Centvis1LayoutComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    readonly _defaultTitle$ = this.layoutService.title$;
    public readonly currentTitle$ = this._defaultTitle$.pipe(
        map((defaultTitle) => {
            this.cdr.markForCheck();
            if (!this.pageTitle) {
                return defaultTitle;
            }
            return this.pageTitle;
        })
    );
    readonly hasNewIndicator$ = this.layoutService.hasNewIndicator$;

    public isOpened: boolean;
    public isScreenSmall: boolean;
    public navigation: Navigation;
    public pageTitle: string;
    public sidebarExpanded = true;

    /**
     * Constructor
     */
    constructor(
        private readonly layoutService: LayoutService,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private cdr: ChangeDetectorRef,
        @Inject(COMMON_LAYOUT_HEADER_ACTIONS)
        public headerActionComponent: BehaviorSubject<CommonLayoutComponentOutlet> // eslint-disable-line
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    public get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    public ngOnInit(): void {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    /**
     * On destroy
     */
    public ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    public toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
