import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SpeechRecognitionComponent } from './speech-recognition.component';

@NgModule({
    declarations: [SpeechRecognitionComponent],
    imports: [CommonModule, MatIconModule],
    exports: [SpeechRecognitionComponent]
})
export class SpeechRecognitionModule {}
