export type IJoinOperator = 'AND' | 'OR';

export type IDataType = 'number' | 'date' | 'string'; //| 'long';

export enum IComparisonType {
    Equal = '=',
    NotEqual = '!=',
    LessThan = '<',
    GreaterThan = '>',
    GreaterThanOrEqual = '>=',
    LessThanOrEqual = '<=',
    Contains = '=*',
    NotContains = '!*',
    StartsWith = '^',
    NotStartsWith = '!^',
    EndsWith = '$',
    NotEndsWith = '!$',
    String = 'str'
}
export type ISearchStringLengthType = number | Partial<Record<'=' | '>=' | '<=' | '>' | '<', number>>;
export interface SearchFilterModel {
    fieldName: string;
    localizedFieldName?: string;
    searchFieldName?: string;
    searchLocalizedFieldName?: string;
    type: IDataType | null;
    comparison: IComparisonType | null;
    searchStringLength?: ISearchStringLengthType;
    caseInsensitive?: boolean;
}
