import { CommonModule } from '@angular/common';
import { Component, Directive, Input, NgModule, TemplateRef } from '@angular/core';

@Component({
    selector: 'cvs-header',
    template: '<ng-content></ng-content>'
})
export class HeaderComponent {}

@Component({
    selector: 'cvs-footer',
    template: '<ng-content></ng-content>'
})
export class FooterComponent {}

@Directive({
    selector: '[cvsTemplate]',
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {}
})
export class CvsNgTemplateDirective {
    @Input() type?: string;

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('cvsTemplate') name?: string;

    constructor(public template: TemplateRef<any>) {}

    getType(): string | undefined {
        return this.name;
    }
}

@NgModule({
    imports: [CommonModule],
    exports: [HeaderComponent, FooterComponent, CvsNgTemplateDirective],
    declarations: [HeaderComponent, FooterComponent, CvsNgTemplateDirective]
})
export class CvsNgTemplateModule {}
