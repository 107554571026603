import { NgModule } from '@angular/core';
import { LanguagesComponent } from 'src/app/layout/common/languages/languages.component';
import { MatSharedModule } from 'src/app/shared';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
    declarations: [LanguagesComponent],
    imports: [MatSharedModule, SharedModule],
    exports: [LanguagesComponent]
})
export class LanguagesModule {}
