import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CvsConfirmationService } from '@cvs/components/confirmation/confirmation.service';
import { CvsConfirmationDialogComponent } from '@cvs/components/confirmation/dialog/dialog.component';
import { CvsNl2BrPipeModule } from '@cvs/pipes';

@NgModule({
    declarations: [CvsConfirmationDialogComponent],
    imports: [MatButtonModule, MatDialogModule, MatIconModule, CommonModule, CvsNl2BrPipeModule],
    providers: [CvsConfirmationService]
})
export class CvsConfirmationModule {
    /**
     * Constructor
     */
    constructor(private _cvsConfirmationService: CvsConfirmationService) {}
}
