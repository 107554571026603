export function isNullOrUndefinedOrEmpty(value: any): boolean {
    return value === null || value === undefined || value === '';
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
export function noop(): () => void {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fn = function () {};
    return fn;
}

export const camelize = (str: string): string => {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
        if (Number(match) === 0) return ''; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
};
