/* eslint-disable */
import { DateTime } from 'luxon';

/* Get the current instant */
const now = DateTime.now();

export const project = {
    githubIssues: {
        overview: {
            'this-week': {
                'new-issues': 75,
                'closed-issues': 214,
                'fixed': 3,
                'wont-fix': 4,
                're-opened': 8,
                'needs-triage': 6
            },
            'last-week': {
                'new-issues': 72,
                'closed-issues': 197,
                'fixed': 6,
                'wont-fix': 11,
                're-opened': 6,
                'needs-triage': 5
            }
        },
        labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        series: {
            'this-week': [
                {
                    name: 'New Incidents',
                    type: 'line',
                    data: [11, 10, 8, 11, 8, 10, 17]
                },
                {
                    name: 'Closed Incidents',
                    type: 'line',
                    data: [42, 28, 43, 34, 20, 25, 22]
                }
            ],
            'last-week': [
                {
                    name: 'New Incidents',
                    type: 'line',
                    data: [9, 8, 10, 12, 7, 11, 15]
                },
                {
                    name: 'Closed Incidents',
                    type: 'line',
                    data: [37, 32, 39, 27, 18, 24, 20]
                }
            ]
        }
    },
    taskDistribution: {
        overview: {
            'this-week': {
                'completed': 148,
                'new': 221
            },
            'last-week': {
                'completed': 233,
                'new': 156
            }
        },
        labels: ['Shipment Coordination', 'Movement & Handling', 'Damage/Loss', 'Warehouse Handling'],
        series: {
            'this-week': [15, 20, 38, 27],
            'last-week': [19, 16, 42, 23]
        }
    },
    schedule: {
        today: [
            {
                title: 'Review Supplier Quality',
                requestNo: 'INCIDENT-00021',
                subTitle:
                    "Conduct a review of the supplier's quality processes to prevent material deficiencies in future shipments.",
                assignedTo: 'Michael Brown',
                time: '2023-08-28',
                status: 'In Progress'
            },
            {
                title: 'Supplier Collaboration',
                requestNo: 'INCIDENT-00032',
                subTitle:
                    'Collaborate with key suppliers to enhance quality control practices and prevent future material deficiencies.',
                assignedTo: 'Ella Davis',
                time: '2023-08-29',
                status: 'In Progress'
            },
            {
                title: 'Implement Training Program',
                requestNo: 'INCIDENT-00034',
                subTitle:
                    'Develop and implement a training program for operators to ensure proper equipment handling and maintenance.',
                assignedTo: 'Emily Johnson',
                time: '2023-09-01',
                status: 'Not Started'
            },
            {
                title: 'Enhance Training Documentation',
                requestNo: 'INCIDENT-00045',
                subTitle:
                    'Enhance training materials to ensure that new employees receive comprehensive training on quality standards.',
                assignedTo: 'Olivia Smith',
                time: '2023-09-10',
                status: 'Not Started'
            },
            {
                title: 'Risk Assessment Workshop',
                requestNo: 'INCIDENT-00049',
                subTitle:
                    'Organize a workshop to identify potential risks across processes and develop mitigation strategies.',
                assignedTo: 'William Brown',
                time: '2023-09-18',
                status: 'Planned'
            }
        ],
        tomorrow: [
            {
                title: 'Investigate Equipment Failure',
                requestNo: 'INCIDENT-00011',
                subTitle:
                    'Conduct a thorough investigation into the recent equipment failure during the production process.',
                assignedTo: 'John Smith',
                time: '2023-08-27',
                status: 'In Progress'
            },
            {
                title: 'Review Documentation Errors',
                requestNo: 'INCIDENT-00019',
                subTitle:
                    'Review recent documentation errors and implement measures to prevent inaccurate record keeping.',
                assignedTo: 'Emma Johnson',
                time: '2023-08-30',
                status: 'In Progress'
            },
            {
                title: 'Root Cause Analysis - Batch Defect',
                requestNo: 'INCIDENT-00025',
                subTitle:
                    'Perform a root cause analysis to identify the reasons behind the recent batch defect in product XYZ.',
                assignedTo: 'Jane Doe',
                time: '2023-09-02',
                status: 'Not Started'
            },
            {
                title: 'Supplier Communication',
                requestNo: 'INCIDENT-00035',
                subTitle:
                    'Communicate with a supplier to address inconsistent material specifications received in recent shipments.',
                assignedTo: 'Ava Wilson',
                time: '2023-09-15',
                status: 'Not Started'
            },
            {
                title: 'SOP Review and Update',
                requestNo: 'INCIDENT-00042',
                subTitle: 'Review and update Standard Operating Procedures (SOPs) to reflect recent process changes.',
                assignedTo: 'Liam Martinez',
                time: '2023-09-24',
                status: 'Planned'
            }
        ],
        recent: [
            {
                title: 'Temperature Excursion in Cold Storage - raised by Pharmacy Technician',
                requestNo: 'INCIDENT-00041',
                subTitle:
                    'The temperature in the cold storage room exceeded the acceptable range, potentially affecting the stored medications.',
                assignedTo: 'Sarah Johnson',
                time: '2023-08-28',
                status: 'In Progress'
            },
            {
                title: 'Supply Chain Delay - raised by Inventory Manager',
                requestNo: 'INCIDENT-00048',
                subTitle:
                    'There is a delay in receiving a critical medication shipment from the supplier, potentially impacting patient treatments.',
                assignedTo: 'Emily Adams',
                time: '2023-08-25',
                status: 'In Progress'
            },
            {
                title: 'Incorrect Labeling of Medication - raised by Pharmacy Supervisor',
                requestNo: 'INCIDENT-00049',
                subTitle:
                    'A batch of medications was incorrectly labeled with the wrong dosage instructions, posing a potential patient safety risk.',
                assignedTo: 'Jennifer Smith',
                time: '2023-08-20',
                status: 'In Progress'
            },
            {
                title: 'Medication Shortage Alert - raised by Inventory Coordinator',
                requestNo: 'INCIDENT-00052',
                subTitle:
                    'A shortage of a critical medication was identified in the inventory management system, requiring immediate action to source alternative options.',
                assignedTo: 'Olivia Taylor',
                time: '2023-08-18',
                status: 'Open'
            },
            {
                title: 'Product Recall Initiation- raised by Quality Assurance Manager ',
                requestNo: 'INCIDENT-00056',
                subTitle:
                    'A quality issue was identified in a batch of medications, necessitating a product recall to ensure patient safety.',
                assignedTo: 'Thomas Walker',
                time: '2023-08-15',
                status: 'In Progress'
            }
        ],
        closed: [
            {
                title: 'Medication Dispensing Error - raised by Pharmacist',
                requestNo: 'INCIDENT-00042',
                subTitle:
                    'A medication dispensing error occurred, leading to a patient receiving the wrong dosage of a prescription.',
                assignedTo: 'Mark Davis',
                time: '2023-08-18',
                status: 'Closed'
            },
            {
                title: 'Medication Expiry Extension - raised by Regulatory Affairs Specialist',
                requestNo: 'INCIDENT-00043',
                subTitle:
                    'Due to supply chain disruptions, a decision was made to extend the expiry date of a batch of medications after thorough testing and assessment.',
                assignedTo: 'William Green',
                time: '2023-08-12',
                status: 'Closed'
            },
            {
                title: 'Adverse Event Reporting - raised by Pharmaceutical Researcher',
                requestNo: 'INCIDENT-00044',
                subTitle:
                    'An adverse event was reported by a patient who experienced unexpected side effects after taking a specific medication.',
                assignedTo: 'Robert Brown',
                time: '2023-08-10',
                status: 'Closed'
            },
            {
                title: 'Compounding Process Deviation - raised by Compounding Pharmacist',
                requestNo: 'INCIDENT-00053',
                subTitle:
                    'During the compounding process, an incorrect ingredient was used, resulting in a batch of compounded medications with inconsistent potency.',
                assignedTo: 'Daniel Wilson',
                time: '2023-08-08',
                status: 'Closed'
            },
            {
                title: 'Expired Medication Discovery - raised by Pharmacy Assistant',
                requestNo: 'INCIDENT-00054',
                subTitle:
                    'Expired medications were found on the shelves during routine stock checking, indicating an issue with inventory management.',
                assignedTo: 'Michael Brown',
                time: '2023-08-05',
                status: 'Closed'
            }
        ]
    }
};
