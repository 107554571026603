import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';

import { LocationStrategy } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@cvs/services';
import { AuthzConfig } from '@cvs/types';
import { environment } from '@environment';

@Injectable()
export class AuthConfigService {
    private readonly authConfig: AuthzConfig;
    constructor(
        @Inject(WINDOW) private window: Window,
        oAuthService: OAuthService,
        private locationStrategy: LocationStrategy
    ) {
        this.authConfig = environment.authConfig;
        const config = this.getConfig();
        oAuthService.configure(config);
    }
    public getConfig(): AuthConfig {
        const baseUrl = this.authConfig.baseUrl;
        const tenantPath = this.authConfig.tenantPath;
        const appUrl = this.window.origin + this.locationStrategy.getBaseHref();
        const issuerUrl = `${baseUrl}${tenantPath}`;
        const sessionIframeUrl = `${issuerUrl}${this.authConfig.sessionIframeUrl}`;
        return {
            issuer: issuerUrl,
            clientId: this.authConfig.clientId,
            scope: this.authConfig.scope,
            userinfoEndpoint: this.authConfig.userinfoEndpoint,
            checkOrigin: false,
            redirectUri: appUrl + 'signed-in-redirect',
            responseType: 'token',
            sessionChecksEnabled: false,
            sessionCheckIFrameUrl: sessionIframeUrl,
            silentRefreshRedirectUri: appUrl + '/assets/silent-check-sso.html',
            useSilentRefresh: true,
            customTokenParameters: ['session_state'],
            useIdTokenHintForSilentRefresh: false,
            requireHttps: environment.production,
            showDebugInformation: !environment.production,
            dummyClientSecret: String.fromCharCode(...JSON.parse(this.authConfig.himitsu)),
            useHttpBasicAuth: true,
            oidc: false,
            timeoutFactor: 0.75,
            disableAtHashCheck: true
        };
    }
}
