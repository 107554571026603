import { of, switchMap } from 'rxjs';

import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment } from '@angular/router';
import { IAuthService } from '@cvs/auth';

export const authGuardCanMatch: CanMatchFn = (route: Route, segments: UrlSegment[]) => {
    const authService = inject(IAuthService);
    const router = inject(Router);
    return authService.check().pipe(
        switchMap((authenticated) => {
            // If the user is not authenticated...
            if (!authenticated) {
                // Redirect to the sign-in page with a redirectUrl param
                const redirectURL = `/${segments.join('/')}`.toLowerCase();
                const urlTree = redirectURL.includes('sign-out')
                    ? router.parseUrl('sign-in')
                    : router.parseUrl(`sign-in?redirectURL=${redirectURL}`);
                return of(urlTree);
            }

            // Allow the access
            return of(true);
        })
    );
};
