import { Injectable } from '@angular/core';
import { ApiEndpoint, Environment } from '@cvs/types';
import { InternalStore, urlJoin } from '@cvs/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const mapToApiUrl =
    (key: string, mock = false) =>
    (apis: ApiEndpoint): string => {
        const host = (apis[key] || apis.default).host || apis.default.host;
        const path = (apis[key] || apis.default).path || apis.default.path;
        return urlJoin(mock ? '' : host, path);
    };

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
    private readonly store = new InternalStore({} as Environment);

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    get createOnUpdateStream() {
        return this.store.sliceUpdate;
    }

    getEnvironment$(): Observable<Environment> {
        return this.store.sliceState((state) => state);
    }

    getEnvironment(): Environment {
        return this.store.state;
    }

    getApiUrl(key?: string, endpoint?: string): string {
        let isMock = false;
        if (endpoint.startsWith('#')) {
            isMock = true;
            endpoint = endpoint.substring(1);
        }
        return urlJoin(mapToApiUrl(key ?? 'default', isMock)(this.store.state.apiRoutes), endpoint ?? '');
    }

    getApiUrl$(key: string): Observable<string> {
        return this.store.sliceState((state) => state.apis).pipe(map(mapToApiUrl(key)));
    }

    setState(environment: Environment): void {
        this.store.set(environment);
    }
}
