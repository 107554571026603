<ol class="flex items-center whitespace-nowrap" aria-label="Breadcrumb">
    <ng-container *ngFor="let item of breadcrumb; let i = index; let isLast = last">
        <li class="inline-flex items-center">
            <a class="flex gap-x-2 underline hover:no-underline items-center leading-5 cvsui-text-default-label"
                *ngIf="item?.url" [routerLink]="item?.url" [queryParams]="item?.queryParams"
                [ngStyle]="{'color': fontColor}">{{ useTransloco? (item.label|transloco) : item.label }}</a>
            <span class="flex gap-x-2 items-center leading-5" *ngIf="!item?.url" [ngClass]="{
                'font-semibold cvsui-text-default-label-dark': isLast,
                'cvsui-text-default-label': !isLast,
            }">{{ useTransloco?
                (item.label|transloco) : item.label }}</span>
            <span class="flex items-center px-3" *ngIf="breadcrumb.length !== i+1">
                <mat-icon *ngIf="separatorIcon" class="icon-size-5 cvsui-text-default-label-dark" [svgIcon]="separatorIcon"></mat-icon>
                <span *ngIf="!separatorIcon" class="text-xxl leading-4 cvsui-text-default-label-dark">{{separatorSymbol}}</span>
            </span>
        </li>
    </ng-container>
</ol>