import { NgModule } from '@angular/core';

import { AlphabetsOnlyDirective } from './alphabet-allowed.directive';
import { NumbersOnlyDirective } from './numbers-only.directive';
import { CvsRegexPatternDirective } from './regex-pattern.directive';

@NgModule({
    declarations: [NumbersOnlyDirective, AlphabetsOnlyDirective, CvsRegexPatternDirective],
    exports: [NumbersOnlyDirective, AlphabetsOnlyDirective, CvsRegexPatternDirective]
})
export class CvsKeypressModule {}
