import { UserComponent } from 'src/app/layout/common/user/user.component';
import { SharedModule } from 'src/app/shared/shared.module';

import { NgModule } from '@angular/core';
import { TrackByPipeModule } from '@cvs/pipes';
import { MatSharedModule } from 'src/app/shared';

@NgModule({
    declarations: [UserComponent],
    imports: [MatSharedModule, SharedModule, TrackByPipeModule],
    exports: [UserComponent]
})
export class UserModule {}
