import { NgModule } from '@angular/core';

import { CvsErrorFocusDirective } from './error-focus.directive';
import { SelectOpenOnFocusDirective } from './mat-select-focus.directive';

@NgModule({
    declarations: [CvsErrorFocusDirective, SelectOpenOnFocusDirective],
    exports: [CvsErrorFocusDirective, SelectOpenOnFocusDirective]
})
export class CvsErrorFocusModule {}
