import { IconsModule } from 'src/app/core/icons/icons.module';
import { TranslocoCoreModule } from 'src/app/core/transloco/transloco.module';

import { NgModule, Optional, SkipSelf } from '@angular/core';
import { Auth2Module } from '@cvs/auth';

import { AuthModule } from './auth/auth.module';

@NgModule({
    providers: [],
    imports: [AuthModule, Auth2Module, IconsModule, TranslocoCoreModule]
})
export class CoreModule {
    /**
     * Constructor
     */
    constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }
    }
}
