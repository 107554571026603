import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cvsCompareWith'
})
export class CvsCompareWithPipe implements PipeTransform {
    transform(comparerKey: string, compareWithKey?: string): (o1: any, o2: any) => boolean {
        return function compareWith(o1: any, o2: any): any {
            const id1 = typeof o1 === 'string' ? o1 : o1[comparerKey];
            const id2 = typeof o2 === 'string' ? o2 : typeof compareWithKey === 'string' ? o2[compareWithKey] : o2;
            return id1 + '' === id2 + '';
        };
    }
}
@NgModule({
    declarations: [CvsCompareWithPipe],
    exports: [CvsCompareWithPipe]
})
export class CvsCompareWithPipeModule {}
