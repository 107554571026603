export enum GridifyConditionEnum {
    Equal = '=',
    NotEqual = '!=',
    LessThan = '<',
    GreaterThan = '>',
    GreaterThanOrEqual = '>=',
    LessThanOrEqual = '<=',
    Contains = '=*',
    NotContains = '!*',
    StartsWith = '^',
    NotStartsWith = '!^',
    EndsWith = '$',
    NotEndsWith = '!$'
}

export enum GridifyLogicalEnum {
    And = ',',
    Or = '|',
    CaseInsensitive = '/i'
}

export enum GridifyOrderEnum {
    Ascending = 'asc',
    Descending = 'desc'
}

export interface FilterType {
    key?: string;
    value?: string;
    caseInsensitive?: boolean;
    order?: GridifyOrderEnum;
    operator?: GridifyConditionEnum;
    label?: string;
}
export type OperatorType = GridifyLogicalEnum.Or | GridifyLogicalEnum.And;
export type FiltersType = (OperatorType | FilterType | FiltersType)[];
export type SortsType =
    | (
          | {
                field: string;
                direction: 'asc' | 'desc' | '';
            }
          | any
      )[]
    | any;
