<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>
<cvs-idle-bar></cvs-idle-bar>
<!-- Navigation -->
<nav class="fixed  z-999 w-full bg-primary border-b border-secondary">
    <div class="flex min-h-16 items-center justify-start">
        <button class="cursor-pointer h-16 group px-2 " (click)="toggleNavigation('mainNavigation')">
            <mat-icon svgIcon="feather:align-left"
                class="icon-size-6.5 text-on-primary group-hover:text-accent-600"></mat-icon>
        </button>
        <div class="flex-auto flex items-center justify-start">
            <a class="flex ml-3 mr-6" [routerLink]="'/home'"> <img class="h-10 w-auto text-primary"
                    [src]="'assets/logos/logo-on-light.svg' | assetsSource">
            </a>
            <div
                class="md:mr-24 border-l-2 border-on-primary-500/90 pl-6 hidden md:flex flex-col justify-start items-start ">
                <h1 *ngIf="(pageTitle ? (pageTitle | transloco) :
    (currentTitle$ | async) | transloco) as pgTitle" class="text-2xl text-on-primary/90 leading-6 font-semibold whitespace-nowrap">
                    {{ pgTitle }}</h1>
                <cvs-breadcrumb></cvs-breadcrumb>
            </div>
        </div>
        <!-- Components -->
        <div class="px-3 py-3 lg:px-5 lg:pl-3">
            <div class="flex items-center space-x-0.5 sm:space-x-2">
                <languages class="hidden md:block"></languages>
                <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
                <search *ngIf="false" [appearance]="'bar'"></search>
                <shortcuts *ngIf="false"></shortcuts>
                <messages *ngIf="true"></messages>
                <notifications *ngIf="true"></notifications>
                <button mat-icon-button [matTooltip]="'Chat' | transloco"  (click)="quickChat.toggle()">
                    <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'" class="text-on-primary"></mat-icon>
                </button>
                <user></user>
            </div>
        </div>
    </div>
    <div class="px-2 mb-0.5 border-t border-secondary flex md:hidden flex-col justify-start items-start ">
        <h1 *ngIf="(pageTitle ? (pageTitle | transloco) :
(currentTitle$ | async) | transloco) as pgTitle" class="text-2xl text-on-primary font-bold whitespace-nowrap">
            {{ pgTitle }}</h1>
        <cvs-breadcrumb></cvs-breadcrumb>
    </div>
</nav>
<!-- Content -->
<div class="flex flex-auto pt-28 md:pt-16 overflow-hidden relative">
    <div id="main-content" class="flex flex-col justify-between relative w-full h-full overflow-y-auto">
        <fuse-vertical-navigation class="bg-card fixed top-0 left-0 flex md:flex" [appearance]="'expo'"
            [mode]="isScreenSmall?'over':'side'" [name]="'mainNavigation'" [navigation]="navigation.compact"
            [opened]="!isScreenSmall" (openedChanged)="isOpened=$event">
            <div fuseVerticalNavigationContentHeader class=" h-28 md:h-16"></div>
        </fuse-vertical-navigation>
        <main [ngClass]="{'md:ml-16':isOpened}" class="flex flex-auto h-full transition-all">
            <router-outlet class="flex-auto" *ngIf="true"></router-outlet>
        </main>
        <footer class="bg-white border-t border-t-primary-100 z-999 p-2 md:p-2 print:hidden " fontClass="text-primary">
        </footer>
    </div>
</div>
<!-- Quick chat -->
<quick-chat #quickChat="quickChat"></quick-chat>