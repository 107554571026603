import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { API_ENDPOINTS } from 'src/app/api-endpoints.constants';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@cvs/services';
import { PagedResponseModel } from '@cvs/types';
import { MessageUser } from './messages.types';

@Injectable({
    providedIn: 'root'
})
export class MessagesService {
    /**
     * Constructor
     */
    private readonly apiEndpoint: string;

    private _messagesUser: ReplaySubject<PagedResponseModel<MessageUser>> = new ReplaySubject<
        PagedResponseModel<MessageUser>
    >(1);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        public environmentService: EnvironmentService
    ) {
        this.apiEndpoint = environmentService.getApiUrl(
            API_ENDPOINTS.incidentApi.baseUrlKey,
            API_ENDPOINTS.incidentApi.notifications.get
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for messages
     */
    get messages$(): Observable<PagedResponseModel<MessageUser>> {
        return this._messagesUser.asObservable();
    }

    /**
     * Delete the messages
     *
     * @param id
     */
    public delete(id: number): Observable<boolean> {
        return this.messages$.pipe(
            take(1),
            switchMap((messages) =>
                this._httpClient
                    .delete<boolean>(this.apiEndpoint + '/' + id)
                    .pipe(
                        map((isDeleted: boolean) => {
                            // Find the index of the deleted messages
                            const index = messages.items.findIndex((item) => item.id === id);

                            // Delete the message
                            messages.items.splice(index, 1);

                            // Update the messages
                            this._messagesUser.next(messages);

                            // Return the deleted status
                            return isDeleted;
                        })
                    )
            )
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all messages
     */
    public getAll(): Observable<PagedResponseModel<MessageUser>> {
        return this._httpClient
            .get<PagedResponseModel<MessageUser>>(this.apiEndpoint, {
                params: {
                    page: 1,
                    pageSize: 15,
                    orderBy: 'isRead asc,createdDateTime desc',
                    filter: 'isDeleted != true,notificationType=Message'
                }
            })
            .pipe(
                tap((messages) => {
                    this._messagesUser.next(messages);
                })
            );
    }

    /**
     * Mark all messages as read
     */
    public markAllAsRead(): Observable<boolean> {
        return this.messages$.pipe(
            take(1),
            switchMap((messages) =>
                this._httpClient.get<boolean>(`${this.apiEndpoint}/mark-all-as-read`).pipe(
                    map((isUpdated: boolean) => {
                        // Go through all messages and set them as read
                        messages.items.forEach((message, index) => {
                            messages[index].read = true;
                        });

                        // Update the messages
                        this._messagesUser.next(messages);

                        // Return the updated status
                        return isUpdated;
                    })
                )
            )
        );
    }

    /**
     * Update the messages
     *
     * @param id
     * @param message
     */
    public update(id: number, message: MessageUser): Observable<MessageUser> {
        const endpoint = this.environmentService.getApiUrl(
            API_ENDPOINTS.incidentApi.baseUrlKey,
            API_ENDPOINTS.incidentApi.notifications.updateUserNotification
        );
        return this.messages$.pipe(
            take(1),
            switchMap((messages) =>
                this._httpClient
                    .patch<MessageUser>(endpoint + '/' + id, message)
                    .pipe(
                        map((updatedMessage: MessageUser) => {
                            // Find the index of the updated Messages
                            const index = messages.items.findIndex((item) => item.id === id);

                            // Update the Message
                            messages[index] = updatedMessage;

                            // Update the Messages
                            this._messagesUser.next(messages);

                            // Return the updated Message
                            return updatedMessage;
                        })
                    )
            )
        );
    }

    public updateAllUserMessagesReadStatus(id: number, message: MessageUser): Observable<MessageUser> {
        const endpoint = this.environmentService.getApiUrl(
            API_ENDPOINTS.incidentApi.baseUrlKey,
            API_ENDPOINTS.incidentApi.notifications.updateUserNotifications
        );
        return this._httpClient.patch<MessageUser>(endpoint + '/' + id, message);
    }

    public updateUserMessagesReadStatus(id: number, message: MessageUser): Observable<MessageUser> {
        const endpoint = this.environmentService.getApiUrl(
            API_ENDPOINTS.incidentApi.baseUrlKey,
            API_ENDPOINTS.incidentApi.notifications.updateUserNotification
        );
        return this._httpClient.patch<MessageUser>(endpoint + '/' + id, message);
    }
}
