import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SSRService {
    // use environment variable FORCE_SSR_SERVER_MODE
    // to force the frontend to render as in server mode
    public readonly isBrowser: boolean = isPlatformBrowser(this.platformId);
    public readonly isServer: boolean = isPlatformServer(this.platformId);

    constructor(@Inject(PLATFORM_ID) private platformId: any) {}
}
