export enum ErrorAnnotationTypes {
    numeric = 'numeric',
    required = 'required',
    minLength = 'minLength',
    maxLength = 'maxLength',
    minNumber = 'minNumber',
    maxNumber = 'maxNumber',
    pattern = 'pattern',
    password = 'password',
    compare = 'compare',
    minDate = 'minDate',
    maxDate = 'maxDate',
    alpha = 'alpha',
    alphaNumeric = 'alphaNumeric',
    email = 'email',
    hexColor = 'hexColor',
    lowerCase = 'lowerCase',
    url = 'url',
    upperCase = 'upperCase',
    nested = 'nested',
    propArray = 'propArray',
    propObject = 'propObject',
    contains = 'contains',
    range = 'range',
    custom = 'custom',
    digit = 'digit',
    creditCard = 'creditCard',
    time = 'time',
    json = 'json',
    greaterThan = 'greaterThan',
    greaterThanEqualTo = 'greaterThanEqualTo',
    lessThan = 'lessThan',
    lessThanEqualTo = 'lessThanEqualTo',
    choice = 'choice',
    different = 'different',
    even = 'even',
    odd = 'odd',
    factor = 'factor',
    leapYear = 'leapYear',
    allOf = 'allOf',
    oneOf = 'oneOf',
    noneOf = 'noneOf',
    mac = 'mac',
    ascii = 'ascii',
    dataUri = 'dataUri',
    port = 'port',
    latLong = 'latLong',
    extension = 'extension',
    fileSize = 'fileSize',
    endsWith = 'endsWith',
    startsWith = 'startsWith',
    primeNumber = 'primeNumber',
    latitude = 'latitude',
    longitude = 'longitude',
    compose = 'compose',
    rule = 'rule',
    file = 'file',
    image = 'image',
    unique = 'unique',
    notEmpty = 'notEmpty',
    ip = 'ip',
    cusip = 'cusip',
    grid = 'grid',
    date = 'date',
    and = 'and',
    or = 'or',
    not = 'not',
    minTime = 'minTime',
    maxTime = 'maxTime',
    requiredTrue = 'requiredTrue',
    mask = 'mask',
    iban = 'iban',
    updateOn = 'updateOn',
    alreadyInUse = 'AlreadyInUse'
}
