import { ComponentType } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { deepMerge } from '@cvs/utils';

import { CvsAlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { CvsPromptDialogComponent } from './prompt-dialog/prompt-dialog.component';

export interface CvsDialogConfig extends MatDialogConfig {
    title?: string;
    titleData?: string;
    message: string;
    messageData?: string;
    dismissible?: boolean;
}

export interface CvsAlertConfig extends CvsDialogConfig {
    closeButton?: string;
    icon?: {
        show?: boolean;
        name?: string;
        color?: 'primary' | 'accent' | 'warn' | 'basic' | 'info' | 'success' | 'warning' | 'error';
    };
}

export interface CvsConfirmConfig extends CvsDialogConfig {
    acceptButton?: string;
    cancelButton?: string;
}

export interface CvsPromptConfig extends CvsConfirmConfig {
    value?: string;
}
@Injectable()
export class CvsDialogService {
    private _renderer2: Renderer2;

    constructor(
        @Inject(DOCUMENT) private _document: any,
        private _dialogService: MatDialog,
        private rendererFactory: RendererFactory2
    ) {
        this._renderer2 = rendererFactory.createRenderer(undefined, null);
    }

    /**
     * params:
     * - component: ComponentType<T>
     * - config: MatDialogConfig
     * Wrapper function over the open() method in MatDialog.
     * Opens a modal dialog containing the given component.
     */
    public open<T>(component: ComponentType<T>, config?: MatDialogConfig): MatDialogRef<T> {
        if (!config.panelClass) {
            config.panelClass = 'cvs-dialog-panel';
        }
        config.autoFocus = config.autoFocus ?? false;
        return this._dialogService.open(component, config);
    }

    /**
     * Wrapper function over the closeAll() method in MatDialog.
     * Closes all of the currently-open dialogs.
     */
    public closeAll(): void {
        this._dialogService.closeAll();
    }

    /**
     * params:
     * - config: CvsPromptConfig {
     *     message: string;
     *     title?: string;
     *     value?: string;
     *     viewContainerRef?: ViewContainerRef;
     *     acceptButton?: string;
     *     cancelButton?: string;
     * }
     *
     * Opens a prompt dialog with the provided config.
     * Returns an MatDialogRef<CvsPromptDialogComponent> object.
     */
    public openPrompt(config: CvsPromptConfig): MatDialogRef<CvsPromptDialogComponent> {
        const dialogConfig: MatDialogConfig = this._createConfig(config);
        const dialogRef: MatDialogRef<CvsPromptDialogComponent> = this._dialogService.open(
            CvsPromptDialogComponent,
            dialogConfig
        );
        const promptDialogComponent: CvsPromptDialogComponent = dialogRef.componentInstance;
        promptDialogComponent.title = config.title;
        promptDialogComponent.message = config.message;
        promptDialogComponent.value = config.value;
        if (config.acceptButton) {
            promptDialogComponent.acceptButton = config.acceptButton;
        }
        if (config.cancelButton) {
            promptDialogComponent.cancelButton = config.cancelButton;
        }
        return dialogRef;
    }
    /**
     * params:
     * - config: CvsAlertConfig {
     *     message: string;
     *     title?: string;
     *     viewContainerRef?: ViewContainerRef;
     *     closeButton?: string;
     * }
     *
     * Opens an alert dialog with the provided config.
     * Returns an MatDialogRef<CvsAlertDialogComponent> object.
     */
    public openAlert(config: CvsAlertConfig): MatDialogRef<CvsAlertDialogComponent> {
        const dialogConfig: MatDialogConfig = this._createConfig(config);
        const dialogRef: MatDialogRef<CvsAlertDialogComponent> = this._dialogService.open(
            CvsAlertDialogComponent,
            dialogConfig
        );
        const alertDialogComponent: CvsAlertDialogComponent = dialogRef.componentInstance;
        alertDialogComponent.title = config.title;
        alertDialogComponent.message = config.message;
        alertDialogComponent.icon = deepMerge(
            {
                show: false
            },
            config.icon
        );
        if (config.closeButton) {
            alertDialogComponent.closeButton = config.closeButton;
        }
        return dialogRef;
    }
    /**
     * params:
     * - config: CvsAlertConfig {
     *     message: string;
     *     title?: string;
     *     viewContainerRef?: ViewContainerRef;
     *     closeButton?: string;
     * }
     *
     * Opens an success dialog with the provided config.
     * Returns an MatDialogRef<CvsAlertDialogComponent> object.
     */
    public openSuccessAlert(config: CvsAlertConfig): MatDialogRef<CvsAlertDialogComponent> {
        const newConfig = deepMerge(
            {
                title: 'Success',
                message: 'Successfully done.',
                icon: {
                    color: 'success',
                    show: true,
                    name: 'feather:check-circle'
                }
            },
            config
        );
        return this.openAlert(newConfig);
    }
    /**
     * params:
     * - config: CvsAlertConfig {
     *     message: string;
     *     title?: string;
     *     viewContainerRef?: ViewContainerRef;
     *     closeButton?: string;
     * }
     *
     * Opens an success dialog with the provided config.
     * Returns an MatDialogRef<CvsAlertDialogComponent> object.
     */
    public openErrorAlert(config: CvsAlertConfig): MatDialogRef<CvsAlertDialogComponent> {
        const newConfig = deepMerge(
            {
                title: 'Error',
                message: 'An error occurred',
                icon: {
                    color: 'error',
                    show: true,
                    name: 'feather:x-circle'
                }
            },
            config
        );
        return this.openAlert(newConfig);
    }

    private _createConfig(config: CvsDialogConfig): MatDialogConfig {
        const dialogConfig: MatDialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = 'cvs-dialog-panel';
        Object.assign(dialogConfig, config);
        return dialogConfig;
    }
}
