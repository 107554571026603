export const countriesData = [
    {
        'name': 'Afghanistan',
        'alternateNames': {
            'localName': 'Afghanistan (local)',
            'commonName': 'Afghanistan (common)'
        },
        'code': 'AF',
        'isd': '+93',
        'flagImagePos': '-1px -3180px',
        'id': 1,
        'displayOrder': 101,
        'population': 407184.0323625323,
        'createdDate': '2023-06-13T05:48:23.3926863Z',
        'createdDateOffset': '2023-03-31T05:48:23.3927704+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Albania',
        'alternateNames': {
            'localName': 'Albania (local)',
            'commonName': 'Albania (common)'
        },
        'code': 'AL',
        'isd': '+355',
        'flagImagePos': '-1px -1310px',
        'id': 2,
        'displayOrder': 102,
        'population': 61277.32870460814,
        'createdDate': '2023-06-10T05:48:23.3929979Z',
        'createdDateOffset': '2023-03-22T05:48:23.3929982+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Algeria',
        'alternateNames': {
            'localName': 'Algeria (local)',
            'commonName': 'Algeria (common)'
        },
        'code': 'DZ',
        'isd': '+213',
        'flagImagePos': '-1px -681px',
        'id': 3,
        'displayOrder': 103,
        'population': 400469.9637696597,
        'createdDate': '2023-04-20T05:48:23.3930004Z',
        'createdDateOffset': '2023-04-24T05:48:23.3930004+00:00',
        'status': 'Approved'
    },
    {
        'name': 'American Samoa',
        'alternateNames': {
            'localName': 'American Samoa (local)',
            'commonName': 'American Samoa (common)'
        },
        'code': 'AS',
        'isd': '+1',
        'flagImagePos': '-1px -2058px',
        'id': 4,
        'displayOrder': 104,
        'population': 201665.42327363818,
        'createdDate': '2023-06-14T05:48:23.3930009Z',
        'createdDateOffset': '2023-04-03T05:48:23.3930009+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Andorra',
        'alternateNames': {
            'localName': 'Andorra (local)',
            'commonName': 'Andorra (common)'
        },
        'code': 'AD',
        'isd': '+376',
        'flagImagePos': '-1px -766px',
        'id': 5,
        'displayOrder': 105,
        'population': 92447.8669435892,
        'createdDate': '2023-04-20T05:48:23.3930014Z',
        'createdDateOffset': '2023-05-02T05:48:23.3930014+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Angola',
        'alternateNames': {
            'localName': 'Angola (local)',
            'commonName': 'Angola (common)'
        },
        'code': 'AO',
        'isd': '+244',
        'flagImagePos': '-1px -2636px',
        'id': 6,
        'displayOrder': 106,
        'population': 575559.4190924652,
        'createdDate': '2023-04-09T05:48:23.3930017Z',
        'createdDateOffset': '2023-04-21T05:48:23.3930018+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Anguilla',
        'alternateNames': {
            'localName': 'Anguilla (local)',
            'commonName': 'Anguilla (common)'
        },
        'code': 'AI',
        'isd': '+1',
        'flagImagePos': '-1px -2687px',
        'id': 7,
        'displayOrder': 107,
        'population': 16012.21374961667,
        'createdDate': '2023-06-02T05:48:23.393002Z',
        'createdDateOffset': '2023-03-14T05:48:23.3930021+00:00',
        'status': 'Created'
    },
    {
        'name': 'Antigua & Barbuda',
        'alternateNames': {
            'localName': 'Antigua & Barbuda (local)',
            'commonName': 'Antigua & Barbuda (common)'
        },
        'code': 'AG',
        'isd': '+1',
        'flagImagePos': '-1px -1140px',
        'id': 8,
        'displayOrder': 108,
        'population': 411529.5678385015,
        'createdDate': '2023-06-09T05:48:23.3930024Z',
        'createdDateOffset': '2023-05-24T05:48:23.3930024+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Argentina',
        'alternateNames': {
            'localName': 'Argentina (local)',
            'commonName': 'Argentina (common)'
        },
        'code': 'AR',
        'isd': '+54',
        'flagImagePos': '-1px -3282px',
        'id': 9,
        'displayOrder': 109,
        'population': 199746.60370493907,
        'createdDate': '2023-03-20T05:48:23.3930026Z',
        'createdDateOffset': '2023-03-25T05:48:23.3930027+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Armenia',
        'alternateNames': {
            'localName': 'Armenia (local)',
            'commonName': 'Armenia (common)'
        },
        'code': 'AM',
        'isd': '+374',
        'flagImagePos': '-1px -205px',
        'id': 10,
        'displayOrder': 110,
        'population': 214131.95817170606,
        'createdDate': '2023-06-04T05:48:23.3930104Z',
        'createdDateOffset': '2023-05-28T05:48:23.3930105+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Aruba',
        'alternateNames': {
            'localName': 'Aruba (local)',
            'commonName': 'Aruba (common)'
        },
        'code': 'AW',
        'isd': '+297',
        'flagImagePos': '-1px -1021px',
        'id': 11,
        'displayOrder': 111,
        'population': 34816.190127633796,
        'createdDate': '2023-04-13T05:48:23.3930108Z',
        'createdDateOffset': '2023-03-14T05:48:23.3930109+00:00',
        'status': 'Created'
    },
    {
        'name': 'Ascension Island',
        'alternateNames': {
            'localName': 'Ascension Island (local)',
            'commonName': 'Ascension Island (common)'
        },
        'code': 'AC',
        'isd': '+247',
        'flagImagePos': '-1px -86px',
        'id': 12,
        'displayOrder': 112,
        'population': 309239.0852837415,
        'createdDate': '2023-05-27T05:48:23.3930112Z',
        'createdDateOffset': '2023-03-15T05:48:23.3930113+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Australia',
        'alternateNames': {
            'localName': 'Australia (local)',
            'commonName': 'Australia (common)'
        },
        'code': 'AU',
        'isd': '+61',
        'flagImagePos': '-1px -2279px',
        'id': 13,
        'displayOrder': 113,
        'population': 97783.89033763277,
        'createdDate': '2023-06-19T05:48:23.3930116Z',
        'createdDateOffset': '2023-06-03T05:48:23.3930116+00:00',
        'status': 'Created'
    },
    {
        'name': 'Austria',
        'alternateNames': {
            'localName': 'Austria (local)',
            'commonName': 'Austria (common)'
        },
        'code': 'AT',
        'isd': '+43',
        'flagImagePos': '-1px -1735px',
        'id': 14,
        'displayOrder': 114,
        'population': 66513.21141898991,
        'createdDate': '2023-03-26T05:48:23.393012Z',
        'createdDateOffset': '2023-05-10T05:48:23.393012+00:00',
        'status': 'Created'
    },
    {
        'name': 'Azerbaijan',
        'alternateNames': {
            'localName': 'Azerbaijan (local)',
            'commonName': 'Azerbaijan (common)'
        },
        'code': 'AZ',
        'isd': '+994',
        'flagImagePos': '-1px -1599px',
        'id': 15,
        'displayOrder': 115,
        'population': 870983.0435869679,
        'createdDate': '2023-05-30T05:48:23.3930123Z',
        'createdDateOffset': '2023-06-17T05:48:23.3930123+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Bahamas',
        'alternateNames': {
            'localName': 'Bahamas (local)',
            'commonName': 'Bahamas (common)'
        },
        'code': 'BS',
        'isd': '+1',
        'flagImagePos': '-1px -460px',
        'id': 16,
        'displayOrder': 116,
        'population': 619419.4048810645,
        'createdDate': '2023-05-07T05:48:23.3930125Z',
        'createdDateOffset': '2023-03-24T05:48:23.3930126+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Bahrain',
        'alternateNames': {
            'localName': 'Bahrain (local)',
            'commonName': 'Bahrain (common)'
        },
        'code': 'BH',
        'isd': '+973',
        'flagImagePos': '-1px -1956px',
        'id': 17,
        'displayOrder': 117,
        'population': 217407.56154443283,
        'createdDate': '2023-06-15T05:48:23.3930128Z',
        'createdDateOffset': '2023-04-05T05:48:23.3930128+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Bangladesh',
        'alternateNames': {
            'localName': 'Bangladesh (local)',
            'commonName': 'Bangladesh (common)'
        },
        'code': 'BD',
        'isd': '+880',
        'flagImagePos': '-1px -2364px',
        'id': 18,
        'displayOrder': 118,
        'population': 208061.86582000222,
        'createdDate': '2023-06-08T05:48:23.3930131Z',
        'createdDateOffset': '2023-05-01T05:48:23.3930131+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Barbados',
        'alternateNames': {
            'localName': 'Barbados (local)',
            'commonName': 'Barbados (common)'
        },
        'code': 'BB',
        'isd': '+1',
        'flagImagePos': '-1px -2075px',
        'id': 19,
        'displayOrder': 119,
        'population': 175086.7055738527,
        'createdDate': '2023-04-15T05:48:23.3930134Z',
        'createdDateOffset': '2023-03-21T05:48:23.3930134+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Belarus',
        'alternateNames': {
            'localName': 'Belarus (local)',
            'commonName': 'Belarus (common)'
        },
        'code': 'BY',
        'isd': '+375',
        'flagImagePos': '-1px -1412px',
        'id': 20,
        'displayOrder': 120,
        'population': 178727.92004718815,
        'createdDate': '2023-05-25T05:48:23.3930138Z',
        'createdDateOffset': '2023-03-31T05:48:23.3930138+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Belgium',
        'alternateNames': {
            'localName': 'Belgium (local)',
            'commonName': 'Belgium (common)'
        },
        'code': 'BE',
        'isd': '+32',
        'flagImagePos': '-1px -1px',
        'id': 21,
        'displayOrder': 121,
        'population': 85870.9495075346,
        'createdDate': '2023-04-08T05:48:23.3930142Z',
        'createdDateOffset': '2023-04-30T05:48:23.3930142+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Belize',
        'alternateNames': {
            'localName': 'Belize (local)',
            'commonName': 'Belize (common)'
        },
        'code': 'BZ',
        'isd': '+501',
        'flagImagePos': '-1px -613px',
        'id': 22,
        'displayOrder': 122,
        'population': 457999.609517314,
        'createdDate': '2023-05-27T05:48:23.3930146Z',
        'createdDateOffset': '2023-03-23T05:48:23.3930146+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Benin',
        'alternateNames': {
            'localName': 'Benin (local)',
            'commonName': 'Benin (common)'
        },
        'code': 'BJ',
        'isd': '+229',
        'flagImagePos': '-1px -1684px',
        'id': 23,
        'displayOrder': 123,
        'population': 58187.52134298707,
        'createdDate': '2023-05-17T05:48:23.3930148Z',
        'createdDateOffset': '2023-05-27T05:48:23.3930149+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Bermuda',
        'alternateNames': {
            'localName': 'Bermuda (local)',
            'commonName': 'Bermuda (common)'
        },
        'code': 'BM',
        'isd': '+1',
        'flagImagePos': '-1px -2585px',
        'id': 24,
        'displayOrder': 124,
        'population': 261767.62082099865,
        'createdDate': '2023-04-16T05:48:23.3930151Z',
        'createdDateOffset': '2023-04-21T05:48:23.3930152+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Bhutan',
        'alternateNames': {
            'localName': 'Bhutan (local)',
            'commonName': 'Bhutan (common)'
        },
        'code': 'BT',
        'isd': '+975',
        'flagImagePos': '-1px -2483px',
        'id': 25,
        'displayOrder': 125,
        'population': 244874.42876306592,
        'createdDate': '2023-04-01T05:48:23.3930154Z',
        'createdDateOffset': '2023-03-31T05:48:23.3930155+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Bolivia',
        'alternateNames': {
            'localName': 'Bolivia (local)',
            'commonName': 'Bolivia (common)'
        },
        'code': 'BO',
        'isd': '+591',
        'flagImagePos': '-1px -2177px',
        'id': 26,
        'displayOrder': 126,
        'population': 226019.29122200608,
        'createdDate': '2023-05-02T05:48:23.3930157Z',
        'createdDateOffset': '2023-05-25T05:48:23.3930158+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Bosnia & Herzegovina',
        'alternateNames': {
            'localName': 'Bosnia & Herzegovina (local)',
            'commonName': 'Bosnia & Herzegovina (common)'
        },
        'code': 'BA',
        'isd': '+387',
        'flagImagePos': '-1px -2092px',
        'id': 27,
        'displayOrder': 127,
        'population': 123401.29937940207,
        'createdDate': '2023-05-17T05:48:23.393016Z',
        'createdDateOffset': '2023-05-31T05:48:23.3930161+00:00',
        'status': 'Created'
    },
    {
        'name': 'Botswana',
        'alternateNames': {
            'localName': 'Botswana (local)',
            'commonName': 'Botswana (common)'
        },
        'code': 'BW',
        'isd': '+267',
        'flagImagePos': '-1px -3724px',
        'id': 28,
        'displayOrder': 128,
        'population': 63864.306301739925,
        'createdDate': '2023-03-23T05:48:23.3930164Z',
        'createdDateOffset': '2023-03-25T05:48:23.3930165+00:00',
        'status': 'Created'
    },
    {
        'name': 'Brazil',
        'alternateNames': {
            'localName': 'Brazil (local)',
            'commonName': 'Brazil (common)'
        },
        'code': 'BR',
        'isd': '+55',
        'flagImagePos': '-1px -1004px',
        'id': 29,
        'displayOrder': 129,
        'population': 182731.38280547544,
        'createdDate': '2023-04-23T05:48:23.3930168Z',
        'createdDateOffset': '2023-04-15T05:48:23.3930168+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'British Indian Ocean Territory',
        'alternateNames': {
            'localName': 'British Indian Ocean Territory (local)',
            'commonName': 'British Indian Ocean Territory (common)'
        },
        'code': 'IO',
        'isd': '+246',
        'flagImagePos': '-1px -86px',
        'id': 30,
        'displayOrder': 130,
        'population': 691718.8906304627,
        'createdDate': '2023-05-09T05:48:23.3930172Z',
        'createdDateOffset': '2023-03-28T05:48:23.3930173+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'British Virgin Islands',
        'alternateNames': {
            'localName': 'British Virgin Islands (local)',
            'commonName': 'British Virgin Islands (common)'
        },
        'code': 'VG',
        'isd': '+1',
        'flagImagePos': '-1px -1854px',
        'id': 31,
        'displayOrder': 131,
        'population': 657652.1196145978,
        'createdDate': '2023-03-26T05:48:23.3930175Z',
        'createdDateOffset': '2023-03-15T05:48:23.3930176+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Brunei',
        'alternateNames': {
            'localName': 'Brunei (local)',
            'commonName': 'Brunei (common)'
        },
        'code': 'BN',
        'isd': '+673',
        'flagImagePos': '-1px -2228px',
        'id': 32,
        'displayOrder': 132,
        'population': 72477.04222821076,
        'createdDate': '2023-05-17T05:48:23.3930179Z',
        'createdDateOffset': '2023-06-16T05:48:23.3930179+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Bulgaria',
        'alternateNames': {
            'localName': 'Bulgaria (local)',
            'commonName': 'Bulgaria (common)'
        },
        'code': 'BG',
        'isd': '+359',
        'flagImagePos': '-1px -3537px',
        'id': 33,
        'displayOrder': 133,
        'population': 97172.30429630927,
        'createdDate': '2023-04-20T05:48:23.3930181Z',
        'createdDateOffset': '2023-05-31T05:48:23.3930182+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Burkina Faso',
        'alternateNames': {
            'localName': 'Burkina Faso (local)',
            'commonName': 'Burkina Faso (common)'
        },
        'code': 'BF',
        'isd': '+226',
        'flagImagePos': '-1px -953px',
        'id': 34,
        'displayOrder': 134,
        'population': 52035.17827596497,
        'createdDate': '2023-03-29T05:48:23.3930185Z',
        'createdDateOffset': '2023-03-17T05:48:23.3930185+00:00',
        'status': 'Created'
    },
    {
        'name': 'Burundi',
        'alternateNames': {
            'localName': 'Burundi (local)',
            'commonName': 'Burundi (common)'
        },
        'code': 'BI',
        'isd': '+257',
        'flagImagePos': '-1px -2551px',
        'id': 35,
        'displayOrder': 135,
        'population': 135904.751829047,
        'createdDate': '2023-05-27T05:48:23.3930189Z',
        'createdDateOffset': '2023-04-28T05:48:23.3930189+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Cambodia',
        'alternateNames': {
            'localName': 'Cambodia (local)',
            'commonName': 'Cambodia (common)'
        },
        'code': 'KH',
        'isd': '+855',
        'flagImagePos': '-1px -290px',
        'id': 36,
        'displayOrder': 136,
        'population': 97951.15681750784,
        'createdDate': '2023-04-06T05:48:23.3930192Z',
        'createdDateOffset': '2023-05-20T05:48:23.3930193+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Cameroon',
        'alternateNames': {
            'localName': 'Cameroon (local)',
            'commonName': 'Cameroon (common)'
        },
        'code': 'CM',
        'isd': '+237',
        'flagImagePos': '-1px -2806px',
        'id': 37,
        'displayOrder': 137,
        'population': 457089.37819232437,
        'createdDate': '2023-05-15T05:48:23.3930196Z',
        'createdDateOffset': '2023-04-09T05:48:23.3930197+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Canada',
        'alternateNames': {
            'localName': 'Canada (local)',
            'commonName': 'Canada (common)'
        },
        'code': 'CA',
        'isd': '+1',
        'flagImagePos': '-1px -1803px',
        'id': 38,
        'displayOrder': 138,
        'population': 103661.70765105162,
        'createdDate': '2023-04-29T05:48:23.3930199Z',
        'createdDateOffset': '2023-03-18T05:48:23.3930199+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Cape Verde',
        'alternateNames': {
            'localName': 'Cape Verde (local)',
            'commonName': 'Cape Verde (common)'
        },
        'code': 'CV',
        'isd': '+238',
        'flagImagePos': '-1px -3639px',
        'id': 39,
        'displayOrder': 139,
        'population': 25866.454574816413,
        'createdDate': '2023-05-10T05:48:23.3930202Z',
        'createdDateOffset': '2023-03-19T05:48:23.3930203+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Caribbean Netherlands',
        'alternateNames': {
            'localName': 'Caribbean Netherlands (local)',
            'commonName': 'Caribbean Netherlands (common)'
        },
        'code': 'BQ',
        'isd': '+599',
        'flagImagePos': '-1px -3741px',
        'id': 40,
        'displayOrder': 140,
        'population': 94031.46167723328,
        'createdDate': '2023-04-16T05:48:23.3930205Z',
        'createdDateOffset': '2023-06-02T05:48:23.3930206+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Cayman Islands',
        'alternateNames': {
            'localName': 'Cayman Islands (local)',
            'commonName': 'Cayman Islands (common)'
        },
        'code': 'KY',
        'isd': '+1',
        'flagImagePos': '-1px -375px',
        'id': 41,
        'displayOrder': 141,
        'population': 583567.8619200774,
        'createdDate': '2023-04-18T05:48:23.3930208Z',
        'createdDateOffset': '2023-06-13T05:48:23.3930208+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Central African Republic',
        'alternateNames': {
            'localName': 'Central African Republic (local)',
            'commonName': 'Central African Republic (common)'
        },
        'code': 'CF',
        'isd': '+236',
        'flagImagePos': '-1px -2466px',
        'id': 42,
        'displayOrder': 142,
        'population': 158712.87859586274,
        'createdDate': '2023-03-29T05:48:23.3930211Z',
        'createdDateOffset': '2023-04-19T05:48:23.3930211+00:00',
        'status': 'Created'
    },
    {
        'name': 'Chad',
        'alternateNames': {
            'localName': 'Chad (local)',
            'commonName': 'Chad (common)'
        },
        'code': 'TD',
        'isd': '+235',
        'flagImagePos': '-1px -1055px',
        'id': 43,
        'displayOrder': 143,
        'population': 63172.45945814172,
        'createdDate': '2023-03-16T05:48:23.3930216Z',
        'createdDateOffset': '2023-05-28T05:48:23.3930216+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Chile',
        'alternateNames': {
            'localName': 'Chile (local)',
            'commonName': 'Chile (common)'
        },
        'code': 'CL',
        'isd': '+56',
        'flagImagePos': '-1px -1752px',
        'id': 44,
        'displayOrder': 144,
        'population': 226016.72096682116,
        'createdDate': '2023-04-12T05:48:23.3930219Z',
        'createdDateOffset': '2023-04-02T05:48:23.3930219+00:00',
        'status': 'Created'
    },
    {
        'name': 'China',
        'alternateNames': {
            'localName': 'China (local)',
            'commonName': 'China (common)'
        },
        'code': 'CN',
        'isd': '+86',
        'flagImagePos': '-1px -1072px',
        'id': 45,
        'displayOrder': 145,
        'population': 226203.99502568934,
        'createdDate': '2023-05-04T05:48:23.3930223Z',
        'createdDateOffset': '2023-04-15T05:48:23.3930224+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Colombia',
        'alternateNames': {
            'localName': 'Colombia (local)',
            'commonName': 'Colombia (common)'
        },
        'code': 'CO',
        'isd': '+57',
        'flagImagePos': '-1px -409px',
        'id': 46,
        'displayOrder': 146,
        'population': 112251.24348846039,
        'createdDate': '2023-04-01T05:48:23.3930227Z',
        'createdDateOffset': '2023-04-07T05:48:23.3930227+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Comoros',
        'alternateNames': {
            'localName': 'Comoros (local)',
            'commonName': 'Comoros (common)'
        },
        'code': 'KM',
        'isd': '+269',
        'flagImagePos': '-1px -1871px',
        'id': 47,
        'displayOrder': 147,
        'population': 348772.66959211475,
        'createdDate': '2023-04-07T05:48:23.3930229Z',
        'createdDateOffset': '2023-05-11T05:48:23.393023+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Congo - Brazzaville',
        'alternateNames': {
            'localName': 'Congo - Brazzaville (local)',
            'commonName': 'Congo - Brazzaville (common)'
        },
        'code': 'CG',
        'isd': '+242',
        'flagImagePos': '-1px -2398px',
        'id': 48,
        'displayOrder': 148,
        'population': 637164.7245444718,
        'createdDate': '2023-04-19T05:48:23.3930232Z',
        'createdDateOffset': '2023-04-18T05:48:23.3930233+00:00',
        'status': 'Created'
    },
    {
        'name': 'Congo - Kinshasa',
        'alternateNames': {
            'localName': 'Congo - Kinshasa (local)',
            'commonName': 'Congo - Kinshasa (common)'
        },
        'code': 'CD',
        'isd': '+243',
        'flagImagePos': '-1px -1990px',
        'id': 49,
        'displayOrder': 149,
        'population': 570395.5784607832,
        'createdDate': '2023-05-13T05:48:23.3930235Z',
        'createdDateOffset': '2023-04-23T05:48:23.3930235+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Cook Islands',
        'alternateNames': {
            'localName': 'Cook Islands (local)',
            'commonName': 'Cook Islands (common)'
        },
        'code': 'CK',
        'isd': '+682',
        'flagImagePos': '-1px -3112px',
        'id': 50,
        'displayOrder': 150,
        'population': 692103.0562188299,
        'createdDate': '2023-05-13T05:48:23.3930238Z',
        'createdDateOffset': '2023-04-11T05:48:23.3930238+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Costa Rica',
        'alternateNames': {
            'localName': 'Costa Rica (local)',
            'commonName': 'Costa Rica (common)'
        },
        'code': 'CR',
        'isd': '+506',
        'flagImagePos': '-1px -2857px',
        'id': 51,
        'displayOrder': 151,
        'population': 24193.070487302408,
        'createdDate': '2023-03-28T05:48:23.3930241Z',
        'createdDateOffset': '2023-06-02T05:48:23.3930242+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Côte d’Ivoire',
        'alternateNames': {
            'localName': 'Côte d’Ivoire (local)',
            'commonName': 'Côte d’Ivoire (common)'
        },
        'code': 'CI',
        'isd': '+225',
        'flagImagePos': '-1px -2194px',
        'id': 52,
        'displayOrder': 152,
        'population': 25175.23182284161,
        'createdDate': '2023-05-23T05:48:23.3930245Z',
        'createdDateOffset': '2023-03-28T05:48:23.3930246+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Croatia',
        'alternateNames': {
            'localName': 'Croatia (local)',
            'commonName': 'Croatia (common)'
        },
        'code': 'HR',
        'isd': '+385',
        'flagImagePos': '-1px -1174px',
        'id': 53,
        'displayOrder': 153,
        'population': 225991.53633000096,
        'createdDate': '2023-04-12T05:48:23.3930352Z',
        'createdDateOffset': '2023-04-17T05:48:23.3930352+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Cuba',
        'alternateNames': {
            'localName': 'Cuba (local)',
            'commonName': 'Cuba (common)'
        },
        'code': 'CU',
        'isd': '+53',
        'flagImagePos': '-1px -987px',
        'id': 54,
        'displayOrder': 154,
        'population': 123415.65008376661,
        'createdDate': '2023-06-05T05:48:23.3930356Z',
        'createdDateOffset': '2023-03-19T05:48:23.3930356+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Curaçao',
        'alternateNames': {
            'localName': 'Curaçao (local)',
            'commonName': 'Curaçao (common)'
        },
        'code': 'CW',
        'isd': '+599',
        'flagImagePos': '-1px -3758px',
        'id': 55,
        'displayOrder': 155,
        'population': 458848.58910996217,
        'createdDate': '2023-06-12T05:48:23.3930359Z',
        'createdDateOffset': '2023-06-01T05:48:23.3930359+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Cyprus',
        'alternateNames': {
            'localName': 'Cyprus (local)',
            'commonName': 'Cyprus (common)'
        },
        'code': 'CY',
        'isd': '+357',
        'flagImagePos': '-1px -732px',
        'id': 56,
        'displayOrder': 156,
        'population': 194595.69355499273,
        'createdDate': '2023-05-18T05:48:23.3930361Z',
        'createdDateOffset': '2023-06-05T05:48:23.3930362+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Czechia',
        'alternateNames': {
            'localName': 'Czechia (local)',
            'commonName': 'Czechia (common)'
        },
        'code': 'CZ',
        'isd': '+420',
        'flagImagePos': '-1px -3095px',
        'id': 57,
        'displayOrder': 157,
        'population': 537301.1503869491,
        'createdDate': '2023-06-10T05:48:23.3930364Z',
        'createdDateOffset': '2023-06-19T05:48:23.3930365+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Denmark',
        'alternateNames': {
            'localName': 'Denmark (local)',
            'commonName': 'Denmark (common)'
        },
        'code': 'DK',
        'isd': '+45',
        'flagImagePos': '-1px -1820px',
        'id': 58,
        'displayOrder': 158,
        'population': 115890.34919587841,
        'createdDate': '2023-04-26T05:48:23.3930367Z',
        'createdDateOffset': '2023-06-14T05:48:23.3930368+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Djibouti',
        'alternateNames': {
            'localName': 'Djibouti (local)',
            'commonName': 'Djibouti (common)'
        },
        'code': 'DJ',
        'isd': '+253',
        'flagImagePos': '-1px -2874px',
        'id': 59,
        'displayOrder': 159,
        'population': 412584.1836798497,
        'createdDate': '2023-05-14T05:48:23.393037Z',
        'createdDateOffset': '2023-04-10T05:48:23.393037+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Dominica',
        'alternateNames': {
            'localName': 'Dominica (local)',
            'commonName': 'Dominica (common)'
        },
        'code': 'DM',
        'isd': '+1',
        'flagImagePos': '-1px -3350px',
        'id': 60,
        'displayOrder': 160,
        'population': 606943.1646567014,
        'createdDate': '2023-05-20T05:48:23.3930374Z',
        'createdDateOffset': '2023-03-31T05:48:23.3930375+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Dominican Republic',
        'alternateNames': {
            'localName': 'Dominican Republic (local)',
            'commonName': 'Dominican Republic (common)'
        },
        'code': 'DO',
        'isd': '+1',
        'flagImagePos': '-1px -2007px',
        'id': 61,
        'displayOrder': 161,
        'population': 45428.94852216463,
        'createdDate': '2023-04-25T05:48:23.3930377Z',
        'createdDateOffset': '2023-04-05T05:48:23.3930378+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Ecuador',
        'alternateNames': {
            'localName': 'Ecuador (local)',
            'commonName': 'Ecuador (common)'
        },
        'code': 'EC',
        'isd': '+593',
        'flagImagePos': '-1px -1531px',
        'id': 62,
        'displayOrder': 162,
        'population': 407540.17899106606,
        'createdDate': '2023-06-18T05:48:23.393038Z',
        'createdDateOffset': '2023-05-23T05:48:23.3930381+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Egypt',
        'alternateNames': {
            'localName': 'Egypt (local)',
            'commonName': 'Egypt (common)'
        },
        'code': 'EG',
        'isd': '+20',
        'flagImagePos': '-1px -3027px',
        'id': 63,
        'displayOrder': 163,
        'population': 663818.7167672821,
        'createdDate': '2023-04-26T05:48:23.3930383Z',
        'createdDateOffset': '2023-04-16T05:48:23.3930384+00:00',
        'status': 'Approved'
    },
    {
        'name': 'El Salvador',
        'alternateNames': {
            'localName': 'El Salvador (local)',
            'commonName': 'El Salvador (common)'
        },
        'code': 'SV',
        'isd': '+503',
        'flagImagePos': '-1px -2160px',
        'id': 64,
        'displayOrder': 164,
        'population': 36907.422069780674,
        'createdDate': '2023-03-15T05:48:23.3930386Z',
        'createdDateOffset': '2023-03-30T05:48:23.3930387+00:00',
        'status': 'Created'
    },
    {
        'name': 'Equatorial Guinea',
        'alternateNames': {
            'localName': 'Equatorial Guinea (local)',
            'commonName': 'Equatorial Guinea (common)'
        },
        'code': 'GQ',
        'isd': '+240',
        'flagImagePos': '-1px -1973px',
        'id': 65,
        'displayOrder': 165,
        'population': 231667.25189524985,
        'createdDate': '2023-06-19T05:48:23.3930389Z',
        'createdDateOffset': '2023-06-02T05:48:23.393039+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Eritrea',
        'alternateNames': {
            'localName': 'Eritrea (local)',
            'commonName': 'Eritrea (common)'
        },
        'code': 'ER',
        'isd': '+291',
        'flagImagePos': '-1px -936px',
        'id': 66,
        'displayOrder': 166,
        'population': 670867.9871520203,
        'createdDate': '2023-03-18T05:48:23.3930395Z',
        'createdDateOffset': '2023-03-13T05:48:23.3930395+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Estonia',
        'alternateNames': {
            'localName': 'Estonia (local)',
            'commonName': 'Estonia (common)'
        },
        'code': 'EE',
        'isd': '+372',
        'flagImagePos': '-1px -3333px',
        'id': 67,
        'displayOrder': 167,
        'population': 72192.51502472289,
        'createdDate': '2023-04-29T05:48:23.3930399Z',
        'createdDateOffset': '2023-05-12T05:48:23.39304+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Eswatini',
        'alternateNames': {
            'localName': 'Eswatini (local)',
            'commonName': 'Eswatini (common)'
        },
        'code': 'SZ',
        'isd': '+268',
        'flagImagePos': '-1px -3129px',
        'id': 68,
        'displayOrder': 168,
        'population': 105836.80957311268,
        'createdDate': '2023-05-13T05:48:23.3930402Z',
        'createdDateOffset': '2023-06-06T05:48:23.3930402+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Ethiopia',
        'alternateNames': {
            'localName': 'Ethiopia (local)',
            'commonName': 'Ethiopia (common)'
        },
        'code': 'ET',
        'isd': '+251',
        'flagImagePos': '-1px -3367px',
        'id': 69,
        'displayOrder': 169,
        'population': 188273.0315009468,
        'createdDate': '2023-03-14T05:48:23.3930405Z',
        'createdDateOffset': '2023-05-27T05:48:23.3930405+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Falkland Islands (Islas Malvinas)',
        'alternateNames': {
            'localName': 'Falkland Islands (Islas Malvinas) (local)',
            'commonName': 'Falkland Islands (Islas Malvinas) (common)'
        },
        'code': 'FK',
        'isd': '+500',
        'flagImagePos': '-1px -3809px',
        'id': 70,
        'displayOrder': 170,
        'population': 732669.6311978245,
        'createdDate': '2023-06-03T05:48:23.3930408Z',
        'createdDateOffset': '2023-06-13T05:48:23.3930408+00:00',
        'status': 'Created'
    },
    {
        'name': 'Faroe Islands',
        'alternateNames': {
            'localName': 'Faroe Islands (local)',
            'commonName': 'Faroe Islands (common)'
        },
        'code': 'FO',
        'isd': '+298',
        'flagImagePos': '-1px -1429px',
        'id': 71,
        'displayOrder': 171,
        'population': 71043.6984461491,
        'createdDate': '2023-04-09T05:48:23.3930411Z',
        'createdDateOffset': '2023-05-10T05:48:23.3930412+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Fiji',
        'alternateNames': {
            'localName': 'Fiji (local)',
            'commonName': 'Fiji (common)'
        },
        'code': 'FJ',
        'isd': '+679',
        'flagImagePos': '-1px -2500px',
        'id': 72,
        'displayOrder': 172,
        'population': 48641.81304767733,
        'createdDate': '2023-04-18T05:48:23.3930414Z',
        'createdDateOffset': '2023-04-03T05:48:23.3930414+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Finland',
        'alternateNames': {
            'localName': 'Finland (local)',
            'commonName': 'Finland (common)'
        },
        'code': 'FI',
        'isd': '+358',
        'flagImagePos': '-1px -2568px',
        'id': 73,
        'displayOrder': 173,
        'population': 92619.27067126754,
        'createdDate': '2023-04-08T05:48:23.3930417Z',
        'createdDateOffset': '2023-05-21T05:48:23.3930417+00:00',
        'status': 'Approved'
    },
    {
        'name': 'France',
        'alternateNames': {
            'localName': 'France (local)',
            'commonName': 'France (common)'
        },
        'code': 'FR',
        'isd': '+33',
        'flagImagePos': '-1px -324px',
        'id': 74,
        'displayOrder': 174,
        'population': 1086.3092851538956,
        'createdDate': '2023-05-08T05:48:23.3930421Z',
        'createdDateOffset': '2023-04-15T05:48:23.3930422+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'French Guiana',
        'alternateNames': {
            'localName': 'French Guiana (local)',
            'commonName': 'French Guiana (common)'
        },
        'code': 'GF',
        'isd': '+594',
        'flagImagePos': '-1px -324px',
        'id': 75,
        'displayOrder': 175,
        'population': 210653.53409453336,
        'createdDate': '2023-04-02T05:48:23.3930425Z',
        'createdDateOffset': '2023-06-06T05:48:23.3930426+00:00',
        'status': 'Closed'
    },
    {
        'name': 'French Polynesia',
        'alternateNames': {
            'localName': 'French Polynesia (local)',
            'commonName': 'French Polynesia (common)'
        },
        'code': 'PF',
        'isd': '+689',
        'flagImagePos': '-1px -2262px',
        'id': 76,
        'displayOrder': 176,
        'population': 289722.7297486536,
        'createdDate': '2023-03-14T05:48:23.3930428Z',
        'createdDateOffset': '2023-04-01T05:48:23.3930429+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Gabon',
        'alternateNames': {
            'localName': 'Gabon (local)',
            'commonName': 'Gabon (common)'
        },
        'code': 'GA',
        'isd': '+241',
        'flagImagePos': '-1px -1157px',
        'id': 77,
        'displayOrder': 177,
        'population': 71932.51736400326,
        'createdDate': '2023-04-25T05:48:23.3930431Z',
        'createdDateOffset': '2023-05-07T05:48:23.3930431+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Gambia',
        'alternateNames': {
            'localName': 'Gambia (local)',
            'commonName': 'Gambia (common)'
        },
        'code': 'GM',
        'isd': '+220',
        'flagImagePos': '-1px -817px',
        'id': 78,
        'displayOrder': 178,
        'population': 16658.14644647667,
        'createdDate': '2023-04-29T05:48:23.3930434Z',
        'createdDateOffset': '2023-05-17T05:48:23.3930434+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Georgia',
        'alternateNames': {
            'localName': 'Georgia (local)',
            'commonName': 'Georgia (common)'
        },
        'code': 'GE',
        'isd': '+995',
        'flagImagePos': '-1px -1123px',
        'id': 79,
        'displayOrder': 179,
        'population': 270078.61040427285,
        'createdDate': '2023-04-09T05:48:23.3930436Z',
        'createdDateOffset': '2023-03-29T05:48:23.3930437+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Germany',
        'alternateNames': {
            'localName': 'Germany (local)',
            'commonName': 'Germany (common)'
        },
        'code': 'DE',
        'isd': '+49',
        'flagImagePos': '-1px -3452px',
        'id': 80,
        'displayOrder': 180,
        'population': 178025.01235453744,
        'createdDate': '2023-05-24T05:48:23.3930439Z',
        'createdDateOffset': '2023-03-28T05:48:23.3930439+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Ghana',
        'alternateNames': {
            'localName': 'Ghana (local)',
            'commonName': 'Ghana (common)'
        },
        'code': 'GH',
        'isd': '+233',
        'flagImagePos': '-1px -2891px',
        'id': 81,
        'displayOrder': 181,
        'population': 224502.24197820542,
        'createdDate': '2023-04-05T05:48:23.3930442Z',
        'createdDateOffset': '2023-06-06T05:48:23.3930442+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Gibraltar',
        'alternateNames': {
            'localName': 'Gibraltar (local)',
            'commonName': 'Gibraltar (common)'
        },
        'code': 'GI',
        'isd': '+350',
        'flagImagePos': '-1px -341px',
        'id': 82,
        'displayOrder': 182,
        'population': 9658.980434926596,
        'createdDate': '2023-06-06T05:48:23.3930447Z',
        'createdDateOffset': '2023-04-22T05:48:23.3930447+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Greece',
        'alternateNames': {
            'localName': 'Greece (local)',
            'commonName': 'Greece (common)'
        },
        'code': 'GR',
        'isd': '+30',
        'flagImagePos': '-1px -188px',
        'id': 83,
        'displayOrder': 183,
        'population': 591983.5508855791,
        'createdDate': '2023-06-15T05:48:23.393045Z',
        'createdDateOffset': '2023-03-17T05:48:23.3930451+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Greenland',
        'alternateNames': {
            'localName': 'Greenland (local)',
            'commonName': 'Greenland (common)'
        },
        'code': 'GL',
        'isd': '+299',
        'flagImagePos': '-1px -2347px',
        'id': 84,
        'displayOrder': 184,
        'population': 623679.7950478724,
        'createdDate': '2023-05-28T05:48:23.3930453Z',
        'createdDateOffset': '2023-05-12T05:48:23.3930454+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Grenada',
        'alternateNames': {
            'localName': 'Grenada (local)',
            'commonName': 'Grenada (common)'
        },
        'code': 'GD',
        'isd': '+1',
        'flagImagePos': '-1px -3316px',
        'id': 85,
        'displayOrder': 185,
        'population': 602.5126929094231,
        'createdDate': '2023-03-28T05:48:23.3930456Z',
        'createdDateOffset': '2023-03-16T05:48:23.3930457+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Guadeloupe',
        'alternateNames': {
            'localName': 'Guadeloupe (local)',
            'commonName': 'Guadeloupe (common)'
        },
        'code': 'GP',
        'isd': '+590',
        'flagImagePos': '-1px -511px',
        'id': 86,
        'displayOrder': 186,
        'population': 806912.2001779571,
        'createdDate': '2023-03-26T05:48:23.3930459Z',
        'createdDateOffset': '2023-05-18T05:48:23.393046+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Guam',
        'alternateNames': {
            'localName': 'Guam (local)',
            'commonName': 'Guam (common)'
        },
        'code': 'GU',
        'isd': '+1',
        'flagImagePos': '-1px -3265px',
        'id': 87,
        'displayOrder': 187,
        'population': 345543.3152858876,
        'createdDate': '2023-05-19T05:48:23.3930462Z',
        'createdDateOffset': '2023-06-02T05:48:23.3930463+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Guatemala',
        'alternateNames': {
            'localName': 'Guatemala (local)',
            'commonName': 'Guatemala (common)'
        },
        'code': 'GT',
        'isd': '+502',
        'flagImagePos': '-1px -1208px',
        'id': 88,
        'displayOrder': 188,
        'population': 113254.6173465607,
        'createdDate': '2023-04-06T05:48:23.3930465Z',
        'createdDateOffset': '2023-04-13T05:48:23.3930465+00:00',
        'status': 'Created'
    },
    {
        'name': 'Guinea',
        'alternateNames': {
            'localName': 'Guinea (local)',
            'commonName': 'Guinea (common)'
        },
        'code': 'GN',
        'isd': '+224',
        'flagImagePos': '-1px -3520px',
        'id': 89,
        'displayOrder': 189,
        'population': 58539.701029177326,
        'createdDate': '2023-05-21T05:48:23.3930468Z',
        'createdDateOffset': '2023-05-24T05:48:23.3930468+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Guinea-Bissau',
        'alternateNames': {
            'localName': 'Guinea-Bissau (local)',
            'commonName': 'Guinea-Bissau (common)'
        },
        'code': 'GW',
        'isd': '+245',
        'flagImagePos': '-1px -2602px',
        'id': 90,
        'displayOrder': 190,
        'population': 551266.366518079,
        'createdDate': '2023-06-05T05:48:23.3930472Z',
        'createdDateOffset': '2023-05-19T05:48:23.3930472+00:00',
        'status': 'Created'
    },
    {
        'name': 'Guyana',
        'alternateNames': {
            'localName': 'Guyana (local)',
            'commonName': 'Guyana (common)'
        },
        'code': 'GY',
        'isd': '+592',
        'flagImagePos': '-1px -1038px',
        'id': 91,
        'displayOrder': 191,
        'population': 55450.02041548468,
        'createdDate': '2023-05-02T05:48:23.3930475Z',
        'createdDateOffset': '2023-03-26T05:48:23.3930476+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Haiti',
        'alternateNames': {
            'localName': 'Haiti (local)',
            'commonName': 'Haiti (common)'
        },
        'code': 'HT',
        'isd': '+509',
        'flagImagePos': '-1px -392px',
        'id': 92,
        'displayOrder': 192,
        'population': 792051.8480188781,
        'createdDate': '2023-06-18T05:48:23.3930478Z',
        'createdDateOffset': '2023-04-10T05:48:23.3930479+00:00',
        'status': 'Created'
    },
    {
        'name': 'Honduras',
        'alternateNames': {
            'localName': 'Honduras (local)',
            'commonName': 'Honduras (common)'
        },
        'code': 'HN',
        'isd': '+504',
        'flagImagePos': '-1px -2959px',
        'id': 93,
        'displayOrder': 193,
        'population': 73723.43360066024,
        'createdDate': '2023-05-11T05:48:23.3930481Z',
        'createdDateOffset': '2023-04-11T05:48:23.3930481+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Hong Kong',
        'alternateNames': {
            'localName': 'Hong Kong (local)',
            'commonName': 'Hong Kong (common)'
        },
        'code': 'HK',
        'isd': '+852',
        'flagImagePos': '-1px -3707px',
        'id': 94,
        'displayOrder': 194,
        'population': 40781.196021929,
        'createdDate': '2023-04-13T05:48:23.3930484Z',
        'createdDateOffset': '2023-04-23T05:48:23.3930484+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Hungary',
        'alternateNames': {
            'localName': 'Hungary (local)',
            'commonName': 'Hungary (common)'
        },
        'code': 'HU',
        'isd': '+36',
        'flagImagePos': '-1px -902px',
        'id': 95,
        'displayOrder': 195,
        'population': 598080.230521667,
        'createdDate': '2023-04-15T05:48:23.3930486Z',
        'createdDateOffset': '2023-05-27T05:48:23.3930487+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Iceland',
        'alternateNames': {
            'localName': 'Iceland (local)',
            'commonName': 'Iceland (common)'
        },
        'code': 'IS',
        'isd': '+354',
        'flagImagePos': '-1px -2704px',
        'id': 96,
        'displayOrder': 196,
        'population': 380157.70109145524,
        'createdDate': '2023-04-04T05:48:23.3930489Z',
        'createdDateOffset': '2023-03-26T05:48:23.3930489+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'India',
        'alternateNames': {
            'localName': 'India (local)',
            'commonName': 'India (common)'
        },
        'code': 'IN',
        'isd': '+91',
        'flagImagePos': '-1px -2245px',
        'id': 97,
        'displayOrder': 1,
        'population': 74804.62972433092,
        'createdDate': '2023-05-19T05:48:23.3930492Z',
        'createdDateOffset': '2023-04-09T05:48:23.3930492+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Indonesia',
        'alternateNames': {
            'localName': 'Indonesia (local)',
            'commonName': 'Indonesia (common)'
        },
        'code': 'ID',
        'isd': '+62',
        'flagImagePos': '-1px -2653px',
        'id': 98,
        'displayOrder': 198,
        'population': 144339.34932934996,
        'createdDate': '2023-03-12T05:48:23.3930536Z',
        'createdDateOffset': '2023-04-21T05:48:23.3930536+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Iran',
        'alternateNames': {
            'localName': 'Iran (local)',
            'commonName': 'Iran (common)'
        },
        'code': 'IR',
        'isd': '+98',
        'flagImagePos': '-1px -2738px',
        'id': 99,
        'displayOrder': 199,
        'population': 146232.22449685834,
        'createdDate': '2023-05-23T05:48:23.393054Z',
        'createdDateOffset': '2023-05-17T05:48:23.3930541+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Iraq',
        'alternateNames': {
            'localName': 'Iraq (local)',
            'commonName': 'Iraq (common)'
        },
        'code': 'IQ',
        'isd': '+964',
        'flagImagePos': '-1px -851px',
        'id': 100,
        'displayOrder': 200,
        'population': 59169.928931273316,
        'createdDate': '2023-04-26T05:48:23.3930543Z',
        'createdDateOffset': '2023-05-29T05:48:23.3930544+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Ireland',
        'alternateNames': {
            'localName': 'Ireland (local)',
            'commonName': 'Ireland (common)'
        },
        'code': 'IE',
        'isd': '+353',
        'flagImagePos': '-1px -2670px',
        'id': 101,
        'displayOrder': 201,
        'population': 344547.0208000943,
        'createdDate': '2023-06-14T05:48:23.3930547Z',
        'createdDateOffset': '2023-04-27T05:48:23.3930547+00:00',
        'status': 'Created'
    },
    {
        'name': 'Israel',
        'alternateNames': {
            'localName': 'Israel (local)',
            'commonName': 'Israel (common)'
        },
        'code': 'IL',
        'isd': '+972',
        'flagImagePos': '-1px -426px',
        'id': 102,
        'displayOrder': 202,
        'population': 78436.56009058152,
        'createdDate': '2023-05-21T05:48:23.3930549Z',
        'createdDateOffset': '2023-06-10T05:48:23.393055+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Italy',
        'alternateNames': {
            'localName': 'Italy (local)',
            'commonName': 'Italy (common)'
        },
        'code': 'IT',
        'isd': '+39',
        'flagImagePos': '-1px -154px',
        'id': 103,
        'displayOrder': 203,
        'population': 211206.88170095894,
        'createdDate': '2023-05-20T05:48:23.3930552Z',
        'createdDateOffset': '2023-04-03T05:48:23.3930553+00:00',
        'status': 'Created'
    },
    {
        'name': 'Jamaica',
        'alternateNames': {
            'localName': 'Jamaica (local)',
            'commonName': 'Jamaica (common)'
        },
        'code': 'JM',
        'isd': '+1',
        'flagImagePos': '-1px -2296px',
        'id': 104,
        'displayOrder': 204,
        'population': 50953.28635804192,
        'createdDate': '2023-05-08T05:48:23.3930555Z',
        'createdDateOffset': '2023-06-02T05:48:23.3930555+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Japan',
        'alternateNames': {
            'localName': 'Japan (local)',
            'commonName': 'Japan (common)'
        },
        'code': 'JP',
        'isd': '+81',
        'flagImagePos': '-1px -528px',
        'id': 105,
        'displayOrder': 205,
        'population': 476532.80192169035,
        'createdDate': '2023-05-02T05:48:23.3930558Z',
        'createdDateOffset': '2023-03-14T05:48:23.3930558+00:00',
        'status': 'Created'
    },
    {
        'name': 'Jordan',
        'alternateNames': {
            'localName': 'Jordan (local)',
            'commonName': 'Jordan (common)'
        },
        'code': 'JO',
        'isd': '+962',
        'flagImagePos': '-1px -1905px',
        'id': 106,
        'displayOrder': 206,
        'population': 294089.8778786043,
        'createdDate': '2023-03-25T05:48:23.393056Z',
        'createdDateOffset': '2023-06-13T05:48:23.3930561+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Kazakhstan',
        'alternateNames': {
            'localName': 'Kazakhstan (local)',
            'commonName': 'Kazakhstan (common)'
        },
        'code': 'KZ',
        'isd': '+7',
        'flagImagePos': '-1px -1565px',
        'id': 107,
        'displayOrder': 207,
        'population': 138992.37989111562,
        'createdDate': '2023-03-19T05:48:23.3930565Z',
        'createdDateOffset': '2023-04-27T05:48:23.3930566+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Kenya',
        'alternateNames': {
            'localName': 'Kenya (local)',
            'commonName': 'Kenya (common)'
        },
        'code': 'KE',
        'isd': '+254',
        'flagImagePos': '-1px -3605px',
        'id': 108,
        'displayOrder': 208,
        'population': 89442.39563348927,
        'createdDate': '2023-06-09T05:48:23.393057Z',
        'createdDateOffset': '2023-04-02T05:48:23.3930571+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Kiribati',
        'alternateNames': {
            'localName': 'Kiribati (local)',
            'commonName': 'Kiribati (common)'
        },
        'code': 'KI',
        'isd': '+686',
        'flagImagePos': '-1px -477px',
        'id': 109,
        'displayOrder': 209,
        'population': 510948.912530647,
        'createdDate': '2023-04-30T05:48:23.3930573Z',
        'createdDateOffset': '2023-06-16T05:48:23.3930573+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Kosovo',
        'alternateNames': {
            'localName': 'Kosovo (local)',
            'commonName': 'Kosovo (common)'
        },
        'code': 'XK',
        'isd': '+383',
        'flagImagePos': '-1px -3860px',
        'id': 110,
        'displayOrder': 210,
        'population': 44587.18698802795,
        'createdDate': '2023-05-20T05:48:23.3930576Z',
        'createdDateOffset': '2023-04-16T05:48:23.3930576+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Kuwait',
        'alternateNames': {
            'localName': 'Kuwait (local)',
            'commonName': 'Kuwait (common)'
        },
        'code': 'KW',
        'isd': '+965',
        'flagImagePos': '-1px -3435px',
        'id': 111,
        'displayOrder': 211,
        'population': 168768.69053151124,
        'createdDate': '2023-04-04T05:48:23.3930579Z',
        'createdDateOffset': '2023-05-31T05:48:23.3930579+00:00',
        'status': 'Created'
    },
    {
        'name': 'Kyrgyzstan',
        'alternateNames': {
            'localName': 'Kyrgyzstan (local)',
            'commonName': 'Kyrgyzstan (common)'
        },
        'code': 'KG',
        'isd': '+996',
        'flagImagePos': '-1px -2143px',
        'id': 112,
        'displayOrder': 212,
        'population': 151449.21924853916,
        'createdDate': '2023-04-26T05:48:23.3930581Z',
        'createdDateOffset': '2023-05-09T05:48:23.3930582+00:00',
        'status': 'Created'
    },
    {
        'name': 'Laos',
        'alternateNames': {
            'localName': 'Laos (local)',
            'commonName': 'Laos (common)'
        },
        'code': 'LA',
        'isd': '+856',
        'flagImagePos': '-1px -562px',
        'id': 113,
        'displayOrder': 213,
        'population': 27670.21361700171,
        'createdDate': '2023-04-09T05:48:23.3930584Z',
        'createdDateOffset': '2023-06-16T05:48:23.3930585+00:00',
        'status': 'Created'
    },
    {
        'name': 'Latvia',
        'alternateNames': {
            'localName': 'Latvia (local)',
            'commonName': 'Latvia (common)'
        },
        'code': 'LV',
        'isd': '+371',
        'flagImagePos': '-1px -2619px',
        'id': 114,
        'displayOrder': 214,
        'population': 159543.5336762335,
        'createdDate': '2023-04-24T05:48:23.3930587Z',
        'createdDateOffset': '2023-06-02T05:48:23.3930588+00:00',
        'status': 'Created'
    },
    {
        'name': 'Lebanon',
        'alternateNames': {
            'localName': 'Lebanon (local)',
            'commonName': 'Lebanon (common)'
        },
        'code': 'LB',
        'isd': '+961',
        'flagImagePos': '-1px -1616px',
        'id': 115,
        'displayOrder': 215,
        'population': 11536.009415979985,
        'createdDate': '2023-06-08T05:48:23.3930591Z',
        'createdDateOffset': '2023-04-15T05:48:23.3930591+00:00',
        'status': 'Created'
    },
    {
        'name': 'Lesotho',
        'alternateNames': {
            'localName': 'Lesotho (local)',
            'commonName': 'Lesotho (common)'
        },
        'code': 'LS',
        'isd': '+266',
        'flagImagePos': '-1px -3010px',
        'id': 116,
        'displayOrder': 216,
        'population': 487286.4366145326,
        'createdDate': '2023-05-18T05:48:23.3930594Z',
        'createdDateOffset': '2023-04-21T05:48:23.3930594+00:00',
        'status': 'Created'
    },
    {
        'name': 'Liberia',
        'alternateNames': {
            'localName': 'Liberia (local)',
            'commonName': 'Liberia (common)'
        },
        'code': 'LR',
        'isd': '+231',
        'flagImagePos': '-1px -2823px',
        'id': 117,
        'displayOrder': 217,
        'population': 169490.2401244339,
        'createdDate': '2023-04-10T05:48:23.3930597Z',
        'createdDateOffset': '2023-05-30T05:48:23.3930597+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Libya',
        'alternateNames': {
            'localName': 'Libya (local)',
            'commonName': 'Libya (common)'
        },
        'code': 'LY',
        'isd': '+218',
        'flagImagePos': '-1px -137px',
        'id': 118,
        'displayOrder': 218,
        'population': 110834.25750464165,
        'createdDate': '2023-04-17T05:48:23.39306Z',
        'createdDateOffset': '2023-05-25T05:48:23.39306+00:00',
        'status': 'Created'
    },
    {
        'name': 'Liechtenstein',
        'alternateNames': {
            'localName': 'Liechtenstein (local)',
            'commonName': 'Liechtenstein (common)'
        },
        'code': 'LI',
        'isd': '+423',
        'flagImagePos': '-1px -1276px',
        'id': 119,
        'displayOrder': 219,
        'population': 254513.2296157831,
        'createdDate': '2023-05-31T05:48:23.3930602Z',
        'createdDateOffset': '2023-05-06T05:48:23.3930603+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Lithuania',
        'alternateNames': {
            'localName': 'Lithuania (local)',
            'commonName': 'Lithuania (common)'
        },
        'code': 'LT',
        'isd': '+370',
        'flagImagePos': '-1px -1446px',
        'id': 120,
        'displayOrder': 220,
        'population': 790690.7612040519,
        'createdDate': '2023-04-25T05:48:23.3930605Z',
        'createdDateOffset': '2023-05-30T05:48:23.3930606+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Luxembourg',
        'alternateNames': {
            'localName': 'Luxembourg (local)',
            'commonName': 'Luxembourg (common)'
        },
        'code': 'LU',
        'isd': '+352',
        'flagImagePos': '-1px -1922px',
        'id': 121,
        'displayOrder': 221,
        'population': 12535.297844318498,
        'createdDate': '2023-06-19T05:48:23.3930608Z',
        'createdDateOffset': '2023-03-17T05:48:23.3930608+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Macao',
        'alternateNames': {
            'localName': 'Macao (local)',
            'commonName': 'Macao (common)'
        },
        'code': 'MO',
        'isd': '+853',
        'flagImagePos': '-1px -3554px',
        'id': 122,
        'displayOrder': 222,
        'population': 61124.388393988644,
        'createdDate': '2023-03-23T05:48:23.3930611Z',
        'createdDateOffset': '2023-04-07T05:48:23.3930612+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Madagascar',
        'alternateNames': {
            'localName': 'Madagascar (local)',
            'commonName': 'Madagascar (common)'
        },
        'code': 'MG',
        'isd': '+261',
        'flagImagePos': '-1px -1667px',
        'id': 123,
        'displayOrder': 223,
        'population': 33904.620267843646,
        'createdDate': '2023-05-29T05:48:23.3930615Z',
        'createdDateOffset': '2023-03-13T05:48:23.3930615+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Malawi',
        'alternateNames': {
            'localName': 'Malawi (local)',
            'commonName': 'Malawi (common)'
        },
        'code': 'MW',
        'isd': '+265',
        'flagImagePos': '-1px -2942px',
        'id': 124,
        'displayOrder': 224,
        'population': 31887.329752008667,
        'createdDate': '2023-03-23T05:48:23.3930618Z',
        'createdDateOffset': '2023-04-23T05:48:23.3930619+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Malaysia',
        'alternateNames': {
            'localName': 'Malaysia (local)',
            'commonName': 'Malaysia (common)'
        },
        'code': 'MY',
        'isd': '+60',
        'flagImagePos': '-1px -2517px',
        'id': 125,
        'displayOrder': 225,
        'population': 132277.61167151527,
        'createdDate': '2023-04-25T05:48:23.3930622Z',
        'createdDateOffset': '2023-04-05T05:48:23.3930622+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Maldives',
        'alternateNames': {
            'localName': 'Maldives (local)',
            'commonName': 'Maldives (common)'
        },
        'code': 'MV',
        'isd': '+960',
        'flagImagePos': '-1px -800px',
        'id': 126,
        'displayOrder': 226,
        'population': 846266.7899125986,
        'createdDate': '2023-06-08T05:48:23.3930625Z',
        'createdDateOffset': '2023-05-25T05:48:23.3930625+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Mali',
        'alternateNames': {
            'localName': 'Mali (local)',
            'commonName': 'Mali (common)'
        },
        'code': 'ML',
        'isd': '+223',
        'flagImagePos': '-1px -3469px',
        'id': 127,
        'displayOrder': 227,
        'population': 19114.771316832266,
        'createdDate': '2023-04-16T05:48:23.3930628Z',
        'createdDateOffset': '2023-04-05T05:48:23.3930628+00:00',
        'status': 'Created'
    },
    {
        'name': 'Malta',
        'alternateNames': {
            'localName': 'Malta (local)',
            'commonName': 'Malta (common)'
        },
        'code': 'MT',
        'isd': '+356',
        'flagImagePos': '-1px -2041px',
        'id': 128,
        'displayOrder': 228,
        'population': 66112.04448674817,
        'createdDate': '2023-05-02T05:48:23.393063Z',
        'createdDateOffset': '2023-05-15T05:48:23.3930631+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Marshall Islands',
        'alternateNames': {
            'localName': 'Marshall Islands (local)',
            'commonName': 'Marshall Islands (common)'
        },
        'code': 'MH',
        'isd': '+692',
        'flagImagePos': '-1px -1463px',
        'id': 129,
        'displayOrder': 229,
        'population': 447363.7513672638,
        'createdDate': '2023-04-21T05:48:23.3930633Z',
        'createdDateOffset': '2023-05-14T05:48:23.3930634+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Martinique',
        'alternateNames': {
            'localName': 'Martinique (local)',
            'commonName': 'Martinique (common)'
        },
        'code': 'MQ',
        'isd': '+596',
        'flagImagePos': '-1px -239px',
        'id': 130,
        'displayOrder': 230,
        'population': 118127.37061342514,
        'createdDate': '2023-06-03T05:48:23.3930637Z',
        'createdDateOffset': '2023-04-18T05:48:23.3930637+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Mauritania',
        'alternateNames': {
            'localName': 'Mauritania (local)',
            'commonName': 'Mauritania (common)'
        },
        'code': 'MR',
        'isd': '+222',
        'flagImagePos': '-1px -307px',
        'id': 131,
        'displayOrder': 231,
        'population': 736034.1214012604,
        'createdDate': '2023-04-26T05:48:23.393064Z',
        'createdDateOffset': '2023-05-14T05:48:23.3930641+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Mauritius',
        'alternateNames': {
            'localName': 'Mauritius (local)',
            'commonName': 'Mauritius (common)'
        },
        'code': 'MU',
        'isd': '+230',
        'flagImagePos': '-1px -2993px',
        'id': 132,
        'displayOrder': 232,
        'population': 698542.88708837,
        'createdDate': '2023-03-25T05:48:23.3930643Z',
        'createdDateOffset': '2023-04-20T05:48:23.3930643+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Mexico',
        'alternateNames': {
            'localName': 'Mexico (local)',
            'commonName': 'Mexico (common)'
        },
        'code': 'MX',
        'isd': '+52',
        'flagImagePos': '-1px -2755px',
        'id': 133,
        'displayOrder': 233,
        'population': 46902.61019985432,
        'createdDate': '2023-06-05T05:48:23.3930646Z',
        'createdDateOffset': '2023-05-22T05:48:23.3930646+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Micronesia',
        'alternateNames': {
            'localName': 'Micronesia (local)',
            'commonName': 'Micronesia (common)'
        },
        'code': 'FM',
        'isd': '+691',
        'flagImagePos': '-1px -2313px',
        'id': 134,
        'displayOrder': 234,
        'population': 449971.7371079664,
        'createdDate': '2023-03-19T05:48:23.3930649Z',
        'createdDateOffset': '2023-05-08T05:48:23.3930649+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Moldova',
        'alternateNames': {
            'localName': 'Moldova (local)',
            'commonName': 'Moldova (common)'
        },
        'code': 'MD',
        'isd': '+373',
        'flagImagePos': '-1px -3690px',
        'id': 135,
        'displayOrder': 235,
        'population': 72988.05721520443,
        'createdDate': '2023-03-23T05:48:23.3930653Z',
        'createdDateOffset': '2023-06-11T05:48:23.3930653+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Monaco',
        'alternateNames': {
            'localName': 'Monaco (local)',
            'commonName': 'Monaco (common)'
        },
        'code': 'MC',
        'isd': '+377',
        'flagImagePos': '-1px -1191px',
        'id': 136,
        'displayOrder': 236,
        'population': 136063.24026772717,
        'createdDate': '2023-03-20T05:48:23.3930656Z',
        'createdDateOffset': '2023-03-14T05:48:23.3930657+00:00',
        'status': 'Created'
    },
    {
        'name': 'Mongolia',
        'alternateNames': {
            'localName': 'Mongolia (local)',
            'commonName': 'Mongolia (common)'
        },
        'code': 'MN',
        'isd': '+976',
        'flagImagePos': '-1px -3503px',
        'id': 137,
        'displayOrder': 237,
        'population': 174334.0659663074,
        'createdDate': '2023-03-23T05:48:23.3930659Z',
        'createdDateOffset': '2023-04-18T05:48:23.393066+00:00',
        'status': 'Created'
    },
    {
        'name': 'Montenegro',
        'alternateNames': {
            'localName': 'Montenegro (local)',
            'commonName': 'Montenegro (common)'
        },
        'code': 'ME',
        'isd': '+382',
        'flagImagePos': '-1px -2976px',
        'id': 138,
        'displayOrder': 238,
        'population': 64638.48333455244,
        'createdDate': '2023-05-03T05:48:23.3930662Z',
        'createdDateOffset': '2023-04-21T05:48:23.3930663+00:00',
        'status': 'Created'
    },
    {
        'name': 'Montserrat',
        'alternateNames': {
            'localName': 'Montserrat (local)',
            'commonName': 'Montserrat (common)'
        },
        'code': 'MS',
        'isd': '+1',
        'flagImagePos': '-1px -749px',
        'id': 139,
        'displayOrder': 239,
        'population': 164984.5969740908,
        'createdDate': '2023-06-16T05:48:23.3930665Z',
        'createdDateOffset': '2023-04-18T05:48:23.3930665+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Morocco',
        'alternateNames': {
            'localName': 'Morocco (local)',
            'commonName': 'Morocco (common)'
        },
        'code': 'MA',
        'isd': '+212',
        'flagImagePos': '-1px -3214px',
        'id': 140,
        'displayOrder': 240,
        'population': 100989.44370762244,
        'createdDate': '2023-03-29T05:48:23.3930668Z',
        'createdDateOffset': '2023-06-14T05:48:23.3930668+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Mozambique',
        'alternateNames': {
            'localName': 'Mozambique (local)',
            'commonName': 'Mozambique (common)'
        },
        'code': 'MZ',
        'isd': '+258',
        'flagImagePos': '-1px -834px',
        'id': 141,
        'displayOrder': 241,
        'population': 89674.86583421862,
        'createdDate': '2023-03-31T05:48:23.393067Z',
        'createdDateOffset': '2023-06-08T05:48:23.3930671+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Myanmar (Burma)',
        'alternateNames': {
            'localName': 'Myanmar (Burma) (local)',
            'commonName': 'Myanmar (Burma) (common)'
        },
        'code': 'MM',
        'isd': '+95',
        'flagImagePos': '-1px -18px',
        'id': 142,
        'displayOrder': 242,
        'population': 28107.56037754897,
        'createdDate': '2023-06-18T05:48:23.3930673Z',
        'createdDateOffset': '2023-06-18T05:48:23.3930674+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Namibia',
        'alternateNames': {
            'localName': 'Namibia (local)',
            'commonName': 'Namibia (common)'
        },
        'code': 'NA',
        'isd': '+264',
        'flagImagePos': '-1px -2534px',
        'id': 143,
        'displayOrder': 243,
        'population': 450923.83228801104,
        'createdDate': '2023-04-22T05:48:23.3930717Z',
        'createdDateOffset': '2023-03-15T05:48:23.3930718+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Nauru',
        'alternateNames': {
            'localName': 'Nauru (local)',
            'commonName': 'Nauru (common)'
        },
        'code': 'NR',
        'isd': '+674',
        'flagImagePos': '-1px -2330px',
        'id': 144,
        'displayOrder': 244,
        'population': 162082.10051787065,
        'createdDate': '2023-03-16T05:48:23.3930722Z',
        'createdDateOffset': '2023-04-16T05:48:23.3930722+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Nepal',
        'alternateNames': {
            'localName': 'Nepal (local)',
            'commonName': 'Nepal (common)'
        },
        'code': 'NP',
        'isd': '+977',
        'flagImagePos': '-1px -120px',
        'id': 145,
        'displayOrder': 245,
        'population': 224017.91648311412,
        'createdDate': '2023-06-19T05:48:23.3930726Z',
        'createdDateOffset': '2023-04-12T05:48:23.3930726+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Netherlands',
        'alternateNames': {
            'localName': 'Netherlands (local)',
            'commonName': 'Netherlands (common)'
        },
        'code': 'NL',
        'isd': '+31',
        'flagImagePos': '-1px -1888px',
        'id': 146,
        'displayOrder': 246,
        'population': 42997.831778978965,
        'createdDate': '2023-04-21T05:48:23.393073Z',
        'createdDateOffset': '2023-05-02T05:48:23.393073+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'New Caledonia',
        'alternateNames': {
            'localName': 'New Caledonia (local)',
            'commonName': 'New Caledonia (common)'
        },
        'code': 'NC',
        'isd': '+687',
        'flagImagePos': '-1px -1650px',
        'id': 147,
        'displayOrder': 247,
        'population': 182878.9090897384,
        'createdDate': '2023-04-26T05:48:23.3930732Z',
        'createdDateOffset': '2023-06-16T05:48:23.3930733+00:00',
        'status': 'Approved'
    },
    {
        'name': 'New Zealand',
        'alternateNames': {
            'localName': 'New Zealand (local)',
            'commonName': 'New Zealand (common)'
        },
        'code': 'NZ',
        'isd': '+64',
        'flagImagePos': '-1px -2024px',
        'id': 148,
        'displayOrder': 248,
        'population': 362028.9676750755,
        'createdDate': '2023-04-02T05:48:23.3930735Z',
        'createdDateOffset': '2023-03-23T05:48:23.3930736+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Nicaragua',
        'alternateNames': {
            'localName': 'Nicaragua (local)',
            'commonName': 'Nicaragua (common)'
        },
        'code': 'NI',
        'isd': '+505',
        'flagImagePos': '-1px -171px',
        'id': 149,
        'displayOrder': 249,
        'population': 426081.40516584174,
        'createdDate': '2023-03-17T05:48:23.3930738Z',
        'createdDateOffset': '2023-05-25T05:48:23.3930739+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Niger',
        'alternateNames': {
            'localName': 'Niger (local)',
            'commonName': 'Niger (common)'
        },
        'code': 'NE',
        'isd': '+227',
        'flagImagePos': '-1px -715px',
        'id': 150,
        'displayOrder': 250,
        'population': 420284.9460995005,
        'createdDate': '2023-06-12T05:48:23.3930741Z',
        'createdDateOffset': '2023-05-28T05:48:23.3930742+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Nigeria',
        'alternateNames': {
            'localName': 'Nigeria (local)',
            'commonName': 'Nigeria (common)'
        },
        'code': 'NG',
        'isd': '+234',
        'flagImagePos': '-1px -3418px',
        'id': 151,
        'displayOrder': 251,
        'population': 399249.4447882738,
        'createdDate': '2023-05-24T05:48:23.3930744Z',
        'createdDateOffset': '2023-05-15T05:48:23.3930745+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Niue',
        'alternateNames': {
            'localName': 'Niue (local)',
            'commonName': 'Niue (common)'
        },
        'code': 'NU',
        'isd': '+683',
        'flagImagePos': '-1px -2840px',
        'id': 152,
        'displayOrder': 252,
        'population': 376032.5164840651,
        'createdDate': '2023-05-11T05:48:23.3930748Z',
        'createdDateOffset': '2023-04-25T05:48:23.3930748+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Norfolk Island',
        'alternateNames': {
            'localName': 'Norfolk Island (local)',
            'commonName': 'Norfolk Island (common)'
        },
        'code': 'NF',
        'isd': '+672',
        'flagImagePos': '-1px -256px',
        'id': 153,
        'displayOrder': 253,
        'population': 64688.20590475947,
        'createdDate': '2023-05-01T05:48:23.3930752Z',
        'createdDateOffset': '2023-03-30T05:48:23.3930752+00:00',
        'status': 'Created'
    },
    {
        'name': 'North Korea',
        'alternateNames': {
            'localName': 'North Korea (local)',
            'commonName': 'North Korea (common)'
        },
        'code': 'KP',
        'isd': '+850',
        'flagImagePos': '-1px -2415px',
        'id': 154,
        'displayOrder': 254,
        'population': 740871.5706998034,
        'createdDate': '2023-04-07T05:48:23.3930755Z',
        'createdDateOffset': '2023-03-15T05:48:23.3930756+00:00',
        'status': 'Closed'
    },
    {
        'name': 'North Macedonia',
        'alternateNames': {
            'localName': 'North Macedonia (local)',
            'commonName': 'North Macedonia (common)'
        },
        'code': 'MK',
        'isd': '+389',
        'flagImagePos': '-1px -1769px',
        'id': 155,
        'displayOrder': 255,
        'population': 55837.04034749415,
        'createdDate': '2023-05-26T05:48:23.3930758Z',
        'createdDateOffset': '2023-04-18T05:48:23.3930758+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Northern Mariana Islands',
        'alternateNames': {
            'localName': 'Northern Mariana Islands (local)',
            'commonName': 'Northern Mariana Islands (common)'
        },
        'code': 'MP',
        'isd': '+1',
        'flagImagePos': '-1px -919px',
        'id': 156,
        'displayOrder': 256,
        'population': 217193.60565607043,
        'createdDate': '2023-06-04T05:48:23.3930761Z',
        'createdDateOffset': '2023-05-11T05:48:23.3930761+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Norway',
        'alternateNames': {
            'localName': 'Norway (local)',
            'commonName': 'Norway (common)'
        },
        'code': 'NO',
        'isd': '+47',
        'flagImagePos': '-1px -1089px',
        'id': 157,
        'displayOrder': 257,
        'population': 533844.6188631358,
        'createdDate': '2023-05-05T05:48:23.3930764Z',
        'createdDateOffset': '2023-05-07T05:48:23.3930764+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Oman',
        'alternateNames': {
            'localName': 'Oman (local)',
            'commonName': 'Oman (common)'
        },
        'code': 'OM',
        'isd': '+968',
        'flagImagePos': '-1px -3384px',
        'id': 158,
        'displayOrder': 258,
        'population': 139487.9119382411,
        'createdDate': '2023-06-12T05:48:23.3930766Z',
        'createdDateOffset': '2023-06-01T05:48:23.3930767+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Pakistan',
        'alternateNames': {
            'localName': 'Pakistan (local)',
            'commonName': 'Pakistan (common)'
        },
        'code': 'PK',
        'isd': '+92',
        'flagImagePos': '-1px -2772px',
        'id': 159,
        'displayOrder': 259,
        'population': 296053.6394068342,
        'createdDate': '2023-04-04T05:48:23.3930769Z',
        'createdDateOffset': '2023-05-22T05:48:23.3930769+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Palau',
        'alternateNames': {
            'localName': 'Palau (local)',
            'commonName': 'Palau (common)'
        },
        'code': 'PW',
        'isd': '+680',
        'flagImagePos': '-1px -273px',
        'id': 160,
        'displayOrder': 260,
        'population': 104272.71797449232,
        'createdDate': '2023-03-14T05:48:23.3930773Z',
        'createdDateOffset': '2023-03-18T05:48:23.3930774+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Palestine',
        'alternateNames': {
            'localName': 'Palestine (local)',
            'commonName': 'Palestine (common)'
        },
        'code': 'PS',
        'isd': '+970',
        'flagImagePos': '-1px -1548px',
        'id': 161,
        'displayOrder': 261,
        'population': 178439.3560896759,
        'createdDate': '2023-06-08T05:48:23.3930776Z',
        'createdDateOffset': '2023-06-17T05:48:23.3930777+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Panama',
        'alternateNames': {
            'localName': 'Panama (local)',
            'commonName': 'Panama (common)'
        },
        'code': 'PA',
        'isd': '+507',
        'flagImagePos': '-1px -1106px',
        'id': 162,
        'displayOrder': 262,
        'population': 234253.89284094048,
        'createdDate': '2023-05-14T05:48:23.3930779Z',
        'createdDateOffset': '2023-04-15T05:48:23.3930779+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Papua New Guinea',
        'alternateNames': {
            'localName': 'Papua New Guinea (local)',
            'commonName': 'Papua New Guinea (common)'
        },
        'code': 'PG',
        'isd': '+675',
        'flagImagePos': '-1px -1939px',
        'id': 163,
        'displayOrder': 263,
        'population': 15643.531178406376,
        'createdDate': '2023-04-14T05:48:23.3930782Z',
        'createdDateOffset': '2023-03-24T05:48:23.3930782+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Paraguay',
        'alternateNames': {
            'localName': 'Paraguay (local)',
            'commonName': 'Paraguay (common)'
        },
        'code': 'PY',
        'isd': '+595',
        'flagImagePos': '-1px -3231px',
        'id': 164,
        'displayOrder': 264,
        'population': 93758.86619129447,
        'createdDate': '2023-05-14T05:48:23.3930785Z',
        'createdDateOffset': '2023-04-05T05:48:23.3930785+00:00',
        'status': 'Created'
    },
    {
        'name': 'Peru',
        'alternateNames': {
            'localName': 'Peru (local)',
            'commonName': 'Peru (common)'
        },
        'code': 'PE',
        'isd': '+51',
        'flagImagePos': '-1px -1225px',
        'id': 165,
        'displayOrder': 265,
        'population': 239096.5106759899,
        'createdDate': '2023-06-19T05:48:23.3930788Z',
        'createdDateOffset': '2023-05-26T05:48:23.3930788+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Philippines',
        'alternateNames': {
            'localName': 'Philippines (local)',
            'commonName': 'Philippines (common)'
        },
        'code': 'PH',
        'isd': '+63',
        'flagImagePos': '-1px -2432px',
        'id': 166,
        'displayOrder': 266,
        'population': 292203.5372015247,
        'createdDate': '2023-06-04T05:48:23.3930791Z',
        'createdDateOffset': '2023-03-21T05:48:23.3930791+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Poland',
        'alternateNames': {
            'localName': 'Poland (local)',
            'commonName': 'Poland (common)'
        },
        'code': 'PL',
        'isd': '+48',
        'flagImagePos': '-1px -1514px',
        'id': 167,
        'displayOrder': 267,
        'population': 44110.242841874315,
        'createdDate': '2023-05-31T05:48:23.3930794Z',
        'createdDateOffset': '2023-04-06T05:48:23.3930794+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Portugal',
        'alternateNames': {
            'localName': 'Portugal (local)',
            'commonName': 'Portugal (common)'
        },
        'code': 'PT',
        'isd': '+351',
        'flagImagePos': '-1px -664px',
        'id': 168,
        'displayOrder': 268,
        'population': 414589.81362906646,
        'createdDate': '2023-05-04T05:48:23.3930798Z',
        'createdDateOffset': '2023-05-28T05:48:23.3930799+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Puerto Rico',
        'alternateNames': {
            'localName': 'Puerto Rico (local)',
            'commonName': 'Puerto Rico (common)'
        },
        'code': 'PR',
        'isd': '+1',
        'flagImagePos': '-1px -596px',
        'id': 169,
        'displayOrder': 269,
        'population': 325625.1530283386,
        'createdDate': '2023-03-24T05:48:23.3930801Z',
        'createdDateOffset': '2023-04-05T05:48:23.3930802+00:00',
        'status': 'Created'
    },
    {
        'name': 'Qatar',
        'alternateNames': {
            'localName': 'Qatar (local)',
            'commonName': 'Qatar (common)'
        },
        'code': 'QA',
        'isd': '+974',
        'flagImagePos': '-1px -579px',
        'id': 170,
        'displayOrder': 270,
        'population': 104998.97748191534,
        'createdDate': '2023-04-29T05:48:23.3930804Z',
        'createdDateOffset': '2023-05-02T05:48:23.3930805+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Réunion',
        'alternateNames': {
            'localName': 'Réunion (local)',
            'commonName': 'Réunion (common)'
        },
        'code': 'RE',
        'isd': '+262',
        'flagImagePos': '-1px -324px',
        'id': 171,
        'displayOrder': 271,
        'population': 107846.21890303693,
        'createdDate': '2023-04-04T05:48:23.3930807Z',
        'createdDateOffset': '2023-04-04T05:48:23.3930808+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Romania',
        'alternateNames': {
            'localName': 'Romania (local)',
            'commonName': 'Romania (common)'
        },
        'code': 'RO',
        'isd': '+40',
        'flagImagePos': '-1px -885px',
        'id': 172,
        'displayOrder': 272,
        'population': 88962.4877673878,
        'createdDate': '2023-04-03T05:48:23.393081Z',
        'createdDateOffset': '2023-03-28T05:48:23.393081+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Russia',
        'alternateNames': {
            'localName': 'Russia (local)',
            'commonName': 'Russia (common)'
        },
        'code': 'RU',
        'isd': '+7',
        'flagImagePos': '-1px -868px',
        'id': 173,
        'displayOrder': 273,
        'population': 55227.901642235796,
        'createdDate': '2023-05-23T05:48:23.3930813Z',
        'createdDateOffset': '2023-05-07T05:48:23.3930813+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Rwanda',
        'alternateNames': {
            'localName': 'Rwanda (local)',
            'commonName': 'Rwanda (common)'
        },
        'code': 'RW',
        'isd': '+250',
        'flagImagePos': '-1px -3673px',
        'id': 174,
        'displayOrder': 274,
        'population': 99083.9410134277,
        'createdDate': '2023-05-06T05:48:23.3930815Z',
        'createdDateOffset': '2023-05-13T05:48:23.3930816+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Samoa',
        'alternateNames': {
            'localName': 'Samoa (local)',
            'commonName': 'Samoa (common)'
        },
        'code': 'WS',
        'isd': '+685',
        'flagImagePos': '-1px -3163px',
        'id': 175,
        'displayOrder': 275,
        'population': 56987.07638132351,
        'createdDate': '2023-03-22T05:48:23.3930818Z',
        'createdDateOffset': '2023-03-22T05:48:23.3930818+00:00',
        'status': 'Closed'
    },
    {
        'name': 'San Marino',
        'alternateNames': {
            'localName': 'San Marino (local)',
            'commonName': 'San Marino (common)'
        },
        'code': 'SM',
        'isd': '+378',
        'flagImagePos': '-1px -2908px',
        'id': 176,
        'displayOrder': 276,
        'population': 189579.53326275607,
        'createdDate': '2023-03-17T05:48:23.3930821Z',
        'createdDateOffset': '2023-05-08T05:48:23.3930822+00:00',
        'status': 'Closed'
    },
    {
        'name': 'São Tomé & Príncipe',
        'alternateNames': {
            'localName': 'São Tomé & Príncipe (local)',
            'commonName': 'São Tomé & Príncipe (common)'
        },
        'code': 'ST',
        'isd': '+239',
        'flagImagePos': '-1px -3299px',
        'id': 177,
        'displayOrder': 277,
        'population': 47732.25858113435,
        'createdDate': '2023-03-26T05:48:23.3930824Z',
        'createdDateOffset': '2023-03-28T05:48:23.3930825+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Saudi Arabia',
        'alternateNames': {
            'localName': 'Saudi Arabia (local)',
            'commonName': 'Saudi Arabia (common)'
        },
        'code': 'SA',
        'isd': '+966',
        'flagImagePos': '-1px -52px',
        'id': 178,
        'displayOrder': 278,
        'population': 453802.6499614919,
        'createdDate': '2023-06-05T05:48:23.3930827Z',
        'createdDateOffset': '2023-05-28T05:48:23.3930827+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Senegal',
        'alternateNames': {
            'localName': 'Senegal (local)',
            'commonName': 'Senegal (common)'
        },
        'code': 'SN',
        'isd': '+221',
        'flagImagePos': '-1px -2925px',
        'id': 179,
        'displayOrder': 279,
        'population': 113543.15009948204,
        'createdDate': '2023-05-17T05:48:23.3930829Z',
        'createdDateOffset': '2023-05-24T05:48:23.393083+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Serbia',
        'alternateNames': {
            'localName': 'Serbia (local)',
            'commonName': 'Serbia (common)'
        },
        'code': 'RS',
        'isd': '+381',
        'flagImagePos': '-1px -3401px',
        'id': 180,
        'displayOrder': 280,
        'population': 57827.17229753043,
        'createdDate': '2023-06-11T05:48:23.3930832Z',
        'createdDateOffset': '2023-05-04T05:48:23.3930833+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Seychelles',
        'alternateNames': {
            'localName': 'Seychelles (local)',
            'commonName': 'Seychelles (common)'
        },
        'code': 'SC',
        'isd': '+248',
        'flagImagePos': '-1px -1327px',
        'id': 181,
        'displayOrder': 281,
        'population': 39502.2210758517,
        'createdDate': '2023-03-30T05:48:23.3930835Z',
        'createdDateOffset': '2023-04-29T05:48:23.3930835+00:00',
        'status': 'Created'
    },
    {
        'name': 'Sierra Leone',
        'alternateNames': {
            'localName': 'Sierra Leone (local)',
            'commonName': 'Sierra Leone (common)'
        },
        'code': 'SL',
        'isd': '+232',
        'flagImagePos': '-1px -970px',
        'id': 182,
        'displayOrder': 282,
        'population': 239277.3089035384,
        'createdDate': '2023-03-23T05:48:23.3930838Z',
        'createdDateOffset': '2023-03-18T05:48:23.3930838+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Singapore',
        'alternateNames': {
            'localName': 'Singapore (local)',
            'commonName': 'Singapore (common)'
        },
        'code': 'SG',
        'isd': '+65',
        'flagImagePos': '-1px -35px',
        'id': 183,
        'displayOrder': 283,
        'population': 87089.83255589176,
        'createdDate': '2023-04-06T05:48:23.393084Z',
        'createdDateOffset': '2023-05-12T05:48:23.3930841+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Sint Maarten',
        'alternateNames': {
            'localName': 'Sint Maarten (local)',
            'commonName': 'Sint Maarten (common)'
        },
        'code': 'SX',
        'isd': '+1',
        'flagImagePos': '-1px -3826px',
        'id': 184,
        'displayOrder': 284,
        'population': 120340.36390923278,
        'createdDate': '2023-03-23T05:48:23.3930843Z',
        'createdDateOffset': '2023-04-15T05:48:23.3930844+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Slovakia',
        'alternateNames': {
            'localName': 'Slovakia (local)',
            'commonName': 'Slovakia (common)'
        },
        'code': 'SK',
        'isd': '+421',
        'flagImagePos': '-1px -3044px',
        'id': 185,
        'displayOrder': 285,
        'population': 23139.26998442135,
        'createdDate': '2023-05-04T05:48:23.3930847Z',
        'createdDateOffset': '2023-03-12T05:48:23.3930848+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Slovenia',
        'alternateNames': {
            'localName': 'Slovenia (local)',
            'commonName': 'Slovenia (common)'
        },
        'code': 'SI',
        'isd': '+386',
        'flagImagePos': '-1px -1582px',
        'id': 186,
        'displayOrder': 286,
        'population': 91243.41789091536,
        'createdDate': '2023-04-24T05:48:23.393085Z',
        'createdDateOffset': '2023-06-13T05:48:23.3930851+00:00',
        'status': 'Created'
    },
    {
        'name': 'Solomon Islands',
        'alternateNames': {
            'localName': 'Solomon Islands (local)',
            'commonName': 'Solomon Islands (common)'
        },
        'code': 'SB',
        'isd': '+677',
        'flagImagePos': '-1px -1361px',
        'id': 187,
        'displayOrder': 287,
        'population': 480086.7986367338,
        'createdDate': '2023-05-25T05:48:23.3930853Z',
        'createdDateOffset': '2023-04-12T05:48:23.3930854+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Somalia',
        'alternateNames': {
            'localName': 'Somalia (local)',
            'commonName': 'Somalia (common)'
        },
        'code': 'SO',
        'isd': '+252',
        'flagImagePos': '-1px -1786px',
        'id': 188,
        'displayOrder': 288,
        'population': 20224.35124187094,
        'createdDate': '2023-05-09T05:48:23.3930893Z',
        'createdDateOffset': '2023-04-01T05:48:23.3930893+00:00',
        'status': 'Closed'
    },
    {
        'name': 'South Africa',
        'alternateNames': {
            'localName': 'South Africa (local)',
            'commonName': 'South Africa (common)'
        },
        'code': 'ZA',
        'isd': '+27',
        'flagImagePos': '-1px -3248px',
        'id': 189,
        'displayOrder': 289,
        'population': 167684.4111395656,
        'createdDate': '2023-03-23T05:48:23.3930896Z',
        'createdDateOffset': '2023-05-29T05:48:23.3930896+00:00',
        'status': 'Created'
    },
    {
        'name': 'South Korea',
        'alternateNames': {
            'localName': 'South Korea (local)',
            'commonName': 'South Korea (common)'
        },
        'code': 'KR',
        'isd': '+82',
        'flagImagePos': '-1px -3078px',
        'id': 190,
        'displayOrder': 290,
        'population': 265.0702624792857,
        'createdDate': '2023-05-02T05:48:23.3930899Z',
        'createdDateOffset': '2023-04-19T05:48:23.3930899+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'South Sudan',
        'alternateNames': {
            'localName': 'South Sudan (local)',
            'commonName': 'South Sudan (common)'
        },
        'code': 'SS',
        'isd': '+211',
        'flagImagePos': '-1px -3775px',
        'id': 191,
        'displayOrder': 291,
        'population': 759302.7409400806,
        'createdDate': '2023-04-13T05:48:23.3930902Z',
        'createdDateOffset': '2023-06-16T05:48:23.3930902+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Spain',
        'alternateNames': {
            'localName': 'Spain (local)',
            'commonName': 'Spain (common)'
        },
        'code': 'ES',
        'isd': '+34',
        'flagImagePos': '-1px -1480px',
        'id': 192,
        'displayOrder': 292,
        'population': 39317.51488636935,
        'createdDate': '2023-04-05T05:48:23.3930904Z',
        'createdDateOffset': '2023-04-28T05:48:23.3930905+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Sri Lanka',
        'alternateNames': {
            'localName': 'Sri Lanka (local)',
            'commonName': 'Sri Lanka (common)'
        },
        'code': 'LK',
        'isd': '+94',
        'flagImagePos': '-1px -3622px',
        'id': 193,
        'displayOrder': 293,
        'population': 56984.97510718324,
        'createdDate': '2023-04-11T05:48:23.3930908Z',
        'createdDateOffset': '2023-05-22T05:48:23.3930909+00:00',
        'status': 'Approved'
    },
    {
        'name': 'St. Barthélemy',
        'alternateNames': {
            'localName': 'St. Barthélemy (local)',
            'commonName': 'St. Barthélemy (common)'
        },
        'code': 'BL',
        'isd': '+590',
        'flagImagePos': '-1px -324px',
        'id': 194,
        'displayOrder': 294,
        'population': 32983.28731661738,
        'createdDate': '2023-05-11T05:48:23.3930916Z',
        'createdDateOffset': '2023-04-26T05:48:23.3930916+00:00',
        'status': 'Closed'
    },
    {
        'name': 'St. Helena',
        'alternateNames': {
            'localName': 'St. Helena (local)',
            'commonName': 'St. Helena (common)'
        },
        'code': 'SH',
        'isd': '+290',
        'flagImagePos': '-1px -630px',
        'id': 195,
        'displayOrder': 295,
        'population': 559150.2795360546,
        'createdDate': '2023-04-25T05:48:23.3930919Z',
        'createdDateOffset': '2023-04-14T05:48:23.3930919+00:00',
        'status': 'Created'
    },
    {
        'name': 'St. Kitts & Nevis',
        'alternateNames': {
            'localName': 'St. Kitts & Nevis (local)',
            'commonName': 'St. Kitts & Nevis (common)'
        },
        'code': 'KN',
        'isd': '+1',
        'flagImagePos': '-1px -103px',
        'id': 196,
        'displayOrder': 296,
        'population': 118783.64617298984,
        'createdDate': '2023-05-13T05:48:23.3930922Z',
        'createdDateOffset': '2023-03-23T05:48:23.3930922+00:00',
        'status': 'Approved'
    },
    {
        'name': 'St. Lucia',
        'alternateNames': {
            'localName': 'St. Lucia (local)',
            'commonName': 'St. Lucia (common)'
        },
        'code': 'LC',
        'isd': '+1',
        'flagImagePos': '-1px -1837px',
        'id': 197,
        'displayOrder': 297,
        'population': 3763.4786963991,
        'createdDate': '2023-04-16T05:48:23.3930925Z',
        'createdDateOffset': '2023-04-08T05:48:23.3930925+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'St. Martin',
        'alternateNames': {
            'localName': 'St. Martin (local)',
            'commonName': 'St. Martin (common)'
        },
        'code': 'MF',
        'isd': '+590',
        'flagImagePos': '-1px -86px',
        'id': 198,
        'displayOrder': 298,
        'population': 29851.666708543573,
        'createdDate': '2023-05-10T05:48:23.3930927Z',
        'createdDateOffset': '2023-03-28T05:48:23.3930928+00:00',
        'status': 'Closed'
    },
    {
        'name': 'St. Pierre & Miquelon',
        'alternateNames': {
            'localName': 'St. Pierre & Miquelon (local)',
            'commonName': 'St. Pierre & Miquelon (common)'
        },
        'code': 'PM',
        'isd': '+508',
        'flagImagePos': '-1px -1378px',
        'id': 199,
        'displayOrder': 299,
        'population': 29829.041839269816,
        'createdDate': '2023-03-18T05:48:23.3930931Z',
        'createdDateOffset': '2023-06-04T05:48:23.3930931+00:00',
        'status': 'Approved'
    },
    {
        'name': 'St. Vincent & Grenadines',
        'alternateNames': {
            'localName': 'St. Vincent & Grenadines (local)',
            'commonName': 'St. Vincent & Grenadines (common)'
        },
        'code': 'VC',
        'isd': '+1',
        'flagImagePos': '-1px -3588px',
        'id': 200,
        'displayOrder': 300,
        'population': 42875.2793816541,
        'createdDate': '2023-05-04T05:48:23.3930933Z',
        'createdDateOffset': '2023-04-16T05:48:23.3930934+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Sudan',
        'alternateNames': {
            'localName': 'Sudan (local)',
            'commonName': 'Sudan (common)'
        },
        'code': 'SD',
        'isd': '+249',
        'flagImagePos': '-1px -443px',
        'id': 201,
        'displayOrder': 301,
        'population': 76539.64668431086,
        'createdDate': '2023-04-19T05:48:23.3930937Z',
        'createdDateOffset': '2023-04-28T05:48:23.3930937+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Suriname',
        'alternateNames': {
            'localName': 'Suriname (local)',
            'commonName': 'Suriname (common)'
        },
        'code': 'SR',
        'isd': '+597',
        'flagImagePos': '-1px -3656px',
        'id': 202,
        'displayOrder': 302,
        'population': 2622.3009302672754,
        'createdDate': '2023-03-12T05:48:23.3930941Z',
        'createdDateOffset': '2023-04-20T05:48:23.3930941+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Sweden',
        'alternateNames': {
            'localName': 'Sweden (local)',
            'commonName': 'Sweden (common)'
        },
        'code': 'SE',
        'isd': '+46',
        'flagImagePos': '-1px -494px',
        'id': 203,
        'displayOrder': 303,
        'population': 421733.86519624986,
        'createdDate': '2023-05-14T05:48:23.3930944Z',
        'createdDateOffset': '2023-06-07T05:48:23.3930944+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Switzerland',
        'alternateNames': {
            'localName': 'Switzerland (local)',
            'commonName': 'Switzerland (common)'
        },
        'code': 'CH',
        'isd': '+41',
        'flagImagePos': '-1px -1718px',
        'id': 204,
        'displayOrder': 304,
        'population': 39154.731649408415,
        'createdDate': '2023-06-10T05:48:23.3930946Z',
        'createdDateOffset': '2023-04-11T05:48:23.3930947+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Syria',
        'alternateNames': {
            'localName': 'Syria (local)',
            'commonName': 'Syria (common)'
        },
        'code': 'SY',
        'isd': '+963',
        'flagImagePos': '-1px -2449px',
        'id': 205,
        'displayOrder': 305,
        'population': 74888.51708075768,
        'createdDate': '2023-04-25T05:48:23.3930949Z',
        'createdDateOffset': '2023-04-16T05:48:23.393095+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Taiwan',
        'alternateNames': {
            'localName': 'Taiwan (local)',
            'commonName': 'Taiwan (common)'
        },
        'code': 'TW',
        'isd': '+886',
        'flagImagePos': '-1px -647px',
        'id': 206,
        'displayOrder': 306,
        'population': 235840.51064120702,
        'createdDate': '2023-04-23T05:48:23.3930952Z',
        'createdDateOffset': '2023-04-15T05:48:23.3930953+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Tajikistan',
        'alternateNames': {
            'localName': 'Tajikistan (local)',
            'commonName': 'Tajikistan (common)'
        },
        'code': 'TJ',
        'isd': '+992',
        'flagImagePos': '-1px -222px',
        'id': 207,
        'displayOrder': 307,
        'population': 242098.46554429032,
        'createdDate': '2023-05-02T05:48:23.3930955Z',
        'createdDateOffset': '2023-03-20T05:48:23.3930955+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Tanzania',
        'alternateNames': {
            'localName': 'Tanzania (local)',
            'commonName': 'Tanzania (common)'
        },
        'code': 'TZ',
        'isd': '+255',
        'flagImagePos': '-1px -3146px',
        'id': 208,
        'displayOrder': 308,
        'population': 11476.444550628357,
        'createdDate': '2023-04-29T05:48:23.3930957Z',
        'createdDateOffset': '2023-05-02T05:48:23.3930958+00:00',
        'status': 'Created'
    },
    {
        'name': 'Thailand',
        'alternateNames': {
            'localName': 'Thailand (local)',
            'commonName': 'Thailand (common)'
        },
        'code': 'TH',
        'isd': '+66',
        'flagImagePos': '-1px -1242px',
        'id': 209,
        'displayOrder': 309,
        'population': 795871.7716670086,
        'createdDate': '2023-06-10T05:48:23.3930962Z',
        'createdDateOffset': '2023-03-21T05:48:23.3930963+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Timor-Leste',
        'alternateNames': {
            'localName': 'Timor-Leste (local)',
            'commonName': 'Timor-Leste (common)'
        },
        'code': 'TL',
        'isd': '+670',
        'flagImagePos': '-1px -3843px',
        'id': 210,
        'displayOrder': 310,
        'population': 370303.04366146884,
        'createdDate': '2023-03-23T05:48:23.3930967Z',
        'createdDateOffset': '2023-05-04T05:48:23.3930968+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Togo',
        'alternateNames': {
            'localName': 'Togo (local)',
            'commonName': 'Togo (common)'
        },
        'code': 'TG',
        'isd': '+228',
        'flagImagePos': '-1px -783px',
        'id': 211,
        'displayOrder': 311,
        'population': 646966.4577513444,
        'createdDate': '2023-06-13T05:48:23.3930971Z',
        'createdDateOffset': '2023-03-25T05:48:23.3930972+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Tokelau',
        'alternateNames': {
            'localName': 'Tokelau (local)',
            'commonName': 'Tokelau (common)'
        },
        'code': 'TK',
        'isd': '+690',
        'flagImagePos': '-1px -3792px',
        'id': 212,
        'displayOrder': 312,
        'population': 16111.813509828455,
        'createdDate': '2023-05-07T05:48:23.3930974Z',
        'createdDateOffset': '2023-04-23T05:48:23.3930974+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Tonga',
        'alternateNames': {
            'localName': 'Tonga (local)',
            'commonName': 'Tonga (common)'
        },
        'code': 'TO',
        'isd': '+676',
        'flagImagePos': '-1px -1395px',
        'id': 213,
        'displayOrder': 313,
        'population': 481210.23809839145,
        'createdDate': '2023-04-15T05:48:23.3930976Z',
        'createdDateOffset': '2023-05-05T05:48:23.3930977+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Trinidad & Tobago',
        'alternateNames': {
            'localName': 'Trinidad & Tobago (local)',
            'commonName': 'Trinidad & Tobago (common)'
        },
        'code': 'TT',
        'isd': '+1',
        'flagImagePos': '-1px -545px',
        'id': 214,
        'displayOrder': 314,
        'population': 426468.5818708742,
        'createdDate': '2023-05-23T05:48:23.3930979Z',
        'createdDateOffset': '2023-04-11T05:48:23.393098+00:00',
        'status': 'Created'
    },
    {
        'name': 'Tunisia',
        'alternateNames': {
            'localName': 'Tunisia (local)',
            'commonName': 'Tunisia (common)'
        },
        'code': 'TN',
        'isd': '+216',
        'flagImagePos': '-1px -698px',
        'id': 215,
        'displayOrder': 315,
        'population': 525533.3378030811,
        'createdDate': '2023-06-13T05:48:23.3930982Z',
        'createdDateOffset': '2023-05-31T05:48:23.3930982+00:00',
        'status': 'Created'
    },
    {
        'name': 'Turkey',
        'alternateNames': {
            'localName': 'Turkey (local)',
            'commonName': 'Turkey (common)'
        },
        'code': 'TR',
        'isd': '+90',
        'flagImagePos': '-1px -2126px',
        'id': 216,
        'displayOrder': 316,
        'population': 440157.274265498,
        'createdDate': '2023-03-14T05:48:23.3930985Z',
        'createdDateOffset': '2023-05-07T05:48:23.3930985+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Turkmenistan',
        'alternateNames': {
            'localName': 'Turkmenistan (local)',
            'commonName': 'Turkmenistan (common)'
        },
        'code': 'TM',
        'isd': '+993',
        'flagImagePos': '-1px -3486px',
        'id': 217,
        'displayOrder': 317,
        'population': 73773.60172657283,
        'createdDate': '2023-05-10T05:48:23.393099Z',
        'createdDateOffset': '2023-06-17T05:48:23.393099+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Turks & Caicos Islands',
        'alternateNames': {
            'localName': 'Turks & Caicos Islands (local)',
            'commonName': 'Turks & Caicos Islands (common)'
        },
        'code': 'TC',
        'isd': '+1',
        'flagImagePos': '-1px -1701px',
        'id': 218,
        'displayOrder': 318,
        'population': 3246.3725285093974,
        'createdDate': '2023-05-11T05:48:23.3930994Z',
        'createdDateOffset': '2023-04-11T05:48:23.3930995+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Tuvalu',
        'alternateNames': {
            'localName': 'Tuvalu (local)',
            'commonName': 'Tuvalu (common)'
        },
        'code': 'TV',
        'isd': '+688',
        'flagImagePos': '-1px -358px',
        'id': 219,
        'displayOrder': 319,
        'population': 303827.01190439146,
        'createdDate': '2023-06-02T05:48:23.3930998Z',
        'createdDateOffset': '2023-05-04T05:48:23.3930998+00:00',
        'status': 'Closed'
    },
    {
        'name': 'U.S. Virgin Islands',
        'alternateNames': {
            'localName': 'U.S. Virgin Islands (local)',
            'commonName': 'U.S. Virgin Islands (common)'
        },
        'code': 'VI',
        'isd': '+1',
        'flagImagePos': '-1px -2381px',
        'id': 220,
        'displayOrder': 320,
        'population': 154383.19056560536,
        'createdDate': '2023-04-02T05:48:23.3931Z',
        'createdDateOffset': '2023-05-29T05:48:23.3931001+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Uganda',
        'alternateNames': {
            'localName': 'Uganda (local)',
            'commonName': 'Uganda (common)'
        },
        'code': 'UG',
        'isd': '+256',
        'flagImagePos': '-1px -1497px',
        'id': 221,
        'displayOrder': 321,
        'population': 12098.89084385639,
        'createdDate': '2023-04-04T05:48:23.3931003Z',
        'createdDateOffset': '2023-04-13T05:48:23.3931004+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Ukraine',
        'alternateNames': {
            'localName': 'Ukraine (local)',
            'commonName': 'Ukraine (common)'
        },
        'code': 'UA',
        'isd': '+380',
        'flagImagePos': '-1px -2721px',
        'id': 222,
        'displayOrder': 322,
        'population': 389829.7678017668,
        'createdDate': '2023-04-14T05:48:23.3931006Z',
        'createdDateOffset': '2023-05-04T05:48:23.3931006+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'United Arab Emirates',
        'alternateNames': {
            'localName': 'United Arab Emirates (local)',
            'commonName': 'United Arab Emirates (common)'
        },
        'code': 'AE',
        'isd': '+971',
        'flagImagePos': '-1px -3061px',
        'id': 223,
        'displayOrder': 323,
        'population': 6753.37357422888,
        'createdDate': '2023-05-08T05:48:23.3931009Z',
        'createdDateOffset': '2023-05-20T05:48:23.3931009+00:00',
        'status': 'Created'
    },
    {
        'name': 'United Kingdom',
        'alternateNames': {
            'localName': 'United Kingdom (local)',
            'commonName': 'United Kingdom (common)'
        },
        'code': 'GB',
        'isd': '+44',
        'flagImagePos': '-1px -86px',
        'id': 224,
        'displayOrder': 324,
        'population': 566298.1333792498,
        'createdDate': '2023-05-26T05:48:23.3931012Z',
        'createdDateOffset': '2023-05-09T05:48:23.3931012+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'United States',
        'alternateNames': {
            'localName': 'United States (local)',
            'commonName': 'United States (common)'
        },
        'code': 'US',
        'isd': '+1',
        'flagImagePos': '-1px -69px',
        'id': 225,
        'displayOrder': 325,
        'population': 237123.97646739057,
        'createdDate': '2023-06-17T05:48:23.3931016Z',
        'createdDateOffset': '2023-05-25T05:48:23.3931016+00:00',
        'status': 'Closed'
    },
    {
        'name': 'Uruguay',
        'alternateNames': {
            'localName': 'Uruguay (local)',
            'commonName': 'Uruguay (common)'
        },
        'code': 'UY',
        'isd': '+598',
        'flagImagePos': '-1px -3571px',
        'id': 226,
        'displayOrder': 326,
        'population': 92440.65920566463,
        'createdDate': '2023-04-17T05:48:23.3931019Z',
        'createdDateOffset': '2023-05-18T05:48:23.3931019+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Uzbekistan',
        'alternateNames': {
            'localName': 'Uzbekistan (local)',
            'commonName': 'Uzbekistan (common)'
        },
        'code': 'UZ',
        'isd': '+998',
        'flagImagePos': '-1px -1293px',
        'id': 227,
        'displayOrder': 327,
        'population': 459435.9563995045,
        'createdDate': '2023-06-07T05:48:23.3931022Z',
        'createdDateOffset': '2023-06-06T05:48:23.3931023+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Vanuatu',
        'alternateNames': {
            'localName': 'Vanuatu (local)',
            'commonName': 'Vanuatu (common)'
        },
        'code': 'VU',
        'isd': '+678',
        'flagImagePos': '-1px -1633px',
        'id': 228,
        'displayOrder': 328,
        'population': 67238.44577475247,
        'createdDate': '2023-04-14T05:48:23.3931025Z',
        'createdDateOffset': '2023-05-13T05:48:23.3931026+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Vatican City',
        'alternateNames': {
            'localName': 'Vatican City (local)',
            'commonName': 'Vatican City (common)'
        },
        'code': 'VA',
        'isd': '+39',
        'flagImagePos': '-1px -3197px',
        'id': 229,
        'displayOrder': 329,
        'population': 10596.21419198432,
        'createdDate': '2023-05-09T05:48:23.3931028Z',
        'createdDateOffset': '2023-05-17T05:48:23.3931028+00:00',
        'status': 'Created'
    },
    {
        'name': 'Venezuela',
        'alternateNames': {
            'localName': 'Venezuela (local)',
            'commonName': 'Venezuela (common)'
        },
        'code': 'VE',
        'isd': '+58',
        'flagImagePos': '-1px -1344px',
        'id': 230,
        'displayOrder': 330,
        'population': 346410.0685707001,
        'createdDate': '2023-04-30T05:48:23.3931031Z',
        'createdDateOffset': '2023-03-14T05:48:23.3931031+00:00',
        'status': 'Created'
    },
    {
        'name': 'Vietnam',
        'alternateNames': {
            'localName': 'Vietnam (local)',
            'commonName': 'Vietnam (common)'
        },
        'code': 'VN',
        'isd': '+84',
        'flagImagePos': '-1px -1259px',
        'id': 231,
        'displayOrder': 331,
        'population': 51819.79386706637,
        'createdDate': '2023-06-05T05:48:23.3931084Z',
        'createdDateOffset': '2023-06-04T05:48:23.3931085+00:00',
        'status': 'Waiting for approval'
    },
    {
        'name': 'Wallis & Futuna',
        'alternateNames': {
            'localName': 'Wallis & Futuna (local)',
            'commonName': 'Wallis & Futuna (common)'
        },
        'code': 'WF',
        'isd': '+681',
        'flagImagePos': '-1px -324px',
        'id': 232,
        'displayOrder': 332,
        'population': 29125.972138447643,
        'createdDate': '2023-05-28T05:48:23.3931087Z',
        'createdDateOffset': '2023-06-01T05:48:23.3931088+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Yemen',
        'alternateNames': {
            'localName': 'Yemen (local)',
            'commonName': 'Yemen (common)'
        },
        'code': 'YE',
        'isd': '+967',
        'flagImagePos': '-1px -2211px',
        'id': 233,
        'displayOrder': 333,
        'population': 154572.4894368221,
        'createdDate': '2023-03-14T05:48:23.393109Z',
        'createdDateOffset': '2023-06-07T05:48:23.3931091+00:00',
        'status': 'Rejected'
    },
    {
        'name': 'Zambia',
        'alternateNames': {
            'localName': 'Zambia (local)',
            'commonName': 'Zambia (common)'
        },
        'code': 'ZM',
        'isd': '+260',
        'flagImagePos': '-1px -2109px',
        'id': 234,
        'displayOrder': 334,
        'population': 246665.4861004441,
        'createdDate': '2023-06-05T05:48:23.3931094Z',
        'createdDateOffset': '2023-06-13T05:48:23.3931094+00:00',
        'status': 'Approved'
    },
    {
        'name': 'Zimbabwe',
        'alternateNames': {
            'localName': 'Zimbabwe (local)',
            'commonName': 'Zimbabwe (common)'
        },
        'code': 'ZW',
        'isd': '+263',
        'flagImagePos': '-1px -2789px',
        'id': 235,
        'displayOrder': 335,
        'population': 91768.74192424271,
        'createdDate': '2023-06-06T05:48:23.3931097Z',
        'createdDateOffset': '2023-04-13T05:48:23.3931098+00:00',
        'status': 'Waiting for approval'
    }
];
