import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';
import { RouterModule } from '@angular/router';
import { SearchComponent } from 'src/app/layout/common/search/search.component';
import { MatSharedModule } from 'src/app/shared';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
    declarations: [SearchComponent],
    imports: [RouterModule.forChild([]), MatSharedModule, SharedModule],
    exports: [SearchComponent],
    providers: [
        {
            provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
            useFactory: (overlay: Overlay) => (): BlockScrollStrategy => overlay.scrollStrategies.block(),
            deps: [Overlay]
        }
    ]
})
export class SearchModule {}
