import { Observable, of } from 'rxjs';
import { InitialDataResolver } from 'src/app/app.resolvers';
import { LayoutComponent } from 'src/app/layout/layout.component';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, PreloadingStrategy, Route } from '@angular/router';

import { authGuardCanMatch } from './core/auth/guards/auth.guard';
import { authRoutes_EmptyLayout, authRoutes_NoAuth_EmptyLayout } from './modules/auth/auth.routes';
import { noAuthLayout } from './modules/no-auth';

@Injectable({
    providedIn: 'root'
})
export class CustomPreloadingStrategy implements PreloadingStrategy {
    public preload(route: Route, fn: () => Observable<any>): Observable<any> {
        return route.data && route.data.preload ? fn() : of(null);
    }
}

export class CustomReuseRouteStrategy extends BaseRouteReuseStrategy {
    public override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig !== curr.routeConfig;
    }
}

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    { path: '', pathMatch: 'full', redirectTo: 'dashboards/analytics' },
    { path: 'home', pathMatch: 'full', redirectTo: 'dashboards/analytics' },
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboards/analytics' },
    { path: 'stakeholder-signed-in-redirect', pathMatch: 'full', redirectTo: 'incident-requests' },

    // no auth routes
    ...authRoutes_NoAuth_EmptyLayout,
    ...noAuthLayout,
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
            preload: true
        },
        children: [
            {
                path: 'home',
                loadChildren: () => import('src/app/modules/landing/home/home.module').then((m) => m.LandingHomeModule)
            }
        ]
    },
    // auth routes
    ...authRoutes_EmptyLayout,
    {
        path: '',
        canMatch: [authGuardCanMatch],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver
        },
        children: [
            {
                path: 'dashboards',
                data: { preload: true, loadAfterSeconds: 1 },
                loadChildren: () => import('src/app/modules/dashboards/dashboard.module').then((m) => m.DashboardModule)
            },
            {
                path: 'tenants',
                loadChildren: () =>
                    import('src/app/modules/tenant/tenant/tenant.page.module.ts').then((m) => m.TenantsModule)
            },
            {
                path: 'organizations',
                loadChildren: () => import('src/app/modules/tenant/tenant.module').then((m) => m.TenantModule)
            },
            {
                path: 'tasks',
                data: { preload: true },
                loadChildren: () => import('src/app/modules/incident/incident.module').then((m) => m.IncidentModule)
            },
            {
                path: 'claims',
                data: { preload: true },
                loadChildren: () => import('src/app/modules/incident/incident.module').then((m) => m.IncidentModule)
            },
            {
                path: 'sent-items',
                data: { preload: true },
                loadChildren: () =>
                    import('src/app/modules/sent-items/sent-items.module').then((m) => m.SentItemsModule)
            },
            {
                path: 'incidents',
                data: { preload: true },
                loadChildren: () => import('src/app/modules/incident/incident.module').then((m) => m.IncidentModule)
            },
            {
                path: 'incident-requests',
                data: { preload: true },
                loadChildren: () =>
                    import('src/app/modules/incident-request/incident-request.module').then(
                        (m) => m.IncidentRequestModule
                    )
            },

            {
                path: 'user/profile',
                loadChildren: () => import('src/app/modules/auth/profile/profile.module').then((m) => m.ProfileModule)
            },
            {
                path: 'configs',
                children: [
                    {
                        path: 'categories',
                        loadChildren: () =>
                            import('src/app/modules/config/category/category.module').then((m) => m.CategoryModule)
                    },
                    {
                        path: 'failure-types',
                        loadChildren: () =>
                            import('src/app/modules/config/failure-type/failure-type.module').then(
                                (m) => m.FailureTypeModule
                            )
                    },
                    {
                        path: 'mdm',
                        loadChildren: () => import('src/app/modules/config/mdm/mdm.module').then((m) => m.MdmModule)
                    }
                ]
            },
            {
                path: 'workflow-configs',
                children: [
                    {
                        path: 'process',
                        loadChildren: () =>
                            import('src/app/modules/workflows/process/process.module').then((m) => m.ProcessModule)
                    },
                    {
                        path: 'status',
                        loadChildren: () =>
                            import('src/app/modules/workflows/status/status.module').then((m) => m.StatusModule)
                    },
                    {
                        path: 'workflow',
                        loadChildren: () =>
                            import('src/app/modules/workflows/workflow/workflow.module').then((m) => m.WorkflowModule)
                    }
                ]
            },
            {
                path: 'notifications',
                data: { preload: true },
                loadChildren: () =>
                    import('src/app/modules/notifications/notifications.module').then((m) => m.NotificationsModule)
            },
            {
                path: 'messages',
                data: { preload: true },
                loadChildren: () =>
                    import('src/app/modules/notifications/notifications.module').then((m) => m.NotificationsModule)
            },
            {
                path: 'print-pdf',
                data: { preload: true },
                loadChildren: () =>
                    import('src/app/modules/incident/pdf/print-pdf/print-pdf.module').then((m) => m.PrintPdfModule)
            }
        ]
    },
    //{ path: '**', redirectTo: '/errors/404' }
    { path: 'unauthorized', redirectTo: '/errors/403' }
];
