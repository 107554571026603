/* eslint-disable @typescript-eslint/ban-types */
// write code to handle http validations errors in angular typescript?

import { HttpErrorResponse } from '@angular/common/http';
import { ElementRef } from '@angular/core';
import { camelize, exists } from '@cvs/utils';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';

export interface ErrorDetail {
    // #region Properties (2)

    key?: string;
    value?: string;

    // #endregion Properties (2)
}
export interface ValidationErrorDetails {
    // #region Properties (3)

    errorCode?: string;
    errorMessage?: string;
    errorMessageKeyParameters?: {
        propertyName?: string;
        propertyValue?: unknown;
    };

    // #endregion Properties (3)
}

interface ServerErrorAttributes {
    // #region Properties (9)

    code?: string | null;
    details?: ErrorDetail[] | null;
    message: string;
    original?: any | null;
    requestId?: string | null;
    status: number;
    statusText?: string | null;
    timestamp?: Date | null;
    validationErrors?: { [key: string]: ValidationErrorDetails[] } | null;

    // #endregion Properties (9)
}

function parseValueFromLine(line: string): string | null {
    if (!line) {
        return null;
    }
    const data = line.split(':');
    data.shift();
    if (data.length > 1) {
        return data.join(':');
    } else {
        return data[0];
    }
}

function parseRequestIdFromLine(line: string): string | null {
    return parseValueFromLine(line);
}

function parseTimestampFromLine(line: string): Date | null {
    const timeStr = parseValueFromLine(line);
    if (!timeStr) {
        return null;
    }
    return new Date(timeStr);
}

function parseMessage(fullMessage: string | null | undefined) {
    if (!fullMessage) {
        return { message: null, requestId: null, timestamp: null };
    }

    const lines = fullMessage.split('\n');
    const message = lines[0];
    const requestId = parseRequestIdFromLine(lines[1]);
    const timestamp = parseTimestampFromLine(lines[2]);
    return {
        message,
        requestId,
        timestamp
    };
}

export class ServerError {
    // #region Properties (9)

    public code: string | null | undefined;
    public details: ErrorDetail[];
    public message: string;
    public original: any;
    public requestId: string | null | undefined;
    public status: number;
    public statusText: string | null | undefined;
    public timestamp: Date | null | undefined;
    public validationErrors?: { [key: string]: ValidationErrorDetails[] } | null;

    // #endregion Properties (9)

    // #region Constructors (1)

    constructor(attributes: ServerErrorAttributes) {
        this.status = attributes.status;
        this.statusText = attributes.statusText;
        this.code = attributes.code;
        this.message = attributes.message;
        this.details = attributes.details || [];
        this.requestId = attributes.requestId;
        this.timestamp = attributes.timestamp;
        this.original = attributes.original;
        this.validationErrors = attributes.validationErrors;
    }

    // #endregion Constructors (1)

    // #region Public Static Methods (2)

    public static fromHttp(response: HttpErrorResponse) {
        const error = response.error || {};
        const { message, requestId, timestamp } = parseMessage(error.message && error.message.value);

        // when the error message returned is not of type ErrorMessage, it will more often
        // than not be a string.
        return new ServerError({
            status: response.status,
            statusText: response.statusText,
            code: error.code ?? error.error,
            details: error.values,
            message: message || (error.message as string) || (error.error_description as string),
            requestId,
            timestamp,
            original: error,
            validationErrors: error.errors || {}
        });
    }

    // write code to set form errors in angular 15
    public static handle400Request<T>(
        err: ServerError,
        formEle: ElementRef<any> = null,
        formGroup: FormGroup<T> = null
    ): ValidationErrorDetails[] | null {
        const formControls = [];
        const validationErrorDetails: ValidationErrorDetails[] = [];
        if (err.validationErrors) {
            Object.keys(err.validationErrors).forEach((prop) => {
                const formControl = formGroup ? (formGroup.get(prop) as FormControl<any>) : null;
                const errDetails = err.validationErrors[prop];
                if (formControl) {
                    const formErrorObj = {};
                    errDetails.forEach((v) => {
                        formErrorObj[camelize(v.errorCode)] = true;
                    });
                    formControl.setErrors(formErrorObj);
                    if (formEle) {
                        const ele = formEle.nativeElement[prop];
                        if (ele) {
                            ele.focus();
                        }
                    }
                    formControls.push(prop);
                } else {
                    validationErrorDetails.push(...errDetails);
                }
            });
            if (formEle && formControls && formControls.length > 0) {
                const ele = formEle.nativeElement[formControls[0]];
                if (ele) {
                    ele.focus();
                }
            }
        }
        return validationErrorDetails.length > 0 ? validationErrorDetails : null;
    }

    // #endregion Public Static Methods (2)

    // #region Public Methods (2)

    public detailsToString() {
        return this.details
            .map(({ key, value }) => {
                return `${key}: ${value}`;
            })
            .join('\n');
    }

    public toString() {
        return (
            [this.status, this.statusText, this.message].filter((x) => exists(x)).join(' - ') + this.detailsToString()
        );
    }

    // #endregion Public Methods (2)
}
export function fixProto(target: Error, prototype: {}) {
    const setPrototypeOf: Function = (Object as any).setPrototypeOf;
    setPrototypeOf ? setPrototypeOf(target, prototype) : ((target as any).__proto__ = prototype);
}

/**
 * Capture and fix the error stack when available
 *
 * Use Error.captureStackTrace
 * Support v8 environments
 */
export function fixStack(target: Error, fn: Function = target.constructor) {
    const captureStackTrace: Function = (Error as any).captureStackTrace;
    captureStackTrace && captureStackTrace(target, fn);
}
export class AppError extends Error {
    // #region Properties (1)

    public name: string;

    // #endregion Properties (1)

    // #region Constructors (1)

    constructor(message?: string, options?: ErrorOptions) {
        super(message, options);
        Object.defineProperty(this, 'name', {
            value: new.target.name,
            enumerable: false,
            configurable: true
        });
        fixProto(this, new.target.prototype);
        fixStack(this);
    }

    // #endregion Constructors (1)
}
