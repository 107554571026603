<mat-form-field class="fuse-mat-dense w-full" [formGroup]="group">
    <mat-label>{{ label }}</mat-label>
    <input
        matInput
        appAutocomplete
        [debounceTime]="debounceTime"
        [minLength]="minLength"
        [matAutocomplete]="auto"
        [formControlName]="controlName"
        [formControl]="control"
        (search)="handleSearch($event)"
        (reset)="resetControl()"
        [attr.type]="attributes?.type || 'text'"
        [ngStyle]="inputStyle"
        [controlErrorAnchor]="matAutocompleteErr"
        (blur)="handleBlur($event)" />
    <mat-progress-spinner
        *ngIf="showSpinner"
        [color]="spinnerColor"
        mode="indeterminate"
        [diameter]="spinnerSize">
    </mat-progress-spinner>
    <mat-icon
        *ngIf="group.get(controlName).value"
        matSuffix
        (click)="group.get(controlName).setValue(null)"
        class="icon-size-4 cursor-pointer"
        [svgIcon]="'heroicons_outline:x-mark'">
    </mat-icon>
    <mat-icon
        class="icon-size-5 cursor-pointer"
        *ngIf="!group.get(controlName).value"
        matSuffix
        [svgIcon]="'heroicons_outline:ellipsis-horizontal'">
    </mat-icon>
    <mat-error
        controlErrorAnchor
        #matAutocompleteErr="controlErrorAnchor"></mat-error>
</mat-form-field>

<mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="handleSelectItem($event)">
    <mat-option *ngFor="let item of filteredItems | async" [value]="item">
        <ng-container
            *ngTemplateOutlet="
                itemTemplate;
                context: { $implicit: item }
            "></ng-container>
        <ng-container *ngIf="!itemTemplate">{{ item[searchKey] }}</ng-container>
    </mat-option>
</mat-autocomplete>
