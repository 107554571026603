import { of, switchMap } from 'rxjs';

import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { IAuthService } from '@cvs/auth';

export const noAuthGuardCanMatch: CanMatchFn = () => {
    const authService = inject(IAuthService);
    return authService.check().pipe(
        switchMap((authenticated) => {
            return of(!authenticated);
        })
    );
};
