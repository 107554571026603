import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { errorTailorImports } from '@ngneat/error-tailor';
import { FuseNgTemplateModule } from '../api/fuse-template';
import { AutoCompleteComponent } from './auto-complete.component';
import { AutocompleteDirective } from './auto-complete.directive';

@NgModule({
    declarations: [AutoCompleteComponent, AutocompleteDirective],
    imports: [
        MatAutocompleteModule,
        MatInputModule,
        MatFormFieldModule,
        MatIconModule,
        MatProgressSpinnerModule,
        errorTailorImports,
        FuseNgTemplateModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [AutoCompleteComponent, AutocompleteDirective, FuseNgTemplateModule]
})
export class FuseAutoCompleteModule {}
