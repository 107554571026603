import { Environment } from '@cvs/types';

export const environment: Environment = {
    production: true,
    buildNumber: '#{{appBuildNumber}}#',
    authConfig: {
        sessionTimeoutInMins: 20,
        sessionTimeoutWarningPeriodInSecs: 45,
        baseUrl: '#{{AuthConfig.IssuerUrl}}#',
        tenantPath: '/realms/#{{AuthConfig.TenantPath}}#',
        clientId: '#{{AuthConfig.ClientId}}#',
        clientIdAad: '#{{AuthConfig.ClientIdAad}}#',
        himitsu: '#{{AuthConfig.SecretArray}}#',
        scope: '#{{AuthConfig.Scope}}#',
        userinfoEndpoint: '#{{AuthConfig.UserinfoEndpoint}}#',
        allowedUrls: ['#{{ApiRoutes.ApiGatewayEndpoint}}#', '#{{ApiRoutes.IdemsApiHost}}#']
    },
    apiRoutes: {
        assets: { 'host': '#{{ApiRoutes.AssetsEndpoint}}#' },
        default: { 'host': '#{{ApiRoutes.ApiGatewayEndpoint}}#' },
        accountApi: {
            host: '#{{ApiRoutes.IdemsApiHost}}#',
            path: '/api'
        },
        tenantApi: {
            host: '#{{ApiRoutes.IdemsApiHost}}#',
            path: '/api'
        },
        mdmApi: {
            host: '#{{ApiRoutes.IdemsApiHost}}#',
            path: '/api'
        },
        newsApi: {
            host: '#{{ApiRoutes.NewsApiHost}}#',
            path: '/v2'
        },
        chatApi: {
            host: '#{{ApiRoutes.IdemsApiHost}}#',
            path: '/api'
        }
    }
};
