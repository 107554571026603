import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';

import { CvsBreadcrumbComponent } from './breadcrumb.component';

@NgModule({
    declarations: [CvsBreadcrumbComponent],
    imports: [RouterModule, CommonModule, TranslocoModule, MatIconModule],
    exports: [CvsBreadcrumbComponent]
})
export class CvsBreadcrumbModule {}
