import { OAuthModuleConfig } from 'angular-oauth2-oidc';

import { environment } from '@environment';

export const authModuleConfig: OAuthModuleConfig = {
    resourceServer: {
        allowedUrls: environment.authConfig.allowedUrls,
        sendAccessToken: true
    }
};
