import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dsign-response-capture',
  template: '',
  styles: [],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DsignResponseCaptureComponent implements OnInit {
  private FormData: any;
  constructor(private readonly activatedRoute: ActivatedRoute) {
    this.getPostedData();
  }
  ngOnInit(): void {
    // eslint-disable-next-line no-console
    console.log('activatedRoute', this.activatedRoute);
    // eslint-disable-next-line no-console
    console.log('activatedRoute in DSignResCapture', this.activatedRoute.queryParams);
  }
  getPostedData() {
    this.activatedRoute.queryParamMap.subscribe(resp => {
      // eslint-disable-next-line no-console
      console.log(resp);
      this.FormData = resp.get('form');
      // eslint-disable-next-line no-console
      console.log(this.FormData);
    })
  }
}
