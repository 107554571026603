import { EMPTY, Observable, throwError } from 'rxjs';

import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { CvsConfirmationService } from '@cvs/components/confirmation';

import { extractError } from './extract-error';
import { ServerError } from './server-error';
import { OAuth2ErrorCode } from './server-error-code.enum';

export interface ErrorHandlerOptions {
    logErrors?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class GlobalErrorHandlingService implements ErrorHandler {
    private readonly confirmationService: CvsConfirmationService;
    private readonly options: ErrorHandlerOptions;

    constructor(
        private injector: Injector,
        private ngZone: NgZone
    ) {
        this.confirmationService = this.injector.get(CvsConfirmationService);
        this.options = {
            logErrors: true
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public handleError(error: any): void {
        const extractedError = extractError(error) || 'Handled unknown error';
        this.captureChunkLoadError(extractedError);
        this.captureRouterMatchError(extractedError);
        if (this.options.logErrors) {
            // eslint-disable-next-line no-console
            console.error(extractedError);
        }
    }

    public handleHttpError(error: HttpErrorResponse): Observable<any> {
        let err = ServerError.fromHttp(error);
        let _title = 'Error';
        let _message = 'Internal Server Error. Please try again after sometime.';
        switch (err.status) {
            case 0:
                _title = 'Offline';
                _message = `Server is offline or unreachable. Please try again after sometime.`;
                err = new ServerError({
                    status: error.status,
                    statusText: error.statusText,
                    message: error.message,
                    code: error.name
                });
                this.ngZone.run(() => {
                    this.confirmationService.closeAll().openError({
                        title: _title,
                        message: _message
                    });
                });
                return EMPTY;
            case HttpStatusCode.BadRequest:
                return throwError(() => err);
            case HttpStatusCode.NotFound:
            case HttpStatusCode.InternalServerError:
                return throwError(() => err);
            case HttpStatusCode.Unauthorized:
                if (err.code === OAuth2ErrorCode.INVALID_GRANT) {
                    return throwError(() => err);
                }
                _title = 'Unauthorized';
                _message = `You are not authorized to use ${error.url}. Please contact administrator for required permissions.`;
                break;
            case HttpStatusCode.Forbidden:
                _title = 'Forbidden';
                _message = `You are forbidden from accessing ${error.url}. Please login and try again.`;
                break;
        }
        return throwError(() => err);
    }

    private captureChunkLoadError(error: { _message?: string }): void {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        const chunkLoadError = /ChunkLoadError [\d]+ failed/;
        const errorMessage = error?._message;
        if (!errorMessage) {
            return;
        }
        if (chunkFailedMessage.test(errorMessage) || chunkLoadError.test(errorMessage)) {
            window.location.reload();
        }
    }

    private captureRouterMatchError(error: { _message?: string } | string): void {
        const routerMatchError = /Cannot match any routes/;
        const errorMessage =
            typeof error === 'string' ? error : error instanceof Error ? error.message : error?._message;
        if (!errorMessage) {
            return;
        }
        if (routerMatchError.test(errorMessage)) {
            this.ngZone.run(() => {
                this.confirmationService.closeAll().openError({
                    title: 'Error',
                    message: 'Page not found.'
                });
            });
        }
    }
}
