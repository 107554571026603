import { Subject, takeUntil, timer } from 'rxjs';
import { NotificationsService } from 'src/app/layout/common/notifications/notifications.service';
import { NotificationUser } from 'src/app/layout/common/notifications/notifications.types';

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { LoggedUserService } from '@common/services/logged-user';
import { PagedResponseModel } from '@cvs/types';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications',
    animations: fuseAnimations
})
export class NotificationsComponent implements OnInit, OnDestroy {
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel')
    private _notificationsPanel: TemplateRef<any>;

    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    public lasUnreadCount = 0;
    public notifications: NotificationUser[];
    public readStatusCount = 0;
    public showMarkAllAsUnReadBtn = false;
    public state: boolean = false;
    public totalCount = 0;
    public unReadStatusCount = 0;
    public unreadCount = 0;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationsService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _loggedUserService: LoggedUserService,
        private _router: Router
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    public ngOnInit(): void {
        timer(0, 20000)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.state = false;
                this._changeDetectorRef.markForCheck();
                setTimeout(() => {
                    this.state = this.lasUnreadCount != this.totalCount;
                    this.lasUnreadCount = this.totalCount;
                    this._notificationsService.getAll().subscribe();
                    // Mark for check
                    this._changeDetectorRef.markForCheck();
                }, 500);
            });
        // Subscribe to notificationUser changes
        this._notificationsService.notifications$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((notifications: PagedResponseModel<NotificationUser>) => {
                // Load the notifications
                this.notifications = notifications.items;
                notifications?.extras['readStatusCounts'].forEach((element) => {
                    if (element.readStatus === 'unread') {
                        this.unReadStatusCount = element.count;
                    }
                    if (element.readStatus === 'read') {
                        this.readStatusCount = element.count;
                    }
                });
                // Calculate the unread count
                this._calculateUnreadCount();
                this.totalCount = notifications['totalItemCount'];

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    public ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    /**
     * Close the notifications panel
     */
    public closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Delete the given notificationUser
     */
    public delete(notificationUser: NotificationUser): void {
        // Delete the notificationUser
        this._notificationsService.delete(notificationUser.id).subscribe(() => {
            this._notificationsService.getAll().subscribe();
            // Mark for check
            this._changeDetectorRef.markForCheck();
        });
    }

    /**
     * Mark all notifications as read
     */
    public markAllAsRead(isRead: boolean): void {
        const notificationId = 0;
        let notificationItem = null;
        if (this.notifications?.length > 0) {
            //notificationId = this.notifications[0].id;
            notificationItem = this.notifications[0];
            notificationItem.isRead = isRead;
            notificationItem.notificationType = 'Alert';
        }
        // Mark all as read
        this._notificationsService
            .updateAllUserNotificationsReadStatus(notificationId, notificationItem)
            .subscribe(() => {
                this._notificationsService.getAll().subscribe();
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    public navigateToNotifications(): void {
        //this._router.navigate("")
        this._router.navigate(['/notifications'], { state: { notificationType: 'Alert' } });
        this.closePanel();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the notifications panel
     */
    public openPanel(): void {
        // Return if the notifications panel or its origin is not defined
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }

    /**
     * Toggle read status of the given notificationUser
     */
    public toggleRead(notificationUser: NotificationUser): void {
        const user = this._loggedUserService.getUser();
        // Toggle the read status
        notificationUser.isRead = !notificationUser.isRead;
        notificationUser.userId = user.id;
        // Update the notificationUser
        this._notificationsService.update(notificationUser.id, notificationUser).subscribe();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    public trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void {
        let count = 0;
        if (this.notifications && this.notifications.length) {
            count = this.notifications.filter((notificationUser) => !notificationUser.isRead).length;
        }

        this.unreadCount = count;
        this.showMarkAllAsUnReadBtn = false;
        if (this.unreadCount === 0 && this.notifications?.length > 0) {
            this.showMarkAllAsUnReadBtn = true;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }
}
