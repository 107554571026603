<cvs-dialog>
    <ng-container cvsDialogTitle *ngIf="title">{{ title }}</ng-container>
    <ng-container cvsDialogContent>
        <form #form="ngForm" novalidate>
            <mat-form-field class="fuse-mat-bold flex-auto w-full">
                <mat-label>{{message}}</mat-label>
                <input matInput #input (keydown.enter)="$event.preventDefault(); form.valid && accept()"
                    [(ngModel)]="value" name="value" required />
            </mat-form-field>
        </form>
    </ng-container>
    <ng-container cvsDialogActions>
        <button mat-stroked-button color="primary" [matDialogClose]="'cancelled'">
            {{ cancelButton | transloco }}
        </button>
        <button mat-flat-button color="primary" #acceptBtn [disabled]="!form.valid" (click)="accept()">
            {{ acceptButton | transloco }}
        </button>
    </ng-container>
</cvs-dialog>