<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>
<cvs-idle-bar></cvs-idle-bar>
<div class="w-full h-full flex">
    <div class="app-navigation">
        <div class="navigation">
            <div class="flex flex-row overflow-hidden h-full">
                <div class="sidebar" [ngClass]="{'w-64':sidebarExpanded,'w-4':!sidebarExpanded}">
                    <div class="sidebar-content transition-all"
                        [ngClass]="{'px-2':sidebarExpanded,'px-6':!sidebarExpanded}">
                        <div class="relative flex flex-row items-start justify-between ps-2.5 py-3 w-full">
                            <img [src]="'assets/logos/logo-on-light.svg' | assetsSource" class="h-7 w-auto"
                                alt="Centvis Logo" />
                        </div>
                        <ng-container *ngTemplateOutlet="mainMenu; context: { showUser: false }"></ng-container>
                    </div>
                </div>
            </div>
            <div class="app-resizer" (click)="sidebarExpanded=!sidebarExpanded">
                <div class="sizer-wrap">
                    <div class="strip"></div>
                    <div class="sizer">
                        <div class="line">

                        </div>
                    </div>
                    <button class="btn">
                        <img src="../../../../../assets/logos/centvis/arrow-on-light.svg"
                            class="h-6 w-auto transition-transform" [ngClass]="{'-rotate-180 -ml-0.5':sidebarExpanded}"
                            alt="Centvis Logo" />
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div id="content" class="">
        <nav class="w-full bg-white border-b cvsui-default-border-color dark:bg-slate-800 dark:border-slate-700">
            <div class="flex flex-row items-center justify-center w-full">
                <div class="cvsui-container-px flex-auto flex flex-col grow items-start justify-center h-full pl-4">
                    <cvs-breadcrumb></cvs-breadcrumb>
                </div>
                <div class="flex items-center gap-3">
                    <languages class="hidden md:block"></languages>
                    <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
                    <search *ngIf="false" [appearance]="'bar'"></search>
                    <shortcuts *ngIf="false"></shortcuts>
                    <messages *ngIf="true"></messages>
                    <notifications *ngIf="true"></notifications>
                    <user cssClass="min-h-11" class="relative border-l cvsui-default-border-color"></user>
                </div>
            </div>
        </nav>
        <div class="cvsui-container-px w-full mt-2 flex flex-row items-center justify-between"
            *ngIf="(pageTitle ? (pageTitle | transloco) :(currentTitle$ | async) | transloco) as pgTitle">
            <h1 class="cvsui-layout-header-title">
                {{ pgTitle }}</h1>
            <div>
                <ng-container *ngIf="headerActionComponent | async as headerActionComponent">
                    <ng-container
                        *ngComponentOutlet="
                            headerActionComponent.outlet;
                            injector: headerActionComponent.injector;
                            content: headerActionComponent.outletContent
                        "></ng-container>
                </ng-container>
            </div>
        </div>
        <router-outlet *ngIf="true"></router-outlet>
        <footer
            class="cvsui-layout-footer mt-auto min-h-12 w-full flex-col-reverse gap-y-1 border-t cvsui-default-border-color py-2 text-md sm:flex-row sm:justify-between sm:gap-y-0 sm:py-0"
            [showLogo]="!sidebarExpanded" fontClass="text-primary">
        </footer>
    </div>
</div>
<ng-template #menuLooper let-navigation="navigation" let-level="level">
    <ng-container *ngFor="let item of navigation">
        <ng-container *ngIf="item.children && item.children.length > 0">
            <div class="relative block w-full ">
                <div role="button" class="flex items-center w-full p-0 leading-tight transition-all rounded-md outline-none 
			text-start cvsui-text-default-label-dark hover:bg-slate-200 hover:bg-opacity-80 hover:cvsui-text-default-label-dark focus:bg-slate-200 focus:bg-opacity-80
			 focus:cvsui-text-default-label-dark active:bg-slate-200 active:bg-opacity-80 active:cvsui-text-default-label-dark"
                    [ngClass]="{'bg-slate-100': item.expanded}">
                    <button type="button" (click)="item.expanded = !item.expanded"
                        class="flex items-center justify-between w-full p-1.5 font-semibold leading-snug text-left transition-colors border-b-0 select-none border-b-blue-slate-100 text-slate-900 hover:text-slate-900">
                        <div class="grid mr-2 place-items-center">
                            <mat-icon *ngIf="item.icon" aria-hidden="true" [svgIcon]="item.icon"></mat-icon>
                        </div>
                        <p
                            [matTooltip]="item.menuId | transloco" [matTooltipPosition]="'above'"
                            class="uppercase block mr-auto font-normal overflow-hidden truncate leading-relaxed text-slate-900 active:bg-slate-200 active:bg-opacity-80 active:cvsui-text-default-label-dark">
                            {{ item.menuId | transloco }}
                        </p>
                        <mat-icon class="ml-4 transition-transform ease-in-out duration-200 text-zinc-500"
                            aria-hidden="true" [ngClass]="{
								'rotate-0': !item.expanded,
								'rotate-90': item.expanded
							}" [svgIcon]="'mat_outline:keyboard_arrow_right'"></mat-icon>
                    </button>
                </div>
                <div [@slideInOut]="item.expanded" *ngIf="item.expanded"
                    class="relative grid overflow-hidden transition-all duration-300 ease-in-out" [ngClass]="{
                'grid-rows-[1fr] opacity-100 bg-white mt-0.5 pb-2 border-b-2 border-dashed': item.expanded,
                'grid-rows-[0fr] opacity-0': !item.expanded
            }">
                    <div
                        class="absolute cvsui-default-border-color border-x border-dashed left-4 top-2 bottom-3 right-1 w-px">
                    </div>
                    <ng-template [ngTemplateOutlet]="menuLooper" [ngTemplateOutletContext]="{
                    $implicit: this,
                    navigation: item.children,
                    level: +level + 1
                }"></ng-template>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!(item.children && item.children.length > 0)">
            <a role="button" [routerLink]="item.link" [routerLinkActive]="'font-semibold bg-slate-100/80'"
                class="truncate overflow-hidden flex items-center w-full leading-tight transition-all rounded-md outline-none text-start hover:bg-slate-200 hover:bg-opacity-80
            hover:cvsui-text-default-label-dark focus:font-semibold focus:cvsui-text-default-label-dark  active:font-semibold active:cvsui-text-default-label-dark"
                [ngClass]="level === 1 ? 'uppercase p-1.5' : 'p-1.5 pl-4'">
                <div class="grid place-items-center">
                    <mat-icon *ngIf="item.icon" [ngClass]="level === 1 ? 'mr-2 ' : 'mr-0.5 icon-size-4'"
                        class="group-hover:cvsui-text-default-label-dark" aria-hidden="true"
                        [svgIcon]="level===1 ? item.icon:''">
                    </mat-icon>
                </div>
                <span   [matTooltip]="item.menuId | transloco" [matTooltipPosition]="'above'">{{ item.menuId | transloco }}</span>
            </a>
        </ng-container>
    </ng-container>
</ng-template>
<ng-template #mainMenu let-showUser="showUser">
    <nav class="flex min-w-56 flex-col gap-1 font-sans text-md font-normal">
        <ng-container
            *ngTemplateOutlet="menuLooper; context: { $implicit: this, navigation: navigation.default , level: 1 }"></ng-container>
    </nav>
</ng-template>
<quick-chat #quickChat="quickChat"></quick-chat>