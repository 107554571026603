import { filter, map } from 'rxjs/operators';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';

import { Breadcrumb } from './breadcrumb.model';
import { CvsBreadcrumbService } from './breadcrumb.service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'cvs-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CvsBreadcrumbComponent implements OnInit {
    @Input() public bgColor = '#eee';
    @Input() public fontColor = '#000000';
    @Input() public fontSize = '18px';
    @Input() public lastLinkColor = '#000000';
    @Input() public separatorIcon = 'mat_outline:chevron_right';
    @Input() public separatorSymbol = '/';
    @Input() public useTransloco = true;

    public breadcrumb: Breadcrumb[] = [];
    public params: { [key: string]: any };

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private cvsBreadcrumbService: CvsBreadcrumbService,
        private cdr: ChangeDetectorRef
    ) {
        this.breadCrumbData();
    }

    public ngOnInit() {
        this.cvsBreadcrumbService.breadcrumbLabels.subscribe((labelData) => {
            for (const label in labelData) {
                if (Object.prototype.hasOwnProperty.call(labelData, label)) {
                    this.breadcrumb.map((crumb) => {
                        const labelParams = crumb.label.match(/[^{{]+(?=\}})/g);
                        if (labelParams) {
                            for (const labelParam of labelParams) {
                                const dynamicData = labelData[label];
                                if (labelParam === label) {
                                    crumb.label = crumb.label.replace('{{' + labelParam + '}}', dynamicData);
                                }
                            }
                        }
                        const urlParams = crumb.url.match(/[^{{]+(?=\}})/g);
                        if (urlParams) {
                            for (const urlParam of urlParams) {
                                const dynamicData = labelData[label];
                                if (urlParam === label) {
                                    crumb.url = crumb.url.replace('{{' + urlParam + '}}', dynamicData);
                                }
                            }
                        }
                    });
                }
            }
            this.cdr.detectChanges();
        });

        this.cvsBreadcrumbService.newBreadcrumb.subscribe((breadcrumb: Breadcrumb[]) => {
            if (breadcrumb.length > 0) {
                this.updateData(this.activatedRoute, breadcrumb);
            }
        });
        let localRoute = this.activatedRoute;
        while (localRoute.firstChild) {
            localRoute = localRoute.firstChild;
        }
        this.updateData(localRoute, null);
    }

    public breadCrumbData(): void {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .pipe(map(() => this.activatedRoute))
            .pipe(
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                })
            )
            .pipe(filter((route) => route.outlet === PRIMARY_OUTLET))

            .subscribe((route) => {
                this.params = route.snapshot.params;
                this.updateData(route, null);
            });
    }

    private updateData(route, newBreadcrumb): void {
        if (route.snapshot.data.breadcrumb || newBreadcrumb) {
            const data = route.snapshot.data.breadcrumb ? route.snapshot.data.breadcrumb : newBreadcrumb;
            const breadcrumb = JSON.parse(JSON.stringify(data));
            breadcrumb.map((crumb) => {
                const urlChunks = crumb.url.split('/');
                for (const chunk of urlChunks) {
                    if (chunk.includes(':')) {
                        const paramID = chunk.replace(':', '');
                        // const routerParamID = route.snapshot.params[paramID];
                        const routerParamID = this.params[paramID];
                        crumb.url = crumb.url.replace(`:${paramID}`, routerParamID);
                    }
                }

                if (!this.useTransloco) {
                    const labelParams = crumb.label.match(/[^{{]+(?=\}})/g);
                    if (labelParams) {
                        for (const labelParam of labelParams) {
                            // const routerParamID = route.snapshot.params[labelParam.trim()];
                            const routerParamID = this.params[labelParam.trim()];
                            if (routerParamID) {
                                crumb.label = crumb.label.replace('{{' + labelParam + '}}', routerParamID);
                            } else {
                                // crumb.label = crumb.label.replace('{{' + labelParam + '}}', '');
                            }
                        }
                    }
                }
            });
            this.breadcrumb = breadcrumb;
            this.cdr.detectChanges();
        } else {
            this.breadcrumb = [];
            this.cdr.detectChanges();
        }
    }
}
