import { Directive, HostListener, Input, NgModule } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Directive({
    selector: 'mat-select[cvsMatSelectBinding]'
})
export class CvsMatSelectBindingDirective {
    @Input()
    public cvsMatSelectBinding: { [key: string]: string } = {};

    constructor(private readonly controlContainer: ControlContainer) {}

    @HostListener('selectionChange', ['$event'])
    public onSelectionChange(event: any) {
        const dataItem = event.source.value;
        if (dataItem == null || dataItem == undefined) return;
        if (this.cvsMatSelectBinding) {
            const keys = Object.keys(this.cvsMatSelectBinding);
            keys.forEach((key) => {
                const dataValue = dataItem[this.cvsMatSelectBinding[key]];
                const control = this.controlContainer.control.get(key);
                if (control) {
                    control.patchValue(dataValue);
                    control.updateValueAndValidity();
                }
            });
        }
    }
}

@NgModule({
    declarations: [CvsMatSelectBindingDirective],
    exports: [CvsMatSelectBindingDirective]
})
export class CvsMatSelectBindingModule {}
