import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CvsNl2BrPipeModule } from '@cvs/pipes';
import { TranslocoModule } from '@ngneat/transloco';

import { CvsAlertDialogComponent } from './alert-dialog/alert-dialog.component';
import {
    CvsDialogActionsDirective,
    CvsDialogComponent,
    CvsDialogContentDirective,
    CvsDialogTitleDirective
} from './dialog.component';
import { CvsDialogService } from './dialog.service';
import { CvsPromptDialogComponent } from './prompt-dialog/prompt-dialog.component';

const TD_DIALOGS: Type<any>[] = [
    CvsPromptDialogComponent,
    CvsAlertDialogComponent,
    CvsDialogComponent,
    CvsDialogTitleDirective,
    CvsDialogActionsDirective,
    CvsDialogContentDirective
];

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        MatDialogModule,
        MatInputModule,
        MatButtonModule,
        MatToolbarModule,
        MatTooltipModule,
        MatIconModule,
        TranslocoModule,
        CvsNl2BrPipeModule
    ],
    declarations: [...TD_DIALOGS],
    exports: [...TD_DIALOGS],
    providers: [CvsDialogService]
})
export class CvsDialogsModule {}
