// use #/path to use mock
export const API_ENDPOINTS = {
    accountApi: {
        baseUrlKey: 'accountApi',
        customer: {
            registrations: {
                all: '/customer/all-registrations',
                get: '/customer/registrations',
                getById: '/customer/registrations/{0}'
            }
        },
        iam: {
            me: {
                menus: 'iam/me/menus',
                notifications: 'iam/me/notifications',
                me: 'iam/me'
            },
            adminDashboard: {
                get: 'iam/dashboard/admin'
            },
            adminUserActivityDashboard: {
                get: 'iam/user-activity'
            },
            otp: {
                getById: 'iam/OtpGenerate/{0}',
                get: 'iam/OtpGenerate',
                create: 'iam/OtpGenerate',
                update: 'iam/OtpGenerate/{0}'
            },
            users: {
                getByCountryId: 'iam/countries/{0}/users',
                resetPassword: {
                    get: '/iam/user/reset-password'
                },
                get: 'iam/user',
                getById: 'iam/user/{0}'
            },
            workflowUsers: {
                get: 'iam/workflow-users'
            },
            tenants: {
                get: 'iam/tenants',
                getById: 'iam/tenants/{0}',
                update: 'iam/tenants/{0}',
                create: 'iam/tenants'
            },
            tenantConfiguration: {
                get: 'iam/tenant-configurations',
                getById: 'iam/tenant-configurations/{0}',
                update: 'iam/tenant-configurations/{0}',
                create: 'iam/tenant-configurations',
                getAll: 'iam/all-tenant-configurations'
            },
            roles: {
                get: 'iam/roles',
                getById: 'iam/roles/{0}'
            },
            permissions: {
                get: 'iam/permission'
            },
            workgroup: {
                get: 'iam/workgroups',
                getById: 'iam/workgroups/{0}',
                getByCountryId: 'iam/countries/{0}/workgroups',
                workgroupMembers: {
                    get: 'iam/workgroups/{0}/members',
                    getall: 'iam/workgroups/{0}/members',
                    delete: 'iam/workgroups/{0}/members/{1}'
                }
            },
            organizations: {
                get: 'iam/organizations',
                getById: 'iam/organizations/{0}',
                update: 'iam/organizations/{0}',
                create: 'iam/organizations',
                getByParentId: 'iam/organizations/{0}/children'
            },
            organizationMapping: {
                get: 'iam/organization-workgroup-mapping',
                getById: 'iam/organization-workgroup-mapping/{0}',
                update: 'iam/organization-workgroup-mapping/{0}',
                create: 'iam/organization-workgroup-mapping',
                delete: 'iam/organization-workgroup-mapping/{0}'
            },
            organizationLogo: {
                get: 'iam/organizations/{0}/logos',
                delete: 'iam/organizations/{0}/logos/{1}',
                getOrganizationLogo: 'iam/organizations/{0}/logo'
            },
            userPreferences: {
                all: '/iam/all-user-preferences',
                get: '/iam/user-preferences',
                getById: '/iam/user-preferences/{0}',
                addFavorite: '/iam/favorites'
            },
            profile: {
                get: 'iam/user/profile/{0}',
                update: 'iam/user/profile/{0}',
                updateAvatar: 'iam/user/profile/avatar'
            },
            workflowProcess: {
                get: 'iam/workflowprocess',
                getById: 'iam/workflowprocess/{0}',
                update: 'iam/workflowprocess/{0}',
                create: 'iam/workflowprocess'
            },
            workflowStatus: {
                get: 'iam/workflowstatus',
                getById: 'iam/workflowstatus/{0}',
                update: 'iam/workflowstatus/{0}',
                create: 'iam/workflowstatus'
            },
            workflow: {
                get: 'iam/workflows',
                getById: 'iam/workflows/{0}',
                update: 'iam/workflows/{0}',
                create: 'iam/workflows'
            },
            workflowTransition: {
                get: 'iam/workflowTransition',
                getById: 'iam/workflowTransition/{0}',
                update: 'iam/workflowTransition/{0}',
                create: 'iam/workflowTransition',
                delete: 'iam/workflowTransition'
            },
            workflowPostFunction: {
                get: 'iam/workflow-post-function',
                getById: 'iam/workflow-post-function/{0}',
                update: 'iam/workflow-post-function/{0}',
                create: 'iam/workflow-post-function',
                delete: 'iam/workflow-post-function/{0}'
            },
            workflowGuard: {
                get: 'iam/workflow-guard',
                getById: 'iam/workflow-guard/{0}',
                update: 'iam/workflow-guard/{0}',
                create: 'iam/workflow-guard'
            }
        }
    },
    mdmApi: {
        baseUrlKey: 'mdmApi',
        countries: {
            getall: 'mdm/countries',
            all: '#/geography/all-countries',
            get: '#/geography/countries'
        },

        types: {
            all: 'mdm/types',
            get: 'mdm/types',
            getById: 'mdm/types/{0}',
            update: 'mdm/types/{0}'
        },
        timeZones: {
            get: 'mdm/time-zones'
        },
        locale: {
            get: 'mdm/locales'
        },
        languages: {
            get: 'mdm/languages'
        },
        branches: {
            get: 'branch/get-all-branches',
            getBranchByCode: 'branch/get-all-branches-bycode'
        },
        customers: {
            getAllCustomers: 'customer/get-all-customers'
        },
        subCategory: {
            all: 'mdm/categories/{0}/subcategories',
            get: 'mdm/categories/{0}/subcategories',
            getById: 'mdm/categories/{0}/subcategories/{1}',
            getByParentId: 'mdm/category/{0}'
        },
        category: {
            all: 'mdm/all-categories',
            get: 'mdm/categories',
            getById: 'mdm/categories/{0}'
        },
        failureType: {
            all: 'mdm/failureTypes',
            get: 'mdm/failureTypes',
            getById: 'mdm/failureTypes/{0}'
        },
        failureSubType: {
            all: 'mdm/failureTypes/{0}/subtypes',
            get: 'mdm/failureTypes/{0}/subtypes',
            getById: 'mdm/failureTypes/{0}/subtypes/{1}',
            getByParentId: 'mdm/failureType/{0}'
        },
        apiConfig: {
            create: 'mdm/apiConfigs',
            update: 'mdm/apiConfigs/{0}',
            getById: 'mdm/apiConfig/{0}'
        },
        departments: {
            getAll: 'department/get-all-departments',
            get: 'mdm/departments',
            getById: 'mdm/departments/{0}',
            update: 'mdm/departments/{0}'
        },
        emails: {
            getAll: 'iam/emailTemplates',
            getById: 'iam/emailTemplates/{0}',
            update: 'iam/emailTemplates/{0}',
            get: 'iam/emailTemplates'
        },
        locations: {
            get: 'mdm/locations'
        }
    },
    incidentApi: {
        baseUrlKey: 'tenantApi',
        dashboards: {
            incidents: 'tenant/dashboard/incidents/daily-count',
            incidentsByStatus: 'tenant/dashboard/incidents/status-count',
            incidentDashboard: 'tenant/dashboard/incidents/dashinfo'
        },
        reports: {
            incidentTrends: 'tenant/reports/incidents/trend-report',
            incidentStats: 'tenant/reports/incidents/summary-stats',
            incidentChart: 'tenant/reports/incidents/summary-chart'
        },
        workFlowAudit: {
            get: 'tenant/incidentWFAudit'
        },
        activity: {
            get: 'tenant/incident-activity'
        },
        incidentStatus: {
            getById: 'tenant/incidents/{0}/status'
        },
        tasks: {
            get: 'tenant/tasks',
            all: 'tenant/tasks',
            getById: 'tenant/tasks/{0}'
        },
        email: {
            getAll: 'tenant/email',
            getById: 'tenant/email/{0}',
            update: 'tenant/email/{0}',
            get: 'tenant/email'
        },
        emailAttachment: {
            getAll: 'tenant/emailAttachment',
            getById: 'tenant/emailAttachment/{0}',
            update: 'tenant/emailAttachment/{0}',
            get: 'tenant/emailAttachment'
        },
        incidentRequest: {
            get: 'tenant/incident-request',
            getById: 'tenant/incident-request/{0}',
            update: 'tenant/incident-request/{0}',
            create: 'tenant/incident-request',
            patch: 'tenant/incident-request/{0}/{1}',
            checkAnonymousUserLimit: 'tenant/incident-request/check-anonymous-user-limit'
        },
        incidents: {
            get: 'tenant/incidents',
            getSentItems: 'tenant/incidents/sent-items',
            getChildrenById: 'tenant/incidents/{0}?style={1}',
            getById: 'tenant/incidents/{0}',
            patch: 'tenant/incidents/{0}/{1}',
            getShipmentSummary: 'shipment-summary/get-shipmentsummary-details',
            getOrDownloadUrl: 'tenant/incidents/print-download',
            getSummaryById: 'tenant/incidents/{0}/summary',
            getAIHelperByIncidentId: 'tenant/incidents/{0}/ai-helper',
            //createIncident: 'incident/create-incident',
            // all: 'tenant/incidents/all-incidents'
            pdf: 'tenant/incidents/pdf',
            getWatchList: 'tenant/incidents/watch-list'
        },
        capa: {
            get: 'tenant/capas',
            all: 'tenant/capas',
            getById: 'tenant/capas/{0}/{1}'
        },
        capaClosure: {
            get: 'tenant/capaClosure',
            all: 'tenant/capaClosure',
            getById: 'tenant/capaClosure/{0}/{1}'
        },
        originRootCause: {
            get: 'tenant/rootCauseAnalysis',
            all: 'tenant/rootCauseAnalysis',
            getById: 'tenant/rootCauseAnalysis/{0}'
        },
        originCorrectivePreventive: {
            get: 'tenant/correctivePreventiveActions',
            all: 'tenant/correctivePreventiveActions',
            getById: 'tenant/correctivePreventiveActions/{0}'
        },
        analysisOfSummary: {
            get: 'tenant/analysisOfSummarys',
            getStatus: 'tenant/incidents/{0}/status',
            all: 'tenant/analysisOfSummarys',
            getById: 'tenant/analysisOfSummarys/{0}'
        },
        incidentAnalysis: {
            get: 'tenant/incidentAnalysis',
            getStatus: 'tenant/incidents/{0}/status',
            all: 'tenant/incidentAnalysis',
            getById: 'tenant/incidentAnalysis/{0}'
        },
        claims: {
            get: 'tenant/claims',
            all: 'tenant/claims',
            getById: 'tenant/claims/{0}'
        },
        claimRequest: {
            get: 'tenant/claim-requests',
            all: 'tenant/claim-requests',
            getById: 'tenant/claim-requests/{0}'
        },
        incidentTypes: {
            get: 'tenant/incidentTypes',
            all: 'tenant/incidentTypes',
            getById: 'tenant/incidentTypes/{0}'
        },
        internalNotes: {
            get: 'tenant/internalNotes',
            all: 'tenant/internalNotes',
            getById: 'tenant/internalNotes/{0}',
            patch: 'tenant/internalNotes/{0}/{1}'
        },
        immediateMeasure: {
            get: 'tenant/immediateMeasure',
            all: 'tenant/immediateMeasure',
            getById: 'tenant/immediateMeasure/{0}'
        },
        documents: {
            get: 'tenant/incidentDocuments',
            all: 'tenant/incidentDocuments/download',
            downloadDocumentsByIds: 'tenant/incidentDocuments/download/{0}',
            getById: 'tenant/incidentDocuments/{0}',
            delete: 'tenant/incidentDocuments/{0}',
            patch: 'tenant/incidentDocuments/{0}/{1}',
            getCompressedFile: 'tenant/incidentDocuments/download-zip',
            deleteAll: 'tenant/incidentDocuments'
        },
        documentSign: {
            get: 'tenant/incidentDocumentsSignVerification',
            create: 'tenant/incidentDocumentsSignVerification'
        },
        deviation: {
            get: 'tenant/deviationReports',
            all: 'tenant/deviationReports'
        },
        dsConfig: {
            getAll: 'tenant/digitalSignatureConfig'
        },
        shipmentNumbers: {
            get: 'tenant/dynaco/shipments'
        },
        customers: {
            get: 'tenant/dynaco/customers'
        },
        serviceProviders: {
            get: 'tenant/dynaco/service-providers'
        },
        notifications: {
            get: 'tenant/notificationUser',
            updateUserNotification: 'tenant/notificationUser',
            updateUserNotifications: 'tenant/notificationUser/all-notifications',
            getById: 'tenant/notificationUser/{0}'
        },
        userDocumentPrintConfig: {
            get: 'tenant/pdf-column-config',
            Update: 'tenant/pdf-column-config/{0}',
            all: 'tenant/pdf-column-config',
            getById: 'tenant/pdf-column-config/{0}'
        }
    },
    newsApi: {
        baseUrlKey: 'newsApi',
        news: {
            everything:
                '/everything?q=+logistics+"supply%20chain"+freight&language=en&sortBy=popularity&apiKey=ebb7981d7ffc442a9644605847e664b3',
            topHeadlines:
                '/top-headlines?q=+logistics+"supply%20chain"+freight&language=en&sortBy=popularity&apiKey=ebb7981d7ffc442a9644605847e664b3'
        }
    },
    chatApi: {
        baseUrlKey: 'chatApi',
        conversations: {
            getAll: 'chat/conversations',
            addParticipant: 'chat/conversations/{0}/add/{1}',
            getGroups: 'chat/conversations/groups',
            getMessages: 'chat/conversations/{0}/messages'
        },
        chatHub: {
            hub: 'chat/chathub'
        }
    }
};
