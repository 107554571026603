import { NgxPermissionsGuard, NgxPermissionsService, NgxRolesService } from 'ngx-permissions';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { INITIATED_PERMISSIONS } from './permissions-guard.const';

@Injectable()
export class CvsPermissionsGuard extends NgxPermissionsGuard {
    constructor(
        private _permissionsService: NgxPermissionsService,
        _rolesService: NgxRolesService,
        _router: Router
    ) {
        super(_permissionsService, _rolesService, _router);
    }
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve: any) => {
            const permissions = this._permissionsService.getPermissions();
            if (permissions[INITIATED_PERMISSIONS]) {
                resolve(true);
            } else {
                (super.canActivateChild(route, state) as Promise<boolean>).then((result) => {
                    resolve(result);
                });
            }
        });
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const permissions = this._permissionsService.getPermissions();
        return new Promise((resolve: any) => {
            if (permissions[INITIATED_PERMISSIONS]) {
                resolve(true);
            } else {
                (super.canActivate(route, state) as Promise<boolean>).then((result) => {
                    resolve(result);
                });
            }
        });
    }
}
