import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { CallbackFilterPipeModule } from '@cvs/pipes';
import { errorTailorImports } from '@ngneat/error-tailor';
import { TranslocoModule } from '@ngneat/transloco';

import { CvsTruncatePipeModule } from '../../pipes/truncate.pipe';
import { CvsGridComponent } from './cvs-grid.component';
import { CvsGridService } from './cvs-grid.service';

@NgModule({
    declarations: [CvsGridComponent],
    providers: [CvsGridService],
    exports: [CvsGridComponent],
    imports: [
        CommonModule,
        RouterModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatCheckboxModule,
        TranslocoModule,
        CallbackFilterPipeModule,
        MatFormFieldModule,
        MatInputModule,
        MatDividerModule,
        MatSelectModule,
        MatTooltipModule,
        MatAutocompleteModule,
        FormsModule,
        ReactiveFormsModule,
        CvsTruncatePipeModule,
        OverlayModule,
        DragDropModule,
        MatDatepickerModule,
        errorTailorImports
    ]
})
export class CvsGridModule {}
