import { Subject } from 'rxjs';
import { UserService } from 'src/app/core/user/user.service';

import { BooleanInput } from '@angular/cdk/coercion';
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit,
    ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { LoggedUser, LoggedUserService } from '@common/services/logged-user';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
    styles: [
        ':host { display: flex; align-items: center;}',
        '.mat-mdc-icon-button svg.cvsui-avatar, .mat-mdc-icon-button img.cvsui-avatar, .cvsui-avatar { @apply w-7 h-7 leading-7 rounded-full ring-1 ring-primary text-primary text-md #{!important};  }'
    ]
})
export class UserComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /* eslint-disable @typescript-eslint/naming-convention */
    public static ngAcceptInputType_showAvatar: BooleanInput;

    @Input() public cssClass = '';
    /* eslint-enable @typescript-eslint/naming-convention */
    @Input() public showAvatar = true;
    @Input() public showUserName = true;

    public user: LoggedUser;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: LoggedUserService,
        private _user: UserService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    public ngOnInit(): void {
        // Subscribe to changing profile image
        this._user.user$.subscribe((res) => {
            this.user = res ? this._userService.getUser() : null;
            this._changeDetectorRef.markForCheck();
        });
        // Subscribe to user changes
        this.user = this._userService.getUser();
    }

    /**
     * On destroy
     */
    public ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    public getInitials(data: string): string {
        const names = data.split('^');
        let initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        } else {
            initials = names[0].substring(0, 2).toUpperCase();
        }
        return initials;
    }

    /**
     * Update the user status
     *
     * @param status
     */

    /**
     * Sign out
     */
    public signOut(): void {
        this._router.navigate(['/sign-out']);
    }

    public viewProfile(): void {
        this._router.navigate(['/user/profile/' + this.user.id]);
    }
}
