import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { API_ENDPOINTS } from 'src/app/api-endpoints.constants';
import { Navigation } from 'src/app/core/navigation/navigation.types';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@cvs/services';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private readonly apiEndpoint: string;

    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        environmentService: EnvironmentService,
        private translate: TranslocoService
    ) {
        this.apiEndpoint = environmentService.getApiUrl(
            API_ENDPOINTS.accountApi.baseUrlKey,
            API_ENDPOINTS.accountApi.iam.me.menus
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    public get(): Observable<Navigation> {
        return this._httpClient.get<Navigation>(this.apiEndpoint).pipe(
            map((n) => {
                n?.compact?.forEach((c) => (c.tooltip = this.translate.translate(c.menuId)));
                n?.futuristic?.forEach((c) => (c.tooltip = this.translate.translate(c.menuId)));
                n?.default?.forEach((c) => (c.tooltip = this.translate.translate(c.menuId)));
                n?.horizontal?.forEach((c) => (c.tooltip = this.translate.translate(c.menuId)));
                return n;
            }),
            tap((navigation) => {
                this._navigation.next(navigation);
            })
        );
    }
}
