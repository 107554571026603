import { AuthServiceDeprecated } from 'src/app/core/auth/auth.service';

import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

@NgModule({
    imports: [HttpClientModule],
    providers: [AuthServiceDeprecated]
})
export class AuthModule {}
