import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, RouterModule } from '@angular/router';
import { SwRegistrationOptions } from '@angular/service-worker';
import { CentvisModule } from '@cvs/centvis.module';
import { environment } from '@environment';
import { FuseModule } from '@fuse/fuse.module';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { FuseConfigModule } from '@fuse/services/config';

import { AppComponent } from './app.component';
import { appInitializerProviders } from './app.initializer';
import { appRoutes, CustomPreloadingStrategy } from './app.routing';
import { appConfig } from './core/config/app.config';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { mockApiServices } from './mock-api';

const routerConfig: ExtraOptions = {
    preloadingStrategy: CustomPreloadingStrategy,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    declarations: [AppComponent],
    providers: [
        DatePipe,

        {
            provide: SwRegistrationOptions,
            useFactory: () => ({ enabled: environment.production })
        },
        // {
        //     provide: RouteReuseStrategy,
        //     useClass: CustomReuseRouteStrategy
        // },
        appInitializerProviders
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices, { delay: 1 }),
        // Centvis
        CentvisModule,
        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule
    ],

    bootstrap: [AppComponent]
})
export class AppModule {}
