<div
    class="absolute left-0 right-0 top-0 p-4 text-center w-full z-99999 transition-all duration-200"
    @slideInTop
    @slideOutTop
    *ngIf="showIdleMessage"
    [ngClass]="{
        'bg-orange-300 text-orange-800': !countdown,
        'bg-red-600 text-red-100': countdown
    }">
    <div class="mx-auto">
        <span class="font-semibold text-base mr-1">You Have Been Idle!</span>
        <span class="text-base">You will get signed out in:</span>
        <span class="font-bold mr-4"
            >{{ countdown  | i18nPlural : countdownMapping }}
        </span>
    </div>
</div>
