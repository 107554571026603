import { OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { noop } from '@cvs/utils';

import { AuthConfigService } from './auth-config.service';
import { AuthInterceptor } from './auth.interceptor';
import { CvsPermissionsGuard } from './guards/permissions-guard';

@NgModule({
    declarations: [],
    exports: [],
    imports: [CommonModule, HttpClientModule, OAuthModule.forRoot()],
    providers: []
})
export class Auth2Module {
    static forRoot(
        authModuleConfig: OAuthModuleConfig,
        storageFactory: () => OAuthStorage
    ): ModuleWithProviders<Auth2Module> {
        return {
            ngModule: Auth2Module,
            providers: [
                AuthConfigService,
                CvsPermissionsGuard,
                { provide: OAuthModuleConfig, useValue: authModuleConfig },
                { provide: OAuthStorage, useFactory: storageFactory },
                {
                    provide: APP_INITIALIZER,
                    multi: true,
                    deps: [AuthConfigService],
                    useFactory: noop
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: AuthInterceptor,
                    multi: true
                }
            ]
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: Auth2Module) {
        if (parentModule) {
            throw new Error('Auth2Module is already loaded. Import it in the AppModule only');
        }
    }
}
