import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { StorageService } from '@cvs/services';
import { exists } from '@cvs/utils';

import { StorageNameType } from '../favorites';
import { OrganizationModel } from '../organization/organization.types';
import { LoggedUser, UserPreferences } from './logged-user.type';

@Injectable({
    providedIn: 'root'
})
export class LoggedUserService {
    // #region Properties (4)
    private readonly USER_INFO_STORAGE_KEY = 'info';
    private readonly ORGANIZATION_INFO_STORAGE_KEY = 'organization';
    private readonly CLIENT_ID_STORAGE_KEY = `${this.USER_INFO_STORAGE_KEY}-client-id`;
    private readonly _storagePrefix = 'logged-user-';
    private readonly storageKeys: string[] = [];

    // #endregion Properties (4)

    // #region Constructors (1)

    constructor(private storageService: StorageService) { }

    // #endregion Constructors (1)

    // #region Public Accessors (4)

    public get clientId(): string {
        const storageValue = this.getFromStorage(this.CLIENT_ID_STORAGE_KEY);
        return storageValue;
    }

    public set clientId(value: string) {
        this.setStorage(this.CLIENT_ID_STORAGE_KEY, value);
    }

    public get organizationId(): number {
        return this.getUser()?.organizationId;
    }

    public get tenantId(): string {
        return this.getUser()?.tenantId;
    }

    // #endregion Public Accessors (4)

    // #region Public Methods (4)

    public clear() {
        this.storageKeys.forEach((k) => this.storageService.removeItem(k));
    }

    public getUser(): LoggedUser | null {
        const storageValue = JSON.parse(this.getFromStorage(this.USER_INFO_STORAGE_KEY));
        if (!storageValue) {
            return null;
        }
        if (storageValue?.user) {
            return storageValue.user as LoggedUser;
        }
        return storageValue as LoggedUser;
    }
    public getPermissions$(): Observable<string[]> {
        const currentUser = this.getUser();
        const userPermissions = currentUser?.permissions || [];
        return of(userPermissions.map((p) => p.name.toUpperCase()));
    }
    public hasPermission(permission: string): boolean {
        const currentUser = this.getUser();
        const userPermissions = currentUser?.permissions || [];
        return userPermissions.some((p) => p.name.toLowerCase() === permission.toLowerCase());
    }

    public setUser(user?: LoggedUser): void {
        this.clear();
        if (user) {
            this.setStorage(this.USER_INFO_STORAGE_KEY, JSON.stringify(user));
            this.setUserPreferences(user?.preferences);
        }
    }

    public setOrganizationDtls(organization: OrganizationModel) {
        this.setStorage(this.ORGANIZATION_INFO_STORAGE_KEY, JSON.stringify(organization));
    }

    public getOrganizationDtls() {
        return JSON.parse(this.getFromStorage(this.ORGANIZATION_INFO_STORAGE_KEY));
    }

    // #endregion Public Methods (4)

    // #region Private Methods (4)

    private getFromStorage(key: string) {
        return this.storageService.getItem(this._storagePrefix + key);
    }

    private setFavorites(key: StorageNameType, data: string) {
        this.storageKeys.push(key);
        return this.storageService.setItem(key, data);
    }

    private setStorage(key: string, data: string) {
        this.storageKeys.push(this._storagePrefix + key);
        return this.storageService.setItem(this._storagePrefix + key, data);
    }

    private setUserPreferences(preferences?: UserPreferences): void {
        if (preferences) {
            if (exists<string>(preferences.favoritesIncidents)) {
                this.setFavorites('favoritesIncidents', preferences.favoritesIncidents);
            }
        }
    }

    // #endregion Private Methods (4)
}
