import { AfterViewInit, Directive, ElementRef, NgModule } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[formControl], [formControlName]'
})
export class ControlNameIdDirective implements AfterViewInit {
    get control() {
        return this.controlDir.control;
    }

    constructor(
        private controlDir: NgControl,
        private host: ElementRef<HTMLFormElement>
    ) {}
    ngAfterViewInit(): void {
        const nativeEle = this.host.nativeElement;
        const name = this.controlDir.name;
        if (nativeEle) {
            nativeEle.setAttribute('name', name + '');
            nativeEle.setAttribute('id', name + '');
            if (nativeEle.classList.contains('mat-mdc-checkbox')) {
                const nEle = nativeEle.querySelector('input[type=checkbox]');
                nEle?.setAttribute('id', name + '-input');
                nEle?.setAttribute('name', name + '-input');
                nativeEle.querySelector('label')?.setAttribute('for', name + '-input');
            } else {
                nativeEle.parentElement.querySelector('label')?.setAttribute('for', name + '');
            }
        }
    }
}

@NgModule({
    declarations: [ControlNameIdDirective],
    exports: [ControlNameIdDirective]
})
export class CvsControlNameIdDirectiveModule {}
