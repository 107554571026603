<div>Copyright &copy; {{ currentYear }} Centvis Solutions (India) Pvt. Ltd.</div>
		<div class="flex flex-row items-center justify-end gap-4">
			<a target="_blank" href="/privacy-policy">Privacy Policy</a>
			<a target="_blank" href="/terms">Terms</a>
			<a target="_blank" href="/code-of-conduct">Code of Conduct</a>
			<a href="https://www.centvis.com" target="_blank" [ngClass]="{ hidden: !showLogo }"
				><img
					[src]="'assets/logos/logo-on-light.svg' | assetsSource"
					alt="centvis logo"
					class="h-4 w-auto"
			/></a>
		</div>