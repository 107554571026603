import { firstValueFrom } from 'rxjs';
import { TranslocoHttpLoader } from 'src/app/core/transloco/transloco.http-loader';

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { environment } from '@environment';
import {
    TRANSLOCO_CONFIG,
    TRANSLOCO_LOADER,
    TRANSLOCO_MISSING_HANDLER,
    Translation,
    TranslocoModule,
    TranslocoService,
    translocoConfig
} from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { TRANSLOCO_PERSIST_LANG_STORAGE, TranslocoPersistLangModule } from '@ngneat/transloco-persist-lang';

import { APP_LANGUAGES } from './transloco.types';

class CvsDefaultHandler {
    handle(key, config) {
        if (config.missingHandler.logMissingKey && !config.prodMode) {
            const msg = `"${key}":"${splitPascalCase(key)}",`;
            // eslint-disable-next-line no-console
            console.info(`%c ${msg}`, 'font-size: 12px; color: red');
        }
        return key;
    }
}
function splitPascalCase(word) {
    const wordRe = /($[a-z])|[A-Z][^A-Z]+/g;
    return word.match(wordRe)?.join(' ') ?? word ?? '__null__';
}
const LANGUAGE_STORAGE_KEY = 'cvs-idems-translate-lang';
@NgModule({
    imports: [
        TranslocoLocaleModule,
        TranslocoPersistLangModule.forRoot({
            storage: {
                provide: TRANSLOCO_PERSIST_LANG_STORAGE,
                useValue: localStorage
            },
            storageKey: LANGUAGE_STORAGE_KEY
        })
    ],
    exports: [TranslocoModule],
    providers: [
        {
            provide: TRANSLOCO_MISSING_HANDLER,
            useClass: CvsDefaultHandler
        },
        {
            // Provide the default Transloco configuration
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: APP_LANGUAGES.map((x) => {
                    return { id: x.id, label: x.nativeName };
                }),

                defaultLang: 'en',
                fallbackLang: 'en',
                reRenderOnLangChange: true,
                prodMode: environment.production,
                missingHandler: { logMissingKey: true, useFallbackTranslation: true, allowEmpty: true }
            })
        },
        {
            // Provide the default Transloco loader
            provide: TRANSLOCO_LOADER,
            useClass: TranslocoHttpLoader
        },
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide: APP_INITIALIZER,
            deps: [TranslocoService],
            useFactory:
                (translocoService: TranslocoService): any =>
                (): Promise<Translation> => {
                    let defaultLang = APP_LANGUAGES.find((x) => x.default).id.toString();
                    if (window && window.navigator) {
                        const wn = window.navigator as any;
                        let lang = wn.languages ? wn.languages[0] : defaultLang;
                        lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
                        if (lang) {
                            const defaultLanguage = APP_LANGUAGES.find(
                                (l) =>
                                    l.cultures.findIndex((c) => c.toLowerCase() == lang.toLowerCase()) >= 0 ||
                                    l.id.toLowerCase() == lang.toLowerCase()
                            );
                            if (defaultLanguage) {
                                defaultLang = defaultLanguage.id.toString();
                            }
                        }
                    }
                    defaultLang = localStorage.getItem(LANGUAGE_STORAGE_KEY) || defaultLang;
                    translocoService.setActiveLang(defaultLang);
                    return firstValueFrom(translocoService.load(defaultLang));
                },
            multi: true
        }
    ]
})
export class TranslocoCoreModule {}
