import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})
export class CvsTruncatePipe implements PipeTransform {
    transform(value: string, limit: number = 20): string {
        if (value) {
            if (value.length < limit) {
                return value;
            } else {
                return value.slice(0, limit) + '...';
            }
        } else {
            return value;
        }
    }
}

@Pipe({
    name: 'truncateFileName'
})
export class CvsTruncateFileNamePipe implements PipeTransform {
    transform(name: string, limit: number = 20): string {
        if (limit && name.length > limit) {
            const lengthOfPart = Math.round(limit / 2) - 1;
            return `${name.substring(0, lengthOfPart)}…${name.substring(name.length - lengthOfPart)}`;
        }

        return name;
    }
}

@NgModule({
    declarations: [CvsTruncatePipe, CvsTruncateFileNamePipe],
    exports: [CvsTruncatePipe, CvsTruncateFileNamePipe]
})
export class CvsTruncatePipeModule {}
