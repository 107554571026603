import { OAuthStorage } from 'angular-oauth2-oidc';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';

import { CommonModule, DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
    APP_INITIALIZER, ChangeDetectionStrategy, Component, ErrorHandler, Injector, NgModule, Optional,
    SkipSelf
} from '@angular/core';
import { LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import {
    DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_RIPPLE_GLOBAL_OPTIONS
} from '@angular/material/core';
import { MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { environment } from '@environment';
import { NgIdleModule } from '@ng-idle/core';
import {
    DefaultControlErrorComponent, errorTailorImports, provideErrorTailorConfig
} from '@ngneat/error-tailor';
import { HashMap, TranslocoService } from '@ngneat/transloco';

import { Auth2Module, authModuleConfig, AuthService, IAuthService } from './auth';
import { CvsConfirmationModule } from './components/confirmation';
import { CvsDialogsModule } from './components/dialogs';
import { GlobalErrorHandlingService, HttpErrorInterceptor } from './error';
import { ErrorAnnotationTypes } from './error/errors.annotation.types';
import { TenantInterceptor, UndefinedToNullInterceptor } from './interceptors';
import { CookieService, EnvironmentService, RoutePartsService, TenantService } from './services';
import { Environment } from './types';

const getInitialData = (injector: Injector) => (): void => {
    const environmentService = injector.get(EnvironmentService);
    environmentService.setState(environment as Environment);
};
const authStorageFactory = (): OAuthStorage => {
    return localStorage;
};

@Component({
    selector: 'app-custom-control-error',
    template: `{{ errorText }}`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            .hide-control {
                display: none !important;
            }

            :host {
                display: contents;
            }
        `
    ]
})
export class CustomControlErrorComponent extends DefaultControlErrorComponent {}

function controlErrorComponentAnchorFn(hostElem: Element, errorElem: Element) {
    try {
        const errorEle = hostElem.parentElement?.querySelectorAll('app-custom-control-error');
        if (errorEle) {
            errorEle.forEach((ele) => {
                if (ele.parentElement == hostElem.parentElement) {
                    ele.remove();
                }
            });
        }
    } catch (error) {
        // empty space
    }
    return () => {
        try {
            hostElem.removeChild(errorElem);
        } catch (error) {
            // empty space
        }
    };
}
const DATE_FORMATS = {
    parse: {
        dateInput: 'dd-MMM-yyyy'
    },
    display: {
        dateInput: 'dd-MMM-yyyy',
        monthYearLabel: 'MMM yyyy',
        dateA11yLabel: 'dd-MMMM-yyyy',
        monthYearA11yLabel: 'MMMM yyyy'
    }
};
@NgModule({
    declarations: [CustomControlErrorComponent],
    imports: [
        CommonModule,
        CvsConfirmationModule,
        CvsDialogsModule,
        Auth2Module.forRoot(authModuleConfig, authStorageFactory),
        errorTailorImports,
        NgIdleModule.forRoot(),
        NgxPermissionsModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 1234,
            positionClass: 'toast-bottom-center',
            preventDuplicates: true,
            progressAnimation: 'decreasing',
            progressBar: true
        })
    ],
    providers: [
        RoutePartsService,
        { provide: ErrorHandler, useClass: GlobalErrorHandlingService },
		CookieService,
        TenantService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TenantInterceptor,
            multi: true // this ensures multiple interceptors can be chained
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UndefinedToNullInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
            useValue: {
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 25, 50],
                showFirstLastButtons: true
            }
        },
        {
            provide: MAT_DATE_LOCALE,
            deps: [TranslocoService],
            useFactory: (languageService: TranslocoService) => languageService.getActiveLang()
        },
        { provide: DateAdapter, useClass: LuxonDateAdapter, deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS] },
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
        {
            provide: DATE_PIPE_DEFAULT_OPTIONS,
            useValue: { dateFormat: 'dd-MMM-yy h:mm:ss a z' }
        },
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [Injector],
            useFactory: getInitialData
        },
        {
            provide: IAuthService,
            useClass: AuthService
        },
        provideErrorTailorConfig({
            errors: {
                useFactory(t: TranslocoService) {
                    const key = (error: ErrorAnnotationTypes, params?: HashMap) =>
                        t.translate(`ErrorMessages.${error}`, { ...params });

                    return {
                        [ErrorAnnotationTypes.alreadyInUse]: () => key(ErrorAnnotationTypes.alreadyInUse),
                        [ErrorAnnotationTypes.required]: () => key(ErrorAnnotationTypes.required),
                        [ErrorAnnotationTypes.email]: () => key(ErrorAnnotationTypes.email),
                        [ErrorAnnotationTypes.digit]: () => key(ErrorAnnotationTypes.digit),
                        [ErrorAnnotationTypes.numeric]: () => key(ErrorAnnotationTypes.numeric),
                        [ErrorAnnotationTypes.minLength]: ({ requiredLength }) =>
                            key(ErrorAnnotationTypes.minLength, { requiredLength }),
                        [ErrorAnnotationTypes.maxLength]: ({ requiredLength }) =>
                            key(ErrorAnnotationTypes.maxLength, { requiredLength }),
                        [ErrorAnnotationTypes.minNumber]: ({ min }) => key(ErrorAnnotationTypes.minNumber, { min }),
                        [ErrorAnnotationTypes.maxNumber]: ({ max }) => key(ErrorAnnotationTypes.maxNumber, { max }),
                        [ErrorAnnotationTypes.minDate]: ({ min }) => key(ErrorAnnotationTypes.minDate, { min }),
                        [ErrorAnnotationTypes.maxDate]: ({ max }) => key(ErrorAnnotationTypes.maxDate, { max }),
                        [ErrorAnnotationTypes.pattern]: ({ pattern }) => key(ErrorAnnotationTypes.pattern, { pattern }),
                        [ErrorAnnotationTypes.url]: () => key(ErrorAnnotationTypes.url),
                        [ErrorAnnotationTypes.allOf]: () => key(ErrorAnnotationTypes.allOf),
                        [ErrorAnnotationTypes.oneOf]: () => key(ErrorAnnotationTypes.oneOf),
                        [ErrorAnnotationTypes.noneOf]: () => key(ErrorAnnotationTypes.noneOf),
                        [ErrorAnnotationTypes.alpha]: () => key(ErrorAnnotationTypes.alpha),
                        [ErrorAnnotationTypes.alphaNumeric]: () => key(ErrorAnnotationTypes.alphaNumeric),
                        [ErrorAnnotationTypes.lowerCase]: () => key(ErrorAnnotationTypes.lowerCase),
                        [ErrorAnnotationTypes.upperCase]: () => key(ErrorAnnotationTypes.upperCase),
                        [ErrorAnnotationTypes.creditCard]: () => key(ErrorAnnotationTypes.creditCard),
                        [ErrorAnnotationTypes.json]: () => key(ErrorAnnotationTypes.json),
                        [ErrorAnnotationTypes.mac]: () => key(ErrorAnnotationTypes.mac),
                        [ErrorAnnotationTypes.compare]: ({ control }) => key(ErrorAnnotationTypes.compare, { control }),
                        // fill for rest of the ErrorAnnotationTypes
                        [ErrorAnnotationTypes.contains]: () => key(ErrorAnnotationTypes.contains),
                        [ErrorAnnotationTypes.range]: () => key(ErrorAnnotationTypes.range),
                        [ErrorAnnotationTypes.custom]: () => key(ErrorAnnotationTypes.custom),
                        [ErrorAnnotationTypes.time]: () => key(ErrorAnnotationTypes.time),
                        [ErrorAnnotationTypes.greaterThan]: () => key(ErrorAnnotationTypes.greaterThan)
                    };
                },
                deps: [TranslocoService]
            },
            controlErrorComponent: CustomControlErrorComponent, // Uncomment to see errors being rendered using a custom component
            controlErrorComponentAnchorFn: controlErrorComponentAnchorFn, // Uncomment to see errors being positioned differently
            controlErrorsOn: {
                async: true,
                blur: true,
                change: false,
                status: true
            }
        })
    ]
})
export class CentvisModule {
    /**
     * Constructor
     */
    constructor(@Optional() @SkipSelf() parentModule?: CentvisModule) {
        if (parentModule) {
            throw new Error('CentvisModule has already been loaded. Import this module in the AppModule only!');
        }
    }
}
