import { NgxMatErrorsModule } from 'ngx-mat-errors';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    SpeechRecognitionModule
} from '@cvs/components/speech-recognition/speech-recognition.module';
import { CvsAssetsSourcePipeModule } from '@cvs/pipes';
import { errorTailorImports } from '@ngneat/error-tailor';
import { TranslocoModule } from '@ngneat/transloco';

const modules = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    NgxMatErrorsModule,
    errorTailorImports,
    SpeechRecognitionModule,
    CvsAssetsSourcePipeModule
];
@NgModule({
    imports: [modules],
    exports: [modules]
})
export class SharedModule {}
