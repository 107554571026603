import { Component } from '@angular/core';

// import { LoggedUserService } from './core/user/logged-user.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    // constructor(private promptUpdateService: PromptUpdateService) {
    //     this.promptUpdateService.check();
    // }
}
