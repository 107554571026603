import { debounceTime, Subject, takeUntil } from 'rxjs';
import { QuickChatService } from 'src/app/layout/common/quick-chat/quick-chat.service';
import { Chat, Message, SendMessageRequest } from 'src/app/layout/common/quick-chat/quick-chat.types';

import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Inject,
    NgZone,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthService } from '@cvs/auth';
import { LogService } from '@cvs/services';

@Component({
    selector: 'quick-chat',
    templateUrl: './quick-chat.component.html',
    styleUrls: ['./quick-chat.component.scss'],
    encapsulation: ViewEncapsulation.None,
    exportAs: 'quickChat'
})
export class QuickChatComponent implements OnInit, AfterViewInit, OnDestroy {
    private _mutationObserver: MutationObserver;
    private _overlay: HTMLElement;
    private _scrollStrategy: ScrollStrategy = this._scrollStrategyOptions.block();
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    @ViewChild('messageInput') public messageInput: ElementRef;

    public chat: Chat;
    public chats: Chat[];
    public inputmessage: FormControl = new FormControl();
    public isTyping: boolean = false;
    public messageText: string;
    public opened = false;
    public selectedChat: Chat;

    /**
     * Constructor
     */
    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private _elementRef: ElementRef,
        private _renderer2: Renderer2,
        private _ngZone: NgZone,
        private _quickChatService: QuickChatService,
        private _scrollStrategyOptions: ScrollStrategyOptions,
        private authService: AuthService,
        private logService: LogService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Decorated methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Host binding for component classes
     */
    @HostBinding('class') public get classList(): any {
        return {
            'quick-chat-opened': this.opened
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    public ngOnInit(): void {
        // Chat
        this._quickChatService.chat$.pipe(takeUntil(this._unsubscribeAll)).subscribe((chat: Chat) => {
            this.selectedChat = chat;
            this.chat = chat;
            if (chat?.messages) {
                chat?.messages?.forEach((x) => {
                    if (!x.readBy?.includes(this.authService.loggedUser.getUser().externalId)) {
                        this.sendMessageReadReceipt(x);
                    }
                });
            }
        });

        // Chats
        this._quickChatService.chats$.pipe(takeUntil(this._unsubscribeAll)).subscribe((chats: Chat[]) => {
            this.chats = chats;
        });

        // Receive message
        this.receiveMessage();

        this._quickChatService.userTyping$.subscribe((x) => {
            if (x?.conversationId === this.chat?.id) {
                this.isTyping = true;
                this.updateUserTyping();
            }
        });

        this._quickChatService.userTyping$.subscribe((x) => {
            if (x?.conversationId === this.chat?.id) {
                this.isTyping = true;
                this.updateUserTyping();
            }
        });

        this.sendUserTyping();
        this.updateMessage();

        // Selected chat
        this._quickChatService.chat$.pipe(takeUntil(this._unsubscribeAll)).subscribe((chat: Chat) => {
            this.selectedChat = chat;
        });
    }

    /**
     * After view init
     */
    public ngAfterViewInit(): void {
        // Fix for Firefox.
        //
        // Because 'position: sticky' doesn't work correctly inside a 'position: fixed' parent,
        // adding the '.cdk-global-scrollblock' to the html element breaks the navigation's position.
        // This fixes the problem by reading the 'top' value from the html element and adding it as a
        // 'marginTop' to the navigation itself.
        this._mutationObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                const mutationTarget = mutation.target as HTMLElement;
                if (mutation.attributeName === 'class') {
                    if (mutationTarget.classList.contains('cdk-global-scrollblock')) {
                        const top = parseInt(mutationTarget.style.top, 10);
                        this._renderer2.setStyle(this._elementRef.nativeElement, 'margin-top', `${Math.abs(top)}px`);
                    } else {
                        this._renderer2.setStyle(this._elementRef.nativeElement, 'margin-top', null);
                    }
                }
            });
        });
        this._mutationObserver.observe(this._document.documentElement, {
            attributes: true,
            attributeFilter: ['class']
        });
    }

    /**
     * On destroy
     */
    public ngOnDestroy(): void {
        // Disconnect the mutation observer
        this._mutationObserver.disconnect();

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    /**
     * Close the panel
     */
    public close(): void {
        // Return if the panel has already closed
        if (!this.opened) {
            return;
        }

        // Close the panel
        this._toggleOpened(false);
    }

    public getSenderName(message: Message) {
        return this.chat.participants.find((x) => x.externalId === message.senderId).fullName.charAt(0);
    }

    public messageDelivered(message: Message): boolean {
        return message.deliveredTo.length === this.chat.participants.length - 1;
    }

    public messageRead(message: Message): boolean {
        return message.readBy.length === this.chat.participants.length - 1;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the panel
     */
    public open(): void {
        // Return if the panel has already opened
        if (this.opened) {
            return;
        }

        // Open the panel
        this._toggleOpened(true);
    }

    public receiveMessage() {
        this._quickChatService.message$.pipe(takeUntil(this._unsubscribeAll)).subscribe((message: Message) => {
            if (message) {
                const index = this.chats.findIndex((x) => x.id === message.conversationId);
                if (index > -1) {
                    if (this.chat.id === this.chats[index].id) {
                        this.chat.messages.push(message);
                    } else {
                        this.chats[index].unreadCount++;
                        this.chats[index].messages.push(message);
                    }

                    if (this.chats[index].id === this.chat.id) {
                        this.sendMessageReadReceipt(message);
                    }

                    if (this.chats[index].id !== this.chat.id) {
                        this.sendMessageDelivery(message);
                    }
                }
            }
        });
    }

    /**
     * Select the chat
     *
     * @param id
     */
    public selectChat(id: string): void {
        // Open the panel
        this._toggleOpened(true);

        // Get the chat data
        this._quickChatService.getChatById(id).subscribe();
    }

    public sendMessage() {
        if (this.inputmessage.value) {
            const messageRequest: SendMessageRequest = {
                conversationId: this.chat.id,
                senderId: this.authService.loggedUser.getUser().externalId,
                text: this.inputmessage.value,
                toUserIds: this.chat.participants.map((x) => x.externalId)
            };

            this._quickChatService.sendMessage(messageRequest);
            this.inputmessage.setValue('');
        }
    }

    public sendMessageDelivery(message: Message) {
        if (message.senderId === this.authService.loggedUser.getUser().externalId) {
            return;
        }

        const conversation = this.chats.find((x) => x.id == message.conversationId);
        const participantIds = conversation.participants.map((x) => x.externalId);
        this._quickChatService.sendMessageDelivery({
            messageId: message.id,
            deliveredTo: this.authService.loggedUser.getUser().externalId,
            conversationId: message.conversationId,
            toUserIds: participantIds
        });
    }

    public sendMessageReadReceipt(message: Message) {
        if (message.senderId === this.authService.loggedUser.getUser().externalId) {
            return;
        }

        this._quickChatService.sendMessageReadReceipt({
            messageId: message.id,
            deliveredTo: this.authService.loggedUser.getUser().externalId,
            conversationId: message.conversationId,
            toUserIds: this.chat?.participants?.map((x) => x.externalId)
        });
    }

    public sendUserTyping() {
        this.inputmessage.valueChanges.pipe(debounceTime(100)).subscribe((x) => {
            if (x) {
                const name = this.authService?.loggedUser.getUser().name;
                this._quickChatService.sendUserTyping({
                    conversationId: this.chat?.id,
                    userName: name,
                    toUserIds: this.chat?.participants
                        ?.map((x) => x.externalId)
                        .filter((y) => y !== this.authService?.loggedUser.getUser().externalId)
                });
            }
        });
    }

    /**
     * Toggle the panel
     */
    public toggle(): void {
        if (this.opened) {
            this.close();
        } else {
            this.open();
        }
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    public trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    public updateMessage() {
        this._quickChatService.messageUpdate$.subscribe((x) => {
            if (!x) {
                return;
            }

            const index = this.chat.messages.findIndex((y) => y.id === x.id);
            if (index > -1) {
                this.chat.messages[index] = x;
            }
        });
    }

    public updateUserTyping() {
        setTimeout(() => {
            this.isTyping = false;
        }, 1000);
    }

    /**
     * Resize on 'input' and 'ngModelChange' events
     *
     * @private
     */
    @HostListener('input')
    @HostListener('ngModelChange')
    private _resizeMessageInput(): void {
        // This doesn't need to trigger Angular's change detection by itself
        this._ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                // Set the height to 'auto' so we can correctly read the scrollHeight
                this.messageInput.nativeElement.style.height = 'auto';

                // Get the scrollHeight and subtract the vertical padding
                this.messageInput.nativeElement.style.height = `${this.messageInput.nativeElement.scrollHeight}px`;
            });
        });
    }

    /**
     * Hide the backdrop
     *
     * @private
     */
    private _hideOverlay(): void {
        if (!this._overlay) {
            return;
        }

        // If the backdrop still exists...
        if (this._overlay) {
            // Remove the backdrop
            this._overlay.parentNode.removeChild(this._overlay);
            this._overlay = null;
        }

        // Disable block scroll strategy
        this._scrollStrategy.disable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show the backdrop
     *
     * @private
     */
    private _showOverlay(): void {
        // Try hiding the overlay in case there is one already opened
        this._hideOverlay();

        // Create the backdrop element
        this._overlay = this._renderer2.createElement('div');

        // Return if overlay couldn't be create for some reason
        if (!this._overlay) {
            return;
        }

        // Add a class to the backdrop element
        this._overlay.classList.add('quick-chat-overlay');

        // Append the backdrop to the parent of the panel
        this._renderer2.appendChild(this._elementRef.nativeElement.parentElement, this._overlay);

        // Enable block scroll strategy
        this._scrollStrategy.enable();

        // Add an event listener to the overlay
        this._overlay.addEventListener('click', () => {
            this.close();
        });
    }

    /**
     * Open/close the panel
     *
     * @param open
     * @private
     */
    private _toggleOpened(open: boolean): void {
        // Set the opened
        this.opened = open;

        // If the panel opens, show the overlay
        if (open) {
            this._showOverlay();
        }
        // Otherwise, hide the overlay
        else {
            this._hideOverlay();
        }
    }
}
