import { Subject, takeUntil, timer } from 'rxjs';

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { LoggedUserService } from '@common/services/logged-user';
import { PagedResponseModel } from '@cvs/types';

import { MessagesService } from './messages.service';
import { MessageUser } from './messages.types';

@Component({
    selector: 'messages',
    templateUrl: './messages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'messages'
})
export class MessagesComponent implements OnInit, OnDestroy {
    @ViewChild('messagesOrigin') private _messagesOrigin: MatButton;
    @ViewChild('messagesPanel') private _messagesPanel: TemplateRef<any>;

    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    public lasUnreadCount = 0;
    public messages: MessageUser[];
    public readStatusCount = 0;
    public showMarkAllAsUnReadBtn = false;
    public state: boolean = false;
    public totalCount = 0;
    public unReadStatusCount = 0;
    public unreadCount = 0;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _messagesService: MessagesService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _loggedUserService: LoggedUserService,
        private _router: Router
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    public ngOnInit(): void {
        // Subscribe to message changes
        timer(0, 20000)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.state = false;
                this._changeDetectorRef.markForCheck();
                setTimeout(() => {
                    this.state = this.lasUnreadCount != this.unreadCount;
                    this.lasUnreadCount = this.unreadCount;
                    this._messagesService.getAll().subscribe();
                    // Mark for check
                    this._changeDetectorRef.markForCheck();
                }, 500);
            });
        // Subscribe to message changes
        this._messagesService.messages$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((messages: PagedResponseModel<MessageUser>) => {
                // Load the messages
                this.messages = messages.items;
                messages?.extras['readStatusCounts'].forEach((element) => {
                    if (element.readStatus === 'unread') {
                        this.unReadStatusCount = element.count;
                    }
                    if (element.readStatus === 'read') {
                        this.readStatusCount = element.count;
                    }
                });
                // Calculate the unread count
                this._calculateUnreadCount();
                this.totalCount = messages['totalItemCount'];
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    public ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    /**
     * Close the messages panel
     */
    public closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Delete the given message
     */
    public delete(message: MessageUser): void {
        // Delete the message
        this._messagesService.delete(message.id).subscribe(() => {
            this._messagesService.getAll().subscribe();
            // Mark for check
            this._changeDetectorRef.markForCheck();
        });
    }

    /**
     * Mark all messages as read
     */
    public markAllAsRead(isRead: boolean): void {
        // Mark all as read
        //this._messagesService.markAllAsRead().subscribe();
        const messageId = 0;
        let messageItem = null;
        if (this.messages?.length > 0) {
            //notificationId = this.notifications[0].id;
            messageItem = this.messages[0];
            messageItem.isRead = isRead;
            messageItem.notificationType = 'Message';
        }
        // Mark all as read
        this._messagesService.updateAllUserMessagesReadStatus(messageId, messageItem).subscribe(() => {
            this._messagesService.getAll().subscribe();
            // Mark for check
            this._changeDetectorRef.markForCheck();
        });
    }

    public navigateToNotifications(): void {
        //this._router.navigate("")
        this._router.navigate(['/messages'], { state: { notificationType: 'Message' } });
        this.closePanel();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the messages panel
     */
    public openPanel(): void {
        // Return if the messages panel or its origin is not defined
        if (!this._messagesPanel || !this._messagesOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._messagesPanel, this._viewContainerRef));
    }

    /**
     * Toggle read status of the given message
     */
    // toggleRead(message: MessageUser): void {
    //     // Toggle the read status
    //     message.isRead = !message.isRead;

    //     // Update the message
    //     this._messagesService.update(message.id, message).subscribe();
    // }
    public toggleRead(notificationUser: MessageUser): void {
        const user = this._loggedUserService.getUser();
        // Toggle the read status
        notificationUser.isRead = !notificationUser.isRead;
        notificationUser.userId = user.id;
        // Update the notificationUser
        this._messagesService.update(notificationUser.id, notificationUser).subscribe();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    public trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void {
        let count = 0;
        if (this.messages && this.messages.length) {
            count = this.messages.filter((message) => !message.isRead).length;
        }
        this.unreadCount = count;
        this.showMarkAllAsUnReadBtn = false;
        if (this.unreadCount === 0 && this.messages?.length > 0) {
            this.showMarkAllAsUnReadBtn = true;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(this._messagesOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }
}
