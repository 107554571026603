import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
    AfterContentInit,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    TemplateRef
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { FuseNgTemplateDirective } from '../api/fuse-template';

/* eslint-disable @angular-eslint/no-output-on-prefix */

@UntilDestroy()
@Component({
    selector: 'fuse-auto-complete',
    templateUrl: './auto-complete.component.html',
    styleUrls: ['./auto-complete.component.scss'],
    exportAs: 'fuseAutoComplete'
})
export class AutoCompleteComponent implements AfterContentInit, OnInit, OnDestroy {
    @Input() public items: any;
    @Input() public searchKey: string = 'key';
    @Input() public minLength: number;
    @Input() public asyncQuery: boolean;
    @Input() public spinnerColor: string;
    @Input() public spinnerSize: number;
    @Input() public showSpinner: boolean;
    @Input() public forceSelection: boolean;
    @Input()
    public inputStyle: any;
    @Input()
    public cssClassName: string;
    @Input()
    public placeholder: string;
    @Input()
    public label: string;
    @Input()
    public group: FormGroup;
    @Input()
    public controlName: string;
    @Input()
    public attributes: any;

    @Output()
    public onBlur: EventEmitter<any> = new EventEmitter();
    @Output()
    public onChange: EventEmitter<any> = new EventEmitter();
    @Output()
    public onFocus: EventEmitter<any> = new EventEmitter();
    @Output() public onSelectItem: EventEmitter<void> = new EventEmitter();
    @Output() public onSearch: EventEmitter<string> = new EventEmitter();
    @Output() public onReset: EventEmitter<string> = new EventEmitter();
    @Input()
    public debounceTime: number = 300;
    @ContentChildren(FuseNgTemplateDirective) templates: QueryList<any>;

    public control: FormControl;
    public itemTemplate: TemplateRef<any>;
    public filteredItems: any;
    private selectedItem: any | boolean;
    constructor() {
        this.debounceTime = 300;
        this.minLength = 2;
        this.spinnerColor = 'primary';
        this.spinnerSize = 20;
        this.showSpinner = false;
        this.selectedItem = false;
        this.forceSelection = true;
    }

    public get data(): Observable<any[]> {
        return this.items instanceof Observable ? this.items : of(this.items);
    }

    public ngOnInit(): void {
        this.filteredItems = this.data;
        if (this.group) {
            this.control = this.group.get(this.controlName) as FormControl;
        }
    }

    public resetControl(): void {
        if (this.control.value === null || !this.control.value.toString().length) {
            this.showSpinner = false;
            this.selectedItem = false;
            this.onReset.emit();
            this.filteredItems = this.data;
        }
    }

    public handleSelectItem(event: MatAutocompleteSelectedEvent): void {
        this.showSpinner = false;
        const item: any = event.option.value;
        this.control.setValue(item[this.searchKey], { emitEvent: false });
        this.selectedItem = item;
        this.onSelectItem.emit(item);
    }

    public handleSearch(value: any): void {
        if (this.asyncQuery) {
            this.onSearch.emit(value);
        } else {
            this.filteredItems = this.filterItems(value);
        }
    }
    public handleBlur(_value: any): void {
        if (this.forceSelection && this.selectedItem === false) {
            this.control.setValue(null);
        }
    }
    ngOnDestroy(): void {
        untilDestroyed(this);
        this.selectedItem = false;
    }
    ngAfterContentInit(): void {
        this.templates.forEach((item) => {
            switch (item.getType()) {
                case 'item':
                    this.itemTemplate = item.template;
                    break;
                default:
                    this.itemTemplate = item.template;
                    break;
            }
        });
    }
    private doFilter(data: any[], value: string): Observable<any[]> {
        return of(data.filter((item) => item[this.searchKey].toLowerCase().indexOf(value.toLowerCase()) === 0));
    }

    private filterItems(value: string): Observable<any[]> {
        if (this.items instanceof Observable) {
            return this.items.pipe(switchMap((data) => this.doFilter(data, value)));
        }
        return this.doFilter(this.items, value);
    }
}
