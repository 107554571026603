/* eslint-disable @typescript-eslint/ban-types */
export class ObjectUtils {
    public static isEmpty<T>(obj: { [key: string]: T }): boolean;
    public static isEmpty(obj: Record<string, unknown>): boolean;
    public static isEmpty(obj: any): boolean {
        return Object.keys(obj).length === 0;
    }
    /**
     * Return the values of an object.
     */
    public static values<T>(obj: { [key: string]: T }): T[];
    public static values(obj: any): any;
    public static values(obj: any): any {
        return Object.keys(obj).map((x) => obj[x]);
    }

    /**
     * Return a new object with only the specified keys
     * e.g.
     * slice({a: 1, b:2, c:3}, ['b', 'c']) {b:2, c:3}
     */
    public static slice(obj: { [key: string]: any }, keys: string[]): { [key: string]: any } {
        const out = {};
        for (const key of keys) {
            if (key in obj) {
                out[key] = obj[key];
            }
        }
        return out;
    }

    /**
     * Return a new object without the specified keys
     * e.g.
     * except({a: 1, b:2, c:3}, ['b']) {a: 1, c:3}
     */
    public static except(obj: { [key: string]: any }, keys: string[]): { [key: string]: any } {
        const out = Object.assign({}, obj);
        for (const key of keys) {
            delete out[key];
        }
        return out;
    }

    /**
     * This will remove all the entry in the object which are null or undefined
     * e.g.
     * compact({a: null, b: undefined, c: 1}) => {c: 1}
     */
    public static compact(obj: { [key: string]: any }): { [key: string]: any } {
        const out = {};
        for (const key of Object.keys(obj)) {
            if (obj[key] !== null && obj[key] !== undefined) {
                out[key] = obj[key];
            }
        }
        return out;
    }

    /**
     * Serialize a simple object to a form that is supposed to be always the same.
     */
    public static serialize(obj: { [key: string]: any }) {
        if (!obj) {
            return '';
        }

        return Object.keys(obj)
            .sort()
            .map((x) => `${x}:${obj[x]}`)
            .join(',');
    }

    public static equals(obj1: any, obj2: any, field?: string): boolean {
        if (field) {
            return this.resolveFieldData(obj1, field) === this.resolveFieldData(obj2, field);
        }

        return this.equalsByValue(obj1, obj2);
    }

    public static equalsByValue(obj1: any, obj2: any): boolean {
        if (obj1 === obj2) {
            return true;
        }

        if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
            return false;
        }

        if (Array.isArray(obj1) && Array.isArray(obj2)) {
            if (obj1.length !== obj2.length) {
                return false;
            }

            for (let i = 0; i < obj1.length; i++) {
                if (!this.equalsByValue(obj1[i], obj2[i])) {
                    return false;
                }
            }
            return true;
        }

        if (obj1 instanceof Date && obj2 instanceof Date) {
            return obj1.getTime() === obj2.getTime();
        }

        if (obj1 instanceof RegExp && obj2 instanceof RegExp) {
            return obj1.toString() === obj2.toString();
        }

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            if (!keys2.includes(key) || !this.equalsByValue(obj1[key], obj2[key])) {
                return false;
            }
        }

        return true;
    }

    public static findIndexInList(item: any, list: any): number {
        return list ? list.indexOf(item) : -1;
    }

    public static insertIntoOrderedArray(item: any, index: number, arr: any[], sourceArr: any[]): void {
        const insertIndex = arr.findIndex((arrItem) => this.findIndexInList(arrItem, sourceArr) > index);
        if (insertIndex === -1) {
            arr.push(item);
        } else {
            arr.splice(insertIndex, 0, item);
        }
    }

    public static isFunction(obj: any) {
        return typeof obj === 'function';
    }

    public static removeDiacritics(str: string) {
        if (str) {
            // Normalize the string to decomposed Unicode form (NFD).
            str = str.normalize('NFD');

            // Use a regular expression to remove diacritics and other special characters.
            str = str.replace(/[\u0300-\u036f]/g, '');

            return str;
        }
        return str;
    }

    public static reArrangeArray(value: any[], from: number, to: number) {
        if (Array.isArray(value) && from !== to) {
            const length = value.length;

            // Ensure 'from' and 'to' are within valid bounds.
            from = ((from % length) + length) % length;
            to = ((to % length) + length) % length;

            if (from !== to) {
                // Splice the element from 'from' index and insert it at 'to' index.
                const [movedElement] = value.splice(from, 1);
                value.splice(to, 0, movedElement);
            }
        }
    }

    /**
     * Resolves the value of a given field in a data object.
     * If the field is a function, it is called with the data object as argument.
     * If the field is a string, it is treated as a path to the value in the data object.
     * @param data The data object to resolve the field value from.
     * @param field The field to resolve the value of.
     * @returns The resolved value of the field, or null if either data or field is null or undefined.
     */
    public static resolveFieldData(data: any, field: any): any {
        if (!data || !field) {
            return null;
        }

        if (this.isFunction(field)) {
            // If the field is a function, call it with the data object as an argument.
            return field(data);
        }

        if (typeof field === 'string' && field.indexOf('.') === -1) {
            // If the field is a string without dots, treat it as a property name and return its value.
            return data[field];
        }

        // If the field is a string with dots, treat it as a path to the value and traverse the object.
        const fields: string[] = typeof field === 'string' ? field.split('.') : [];

        return fields.reduce((value, currentField) => {
            if (value == null) {
                return null;
            }
            return value[currentField];
        }, data);
    }
}

/**
 * @returns true if obj is NOT null or undefined
 */
export function exists<T>(obj: T | null | undefined): obj is T {
    return obj !== undefined && obj !== null;
}

/**
 * @returns true if obj is null or undefined
 */
export function nil<T>(obj: T | null | undefined): obj is undefined | null {
    return !exists<T>(obj);
}

export function deepClone<T>(value: T): T {
    return deepMerge({}, value);
}

export function deepMerge<A extends {}, B extends {}>(target: A, source: B): A & B {
    const destination: any = {};
    if (isMergable(target)) {
        Object.keys(target).forEach((key) => {
            destination[key] = deepClone(target[key]);
        });
    }
    if (isMergable(source)) {
        target = target || ({} as any);
        Object.keys(source).forEach((key) => {
            if (isMergable(source[key])) {
                destination[key] = deepMerge(target[key] || {}, source[key]);
            } else {
                destination[key] = source[key];
            }
        });
    } else {
        return source as any;
    }

    return destination;
}

function isMergable(obj: Record<string, unknown>): boolean {
    return obj != null && typeof obj === 'object' && !Array.isArray(obj);
}
