<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>
<!-- Navigation -->
<nav class="fixed z-999 w-full bg-primary border-b border-secondary">
    <div class="flex min-h-16 items-center justify-start">
        <div class="flex-auto flex items-center justify-start">
            <a class="flex ml-3 mr-6" [routerLink]="'/home'"> <img class="h-8 text-primary"
                    [src]="'assets/logos/logo-on-light.svg' | assetsSource">
            </a>
            <div
                class="md:mr-24 border-l-2 border-on-primary-500 pl-6 hidden md:flex flex-col justify-start items-start ">
                <h1 *ngIf="(pageTitle ? (pageTitle | transloco) :
    (currentTitle$ | async) | transloco) as pgTitle" class="text-2xl text-on-primary font-bold whitespace-nowrap">
                    {{ pgTitle }}</h1>
                    <cvs-breadcrumb></cvs-breadcrumb>

            </div>
        </div>
    </div>
    <div class="px-2 mb-0.5 border-t border-secondary flex md:hidden flex-col justify-start items-start ">
        <h1 *ngIf="(pageTitle ? (pageTitle | transloco) :
(currentTitle$ | async) | transloco) as pgTitle" class="text-2xl text-on-primary font-bold whitespace-nowrap">
            {{ pgTitle }}</h1>
            <cvs-breadcrumb></cvs-breadcrumb>

    </div>
</nav>
<!-- Content -->
<div class="flex flex-auto pt-28 md:pt-16 overflow-hidden bg-gray-50 dark:bg-gray-900 relative">
    <div id="main-content" class="flex flex-col justify-between relative w-full h-full overflow-y-auto">
        <main  class="flex flex-auto h-full transition-all">
            <router-outlet class="flex-auto" *ngIf="true"></router-outlet>
        </main>
        <footer class="bg-[#031E3E] z-999 p-2 md:p-4 print:hidden " fontClass="text-white">
        </footer>
    </div>
</div>