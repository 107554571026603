import { catchError, finalize, map, mergeMap, Observable, of, retry, Subject, tap, timer } from 'rxjs';

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpStatusCode } from '@angular/common/http';
import { Injector } from '@angular/core';
import { LoggedUserService } from '@common/services/logged-user';
import { CvsConfirmationService } from '@cvs/components/confirmation';
import {
    HTTP_CLIENT__MAX_RETRIES,
    HTTP_CLIENT__RETRIES_DELAY_IN_MS,
    PAGINATION__MAX_ROWS_PER_PAGE
} from '@cvs/constants';
import { ServerError } from '@cvs/error/server-error';
import {
    createGridify,
    createQueryString,
    createSort,
    FiltersType,
    GridifyConditionEnum,
    SortsType
} from '@cvs/gridify';
import { PagedResponseModel, ResponseModelType } from '@cvs/types';
import { StringFormatUtils } from '@cvs/utils';

import { EnvironmentService } from './environment.service';
import { LogService } from './log.service';
import { NotificationService } from './notification.service';

export type ApiEndpointsType = {
    create?: string;
    patch?: string;
    update?: string;
    get?: string;
    getById?: string;
    delete?: string;
    getAll?: string;
    search?: string;
    [key: string]: string;
};
export type MessageInfo = {
    title?: string;
    message: string;
};
export interface ServiceOptions {
    filters: {
        mode?: 'gridify' | 'queryString';
    };
    notification: {
        uiComponent?: 'snackbar' | 'dialog' | 'toastr';
        validationErrorComponent?: 'snackbar' | 'dialog' | 'toastr';
        onSuccess: { info?: MessageInfo } | false;
        onError: { info?: MessageInfo; handleValidationErrors?: boolean; rethrowError?: boolean } | false;
    };
}

export const RetryableHttpStatusCode = new Set([
    0,
    HttpStatusCode.RequestTimeout,
    HttpStatusCode.InternalServerError,
    HttpStatusCode.BadGateway,
    HttpStatusCode.ServiceUnavailable,
    HttpStatusCode.GatewayTimeout
]);
export abstract class AbstractHttpService {
    protected readonly cvsConfirmationService: CvsConfirmationService;
    protected readonly environmentService: EnvironmentService;
    protected readonly http: HttpClient;
    protected readonly logService: LogService;
    protected readonly loggedUser: LoggedUserService;
    protected readonly notificationService: NotificationService;
    protected readonly options: ServiceOptions = {
        filters: {
            mode: 'gridify'
        },
        notification: {
            uiComponent: 'toastr',
            validationErrorComponent: 'toastr',
            onSuccess: {
                info: { title: 'Success', message: 'Successfully saved.' }
            },
            onError: {
                info: {
                    title: 'Error',
                    message: 'An error occurred while performing {0}.'
                },
                rethrowError: false,
                handleValidationErrors: true
            }
        }
    };
    protected readonly paginationKeys = {
        firstItemOnPage: 'firstItemOnPage',
        hasNextPage: 'hasNextPage',
        hasPreviousPage: 'hasPreviousPage',
        isFirstPage: 'isFirstPage',
        isLastPage: 'isLastPage',
        lastItemOnPage: 'lastItemOnPage',
        page: 'page',
        pageCount: 'pageCount',
        pageSize: 'pageSize',
        totalItemCount: 'totalItemCount'
    };

    protected apiEndpoints: ApiEndpointsType;
    protected httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    protected loadingSubject = new Subject<boolean>();
    protected retryAttempts = HTTP_CLIENT__MAX_RETRIES;
    protected retryDelayMs = HTTP_CLIENT__RETRIES_DELAY_IN_MS;

    protected constructor(protected readonly injector: Injector) {
        this.cvsConfirmationService = injector.get(CvsConfirmationService);
        this.notificationService = injector.get(NotificationService);
        this.http = injector.get(HttpClient);
        this.environmentService = injector.get(EnvironmentService);
        this.logService = injector.get(LogService);
        this.loggedUser = injector.get(LoggedUserService);
        this.apiEndpoints = this.getApiEndpoints();
    }

    public dispose(): void {
        this.loadingSubject.next(false);
    }

    public withError(messageInfo: MessageInfo | false, handleValidationErrors = true) {
        if (typeof messageInfo == 'boolean') {
            this.options.notification.onError = false;
            return this;
        }
        this.options.notification.onError = {
            info: messageInfo,
            handleValidationErrors
        };
        return this;
    }

    public withSuccess(messageInfo: MessageInfo | false) {
        if (typeof messageInfo == 'boolean') {
            this.options.notification.onSuccess = false;
            return this;
        }
        this.options.notification.onSuccess = {
            info: messageInfo
        };
        return this;
    }

    /**
     *
     * @param error Handle Error
     * @returns
     */
    protected handleError<T>(operation = 'operation', result?: T) {
        return (error: HttpErrorResponse): Observable<T> => {
            let errorMessage = '';
            if (error instanceof ServerError) {
                // client-side error
                errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            } else {
                // server-side error
                errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
            this.log(`${operation} failed: ${errorMessage} ${result}`, true);
            if (this.options.notification.onError) {
                const msg = this.options.notification.onError.info.message;
                if (
                    this.options.notification.onError.handleValidationErrors &&
                    error instanceof ServerError &&
                    error.validationErrors
                ) {
                    // console.log(error.validationErrors);
                }
                switch (this.options.notification.uiComponent) {
                    case 'dialog':
                        return this.cvsConfirmationService
                            .openError({
                                title: this.options.notification.onError.info.title,
                                message: StringFormatUtils.formatStringWithNumericPlaceholders(msg, operation)
                            })
                            .afterClosed()
                            .pipe(
                                map((v) => {
                                    return { afterClosedData: v, operation, ...result };
                                })
                            );
                    case 'toastr':
                        return this.notificationService
                            .showError(
                                StringFormatUtils.formatStringWithNumericPlaceholders(msg, operation),
                                this.options.notification.onError.info.title
                            )[0]
                            .onHidden.pipe(
                                map((v) => {
                                    return { afterClosedData: v, operation, ...result };
                                })
                            );
                }
            } else {
                throw error;
            }
            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    protected handleSuccess<T>(
        operation: 'add' | 'update' | 'delete' | 'patch' | 'getById' | 'new' | 'search' | 'addFormData',
        info: { [key: string]: string | boolean | number }
    ) {
        return (data: T): Observable<T> => {
            if (this.options.notification.onSuccess) {
                const msg = this.options.notification.onSuccess.info.message;

                switch (this.options.notification.uiComponent) {
                    case 'dialog':
                        return this.cvsConfirmationService
                            .openError({
                                title: this.options.notification.onSuccess.info.title,
                                message: StringFormatUtils.formatStringWithNumericPlaceholders(msg, operation)
                            })
                            .afterClosed()
                            .pipe(
                                map((v) => {
                                    return { ...info, afterClosedData: v, operation, ...data };
                                })
                            );
                    case 'toastr':
                        return this.notificationService
                            .showSuccess(
                                StringFormatUtils.formatStringWithNumericPlaceholders(msg, operation),
                                this.options.notification.onSuccess.info.title
                            )[0]
                            .onHidden.pipe(
                                map((v) => {
                                    return { ...info, afterClosedData: v, operation, ...data };
                                })
                            );
                }
            }
            this.log(`${operation} succeeded`);
            return of(data as T);
        };
    }

    /**
     * Log Messages
     * @param message
     * @param isError
     */
    protected log(message: string, isError = false) {
        if (isError) {
            this.logService.error(`AbstractHttpService: ${message}`);
        } else this.logService.info(`AbstractHttpService: ${message}`);
    }

    protected shouldRetry(error: any) {
        if (RetryableHttpStatusCode.has(error['status'])) {
            return timer(HTTP_CLIENT__RETRIES_DELAY_IN_MS); // Adding a timer from RxJS to return observable to delay param.
        }
        throw error;
    }

    protected abstract getApiEndpoints(): ApiEndpointsType;
}

export abstract class BaseQueryHttpService<T extends ResponseModelType> extends AbstractHttpService {
    protected constructor(protected readonly injector: Injector) {
        super(injector);
    }

    /**
     * Get Loading Observable
     */
    public get loading(): Observable<boolean> {
        return this.loadingSubject.asObservable();
    }

    /**
     *
     * @param param0
     * @returns
     */
    public autocomplete$({
        currentPage = 1,
        rowsPerPage = 10,
        sort = null,
        filters = null,
        apiEndpoint = null
    }: {
        currentPage: number | null | undefined;
        rowsPerPage?: number | null | undefined;
        sort:
            | {
                  field: string;
                  direction: 'asc' | 'desc' | '';
              }
            | null
            | undefined;
        filters?: { key?: string; value?: string; operator?: '=*' } | null | undefined | string;
        apiEndpoint?: string;
    }) {
        return this.search$({
            currentPage,
            rowsPerPage,
            sort,
            apiEndpoint,
            filters:
                typeof filters === 'string'
                    ? filters
                    : [
                          {
                              key: filters?.key,
                              value: filters?.value,
                              operator:
                                  filters?.operator == '=*' ? GridifyConditionEnum.Contains : GridifyConditionEnum.Equal
                          }
                      ]
        }).pipe(
            map((res) => {
                const newResponse = {
                    items: res.items || [],
                    metadata: {
                        pagination: {
                            totalItemCount: res.metadata?.pagination?.totalItemCount || 0
                        }
                    }
                };
                return newResponse;
            })
        );
    }

    public dispose(): void {
        this.loadingSubject.next(false);
    }

    /**
     * search and get all data
     * @param param0
     * @returns
     */
    public getAll$({
        sort = null,
        filters = null,
        httpParams = null,
        urlPathParams = null
    }: {
        sort?: SortsType | null | undefined;
        filters?: FiltersType | null | undefined;
        httpParams?: {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        };
        urlPathParams?: string[];
    } = {}): Observable<PagedResponseModel<T>> {
        this.loadingSubject.next(true);
		
        const sortParam = sort ? createSort(sort) : '';
        let httpNewParams: HttpParams = new HttpParams({
            fromObject: httpParams
        });
        // filter code
		if (filters && filters.length > 0) {
                if (filters[0] === ',' || filters[0] === '|') {
                    filters.shift();
                }
            }
        if (this.options.filters.mode === 'queryString') {
            const queryString = filters ? createQueryString(filters) || '' : '';
            if (queryString && queryString.length > 0) {
                const urlParams = new URLSearchParams(queryString);
                const params = Object.fromEntries(urlParams); // {abc: "foo", def: "[asf]", xyz: "5"}
                (Object.keys(params) as (keyof typeof params)[]).forEach((key) => {
                    httpNewParams = httpNewParams.append(key + '', params[key]);
                });
            }
        } else {
            const gridify = filters ? createGridify(filters) || '' : '';
            if (gridify && gridify.length > 0) {
                httpNewParams = httpNewParams.append('filter', gridify);
            }
        }

        if (sortParam && sortParam.length > 0) {
            httpNewParams = httpNewParams.append('orderBy', sortParam);
        }
        let endpoint = this.apiEndpoints.getAll;
        if (urlPathParams && urlPathParams?.length > 0) {
            urlPathParams.forEach((x) => (x = encodeURIComponent(x)));
            endpoint = StringFormatUtils.formatStringWithNumericPlaceholders(endpoint, ...urlPathParams);
        }
        return this.http
            .get<T>(endpoint, {
                params: httpNewParams
            })
            .pipe(
                finalize(() => this.loadingSubject.next(false)),
                retry({ count: this.retryAttempts, delay: this.shouldRetry }),
                map((res) => {
                    const newResponse: PagedResponseModel<T> = {
                        items: Array.isArray(res) ? res : []
                    };
                    return newResponse;
                }),
                tap((x) => (Array.isArray(x) ? this.log(`found data matching`) : this.log(`no data matching`))),
                catchError(this.handleError<PagedResponseModel<T>>('search', { items: [] }))
            );
    }

    /**
     * search and get all data
     * @param param0
     * @returns
     */
    public getAllChildren$({
        sort = null,
        filters = null,
        httpParams = null,
        urlPathParams = null
    }: {
        sort?: SortsType | null | undefined;
        filters?: FiltersType | null | undefined;
        httpParams?: {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        };
        urlPathParams?: string[];
    } = {}): Observable<PagedResponseModel<T>> {
        this.loadingSubject.next(true);

        const sortParam = sort ? createSort(sort) : '';
        let httpNewParams: HttpParams = new HttpParams({
            fromObject: httpParams
        });
        // filter code
		if (filters && filters.length > 0) {
                if (filters[0] === ',' || filters[0] === '|') {
                    filters.shift();
                }
            }
        if (this.options.filters.mode === 'queryString') {
            const queryString = filters ? createQueryString(filters) || '' : '';
            if (queryString && queryString.length > 0) {
                const urlParams = new URLSearchParams(queryString);
                const params = Object.fromEntries(urlParams); // {abc: "foo", def: "[asf]", xyz: "5"}
                (Object.keys(params) as (keyof typeof params)[]).forEach((key) => {
                    httpNewParams = httpNewParams.append(key + '', params[key]);
                });
            }
        } else {
            const gridify = filters ? createGridify(filters) || '' : '';
            if (gridify && gridify.length > 0) {
                httpNewParams = httpNewParams.append('filter', gridify);
            }
        }

        if (sortParam && sortParam.length > 0) {
            httpNewParams = httpNewParams.append('orderBy', sortParam);
        }
        let endpoint = this.apiEndpoints.children;
        if (urlPathParams && urlPathParams?.length > 0) {
            urlPathParams.forEach((x) => (x = encodeURIComponent(x)));
            endpoint = StringFormatUtils.formatStringWithNumericPlaceholders(endpoint, ...urlPathParams);
        }
        return this.http
            .get<T>(endpoint, {
                params: httpNewParams
            })
            .pipe(
                finalize(() => this.loadingSubject.next(false)),
                retry({ count: this.retryAttempts, delay: this.shouldRetry }),
                map((res) => {
                    const newResponse: PagedResponseModel<T> = {
                        items: Array.isArray(res) ? res : []
                    };
                    return newResponse;
                }),
                tap((x) => (Array.isArray(x) ? this.log(`found data matching`) : this.log(`no data matching`))),
                catchError(this.handleError<PagedResponseModel<T>>('search', { items: [] }))
            );
    }

    /**
     *
     * @param id Primary Key
     * @returns Observable
     */
    public getById$<Tg = T>(
        id: number | string[] | string,
        httpParams: {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        } = {}
    ): Observable<Tg> {
        this.loadingSubject.next(true);
        const params = !Array.isArray(id) ? [id + ''] : id || [];
        const endpoint = StringFormatUtils.formatStringWithNumericPlaceholders(this.apiEndpoints.getById, ...params);
        return this.http
            .get<Tg>(endpoint, {
                params: httpParams
            })
            .pipe(
                retry({ count: this.retryAttempts, delay: this.shouldRetry }),
                finalize(() => this.loadingSubject.next(false)),
                tap((_) => this.log(`fetched id=${id}`)),
                catchError(this.handleError<Tg>(`getById id=${id}`))
            );
    }

    public new$(
        httpParams: {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        } = {}
    ): Observable<T> {
        this.loadingSubject.next(true);
        const endpoint = StringFormatUtils.formatStringWithNumericPlaceholders(this.apiEndpoints.getById, 'new');
        return this.http
            .get<T>(endpoint, {
                params: httpParams
            })
            .pipe(
                retry({ count: this.retryAttempts, delay: this.shouldRetry }),
                finalize(() => this.loadingSubject.next(false)),
                tap((_) => this.log(`fetched new`)),
                catchError(this.handleError<T>(`new`, {} as T))
            );
    }

    /**
     * search and get paged data
     * @param param0
     * @returns
     */
    public search$({
        currentPage = 1,
        rowsPerPage = PAGINATION__MAX_ROWS_PER_PAGE,
        sort = null,
        filters = null,
        httpParams = null,
        urlPathParams = null,
        apiEndpoint = null
    }: {
        currentPage: number | null | undefined;
        rowsPerPage?: number | null | undefined;
        sort?: SortsType | null | undefined;
        filters?: FiltersType | null | undefined | string;
        httpParams?: {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        };
        urlPathParams?: string[];
        apiEndpoint?: string;
    }): Observable<PagedResponseModel<T>> {
        this.loadingSubject.next(true);
        const page = currentPage < 1 ? 1 : currentPage;
        const pageSize = rowsPerPage <= 0 ? PAGINATION__MAX_ROWS_PER_PAGE : rowsPerPage;
        const sortParam = sort ? createSort(sort) : '';
        let httpNewParams: HttpParams = new HttpParams({
            fromObject: httpParams
        });

        httpNewParams = httpNewParams.append('page', page);
        httpNewParams = httpNewParams.append('pageSize', pageSize);
        // filter code
		if (typeof filters !== 'string' && filters && filters.length > 0) {
                if (filters[0] === ',' || filters[0] === '|') {
                    filters.shift();
                }
            }
        if (this.options.filters.mode === 'queryString') {
            const queryString = filters
                ? (typeof filters === 'string' ? filters : createQueryString(filters)) || ''
                : '';
            if (queryString && queryString.length > 0) {
                const urlParams = new URLSearchParams(queryString);
                const params = Object.fromEntries(urlParams); // {abc: "foo", def: "[asf]", xyz: "5"}
                (Object.keys(params) as (keyof typeof params)[]).forEach((key) => {
                    httpNewParams = httpNewParams.append(key + '', params[key]);
                });
            }
        } else {
            const gridify = filters ? (typeof filters === 'string' ? filters : createGridify(filters)) || '' : '';
            if (gridify && gridify.length > 0) {
                httpNewParams = httpNewParams.append('filter', gridify);
            }
        }

        if (sortParam && sortParam.length > 0) {
            httpNewParams = httpNewParams.append('orderBy', sortParam);
        }
        let endpoint = apiEndpoint ?? this.apiEndpoints.search;
        if (urlPathParams && urlPathParams?.length > 0) {
            urlPathParams.forEach((x) => (x = encodeURIComponent(x)));
            endpoint = StringFormatUtils.formatStringWithNumericPlaceholders(endpoint, ...urlPathParams);
        }
        return this.http
            .get<PagedResponseModel<T>>(endpoint, {
                params: httpNewParams
            })
            .pipe(
                retry({ count: this.retryAttempts, delay: this.shouldRetry }),
                finalize(() => this.loadingSubject.next(false)),
                map((res) => {
                    const newResponse: PagedResponseModel<T> = {
                        extras: res.extras,
                        items: res.items || [],
                        metadata: {
                            pagination: {
                                firstItemOnPage: res[this.paginationKeys.firstItemOnPage],
                                hasNextPage: res[this.paginationKeys.hasNextPage],
                                hasPreviousPage: res[this.paginationKeys.hasPreviousPage],
                                isFirstPage: res[this.paginationKeys.isFirstPage],
                                isLastPage: res[this.paginationKeys.isLastPage],
                                lastItemOnPage: res[this.paginationKeys.lastItemOnPage],
                                page: res[this.paginationKeys.page],
                                pageCount: res[this.paginationKeys.pageCount],
                                pageSize: res[this.paginationKeys.pageSize],
                                totalItemCount: res[this.paginationKeys.totalItemCount]
                            }
                        }
                    };
                    return newResponse;
                }),
                tap((x) =>
                    x.metadata.pagination.totalItemCount
                        ? this.log(`found data matching`)
                        : this.log(`no data matching`)
                ),
                catchError(this.handleError<PagedResponseModel<T>>('search', { items: [] }))
            );
    }
}

export abstract class BaseHttpService<T extends ResponseModelType> extends BaseQueryHttpService<T> {
    protected constructor(protected readonly injector: Injector) {
        super(injector);
    }

    /**
     * Add New
     * @param model
     * @returns
     */
    public add$(model: T, urlPathParams?: string[]): Observable<T> {
        this.loadingSubject.next(true);
        let endpoint = this.apiEndpoints.create ?? this.apiEndpoints.get;
        if (urlPathParams && urlPathParams?.length > 0) {
            urlPathParams.forEach((x) => (x = encodeURIComponent(x)));
            endpoint = StringFormatUtils.formatStringWithNumericPlaceholders(endpoint, ...urlPathParams);
        }
        return this.http.post<T>(endpoint, model, this.httpOptions).pipe(
            finalize(() => this.loadingSubject.next(false)),
            mergeMap(this.handleSuccess<T>('add', { success: true })),
            catchError(this.handleError<T>('add'))
        );
    }
    /**
     * Add New
     * @param model
     * @returns
     */
    public addFormData$(formData: FormData, urlPathParams?: string[]): Observable<T> {
        this.loadingSubject.next(true);
        let endpoint = this.apiEndpoints.create ?? this.apiEndpoints.get;
        if (urlPathParams && urlPathParams?.length > 0) {
            urlPathParams.forEach((x) => (x = encodeURIComponent(x)));
            endpoint = StringFormatUtils.formatStringWithNumericPlaceholders(endpoint, ...urlPathParams);
        }
        this.httpOptions.headers = undefined;
        return this.http.post<T>(endpoint, formData, this.httpOptions).pipe(
            finalize(() => this.loadingSubject.next(false)),
            mergeMap(this.handleSuccess<T>('addFormData', { success: true })),
            catchError(this.handleError<T>('add'))
        );
    }
    public dispose(): void {
        super.dispose();
    }

    /**
     * Update
     * @param id
     * @param model
     * @returns
     */
    public patch$(id: number | string[], model: Partial<T>): Observable<any> {
        this.loadingSubject.next(true);
        const params = !Array.isArray(id) ? [id + ''] : id || [];
        const endpoint = StringFormatUtils.formatStringWithNumericPlaceholders(
            this.apiEndpoints.patch || this.apiEndpoints.update || this.apiEndpoints.getById,
            ...params
        );
        return this.http.patch<T>(endpoint, model, this.httpOptions).pipe(
            finalize(() => this.loadingSubject.next(false)),
            mergeMap(this.handleSuccess<T>('patch', { success: true })),
            catchError(this.handleError<any>('patch'))
        );
    }

    /**
     * Update
     * @param id
     * @param model
     * @returns
     */
    public update$(id: number | string[], model: T): Observable<any> {
        this.loadingSubject.next(true);
        const params = !Array.isArray(id) ? [id + ''] : id || [];
        const endpoint = StringFormatUtils.formatStringWithNumericPlaceholders(
            this.apiEndpoints.update || this.apiEndpoints.getById,
            ...params
        );
        return this.http.put<T>(endpoint, model, this.httpOptions).pipe(
            finalize(() => this.loadingSubject.next(false)),
            mergeMap(this.handleSuccess<T>('update', { success: true })),
            catchError(this.handleError<any>('update'))
        );
    }

    /**
     * Delete
     * @param id
     * @returns
     */
    public delete$(id: number): Observable<any> {
        this.loadingSubject.next(true);
        const params = !Array.isArray(id) ? [id + ''] : id || [];
        const endpoint = StringFormatUtils.formatStringWithNumericPlaceholders(
            this.apiEndpoints.delete || this.apiEndpoints.getById,
            ...params
        );
        return this.http.delete<T>(endpoint, this.httpOptions).pipe(
            finalize(() => this.loadingSubject.next(false)),
            mergeMap(this.handleSuccess<T>('delete', { success: true })),
            catchError(this.handleError<any>('delete'))
        );
    }
}
