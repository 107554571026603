import { DateTime } from 'luxon';

import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { InputBoolean } from '@cvs/utils';
import { environment } from '@environment';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./footer.component.scss'],
    exportAs: 'footer'
})
export class FooterComponent {
    @Input() public fontClass: string = '';
    @Input() @InputBoolean() public showBuildNumber: boolean = true;
    @Input() @InputBoolean() public showLogo: boolean = false;

    public get buildNumber(): string {
        const isValidDate = DateTime.fromFormat(environment.buildNumber, 'yyyyMMdd-HHmmssZZ');
        return isValidDate.isValid ? isValidDate.toLocaleString(DateTime.DATETIME_FULL) : environment.buildNumber;
    }

    /**
     * Getter for current year
     */
    public get currentYear(): number {
        return new Date().getFullYear();
    }
}
