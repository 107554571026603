import { Injectable, Injector } from '@angular/core';
import { StorageService } from '@cvs/services';
import { CvsGridSearchDataType, GridStorageData } from './cvs-grid.types';

export type GridStorageNameType = 'columns' | 'searches';

@Injectable()
export class CvsGridService {
    private readonly storageService: StorageService;
    private _prefix = 'grid';
    /**
     *
     */
    constructor(injector: Injector) {
        this.storageService = injector.get(StorageService);
    }
    /**
     *
     * @param storageName
     * @param keyName
     * @param data
     * @returns
     */
    public exists(storageName: string, keyName: GridStorageNameType): boolean {
        const storage = this.getFromStorage(storageName);
        if (!storage) {
            return false;
        }
        const gridPreferences = JSON.parse(storage) as GridStorageData;
        return Array.isArray(gridPreferences[keyName]);
    }
    /**
     *
     * @param storageName
     * @returns
     */
    public getAll(storageName: string): GridStorageData {
        const storage = this.getFromStorage(storageName) || '{}';
        const gridPreferences = JSON.parse(storage) as GridStorageData;
        return gridPreferences;
    }

    /**
     *
     * @param storageName
     * @returns
     */
    public getColumns(storageName: string): Array<string> {
        const gridPreferences = this.getAll(storageName);
        return gridPreferences.columns || [];
    }
    /**
     *
     * @param storageName
     * @returns
     */
    public getSearch(storageName: string): Array<CvsGridSearchDataType> {
        const gridPreferences = this.getAll(storageName);
        return gridPreferences.searches || [];
    }
    /**
     *
     * @param storageName
     * @param data
     * @returns
     */
    public saveColumn(storageName: string, data: string[]): void {
        const storage = this.getFromStorage(storageName);
        const data2Push: GridStorageData = {};
        data2Push.columns = data;
        if (!storage) {
            this.setStorage(storageName, JSON.stringify(data2Push));
            return;
        }
        const gridPreferences = JSON.parse(storage) as GridStorageData;
        gridPreferences.columns = data;
        this.setStorage(storageName, JSON.stringify(gridPreferences));
    }
    public saveSearches(storageName: string, data: CvsGridSearchDataType): void {
        const storage = this.getFromStorage(storageName);
        const data2Push: GridStorageData = {};
        data2Push.searches = [data];
        if (!storage) {
            this.setStorage(storageName, JSON.stringify(data2Push));
            return;
        }
        const gridPreferences: GridStorageData = {};
        gridPreferences.columns = JSON.parse(storage);
        const searchItemIndex = gridPreferences?.searches?.findIndex(
            (x) => x.name.toLowerCase() === data.name.toLowerCase()
        );
        if (searchItemIndex && searchItemIndex >= 0) {
            gridPreferences.searches.splice(searchItemIndex, 1);
        } else {
            gridPreferences.searches = [];
            gridPreferences.searches.push(data);
        }
        this.setStorage(storageName, JSON.stringify(gridPreferences));
    }
    private getFromStorage(key: string) {
        return this.storageService.getItem(`${this._prefix}${this.toProperCase(key)}`);
    }
    private setStorage(key: string, data: string) {
        return this.storageService.setItem(`${this._prefix}${this.toProperCase(key)}`, data);
    }
    private toProperCase(data: string) {
        return data.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
        });
    }
}
