import { NoAuthLayoutComponent } from 'src/app/layout/layouts/no-auth/no-auth.component';
import { CvsSharedModule } from 'src/app/shared';
import { SharedModule } from 'src/app/shared/shared.module';

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CvsBreadcrumbModule } from '@cvs/components/breadcrumb';

import { FooterModule } from '../../common/footer/footer.module';

@NgModule({
    declarations: [NoAuthLayoutComponent],
    imports: [RouterModule, CvsSharedModule, SharedModule, FooterModule, CvsBreadcrumbModule],
    exports: [NoAuthLayoutComponent]
})
export class NoAuthLayoutModule {}
