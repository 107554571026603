import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { ReplaySubject } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { LogService } from './log.service';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    public sessionIdle$: ReplaySubject<{ countdown: number; isIdle: boolean; isTimeOut: boolean } | undefined> =
        new ReplaySubject(1);
    private countdownStarted = false;
    private sessionTimeout: number = 60;
    private sessionTimeoutWarningPeriod: number = 10;
    constructor(
        private readonly idle: Idle,
        private readonly logService: LogService,
        private readonly router: Router,
        readonly environmentService: EnvironmentService
    ) {
        const authConfig = environmentService.getEnvironment().authConfig;
        this.sessionTimeout = authConfig.sessionTimeoutInMins * 60;
        this.sessionTimeoutWarningPeriod = authConfig.sessionTimeoutWarningPeriodInSecs;
        this.configureIdleService();
    }
    public initializeSession(): void {
        this.startIdleService();
    }
    public destroySession(): void {
        this.stopIdleService();
    }
    protected startIdleService(): void {
        this.idle.watch();
        this.sessionIdle$.next(undefined);
    }

    protected stopIdleService(): void {
        this.idle.stop();
        this.sessionIdle$.next(undefined);
    }
    protected configureIdleService(): void {
        if (this.sessionTimeoutWarningPeriod >= this.sessionTimeout) {
            const errorMsg = `SessionService: authConfig constant is not valid.\n\n- sessionTimeoutWarningPeriod cannot be equal or higher than sessionTimeout\n`;
            throw new Error(errorMsg);
        }
        // seconds before the user is considered to be idle (should be calculated subtracting the timeout warning period)
        this.idle.setIdle(this.sessionTimeout - this.sessionTimeoutWarningPeriod);
        // seconds before the session times out and the timeout warning event should be emitted
        this.idle.setTimeout(this.sessionTimeoutWarningPeriod);
        // sets the default interrupts (clicks, scrolls, touches to the document)
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.onIdleStart.subscribe(() => {
            this.logService.warn('SessionService : the user has gone idle');
            this.sessionIdle$.next({
                countdown: null,
                isIdle: true,
                isTimeOut: false
            });
        });
        this.idle.onIdleEnd.subscribe(() => {
            this.logService.info('SessionService: the user is no longer idle');
            if (this.countdownStarted) {
                this.countdownStarted = false;
            }
            this.sessionIdle$.next(undefined);
        });

        this.idle.onTimeout.subscribe(() => {
            this.logService.warn('SessionService: the user session has timed out!');
            this.router.navigateByUrl('/sign-out');
        });

        this.idle.onTimeoutWarning.subscribe((countdown: number) => {
            if (countdown === this.idle.getTimeout()) {
                this.countdownStarted = true;
            }
            this.sessionIdle$.next({
                countdown: countdown,
                isIdle: true,
                isTimeOut: true
            });
        });
    }
}
