<!--MAIN CODE-->
<div class="cvs-grid-container cvs-grid-{{options.mode}}" [id]="id">
    <div *ngIf="options && options.caption"
        class="flex flex-row items-center justify-start gap-1 -mx-2 pt-2 pb-3 px-4 border-b cvsui-default-border-color uppercase  mb-2.5 leading-normal text-left text-primary">
        <div class="flex-auto flex flex-row items-center justify-start">
            <mat-icon svgIcon="heroicons_mini:chevron-double-right" class="text-current"></mat-icon>
            <span class="text-base font-semibold">
                {{
                options.caption | transloco
                }}</span>
        </div>
        <button *ngIf="newClick" (click)="newClick($event)"><mat-icon
                svgIcon="heroicons_outline:plus-circle"></mat-icon>
        </button>
    </div>
    <div class="toolbar-container" [ngClass]="this.options.toolbar?.enable?'':'hidden'">
        <div class="toolbar">
            <div class="w-full " #advanceSearchTrigger="cdkOverlayOrigin" cdkOverlayOrigin
                [ngClass]="this.options.toolbar?.showCommonSearch?'':'hidden'">
                <form class="flex items-center">
                    <label for="simple-search" class="sr-only">{{'Search' | transloco}}</label>
                    <div class="relative">
                        <!-- <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <mat-icon svgIcon="heroicons_outline:magnifying-glass"></mat-icon>
                            </div> -->
                        <input [formControl]="searchInputCtrl" matInput type="text" #searchInput
                            [matAutocomplete]="searchInputAuto" placeholder="Search"
                            (keypress)="announceFilterAnyClick(searchInput.value, $event.key)"
                            class="min-w-64 w-64 border cvsui-default-border-color text-gray-900 
                                rounded-lg shadow-inner focus:ring-primary-500 focus:border-primary-500 
                                block pr-10 p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
                                dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 toolbar-item" />
                        <mat-autocomplete autoActiveFirstOption #searchInputAuto="matAutocomplete"
                            [displayWith]="displaySearchInputFn" (optionSelected)="onSearchOptionSelected($event)">
                            <mat-option *ngFor="let option of savedSearchItems | async" [value]="option">
                                {{ option.name }}
                            </mat-option>
                        </mat-autocomplete>
                        <div class="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                            (click)="announceFilterAnyClick(searchInput.value, 'Manual')">
                            <mat-icon svgIcon="heroicons_outline:arrow-right-circle" class="icon-size-4"
                                [ngClass]="searchInputCtrl.value.length>0?'text-primary ring-1 ring-primary-500':'text-gray-500'"></mat-icon>
                        </div>
                    </div>
                    <button type="button" mat-icon-button matTooltip="{{ 'AdvancedSearch' | transloco }}" class="ml-2"
                        (click)="onAdvancedSearchClick()" *ngIf="options.toolbar?.showAdvancedSearch">
                        <mat-icon svgIcon="mat_outline:manage_search"></mat-icon>
                    </button>

                </form>
            </div>
            <button type="button" mat-icon-button matTooltip="{{ 'Download' | transloco }}"
                [matMenuTriggerFor]="downloadMenu" *ngIf="options.toolbar?.showDownloadOption">
                <mat-icon svgIcon="heroicons_outline:document-arrow-down"></mat-icon>
                <!-- <span>{{ 'Download' | transloco }}</span> -->
            </button>
            <mat-menu #downloadMenu="matMenu" [xPosition]="'before'" class="mt-2">
                <ng-container *ngFor="let file of options.downloadOption.files; let last = last; trackBy: trackByFn">
                    <button mat-menu-item (click)="onDownloadClick(file)">
                        {{ file + '' | transloco }}
                    </button>
                    <mat-divider *ngIf="!last" class="my-0"></mat-divider>
                </ng-container>
            </mat-menu>

            <button #toggleColumnTrigger="cdkOverlayOrigin" cdkOverlayOrigin type="button" mat-icon-button
                matTooltip="{{ 'ShowHideColumns' | transloco }}" (click)="onViewColumnClick()"
                [ngClass]="{'hidden':!options.toolbar?.showColumnSelector}">
                <mat-icon svgIcon="heroicons_outline:view-columns"></mat-icon>
                <!-- <span>{{ 'Columns' | transloco }}</span> -->
            </button>
            <button type="button" mat-icon-button matTooltip="{{ 'CardStyle' | transloco }}"
                (click)="onGridModeClick('card')" *ngIf="options.mode === 'table'">
                <mat-icon svgIcon="heroicons_outline:credit-card"></mat-icon>
            </button>
            <button type="button" mat-icon-button matTooltip="{{ 'TableStyle' | transloco }}"
                (click)="onGridModeClick('table')" *ngIf="options.mode === 'card'">
                <mat-icon svgIcon="heroicons_outline:table-cells"></mat-icon>
            </button>
        </div>
    </div>
    <div class="-mt-4 py-3" *ngIf="(advanceSearchTempFilters ??[]).length>0">
        <span
            class="inline-flex rounded-xl flex-row items-center p-1 mr-2 mt-2 border border-primary-600 bg-primary-500 text-white"
            *ngFor="let asFilter of advanceSearchTempFilters; trackBy: trackByColumn">
            <span class="pl-1">{{ asFilter.label | transloco }} {{ 'Operator.' + asFilter.operatorLabel | transloco }}
                {{ asFilter.value }}</span>
            <button type="button" mat-icon-button (click)="onRemoveFilter(asFilter.pk)"
                class="flex-shrink-0 ml-2 h-4 w-4 rounded-full inline-flex items-center justify-center hover:bg-white">
                <mat-icon svgIcon="heroicons_outline:x-mark"
                    class="icon-size-4 text-white hover:text-primary-500"></mat-icon>
            </button>
        </span>
    </div>

    <div #tableContainer class="-cvs-grid-{{border}}">
        <ng-container *ngIf="dataSource | async as gridData; else loadingPanel">
            <!--Loading-->
            <div [ngClass]="{ 'hidden': !isLoading }"
                class="absolute backdrop-blur-sm md:rounded-lg top-0 left-0 right-0 bottom-0 w-full h-full z-50 overflow-hidden flex flex-col items-center justify-center">
                <div
                    class="cvs-grid-loader ease-linear rounded-full border-4 border-accent border-t-primary  border-t-4 h-12 w-12 mb-4">
                </div>
                <h2 class="text-center text-primary  font-semibold">{{ 'Loading' | transloco }}</h2>
            </div>
            <!--main container-->
            <ng-container *ngIf="gridData && gridData.items">
                <!--data table-->
                <div class="cvs-grid-{{options.mode}}-container">
                    <ng-container *ngTemplateOutlet="
                            options.mode === 'card' ? dataCardPanel : dataTablePanel;
                            context: { $implicit: gridData }
                        "></ng-container>
                </div>
                <!--pagination-->
                <ng-container *ngIf="gridData.items.length > 0 && this.options.pagination?.enable">
                    <ng-container
                        *ngTemplateOutlet="dataPagerPanel; context: { $implicit: getDataPager(gridData) }"></ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>
<!--TEMPLATES-->
<ng-template #loadingPanel>
    <ng-container *ngTemplateOutlet="
            options.mode === 'card' ? dataCardPanel : dataTablePanel;
            context: { $implicit: loadingGridData }
        "></ng-container>
</ng-template>
<!--DATA PAGER-->
<ng-template let-pagination #dataPagerPanel>
    <div class="cvs-grid-paginator">
        <button type="button" mat-icon-button class="flex items-center justify-center"
            matTooltip="{{ 'Reset' | transloco }}" (click)="announceFilterAnyClick('', 'Manual')">
            <mat-icon svgIcon="mat_outline:refresh"></mat-icon>
        </button>
        <mat-paginator (page)="announcePageChange($event)" [pageSize]="pagination.pageSize" class="flex-auto"
            [pageIndex]="pagination.page - 1" [length]="pagination.totalItemCount"
            [ngClass]="{ 'md:rounded-lg': options.mode === 'card' }"></mat-paginator>
    </div>
</ng-template>
<!--DATA TABLE-->
<ng-template let-gridData #dataTablePanel>
    <table (matSortChange)="announceSortChange($event)" matSort matSortDisableClear [matSortDisabled]="!enableSorting"
        mat-table [dataSource]="gridData?.items"
        class="cvs-table cvs-table-sm -cvs-table-striped -cvs-table-hover -cvs-table-{{border}}"
        [trackBy]="trackByMatTableFn.bind(this)" fixedLayout="true" *transloco="let t">

        <ng-container *ngIf="options.rowSelectable" matColumnDef="CvsGridCheckboxColumnDef">
            <th mat-header-cell *matHeaderCellDef class="cvs-grid-checkbox-cell" scope="col">
                <mat-checkbox class="cvs-align-bottom mdc-checkbox-md" *ngIf="options.multiSelectable"
                    [checked]="rowSelection.hasValue() && _isAllSelected(gridData?.items)"
                    [indeterminate]="rowSelection.hasValue() && !_isAllSelected(gridData?.items)"
                    (change)="$event ? _toggleMasterCheckbox(gridData?.items) : null">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row; let index = index; let dataIndex = dataIndex" scope="row">
                <mat-checkbox class="cvs-align-bottom mdc-checkbox-md" [checked]="rowSelection.isSelected(row)"
                    (click)="$event.stopPropagation()" (change)="$event ? _toggleNormalCheckbox(row) : null">
                </mat-checkbox>
            </td>
            <td mat-footer-cell *matFooterCellDef class="cvs-grid-checkbox-cell"></td>
        </ng-container>
        <ng-container *ngFor="let col of columns; let colIndex = index; let last = last; trackBy: trackByColumn">
            <ng-container [matColumnDef]="col.def" [sticky]="col.sticky === 'start'" [stickyEnd]="col.sticky === 'end'">
                <th [attr.aria-colindex]="colIndex + 1" scope="col" mat-header-cell *matHeaderCellDef mat-sort-header
                    [disabled]="!(col.sorting && col.sorting === true)" [ngClass]="getColumnCssClass(col, 'th')"
                    [width]="col.width">
                    {{ t(getColumnHeaderData(col, colIndex)) }}
                </th>
                <td [attr.aria-colindex]="colIndex + 1" mat-cell *matCellDef="let element"
                    [ngClass]="getColumnCssClass(col)" [width]="col.width">
                    <ng-container *ngTemplateOutlet="
                            col.view === cellView.action ? actionCellPanel : dataCellPanel;
                            context: { $implicit: { col, colIndex, element } }
                        "></ng-container>
                </td>
            </ng-container>
        </ng-container>
        <ng-container [matColumnDef]="'messageCol'">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns?.length"
                class="whitespace-nowrap py-4 pl-4 pr-3  font-medium sm:pl-6">
                <div class="inline-flex items-center rounded bg-{{
                        getMessageBody(gridData, 'color')
                    }}-100 px-2 py-0.5  font-medium text-{{ getMessageBody(gridData, 'color') }}-800">
                    <mat-icon *ngIf="getMessageBody(gridData, 'icon')" [svgIcon]="getMessageBody(gridData, 'icon')"
                        class="mr-2 icon-size-5"></mat-icon>
                    {{ getMessageBody(gridData) }}
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let rowIndex = index; let dataIndex = dataIndex"
            [ngClass]="{ 'cvs-table-row-selected': rowSelection.isSelected(row) }" [attr.aria-rowindex]="rowIndex + 1"
            class="{{ options.rowClassFormatter(row, rowIndex) }}"
            (click)="_selectRow($event, row, _getIndex(rowIndex, dataIndex))"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="relative whitespace-nowrap py-4 pl-4 pr-3  font-medium sm:pl-6"
                [attr.colspan]="displayedColumns?.length">
                <div class="sticky left-6 inline-flex items-center rounded bg-{{
                        getMessageBody(gridData, 'color') || 'blue'
                    }}-100 px-2 py-0.5  font-medium text-{{ getMessageBody(gridData, 'color') || 'blue' }}-800">
                    <mat-icon [svgIcon]="getMessageBody(gridData, 'icon') || 'heroicons_outline:information-circle'"
                        class="mr-2 icon-size-5 text-{{ getMessageBody(gridData, 'color') || 'blue' }}-800"></mat-icon>
                    {{ t(getMessageBody(gridData) || 'NoRecordsFound') }}
                </div>
            </td>
        </tr>
    </table>
</ng-template>
<!--CARD TABLE-->
<ng-template let-gridData #dataCardPanel>
    <div (click)="_selectRow($event, element, _getIndex(rowIndex, dataIndex))" class="cvs-grid-card-row {{ cardGridCols }} {{
            options.rowClassFormatter(element, rowIndex)
        }} " [ngClass]="{ 'border-dashed border-2 border-sky-400 ': rowSelection.isSelected(element) }"
        [attr.aria-rowindex]="rowIndex + 1" *ngFor="
            let element of gridData?.items;
            let rowIndex = index;
            let dataIndex = index;
            let first = first;
            trackBy: trackByMatTableFn.bind(this)
        " (matSortChange)="announceSortChange($event)" matSort matSortDisableClear [matSortDisabled]="!enableSorting">
        <ng-container *ngFor="let col of columns; let colIndex = index; let last = last; trackBy: trackByColumn">
            <ng-container *ngIf="displayedColumns.includes(col.def)">
                <dl *transloco="let t" [attr.aria-colindex]="colIndex + 1" scope="col" class="cvs-grid-card-col"
                    [ngClass]="getColumnCssClass(col, 'card')">
                    <ng-container
                        *ngIf="!(col.view === cellView.action || col.hideHeader === 'card' || col.hideHeader === 'all')">
                        <dl [mat-sort-header]="col.def" [disabled]="!first" class="header">{{
                            t(getColumnHeaderData(col, colIndex)) }}</dl>
                    </ng-container>
                    <dd class="-mt-0.5 pt-0.5">
                        <ng-container *ngTemplateOutlet="
                            col.view === cellView.action ? actionCellPanel : dataCellPanel;
                            context: { $implicit: { col, colIndex, element } }
                        "></ng-container>
                    </dd>
                </dl>
            </ng-container>
        </ng-container>
    </div>
    <div class="bg-white rounded-lg drop-shadow-{{
            shadow
        }} m-2 p-2.5 border relative whitespace-nowrap py-4 pl-4 pr-3  font-medium sm:pl-6"
        *ngIf="gridData?.metadata?.message || gridData?.metadata?.pagination?.totalItemCount === 0">
        <div class="sticky h-full inline-flex items-center rounded bg-{{
                getMessageBody(gridData, 'color') || 'blue'
            }}-100 p-2  leading-4 font-medium text-{{ getMessageBody(gridData, 'color') || 'blue' }}-800">
            <mat-icon [svgIcon]="getMessageBody(gridData, 'icon') || 'heroicons_outline:information-circle'"
                class="mr-2 icon-size-5 text-{{ getMessageBody(gridData, 'color') || 'blue' }}-800"></mat-icon>
            {{ getMessageBody(gridData) || 'NoRecordsFound' | transloco }}
        </div>
    </div>
</ng-template>
<!--DATA CELL-->
<ng-template let-cellData #dataCellPanel>
    <ng-container *ngIf="getColumnObject(cellData.col, cellData.element, cellData.i) as cellColData">
        <ng-container *ngIf="cellColData.view === 'html'">
            <div [innerHTML]="cellColData.cellData" class="span-words"> </div>
        </ng-container>
        <ng-container *ngIf="cellColData.view === 'text'">
            <span [class]="cellColData.cssClass" [ngClass]="{ 'cvs-grid-': cellData.col === 'card' }"
                [matTooltip]="cellColData.tooltip" [matTooltipDisabled]="cellColData.tooltip===undefined">
                {{ cellData.col.truncate ? ((cellColData.cellData || '--') | truncate:cellData.col.truncate) :
                (cellColData.cellData || '--') }}
            </span>
        </ng-container>
        <ng-container *ngIf="cellColData.view === 'link'">
            <a [ngClass]="cellColData.cssClass" [routerLink]="cellColData.url"
                class="underline hover:bg-sky-200/50 rounded p-1 -mx-1">
                {{ cellColData.cellData }}
            </a>
        </ng-container>
        <ng-container *ngIf="cellColData.view === 'chip'">
            <span class="inline-flex  items-center rounded leading-none p-1" [ngClass]="cellColData.chipColor">{{
                cellColData.cellData
                }}</span>
        </ng-container>
        <ng-container *ngIf="cellColData.view === 'template'">
            <ng-container *ngTemplateOutlet="
                    cellData.col.template;
                    context: { $implicit: { cellColData, element: cellData.element, colIndex: cellData.i } }
                "></ng-container>
        </ng-container>
    </ng-container>
</ng-template>
<!--ACTION CELL-->
<ng-template let-cellData #actionCellPanel>
    <div class="inline-flex flex-row align-center">
        <ng-container *ngFor="let action of cellData.col.actions(cellData.element, cellData.i); trackBy: trackByFn">
            <ng-container *ngIf="!action.children">
                <button mat-icon-button class="action-button-medium" [matTooltip]="action.linkText | transloco"
                    (click)="announceActionItemClick($event, action, cellData)">
                    <mat-icon *ngIf="action.iconSvg" class="{{ action.iconClass }}"
                        [svgIcon]="action.iconSvg"></mat-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="action.children">
                <button mat-icon-button class="action-button-medium" [matMenuTriggerFor]="listMenu"
                    [matMenuTriggerData]="cellData.element" mat-icon-button>
                    <mat-icon [svgIcon]="action.iconSvg" class="{{ action.iconClass }}"></mat-icon>
                </button>
                <mat-menu #listMenu="matMenu">
                    <ng-container *ngFor="let childActions of action.children; trackBy: trackByFn">
                        <button mat-menu-item (click)="announceActionItemClick($event, childActions, cellData)">
                            <mat-icon *ngIf="childActions.iconSvg" class="icon-size-4.5 {{ action.iconClass }}"
                                [svgIcon]="childActions.iconSvg"></mat-icon>
                            <span class=" {{ childActions.iconClass }}">{{ childActions.linkText }}</span>
                        </button>
                    </ng-container>
                </mat-menu>
            </ng-container>
        </ng-container>

    </div>

</ng-template>
<!--SHOW HIDE COLUMNS-->
<ng-template cdkConnectedOverlay (backdropClick)="showColumnSelection = false" [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOrigin]="toggleColumnTrigger" [cdkConnectedOverlayOpen]="showColumnSelection"
    [cdkConnectedOverlayViewportMargin]="7" [cdkConnectedOverlayPanelClass]="['cdk-overlay-pane','max-h-96']"
    [cdkConnectedOverlayPositions]="[
{
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
    offsetX: -4,
},
{
    originX: 'start',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'top',
    offsetX: -4,
},
{
    originX: 'start',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'bottom',
    offsetX: -4,
}
]" [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'">
    <div class="flex flex-col cvsui-card border-primary shadow-xl drop-shadow-xl p-1">
        <div class="cvsui-drag-list-header">
            <div class="cvsui-group items-center justify-between w-full">
                <div class="flex-auto cvsui-group gap-x-1">
                    <button mat-icon-button
                        class="cvsui-button-icon cvsui-button-icon-md cvsui-button-icon-default cvsui-button-icon-rounded-full"
                        size="md" (click)="onToggleAllColumns()">
                        <mat-icon
                            [svgIcon]="allColumnsVisible() ? 'heroicons_outline:eye' : 'heroicons_outline:eye-slash'"
                            [ngClass]="
								allColumnsVisible() ? 'cvsui-text-default-label-dark' : 'cvsui-text-default-label'
							"></mat-icon>
                    </button>
                    <button mat-icon-button
                        class="cvsui-button-icon cvsui-button-icon-md cvsui-button-icon-default cvsui-button-icon-rounded-full"
                        size="md" (click)="selectAllDisplayColumns()">
                        <mat-icon [svgIcon]="'mat_outline:restore'"></mat-icon>
                    </button>
                </div>
                <button mat-icon-button
                    class="cvsui-button-icon cvsui-button-icon-md cvsui-button-icon-default cvsui-button-icon-rounded-full"
                    size="md">
                    <mat-icon [svgIcon]="'mat_outline:save'"></mat-icon>
                </button>
            </div>
        </div>
        <ul cdkDropList (cdkDropListDropped)="drop($event)"
            class="cvsui-drag-list divide-y border-primary cvsui-default-divider-color cvsui-rounded drop-shadow-2xl overflow-auto">
            <ng-container
                *ngFor="let column of sortedColumns; let colIndex = index; let last = last; trackBy: trackByColumn">
                <li class="cvsui-drag-list-item cvsui-group justify-between gap-x-1" cdkDrag [cdkDragData]="column">
                    <div class="cvsui-drag-list-placeholder" *cdkDragPlaceholder></div>
                    <div class="cvsui-group">
                        <button mat-icon-button
                            class="cvsui-button-icon cvsui-button-icon-xs cvsui-button-icon-default cvsui-button-icon-rounded-full"
                            [disabled]="!column.canShowHide"
                            (click)="column.canShowHide ? onShowHideColumnCheckboxChange(column) : null">
                            <mat-icon
                                [svgIcon]="column.showInGrid ? 'heroicons_outline:eye' : 'heroicons_outline:eye-slash'"
                                [ngClass]="
                            column.showInGrid ? 'cvsui-text-default-label-dark' : 'cvsui-text-default-label'
                        " class="icon-size-4"></mat-icon>
                        </button>
                        <span
                            [ngClass]="column.showInGrid ? 'cvsui-text-default-label-dark' : 'cvsui-text-default-label'"
                            class="text-sm">{{ getColumnHeaderData(column, colIndex) | transloco }}</span>
                    </div>
                    <button mat-icon-button
                        class="cvsui-button-icon cvsui-button-icon-xs cvsui-button-icon-default cvsui-button-icon-rounded-full"
                        cdkDragHandle>
                        <mat-icon svgIcon="mat_outline:drag_indicator"
                            class="cvsui-text-default-label icon-size-5"></mat-icon>
                    </button>
                </li>
            </ng-container>
        </ul>
    </div>
</ng-template>
<ng-template cdkConnectedOverlay (backdropClick)="showAdvancedSearchPanel = false"
    [cdkConnectedOverlayHasBackdrop]="true" [cdkConnectedOverlayOrigin]="advanceSearchTrigger"
    [cdkConnectedOverlayOpen]="showAdvancedSearchPanel" [cdkConnectedOverlayViewportMargin]="7"
    [cdkConnectedOverlayPositions]="[
{
originX: 'start',
originY: 'top',
overlayX: 'start',
overlayY: 'top',
},
]" [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'">
    <div class="flex flex-col gap-4 cvsui-card border-primary shadow-xl drop-shadow-xl p-4 w-full max-h-140 md:max-h-192 overflow-x-auto">
        <form #advSearchNgForm [formGroup]="advSearchFormGroup" (ngSubmit)="onApplyAdvanceSearch()" >
            <div class="grid grid-cols-1 gap-4 " 
                [ngClass]="'sm:grid-cols-' + searchNoOfCols">
                <ng-container
                    *ngFor="let column of sortedColumns; let colIndex = index; let last = last; trackBy: trackByColumn">
                    <ng-container *ngIf="(column.searchable?.enable ?? true)">
                        <ng-container [ngSwitch]="column.searchable?.controlType">
                            <ng-template [ngSwitchCase]="'text'">
                                <mat-form-field class="fuse-mat-dense min-w-50"
                                    [ngClass]="'cvsui-form-field-' +column.searchable?.controlType +' col-span-' +column.searchable?.colSpan">
                                    <mat-label>{{
                                        column.searchable?.label + '' | transloco
                                        }}</mat-label>
                                    <input matInput [attr.autocomplete]="column.colId" [name]="column.colId"
                                        [formControlName]="column.colId" [controlErrorAnchor]="txtErr" />
                                    <mat-error>
                                        <ng-template #txtErr="controlErrorAnchor" controlErrorAnchor></ng-template>
                                    </mat-error>
                                </mat-form-field>
                            </ng-template>
                            <ng-template [ngSwitchCase]="'check-box'">
                                <mat-checkbox class="fuse-mat-dense min-w-50"
                                    [ngClass]="'cvsui-matcheckbox-mdc-checkbox' +   'col-span-' +column.searchable?.colSpan"
                                    [attr.autocomplete]="column.colId" [name]="column.colId" [color]="'primary'"
                                    [formControlName]="column.colId">
                                    <span class="'cvsui-matcheckbox-label'">
                                        {{ column.searchable?.label + '' | transloco }}
                                    </span>
                                </mat-checkbox>
                            </ng-template>
                            <ng-template [ngSwitchCase]="'number'">
                                <mat-form-field class="fuse-mat-dense min-w-50" [ngClass]="
                        'cvsui-form-field-' +
                        column.searchable?.controlType +
                        ' col-span-' +
                        column.searchable?.colSpan
                    ">
                                    <mat-label>{{
                                        column.searchable?.label + '' | transloco
                                        }}</mat-label>
                                    <input matInput [attr.autocomplete]="column.colId" [name]="column.colId"
                                        [formControlName]="column.colId" type="number"
                                        [controlErrorAnchor]="numberErr" />
                                    <mat-error>
                                        <ng-template #numberErr="controlErrorAnchor" controlErrorAnchor></ng-template>
                                    </mat-error>
                                </mat-form-field>
                            </ng-template>
                            <ng-template [ngSwitchCase]="'date'">
                                <mat-form-field class="fuse-mat-dense min-w-50" [ngClass]="
                        'cvsui-form-field-' +
                        column.searchable?.controlType +
                        ' col-span-' +
                        column.searchable?.colSpan
                    ">
                                    <mat-label>{{
                                        column.searchable?.label + '' | transloco
                                        }}</mat-label>
                                    <input matInput [attr.autocomplete]="column.colId" [name]="column.colId"
                                        [formControlName]="column.colId" [matDatepicker]="dtpicker"
                                        [controlErrorAnchor]="dtpickerErr" />
                                    <mat-datepicker-toggle matSuffix [for]="dtpicker">
                                        <mat-icon class="flex flex-col items-center justify-center m-0 icon-size-5"
                                            matDatepickerToggleIcon
                                            svgIcon="heroicons_outline:calendar-days"></mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #dtpicker></mat-datepicker>
                                    <mat-error>
                                        <ng-template #dtpickerErr="controlErrorAnchor" controlErrorAnchor></ng-template>
                                    </mat-error>
                                </mat-form-field>
                            </ng-template>
                            <ng-template [ngSwitchCase]="'date-range'">
                                <mat-form-field class="fuse-mat-dense min-w-50" [ngClass]="
                        'cvsui-form-field-' +
                        column.searchable?.controlType +
                        ' col-span-' +
                        column.searchable?.colSpan
                    ">
                                    <mat-label>{{
                                        column.searchable?.label + '' | transloco
                                        }}</mat-label>
                                    <mat-date-range-input [rangePicker]="rangePicker" [formGroupName]="column.colId">
                                        <input matStartDate formControlName="start" />
                                        <input matEndDate formControlName="end" />
                                    </mat-date-range-input>
                                    <mat-hint class="text-xs">DD-MMM-YYYY –
                                        DD-MMM-YYYY</mat-hint>
                                    <mat-datepicker-toggle matIconSuffix [for]="rangePicker">
                                        <mat-icon class="flex flex-col items-center justify-center m-0 icon-size-5"
                                            matDatepickerToggleIcon
                                            svgIcon="heroicons_outline:calendar-days"></mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-date-range-picker #rangePicker></mat-date-range-picker>
                                </mat-form-field>
                            </ng-template>
                            <ng-template [ngSwitchCase]="'select'">
                                <mat-form-field class="fuse-mat-dense min-w-50" [ngClass]="
                        'cvsui-form-field-' +
                        column.searchable?.controlType +
                        ' col-span-' +
                        column.searchable?.colSpan
                    ">
                                    <mat-label>{{
                                        column.searchable?.label + '' | transloco
                                        }}</mat-label>
                                    <mat-select [formControlName]="column.colId" [controlErrorAnchor]="selectErr">
                                        <mat-option *ngFor="let option of column.searchable?.options"
                                            [value]="option.value">
                                            {{ option.label | transloco }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>
                                        <ng-template #selectErr="controlErrorAnchor" controlErrorAnchor></ng-template>
                                    </mat-error>
                                </mat-form-field>
                            </ng-template>
                            <ng-template [ngSwitchCase]="'multi-select'">
                                <mat-form-field class="fuse-mat-dense min-w-50" [ngClass]="
                        'cvsui-form-field-' +
                        column.searchable?.controlType +
                        ' col-span-' +
                        column.searchable?.colSpan
                    ">
                                    <mat-label>{{
                                        column.searchable?.label + '' | transloco
                                        }}</mat-label>
                                    <mat-select [multiple]="true" [formControlName]="column.colId"
                                        [controlErrorAnchor]="selectMulErr">
                                        <mat-option *ngFor="let option of column.searchable?.options"
                                            [value]="option.value">
                                            {{ option.label | transloco }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>
                                        <ng-template #selectMulErr="controlErrorAnchor"
                                            controlErrorAnchor></ng-template>
                                    </mat-error>
                                </mat-form-field>
                            </ng-template>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
            <div class="w-full flex flex-row items-center justify-between pt-2">
                <div class="flex items-center gap-1 h-9">
                    <button mat-flat-button color="primary" type="submit">
                        {{ 'Search' | transloco }}
                    </button>
                    <button mat-flat-button type="submit" (click)="advSearchNgForm.reset()">
                        {{ 'Reset' | transloco }}
                    </button>
                </div>
                <div class="flex items-center h-9">
                    <mat-checkbox cvsMatCheckbox formControlName="matchAll">{{
                        'MatchAll' | transloco
                        }}</mat-checkbox>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<span
    class="hidden w-49p break-words bg-gray-100 text-gray-800 bg-pink-100 text-pink-800 bg-green-100 text-green-800 bg-red-100 text-red-800 decoration-dashed border-dashed drop-shadow-none drop-shadow-sm drop-shadow-md drop-shadow-xl drop-shadow-lg drop-shadow-2xl"></span>