import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeZh from '@angular/common/locales/zh';
export enum Language {
    Es = 'es',
    En = 'en',
    De = 'de',
    Zh = 'zh'
}

export const APP_LANGUAGES = [
    {
        id: Language.En,
        name: 'English',
        nativeName: 'English',
        culture: 'en-GB',
        cultures: ['en-GB', 'en-US'],
        flagCode: 'gb-ukm',
        default: true
    },
    {
        id: Language.De,
        name: 'German',
        nativeName: 'Deutsch',
        culture: 'de-DE',
        cultures: ['de-DE'],
        flagCode: 'de',
        default: false
    },
    {
        id: Language.Es,
        name: 'Spanish',
        nativeName: 'español',
        culture: 'es-ES',
        cultures: ['es-ES'],
        flagCode: 'es',
        default: false
    },
    {
        id: Language.Zh,
        name: 'Chinese',
        nativeName: '中国人',
        culture: 'zh-CN',
        cultures: ['zh-CN'],
        flagCode: 'cn',
        default: false
    }
];

export const ANGULAR_LOCALE_DATA: { [language in Language]: any } = {
    [Language.Es]: localeEs,
    [Language.En]: localeEn,
    [Language.De]: localeDe,
    [Language.Zh]: localeZh
};
