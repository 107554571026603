import { Injectable } from '@angular/core';
import { CvsSafeAny } from '@cvs/types';

@Injectable({
    providedIn: 'root'
})
export class DownloadService {
    private readonly saveData: CvsSafeAny;

    constructor() {
        this.saveData = (() => {
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.style.display = 'none';

            return (fileData, format, fileName, preview: boolean = false) => {
                const fileExt = fileName.slice((Math.max(0, fileName.lastIndexOf('.')) || Infinity) + 1).toLowerCase();
                let blob = null;

                if (format === 'blob' || format === 'data') {
                    blob = new Blob([fileData], { type: this.getBlobType(fileExt) });
                }
                if (format === 'object' || format === 'json') {
                    const json = JSON.stringify(fileData);
                    blob = new Blob([json], { type: this.getBlobType(fileExt) });
                }

                if (blob) {
                    if (typeof window.navigator !== 'undefined' && window.navigator['msSaveOrOpenBlob']) {
                        window.navigator['msSaveOrOpenBlob'](blob, fileName);
                    } else {
                        const url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = fileName;

                        if (preview && this.isViewAble(fileExt)) {
                            const win = window.open('', '_blank');
                            setTimeout(() => {
                                win.document.title = a?.download ? a?.download : 'File';
                            }, 10);
                            win.document.write(
                                '<iframe src="' +
                                    url +
                                    '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
                            );
                        } else {
                            a.click();
                        }

                        window.URL.revokeObjectURL(url);
                    }
                }
            };
        })();
    }

    /**
     * Invokes content download for a Blob with a file name.
     *
     * @param blob Content to download.
     * @param fileName Name of the resulting file.
     */
    public downloadBase64(encodedData: CvsSafeAny, fileName: string, preview: boolean = false): void {
        fetch(`data:application/octet-stream;base64,${encodedData}`)
            .then((response) => response.blob())
            .then((myBlob) => {
                this.downloadBlob(myBlob, fileName, preview);
            });
    }

    /**
     * Invokes content download for a Blob with a file name.
     *
     * @param blob Content to download.
     * @param fileName Name of the resulting file.
     */
    public downloadBlob(blob: Blob, fileName: string, preview: boolean = false): void {
        this.saveData(blob, 'blob', fileName, preview);
    }

    /**
     * Invokes content download for a data array with a file name.
     *
     * @param data Data to download.
     * @param fileName Name of the resulting file.
     */
    public downloadData(data: CvsSafeAny, fileName: string, preview: boolean = false): void {
        this.saveData(data, 'data', fileName, preview);
    }

    /**
     * Invokes content download for a JSON object with a file name.
     *
     * @param json JSON object to download.
     * @param fileName Name of the resulting file.
     */
    public downloadJSON(json: CvsSafeAny, fileName: string, preview: boolean = false): void {
        this.saveData(json, 'json', fileName, preview);
    }

    /**
     * Invokes the download of the file by its URL address.
     *
     * @param url Url address pointing to the file.
     * @param fileName Name of the file download.
     */
    public downloadUrl(url: string, fileName: string): void {
        if (url && fileName) {
            const link = document.createElement('a');

            link.style.display = 'none';
            link.download = fileName;
            link.href = url;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    private getBlobType(extension: string): string {
        let fileType = 'octet/stream';
        if (extension === 'pdf' || extension === 'csv') {
            fileType = `application/${extension}`;
        } else if (extension === 'jpeg' || extension === 'jpg' || extension === 'png') {
            fileType = `image/${extension}`;
        } else if (extension === 'txt') {
            fileType = 'text/plain';
        } else if (extension === 'ppt' || extension === 'pot' || extension === 'pps' || extension === 'ppa') {
            fileType = 'application/vnd.ms-powerpoint';
        } else if (extension === 'pptx') {
            fileType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        } else if (extension === 'doc' || extension === 'dot') {
            fileType = 'application/msword';
        } else if (extension === 'docx') {
            fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        } else if (extension === 'xls' || extension === 'xlt' || extension === 'xla') {
            fileType = 'application/vnd.ms-excel';
        } else if (extension === 'xlsx') {
            fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        }

        return fileType;
    }

    private isViewAble(extension: string): boolean {
        return (
            extension === 'pdf' ||
            extension === 'jpeg' ||
            extension === 'png' ||
            extension === 'jpg' ||
            extension === 'txt' ||
            extension === 'html' ||
            extension === 'vb' ||
            extension === 'sql' ||
            extension === 'py' ||
            extension === 'java' ||
            extension === 'js' ||
            extension === 'test' ||
            extension === 'json' ||
            extension === 'css'
        );
    }
}
