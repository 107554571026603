import {
    NullValidationHandler,
    OAuthErrorEvent,
    OAuthInfoEvent,
    OAuthService,
    OAuthSuccessEvent
} from 'angular-oauth2-oidc';
import camelize from 'camelize-ts';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, ReplaySubject, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoggedUser, LoggedUserService } from '@common/services/logged-user';
import { CookieService, LogService } from '@cvs/services';
import { SessionService } from '@cvs/services/session.service';
import { environment } from '@environment';

import { IAuthService } from './base-auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends IAuthService {
    private nullUserInfo = {
        avatar: null,
        isNull: true
    };
    private userInfo$: ReplaySubject<any | null> = new ReplaySubject(1);
    constructor(
        oauthService: OAuthService,
        private router: Router,
        cookie: CookieService,
        logService: LogService,
        private sessionService: SessionService,
        permissionsService: NgxPermissionsService,
        readonly loggedUser: LoggedUserService
    ) {
        ////console.log('Permissions.AuthService.constructor');
        super(cookie, logService, permissionsService, oauthService);
        this.configure();
        this.redirectOnCallback().subscribe();
    }

    /**
     * Get User Info
     */
    public getUserInfo(): Observable<any> {
        return this.userInfo$;
    }
    /**
     * Extract the username from the Keycloak generated access token (JWT)
     */
    public getUserName(): string {
        return this.getJwtAsObject().preferred_username;
    }
    /**
     * User Password to get token
     */
    public async loginWithPassword(loginInfo: {
        username: string;
        password: string;
        rememberMe?: boolean;
        redirectUrl?: string;
    }): Promise<object> {
        this.userInfo$.next(this.nullUserInfo);
        this.loggedUser.setUser(null);
        this.sessionService.destroySession();
        this.permissionsService.flushPermissions();
        const { username, password } = loginInfo;
        return this.oauthService.fetchTokenUsingPasswordFlow(username, password).then(async (tokenResponse) => {
            this.oauthService.userinfoEndpoint = environment.authConfig.userinfoEndpoint;
            let userResponse = await this.oauthService.loadUserProfile();
            userResponse = userResponse['info'] ? userResponse['info'] : userResponse;
            if (userResponse) {
                userResponse['isNull'] = false;
                this.loggedUser.setUser(userResponse as LoggedUser);
                this.sessionService.initializeSession();
                if (userResponse['permissions']) {
                    this.permissionsService.flushPermissions();
                    const per = userResponse['permissions'].map((p) => p.name.toUpperCase());
                    ////console.log('Permissions.AuthService.loginWithPassword.loadPermissions', per);

                    this.permissionsService.loadPermissions(per);
                }
            } else {
                this.loggedUser.setUser(null);
                this.sessionService.destroySession();
                this.permissionsService.flushPermissions();
            }
            this.userInfo$.next(userResponse ?? this.nullUserInfo);

            return camelize({
                ...userResponse,
                ...tokenResponse
            });
        });
    }
    /**
     * Redirect to keycloak login page by client id
     */
    public login(): void {
        this.loggedUser.setUser(null);
        this.userInfo$.next(this.nullUserInfo);
        this.sessionService.destroySession();
        this.permissionsService.flushPermissions();
        this.oauthService.initLoginFlow();
    }
    /**
     * Determines if the current user has a valid id token
     * Returns true if an IdToken exists and not expired within the session storage, false otherwise
     */
    public hasValidIdToken(): boolean {
        return this.oauthService.hasValidAccessToken();
    }
    /**
     * Logout and clear storage
     */
    public async logout(): Promise<any> {
        this.loggedUser.setUser(null);
        this.userInfo$.next(this.nullUserInfo);
        this.sessionService.destroySession();
        this.permissionsService.flushPermissions();
        return await this.oauthService.revokeTokenAndLogout(true);
    }
    public redirectOnCallback(): Observable<any> {
        return this.oauthService.events.pipe(
            tap((event) => {
                if (event instanceof OAuthErrorEvent) {
                    //console.error(event);
                    if (
                        event.type === 'token_validation_error' ||
                        event.type === 'user_profile_load_error' ||
                        event.type === 'token_refresh_error'
                    ) {
                        this.logout()
                            .then(() => this.router.navigate(['/sign-in']))
                            .catch(() => this.router.navigate(['/sign-in']));
                    }
                } else if (event instanceof OAuthSuccessEvent) {
                    if (event.type === 'token_received') {
                        // this.navigateToHomepage();
                    }
                } else if (event instanceof OAuthInfoEvent) {
                    if (event.type === 'token_expires') {
                        this.refreshToken();
                        //console.info('token expires soon: ' + this.getTokenIdExpirationDate().toISOString());
                    }
                    if (event.type === 'logout') {
                        //console.warn('logout');
                    }
                } else {
                    //console.warn(event);
                }
            })
        );
    }
    /**
     * Extract the expiration date of id token
     */
    public getTokenIdExpirationDate(): Date {
        return new Date(this.oauthService.getAccessTokenExpiration());
    }
    /**
     * Refresh token request
     */
    public refreshToken() {
        return this.oauthService.refreshToken();
    }

    private configure(): void {
        this.oauthService.tokenValidationHandler = new NullValidationHandler();
        this.listenWindowsStorage();
        this.oauthService.loadDiscoveryDocumentAndTryLogin().then((hasReceivedTokens) => {
            // this would have stored all the tokens needed
            if (hasReceivedTokens) {
                ////console.log('Permissions.AuthService.configure.hasReceivedTokens');
                return Promise.resolve();
            } else {
                // may want to check if you were previously authenticated
                if (
                    this.oauthService.hasValidAccessToken() &&
                    this.oauthService.getAccessTokenExpiration() >
                        new Date().getTime() - new Date('1970-01-01').getTime()
                ) {
                    this.oauthService.userinfoEndpoint = environment.authConfig.userinfoEndpoint;
                    this.oauthService
                        .loadUserProfile()
                        .then((r) => (r['info'] ? r['info'] : r))
                        .then((userResponse) => {
                            if (userResponse) {
                                userResponse['isNull'] = false;
                                this.sessionService.initializeSession();
                                this.loggedUser.setUser(userResponse as LoggedUser);
                                if (userResponse['permissions']) {
                                    this.permissionsService.flushPermissions();
                                    const per = userResponse['permissions'].map((p) => p.name.toUpperCase());
                                    ////console.log('Permissions.AuthService.configure.loadPermissions', per);
                                    this.permissionsService.loadPermissions(per);
                                }
                                this.userInfo$.next(userResponse);
                            } else {
                                this.loggedUser.setUser(null);
                                this.sessionService.destroySession();
                                this.permissionsService.flushPermissions();
                                this.userInfo$.next(this.nullUserInfo);
                            }
                        });
                    return Promise.resolve();
                } else {
                    // to safe guard this from progressing through the calling promise,
                    // resolve it when it directed to the sign up page
                    return new Promise((resolve) => {
                        this.oauthService.logOut(true);
                        window.addEventListener('unload', () => {
                            resolve(true);
                        });
                    });
                }
            }
        });
    }
    private listenWindowsStorage(): void {
        if (typeof window !== 'undefined' && window.addEventListener) {
            window.addEventListener('storage', (event: StorageEvent) => {
                // The `key` is `null` if the event was caused by `.clear()`
                if (event.key !== 'access_token' && event.key !== null) {
                    return;
                }
                if (!this.hasValidIdToken()) {
                    this.router.navigateByUrl('/sign-out');
                }
            });
        }
    }
    private getJwtAsObject(): any {
        const accessToken: string = this.oauthService.getAccessToken();
        const tokens: string[] = accessToken.split('.');
        return JSON.parse(atob(tokens[1]));
    }
}
