import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MessagesComponent } from 'src/app/layout/common/messages/messages.component';
import { MatSharedModule } from 'src/app/shared';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
    declarations: [MessagesComponent],
    imports: [RouterModule, OverlayModule, PortalModule, MatSharedModule, SharedModule],
    exports: [MessagesComponent]
})
export class MessagesModule {}
