import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { errorTailorImports } from '@ngneat/error-tailor';
import { TranslocoModule } from '@ngneat/transloco';

import {
    AutocompleteComponent, CvsFormAutocompleteSuffixDirective
} from './auto-complete.component';
import { OptionsScrollDirective, TabDirective } from './auto-complete.directive';

@NgModule({
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        TranslocoModule,
        MatProgressSpinnerModule,
        MatIconModule,
        FormsModule,
        errorTailorImports,
        MatButtonModule
    ],
    exports: [AutocompleteComponent, CvsFormAutocompleteSuffixDirective],
    declarations: [AutocompleteComponent, CvsFormAutocompleteSuffixDirective, TabDirective, OptionsScrollDirective]
})
export class CvsAutocompleteModule {}
