import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SettingsComponent } from 'src/app/layout/common/settings/settings.component';
import { CvsSharedModule, MatSharedModule } from 'src/app/shared';

@NgModule({
    declarations: [SettingsComponent],
    imports: [CommonModule, RouterModule, MatSharedModule, CvsSharedModule],
    exports: [SettingsComponent]
})
export class SettingsModule {}
