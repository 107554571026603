import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'cvs-alert-dialog',
    templateUrl: './alert-dialog.component.html'
})
export class CvsAlertDialogComponent {
    title?: string;
    message?: string;
    closeButton?: string = 'Ok';
    icon?: {
        show?: boolean;
        name?: string;
        color?: 'primary' | 'accent' | 'warn' | 'basic' | 'info' | 'success' | 'warning' | 'error';
    } = {
        show: false
    };
    constructor(private _dialogRef: MatDialogRef<CvsAlertDialogComponent>) {}

    close(): void {
        this._dialogRef.close();
    }
}
