import { noAuthGuardCanMatch } from 'src/app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'src/app/layout/layout.component';

export const noAuthLayout = [
    {
        path: '',
        canMatch: [noAuthGuardCanMatch],
        component: LayoutComponent,
        data: {
            layout: 'no-auth'
        },
        children: [
            {
                path: 'report-incident',
                loadChildren: () =>
                    import('src/app/modules/incident-request/details/details.module').then(
                        (m) => m.IncidentRequestDetailsModule
                    )
            }
        ]
    }
];
