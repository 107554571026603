import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

const JSON_TYPE = 'application/json;charset=UTF-8';
const JSON_EXTENSION = '.json';

const CSV_TYPE = 'text/csv;charset=UTF-8';
const CSV_EXTENSION = '.csv';

@Injectable({
    providedIn: 'root'
})
export class ExcelService {
    public exportJsonAsCsvFile(filename: string, data: any[]) {
        if (!data || data.length <= 1) throw new Error('No data on the table.');
        if (!filename.includes(CSV_EXTENSION)) {
            filename = filename + CSV_EXTENSION;
        }
        // handle null or undefined values here
        const replacer = (key, value) => value ?? '';
        const header = Object.keys(data[1]);
        const rowItems = data.map((row) =>
            header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(',')
        );

        // join header and body, and break into separate lines
        const csv = [...rowItems].join('\r\n');
        this.saveFile(csv, filename, CSV_TYPE);
    }
    public exportJsonAsJsonFile(filename: string, data: any[]) {
        if (!data || data.length == 0) throw new Error('No data on the table.');
        if (!filename.includes(JSON_EXTENSION)) {
            filename = filename + JSON_EXTENSION;
        }
        this.saveFile(JSON.stringify(data), filename, JSON_TYPE);
    }
    public exportJsonAsExcelFile(filename: string, sheetName: string, data: any[]) {
        if (!data || data.length <= 1) throw new Error('No data on the table.');

        const parseData = XLSX.utils.json_to_sheet(data, {
            skipHeader: true
        });

        sheetName = this.formatSheetName(sheetName);
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(sheetName);
        worksheet.properties.defaultColWidth = 125;
        this.formatBody(worksheet, parseData);

        this.formatHeader(worksheet);

        this.formatWorksheetLength(worksheet);

        this.saveAsExcelFile(workbook, filename);
    }

    private formatSheetName(sheetName: string): string {
        if (sheetName.includes('/')) sheetName = sheetName.replace('/', '_').replace('/', '_');
        return sheetName;
    }

    private formatHeader(worksheet: any): void {
        const headerLen = 1;
        const headerRows = worksheet.getRows(1, headerLen);
        for (const h in headerRows) {
            headerRows[h].eachCell((cell) => {
                cell.width = 124;
                cell.font = { name: 'Verdana', size: 10, bold: true, color: { argb: '000000' } };
                cell.alignment = { vertical: 'top', horizontal: 'left', wrapText: true };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFF00' }
                };
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thick' },
                    right: { style: 'thin' }
                };
            });
        }
    }

    private formatBody(worksheet: any, bodyData: any): void {
        for (const cVal in bodyData) {
            if (!cVal.includes('!')) {
                const cell = worksheet.getCell(cVal);
                cell.value = bodyData[cVal]['v'];

                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
                cell.font = { name: 'Verdana', size: 9, color: { argb: '000000' } };
                cell.alignment = { vertical: 'top', horizontal: 'left', wrapText: true };
            }
        }
    }

    private saveAsExcelFile(workbook: Workbook, filename: string): void {
        if (!filename.includes(EXCEL_EXTENSION)) {
            filename = filename + EXCEL_EXTENSION;
        }
        workbook.xlsx.writeBuffer().then((data) => {
            this.saveFile(data, filename, EXCEL_TYPE);
        });
    }
    private saveFile(data: any, filename: string, type: string) {
        const blob = new Blob([data], { type });
        FileSaver.saveAs(blob, filename);
    }
    private formatWorksheetLength(ws: any): void {
        // Format length of columns
        ws.columns.forEach((column) => {
            let maxLength = 0;
            column['eachCell']({ includeEmpty: true }, (cell) => {
                if (!cell.isMerged) {
                    const columnLength = cell.value ? cell.value.toString().length - 10 : 10;
                    if (columnLength > maxLength) {
                        maxLength = columnLength;
                    }
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength;
        });
    }
}
