import { map, Subject } from 'rxjs';

import { ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation } from '@angular/core';

import { LayoutService } from '../../layout.service';

@Component({
    selector: 'no-auth-layout',
    templateUrl: './no-auth.component.html',
    encapsulation: ViewEncapsulation.None
})
export class NoAuthLayoutComponent implements OnDestroy {
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    readonly currentTitle$ = this.layoutService.title$.pipe(
        map((defaultTitle) => {
            this.cdr.markForCheck();
            if (!this.pageTitle) {
                return defaultTitle;
            }
            return this.pageTitle;
        })
    );

    public pageTitle: string;

    constructor(
        private readonly layoutService: LayoutService,
        private cdr: ChangeDetectorRef
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On destroy
     */
    public ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
