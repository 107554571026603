/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/directive-selector */
import { CommonModule } from '@angular/common';
import { Component, Directive, Input, NgModule, TemplateRef } from '@angular/core';

@Component({
    selector: 'f-header',
    template: '<ng-content></ng-content>'
})
export class HeaderComponent {}

@Component({
    selector: 'f-footer',
    template: '<ng-content></ng-content>'
})
export class FooterComponent {}

@Directive({
    selector: '[fuseTemplate]'
})
export class FuseNgTemplateDirective {
    @Input() type: string;

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('fuseTemplate') name: string;

    constructor(public template: TemplateRef<any>) {}

    getType(): string {
        return this.name;
    }
}

@NgModule({
    imports: [CommonModule],
    exports: [HeaderComponent, FooterComponent, FuseNgTemplateDirective],
    declarations: [HeaderComponent, FooterComponent, FuseNgTemplateDirective]
})
export class FuseNgTemplateModule {}
