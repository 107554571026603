import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { API_ENDPOINTS } from 'src/app/api-endpoints.constants';
import { NotificationUser } from 'src/app/layout/common/notifications/notifications.types';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@cvs/services';
import { PagedResponseModel } from '@cvs/types';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    /**
     * Constructor
     */
    private readonly apiEndpoint: string;

    private _notificationsUser: ReplaySubject<PagedResponseModel<NotificationUser>> = new ReplaySubject<
        PagedResponseModel<NotificationUser>
    >(1);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        public environmentService: EnvironmentService
    ) {
        this.apiEndpoint = environmentService.getApiUrl(
            API_ENDPOINTS.incidentApi.baseUrlKey,
            API_ENDPOINTS.incidentApi.notifications.get
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
    get notifications$(): Observable<PagedResponseModel<NotificationUser>> {
        return this._notificationsUser.asObservable();
    }

    /**
     * Delete the notification
     *
     * @param id
     */
    public delete(id: number): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap((notifications) =>
                this._httpClient
                    .delete<boolean>(this.apiEndpoint + '/' + id)
                    .pipe(
                        map((isDeleted: boolean) => {
                            // Find the index of the deleted notification
                            const index = notifications.items.findIndex((item) => item.id === id);

                            // Delete the notification
                            notifications.items.splice(index, 1);

                            // Update the notifications
                            this._notificationsUser.next(notifications);

                            // Return the deleted status
                            return isDeleted;
                        })
                    )
            )
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all notifications
     */
    public getAll(): Observable<PagedResponseModel<NotificationUser>> {
        return this._httpClient
            .get<PagedResponseModel<NotificationUser>>(this.apiEndpoint, {
                params: {
                    page: 1,
                    pageSize: 15,
                    orderBy: 'isRead asc,createdDateTime desc',
                    filter: 'isDeleted != true,notificationType=Alert'
                }
            })
            .pipe(
                tap((notifications) => {
                    this._notificationsUser.next(notifications);
                })
            );
    }

    /**
     * Mark all notifications as read
     */
    public markAllAsRead(): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap((notifications) =>
                this._httpClient.get<boolean>(`${this.apiEndpoint}/mark-all-as-read`).pipe(
                    map((isUpdated: boolean) => {
                        // Go through all notifications and set them as read
                        notifications.items.forEach((notification, index) => {
                            notifications[index].read = true;
                        });

                        // Update the notifications
                        this._notificationsUser.next(notifications);

                        // Return the updated status
                        return isUpdated;
                    })
                )
            )
        );
    }

    /**
     * Update the notification
     *
     * @param id
     * @param notification
     */
    public update(id: number, notification: NotificationUser): Observable<NotificationUser> {
        const endpoint = this.environmentService.getApiUrl(
            API_ENDPOINTS.incidentApi.baseUrlKey,
            API_ENDPOINTS.incidentApi.notifications.updateUserNotification
        );
        return this.notifications$.pipe(
            take(1),
            switchMap((notifications) =>
                this._httpClient
                    .patch<NotificationUser>(endpoint + '/' + id, notification)
                    .pipe(
                        map((updatedNotification: NotificationUser) => {
                            // Find the index of the updated notification
                            const index = notifications.items.findIndex((item) => item.id === id);

                            // Update the notification
                            notifications[index] = updatedNotification;

                            // Update the notifications
                            this._notificationsUser.next(notifications);

                            // Return the updated notification
                            return updatedNotification;
                        })
                    )
            )
        );
    }

    public updateAllUserNotificationsReadStatus(id: number, notification: NotificationUser): Observable<NotificationUser> {
        const endpoint = this.environmentService.getApiUrl(
            API_ENDPOINTS.incidentApi.baseUrlKey,
            API_ENDPOINTS.incidentApi.notifications.updateUserNotifications
        );
        return this._httpClient.patch<NotificationUser>(endpoint + '/' + id,
            notification);
    }

    public updateUserNotificationsReadStatus(id: number, notification: NotificationUser): Observable<NotificationUser> {
        const endpoint = this.environmentService.getApiUrl(
            API_ENDPOINTS.incidentApi.baseUrlKey,
            API_ENDPOINTS.incidentApi.notifications.updateUserNotification
        );
        return this._httpClient.patch<NotificationUser>(endpoint + '/' + id,
            notification);
    }
}
