/* eslint-disable no-console */
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CvsSafeAny } from '@cvs/types';

export function toBoolean(value: boolean | string): boolean {
    return coerceBooleanProperty(value);
}
export function InputBoolean(): CvsSafeAny {
    return propDecoratorFactory('InputBoolean', toBoolean);
}
function propDecoratorFactory<T, D>(
    name: string,
    fallback: (v: T) => D
): (target: CvsSafeAny, propName: string) => void {
    function propDecorator(
        target: CvsSafeAny,
        propName: string,
        originalDescriptor?: TypedPropertyDescriptor<CvsSafeAny>
    ): CvsSafeAny {
        const privatePropName = `$$__centvisPropDecorator__${propName}`;

        if (Object.prototype.hasOwnProperty.call(target, privatePropName)) {
            console.warn(`The prop "${privatePropName}" is already exist, it will be overridden by ${name} decorator.`);
        }

        Object.defineProperty(target, privatePropName, {
            configurable: true,
            writable: true
        });

        return {
            get(): string {
                return originalDescriptor && originalDescriptor.get
                    ? originalDescriptor.get.bind(this)()
                    : this[privatePropName];
            },
            set(value: T): void {
                if (originalDescriptor && originalDescriptor.set) {
                    originalDescriptor.set.bind(this)(fallback(value));
                }
                this[privatePropName] = fallback(value);
            }
        };
    }

    return propDecorator;
}
